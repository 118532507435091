import { CategoryBlock } from "@/components/page/blocks/category-block";
import { DateBlock } from "@/components/page/blocks/date-block";
import { DatetimeBlock } from "@/components/page/blocks/datetime-block";
import { NumberBlock } from "@/components/page/blocks/number-block";
import { TextBlock } from "@/components/page/blocks/text-block";
import type { BasePageBlockPropsU } from "@/components/page/page-generics";
import { BooleanBlock } from "./blocks/boolean-block";
import { DocumentBlock } from "./blocks/document-block";

export function BaseBlockRenderer(props: BasePageBlockPropsU) {
	switch (props.blockType) {
		case "boolean":
			return <BooleanBlock {...props} />;
		case "category":
			return <CategoryBlock {...props} />;
		case "date":
			return <DateBlock {...props} />;
		case "datetime":
			return <DatetimeBlock {...props} />;
		case "document":
			return <DocumentBlock {...props} />;
		case "number":
			return <NumberBlock {...props} />;
		case "text":
			return <TextBlock {...props} />;
		default: {
			const exhaustiveCheck: never = props;
			return exhaustiveCheck;
		}
	}
}
