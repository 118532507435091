import {
	type AppState,
	useAppContext,
} from "@/contexts/app-context/app-context";
import type {
	DirectoryNode,
	FileType,
} from "@/contexts/app-context/tree-handlers";
import { TabContext } from "@/contexts/tabs-context/context";
import { createTabRouter } from "@/contexts/tabs-context/tab-routes";
import { FolderState } from "@/contexts/tabs-context/tab-states/folder-state";
import { SearchState } from "@/contexts/tabs-context/tab-states/search-state";
import {
	type SearchResult,
	UploadState,
} from "@/contexts/tabs-context/tab-states/upload-state";
import { WebSearchState } from "@/contexts/tabs-context/tab-states/web-search-state";
import { type TabPathObject, pathObjectToString } from "@/paths";
import type { FileId, UploadId } from "@api/schemas";
import {
	File,
	FilePdf,
	Folder,
	Globe,
	type Icon,
	MagnifyingGlass,
	Rss,
	Table,
} from "@phosphor-icons/react";
import type { RouterState } from "@remix-run/router";
import {
	Actions,
	DockLocation,
	type IJsonModel,
	Model,
	TabNode,
	TabSetNode,
} from "flexlayout-react";
import { makeAutoObservable, runInAction } from "mobx";
import { useContext } from "react";
import { RouterProvider, type createMemoryRouter } from "react-router-dom";
import { ulid } from "ulid";

// Tabs are either a file type, search, manage-tables, or manage-feeds
export type TabType =
	| FileType
	| "search"
	| "manage_tables"
	| "manage_feeds"
	| "websearch";

export type TabTypeStateMap = {
	folder: FolderState;
	upload: UploadState;
	search: SearchState;
	// TODO(John): add states for feeds and tables
	feed_channel: {
		type: "feed_channel";
		feedChannelNode: DirectoryNode<"feed_channel">;
	};
	feed_item: { type: "feed_item"; feedItemNode: DirectoryNode<"feed_item"> };
	table: { type: "table"; tableNode: DirectoryNode<"table"> };
	manage_tables: { type: "manage_tables" };
	manage_feeds: { type: "manage_feeds" };
	websearch: WebSearchState;
	page: { type: "page"; pageNode: DirectoryNode<"page"> };
};

export const TabIconMap: Record<TabType, Icon> = {
	folder: Folder,
	upload: FilePdf,
	search: MagnifyingGlass,
	feed_channel: Rss,
	feed_item: Rss,
	table: Table,
	manage_tables: Table,
	manage_feeds: Rss,
	websearch: Globe,
	page: File,
};

export class Tab {
	tabStore: TabStore;

	id: string;

	#router: ReturnType<typeof createMemoryRouter>;
	provider: React.ReactNode;
	routerState: RouterState;

	constructor(props: {
		tabsState: TabStore;
		id: string;
		initialPathObject: TabPathObject;
	}) {
		this.id = props.id;
		this.#router = createTabRouter(props.initialPathObject);
		this.tabStore = props.tabsState;
		this.routerState = this.#router.state;

		// Initialize a listener for the router that updates the pathname
		this.#router.subscribe((state) => {
			runInAction(() => {
				this.routerState = state;
			});
		});

		this.provider = (
			<TabProvider tab={this}>
				<RouterProvider router={this.#router} />
			</TabProvider>
		);

		makeAutoObservable(this);
	}

	get state(): TabTypeStateMap[TabType] | null {
		const match = this.routerState.matches.at(-1);
		if (!match) {
			return null;
		}

		// First, we capture all non-file tabs
		switch (match.pathname) {
			case "/search":
				return new SearchState(this.tabStore.appState);
			case "/manage-tables":
				return {
					type: "manage_tables",
				};
			case "/manage-feeds":
				return {
					type: "manage_feeds",
				};
			case "/web-search":
				return new WebSearchState(this.tabStore.appState);
			default:
				break;
		}

		// Now, we handle file tabs
		const fileId = match.params.fileId;

		if (!fileId) {
			return new FolderState(this.tabStore.appState, null);
		}

		const fileNode = this.tabStore.appState.fileNodeTree.nodeMap.get(
			fileId as FileId,
		);

		if (!fileNode) {
			console.error("File node not found", fileId);
			return null;
		}

		switch (fileNode.file.file_type) {
			case "folder": {
				return new FolderState(
					this.tabStore.appState,
					fileNode as DirectoryNode<"folder">,
				);
			}

			case "upload": {
				const searchResult = this.routerState.location.state as SearchResult;
				return new UploadState(
					this.tabStore.appState,
					fileId as UploadId,
					searchResult,
				);
			}

			case "feed_channel": {
				return {
					type: "feed_channel",
					feedChannelNode: fileNode as DirectoryNode<"feed_channel">,
				};
			}

			case "feed_item": {
				return {
					type: "feed_item",
					feedItemNode: fileNode as DirectoryNode<"feed_item">,
				};
			}

			case "table": {
				return {
					type: "table",
					tableNode: fileNode as DirectoryNode<"table">,
				};
			}

			case "page": {
				return {
					type: "page",
					pageNode: fileNode as DirectoryNode<"page">,
				};
			}

			default: {
				const _exhaustiveCheck: never = fileNode.file;
				return _exhaustiveCheck;
			}
		}
	}

	get display(): {
		icon: Icon;
		label: string;
	} {
		if (!this.state) {
			console.error("No state found for tab", this.id);
			return {
				icon: TabIconMap.folder,
				label: "Files",
			};
		}
		const type = this.state.type;
		let label: string;
		switch (type) {
			case "search": {
				const query = this.state.searchConfig.query;
				label = query ? `${query}` : "Search";
				break;
			}

			case "folder": {
				const folderName = this.state.folderNode?.file.file_name;
				label = folderName ?? "Files";
				break;
			}

			case "upload": {
				const uploadName = this.state.upload.file_name;
				label = uploadName ?? "Upload";
				break;
			}

			case "feed_channel": {
				const feedChannelName = this.state.feedChannelNode.file.file_name;
				label = feedChannelName ?? "Feed Channel";
				break;
			}

			case "feed_item": {
				const feedItemName = this.state.feedItemNode.file.file_name;
				label = feedItemName ?? "Feed Item";
				break;
			}

			case "table": {
				label = "Tables";
				break;
			}

			case "manage_tables": {
				label = "Manage Tables";
				break;
			}

			case "manage_feeds": {
				label = "Manage Feeds";
				break;
			}

			case "websearch": {
				const query = this.state.webSearchConfig.query;
				label = query ? `${query}` : "Web Search";
				break;
			}

			case "page": {
				const pageName = this.state.pageNode.file.file_name;
				label = pageName ?? "Page";
				break;
			}

			default: {
				const _exhaustiveCheck: never = type;
				label = _exhaustiveCheck;
			}
		}

		return {
			icon: TabIconMap[type],
			label,
		};
	}

	navigate(pathObject: TabPathObject) {
		const path = pathObjectToString(pathObject);
		this.#router.navigate(path, {
			state: "searchResult" in pathObject ? pathObject.searchResult : undefined,
		});
	}
}

export const TabProvider = ({
	children,
	tab,
}: {
	children: React.ReactNode;
	tab: Tab;
}) => {
	return <TabContext.Provider value={tab}>{children}</TabContext.Provider>;
};

export const useTab = () => {
	const context = useContext(TabContext);
	if (!context) {
		throw new Error("useTab must be used within an TabProvider");
	}
	return context;
};

export class TabStore {
	appState: AppState;

	model: Model;
	tabs: Map<string, Tab> = new Map();

	constructor(appState: AppState) {
		makeAutoObservable(
			this,
			{},
			{
				autoBind: true,
			},
		);
		this.appState = appState;

		this.tabs = new Map<string, Tab>();
		const initialTabId = this.getNewTabId();
		this.tabs.set(
			initialTabId,
			new Tab({
				tabsState: this,
				id: initialTabId,
				initialPathObject: {
					path: "search",
				},
			}),
		);

		const initialTabSetId = this.getNextTabSetId();

		const initalModelState: IJsonModel = {
			global: {
				tabSetEnableMaximize: false,
				tabSetEnableDeleteWhenEmpty: true,
				tabEnableRename: false,
				tabDragSpeed: 0,
			},
			borders: [],
			layout: {
				type: "row",
				weight: 100,
				children: [
					{
						type: "tabset",
						name: initialTabSetId,
						weight: 50,
						enableDrag: true,
						enableDrop: true,
						active: true,
						children: [
							{
								type: "tab",
								name: initialTabId,
								component: "button",
							},
						],
					},
				],
			},
		};
		this.model = Model.fromJson(initalModelState);
	}

	get activeTab(): Tab | null {
		const activeTabSet = this.model.getActiveTabset();
		if (!activeTabSet) return null;

		const activeTab = activeTabSet.getSelectedNode() as TabNode | undefined;
		if (!activeTab) return null;

		const activeTabId = activeTab.getName();
		const tab = this.tabs.get(activeTabId);
		if (!tab) {
			console.error("Tab not found", activeTabId);
			return null;
		}
		return tab;
	}

	/**
	 * Navigates the current active tab. Creates a new tab if there's no current
	 * active tab.
	 */
	navigate(pathObject: TabPathObject) {
		let activeTab = this.activeTab;
		if (!activeTab) {
			activeTab = this.createTabInActiveTabSet(pathObject);
		}
		activeTab.navigate(pathObject);
	}

	createTabInActiveTabSet = (initialPathObject: TabPathObject): Tab => {
		const activeTabSet = this.model.getActiveTabset();
		if (!activeTabSet) throw new Error("No active tab set found");

		const activeTabSetId = activeTabSet.getId();
		const tabId = this.getNewTabId();
		const newTab = new Tab({
			tabsState: this,
			id: tabId,
			initialPathObject,
		});
		this.tabs.set(tabId, newTab);
		const location = DockLocation.CENTER;
		const index = 0;
		this.model.doAction(
			Actions.addNode(
				{
					type: "tab",
					name: tabId,
					component: "button",
				},
				activeTabSetId,
				location,
				index,
			),
		);
		return newTab;
	};

	//Actions.ADD_NODE
	addTabToPane = (
		// paneId: string,
		tabId: string,
	) => {
		const tab = new Tab({
			tabsState: this,
			id: tabId,
			initialPathObject: {
				path: "search",
			},
		});
		this.tabs.set(tabId, tab);
	};

	// Actions.MOVE_NODE

	// Actions.DELETE_TAB
	removeTab = (tabId: string) => {
		this.tabs.delete(tabId);
	};

	// Actions.DELETE_TABSET
	removeTabsOfTabSetNode = (tabSetId: string) => {
		const tabSet = this.model.getNodeById(tabSetId);
		if (!tabSet || !(tabSet instanceof TabSetNode)) return;
		const tabs = tabSet.getChildren();
		for (const tab of tabs) {
			if (tab instanceof TabNode) {
				this.removeTab(tab.getName());
			}
		}
	};
	// Actions.SELECT_TAB
	// Actions.SET_ACTIVE_TABSET
	// Actions.CLOSE_WINDOW
	// Actions.CREATE_WINDOW
	// Utility Methods
	getNextTabSetId() {
		return `container_${ulid()}`;
	}

	getNewTabId() {
		return `tab_${ulid()}`;
	}

	tabFactory = (node: TabNode) => {
		const component = node.getComponent();
		const name = node.getName();
		if (component === "button") {
			const tab = this.tabs.get(name);
			if (!tab) return null;
			return (
				<div key={name} className="bg-white">
					{tab.provider}
				</div>
			);
		}
		return null;
	};
}

export const useTabStore = () => {
	const appContext = useAppContext();
	if (!appContext) {
		throw new Error("useTabsContext must be used within an AppProvider");
	}

	return appContext.tabStore;
};
