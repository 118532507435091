import {
	ColumnTypeIcons,
	ColumnTypeLabels,
} from "@/components/table/column-type-indicators";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { usePageContext } from "@/contexts/page-context/page-context";
import {
	type BlockMetadata,
	ColumnType,
	type MaterializedColumn,
} from "@api/schemas";
import { CaretUpDown } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { toast } from "sonner";

export const PageCreationHeader = observer(() => {
	const pageContext = usePageContext();
	const [blockName, setBlockName] = useState("");
	const [blockType, setBlockType] = useState<ColumnType>(ColumnType.text);
	const [blockDescription, setBlockDescription] = useState("");

	const [proxiedColumn, setProxiedColumn] = useState<MaterializedColumn | null>(
		null,
	);

	const [open, setOpen] = useState(false);

	return (
		<div className="flex items-center gap-2 p-0.5">
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<button
						type="button"
						className="rounded-full border border-neutral-200 px-2 py-1 font-semibold text-neutral-500 text-xs"
					>
						+ Add block
					</button>
				</PopoverTrigger>
				<PopoverContent align="start" className="w-64 p-0">
					<section className="px-2 py-1">
						<Label>Add a block</Label>
					</section>

					<Separator />

					<section className="flex flex-col px-2 pt-2">
						<Label>Name</Label>
						<Input
							className="mt-2 h-8"
							value={blockName}
							onChange={(e) => setBlockName(e.target.value)}
						/>
					</section>

					<section className="flex flex-col px-2 pt-2">
						<Label>Type</Label>
						<DropdownMenu>
							<DropdownMenuTrigger className="mt-2 flex w-full items-center rounded-md border px-2 py-1">
								{blockType ? (
									<div className="flex grow items-center">
										{(() => {
											const IconComponent = ColumnTypeIcons[blockType];
											return <IconComponent className="mr-2" />;
										})()}
										<span className="text-sm">
											{ColumnTypeLabels[blockType]}
										</span>
									</div>
								) : (
									<SelectValue placeholder="Select..." />
								)}
								<CaretUpDown />
							</DropdownMenuTrigger>
							<DropdownMenuContent className="max-w-72">
								<DropdownMenuItem
									onSelect={() => setBlockType(ColumnType.text)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.text]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Text</h2>
											<span className="text-neutral-500">
												A simple text field that you directly input data into.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setBlockType(ColumnType.document)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.document]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Document</h2>
											<span className="text-neutral-500">
												A reference to an upload or article in your workspace.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setBlockType(ColumnType.category)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.category]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Select</h2>
											<span className="text-neutral-500">
												An option you can select from a list. Helpful for
												categorizing data.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setBlockType(ColumnType.boolean)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.boolean]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Checkbox</h2>
											<span className="text-neutral-500">
												Indicate if an item matches a condition.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setBlockType(ColumnType.date)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.date]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Date</h2>
											<span className="text-neutral-500">
												A specific date. Useful for deadlines or events.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setBlockType(ColumnType.datetime)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.datetime]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Date + time</h2>
											<span className="text-neutral-500">
												A specific date and time. Useful for deadlines or
												events.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setBlockType(ColumnType.number)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.number]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Number</h2>
											<span className="text-neutral-500">
												A number. Useful for quantities or measurements.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</section>

					<section className="px-2 pt-3 pb-2">
						<Button
							className="w-full"
							onClick={() => {
								let blockMetadata: BlockMetadata;
								if (blockType === ColumnType.proxy) {
									if (!proxiedColumn) {
										toast.error("Please select a rollup column.");
										return;
									}
									blockMetadata = {
										// @ts-expect-error
										column_type: blockType,
										proxied_column_ids: [proxiedColumn.column_id],
										column_name: blockName,
										column_description: blockDescription,
									};
								} else if (blockType === ColumnType.category) {
									blockMetadata = {
										column_type: blockType,
										categories: {},
										column_name: blockName,
										column_description: blockDescription,
									};
								} else {
									blockMetadata = {
										// @ts-expect-error
										column_type: blockType,
										column_name: blockName,
										column_description: blockDescription,
									};
								}
								if (!blockMetadata.column_name) {
									toast.error("Please enter a label.");
									return;
								}
								pageContext.createBlock({
									previous_block_id: null,
									blockMetadata,
								});
								setBlockName("");
								setBlockDescription("");
								setBlockType(ColumnType.text);
								setProxiedColumn(null);
								setOpen(false);
							}}
						>
							Add block
						</Button>
					</section>
				</PopoverContent>
			</Popover>
		</div>
	);
});
