import type { BasePageBlockProps } from "@/components/page/page-generics";
import { usePageContext } from "@/contexts/page-context/page-context";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const NumberBlock = observer(
	({ blockId, blockValue }: BasePageBlockProps<"number">) => {
		const pageContext = usePageContext();

		const currentValue = blockValue?.cell_value;

		const [newValue, setNewValue] = useState<string>(
			currentValue?.toString() ?? "",
		);
		useEffect(() => {
			setNewValue(currentValue?.toString() ?? "");
		}, [currentValue]);

		const onSubmit = () => {
			// if the new value is empty, clear the cell
			if (newValue.trim() === "") {
				pageContext.updateBlockValue({
					blockId,
					blockValue: {
						cell_value: null,
						cell_value_type: "number",
					},
				});
				return;
			}
			const newNumber = Number.parseFloat(newValue);
			if (newNumber !== currentValue && !Number.isNaN(newNumber)) {
				pageContext.updateBlockValue({
					blockId,
					blockValue: {
						cell_value: newNumber,
						cell_value_type: "number",
					},
				});
			}
		};

		return (
			<input
				value={newValue}
				onChange={(e) => {
					setNewValue(e.target.value);
				}}
				disabled={!pageContext.editable}
				className="w-full text-sm outline-none placeholder:text-neutral-400 focus:outline-none"
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						onSubmit();
					}
				}}
				placeholder="0"
				onBlur={onSubmit}
			/>
		);
	},
);
