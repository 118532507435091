import { Button } from "@/components/ui/button";
import {
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { UploadCoverImage } from "@/components/upload-cover-image";
import { useAppContext } from "@/contexts/app-context/app-context";
import { inferUploadMetadata } from "@api/fastAPI";
import type { Upload, UploadId } from "@api/schemas";
import { PageResolution, UploadType } from "@api/schemas";
import { FileMagnifyingGlass, Plus, Spinner, X } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { toast } from "sonner";

interface InputWithHistoryProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	originalValue: string;
	setValue: (value: string) => void;
}

const InputWithHistory = observer(
	({
		originalValue,
		value,
		setValue,
		className,
		...props
	}: InputWithHistoryProps) => {
		const showOriginalValue = originalValue !== value;
		const inputRef = useRef<HTMLInputElement>(null);

		return (
			<div className={className}>
				<Input
					{...props}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					className={clsx(
						"rounded-t-md",
						showOriginalValue && "rounded-b-none",
					)}
					ref={inputRef}
				/>
				{showOriginalValue && (
					<div className="line-clamp-1 flex items-center justify-between gap-1 rounded-b-lg bg-neutral-100 py-1 pr-1 pl-2 text-xs ">
						<div className="text-neutral-500">
							Previously {originalValue ? `"${originalValue}"` : "empty"}
						</div>
						<button
							type="button"
							className="shrink-0 rounded px-1 py-0.5 text-neutral-700 text-xs hover:bg-neutral-200"
							onClick={() => {
								setValue(originalValue ?? "");
								inputRef.current?.focus();
							}}
						>
							Restore
						</button>
					</div>
				)}
			</div>
		);
	},
);

const _MetadataEditDialogContent = observer(
	({
		upload,
	}: {
		upload: Upload;
	}) => {
		const appContext = useAppContext();
		const [title, setTitle] = useState(upload.upload_title ?? "");
		const [subtitle, setSubtitle] = useState(upload.upload_subtitle ?? "");
		const [publisher, setPublisher] = useState(upload.upload_publisher ?? "");
		const [yearPublished, setYearPublished] = useState(
			upload.upload_year_published ?? null,
		);
		const [authors, setAuthors] = useState(upload.upload_authors ?? []);
		const [uploadType, setUploadType] = useState<UploadType | null>(
			upload.upload_type,
		);

		const [inferringMetadata, setInferringMetadata] = useState(false);

		const addAuthor: React.MouseEventHandler<HTMLButtonElement> = (e) => {
			e.preventDefault();
			setAuthors([...authors, ""]);
		};

		const removeAuthor = (index: number) => {
			setAuthors(authors.filter((_, i) => i !== index));
		};

		const updateAuthor = (index: number, value: string) => {
			const updatedAuthors = [...authors];
			updatedAuthors[index] = value;
			setAuthors(updatedAuthors);
		};

		const onSubmit = () => {
			if (inferringMetadata) {
				toast.error(
					"Please wait for metadata to be inferred before applying changes.",
				);
				return;
			}
			appContext.updateUploadMetadata({
				uploadId: upload.upload_id,
				newMetadata: {
					file_name: title,
					upload_subtitle: subtitle,
					upload_publisher: publisher,
					upload_year_published: yearPublished,
					upload_authors: upload.upload_authors,
					upload_type: uploadType,
				},
			});
		};

		return (
			<DialogContent
				// Is there a cleaner way to prevent the dialog from affecting the tree?
				onClick={(e) => {
					e.stopPropagation();
				}}
				onSelect={(e) => {
					e.stopPropagation();
				}}
				onKeyDown={(e) => {
					e.stopPropagation();
				}}
				className="max-w-screen-lg"
			>
				<DialogHeader>
					<DialogTitle>Edit upload metadata</DialogTitle>
					<DialogDescription>
						Add metadata to help categorize and organize your uploads.
					</DialogDescription>
				</DialogHeader>
				<div className="flex items-start">
					<section className="flex h-full max-w-80 flex-col items-center justify-center gap-4 rounded-xl border bg-neutral-100 p-4">
						<UploadCoverImage
							resolution={PageResolution.low}
							upload_id={upload.upload_id}
							upload_status={upload.upload_status}
							className={(uploadStatus) =>
								clsx("h-72 max-w-72", uploadStatus === "ready" && "shadow-xl")
							}
						/>
						<h2 className="text-center text-neutral-700 text-sm">
							{upload.file_name}
						</h2>
						<div>
							<Tooltip>
								<TooltipTrigger asChild>
									<Button
										onClick={() => {
											setInferringMetadata(true);
											inferUploadMetadata({ upload_id: upload.upload_id })
												.then((res) => {
													setInferringMetadata(false);
													const inferred = res.data.extracted_metadata;
													inferred.type && setUploadType(inferred.type);
													inferred.title && setTitle(inferred.title);
													inferred.subtitle && setSubtitle(inferred.subtitle);
													inferred.publisher &&
														setPublisher(inferred.publisher);
													inferred.year_published &&
														setYearPublished(inferred.year_published);
													inferred.authors && setAuthors(inferred.authors);
												})
												.catch(() => {
													setInferringMetadata(false);
												});
										}}
										variant="outline"
										className="flex items-center gap-1"
										disabled={inferringMetadata}
									>
										{inferringMetadata ? (
											<Spinner className="animate-spin text-base" />
										) : (
											<FileMagnifyingGlass
												weight="duotone"
												className="text-base"
											/>
										)}
										{inferringMetadata ? "Inferring..." : "Infer metadata"}
									</Button>
								</TooltipTrigger>
								<TooltipContent>
									Use AI to automatically fill in metadata by reading the
									document.
								</TooltipContent>
							</Tooltip>
						</div>
					</section>
					<section className="grow">
						<form
							className={clsx(
								"relative grid gap-4 py-4 text-neutral-700",
								inferringMetadata && "pointer-events-none animate-pulse",
							)}
						>
							{inferringMetadata && (
								<div className="absolute inset-0 flex items-center justify-center bg-white/50">
									<Label className="text-right text-neutral-900">
										Inferring metadata...
									</Label>
								</div>
							)}
							<div className="grid grid-cols-4 items-center gap-4">
								<Label htmlFor="name" className="text-right text-neutral-900">
									Document type
								</Label>
								<Select
									disabled={inferringMetadata}
									value={uploadType ?? undefined}
									onValueChange={(value) => {
										if (value === UploadType.article) {
											setUploadType(UploadType.article);
										} else if (value === UploadType.book) {
											setUploadType(UploadType.book);
										} else {
											setUploadType(null);
										}
									}}
								>
									<SelectTrigger className="w-36">
										<SelectValue placeholder="Select..." />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value={UploadType.article}>Article</SelectItem>
										<SelectItem value={UploadType.book}>Book</SelectItem>
									</SelectContent>
								</Select>
							</div>

							<div className="grid grid-cols-4 items-center gap-4">
								<Label htmlFor="name" className="text-right text-neutral-900">
									Title
								</Label>
								<InputWithHistory
									disabled={inferringMetadata}
									value={title}
									className="col-span-3"
									originalValue={upload.upload_title ?? ""}
									setValue={setTitle}
								/>
							</div>
							<div className="grid grid-cols-4 items-center gap-4">
								<Label htmlFor="name" className="text-right text-neutral-900">
									Subtitle
								</Label>
								<InputWithHistory
									disabled={inferringMetadata}
									value={subtitle}
									className="col-span-3"
									originalValue={upload.upload_subtitle ?? ""}
									setValue={setSubtitle}
								/>
							</div>

							<div className="mt-6 grid grid-cols-4 items-center gap-4">
								<Label
									htmlFor="publisher"
									className="text-right text-neutral-900"
								>
									Publisher
								</Label>
								<InputWithHistory
									disabled={inferringMetadata}
									value={publisher}
									className="col-span-3"
									originalValue={upload.upload_publisher ?? ""}
									setValue={setPublisher}
								/>
							</div>
							<div className="grid grid-cols-4 items-center gap-4">
								<Label
									htmlFor="yearPublished"
									className="text-right text-neutral-900"
								>
									Year Published
								</Label>
								<InputWithHistory
									disabled={inferringMetadata}
									value={yearPublished?.toString() ?? ""}
									className="col-span-3"
									originalValue={upload.upload_year_published?.toString() ?? ""}
									type="number"
									setValue={(value) => setYearPublished(Number.parseInt(value))}
								/>
							</div>

							<div className="mt-6 grid grid-cols-4 items-start gap-4">
								<Label
									htmlFor="authors"
									className="pt-2 text-right text-neutral-900"
								>
									Authors
								</Label>
								<div className="col-span-3 space-y-2">
									{authors.map((author, index) => (
										// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
										<div key={index} className="flex items-center gap-2">
											<Input
												value={author}
												placeholder="Author name"
												onChange={(e) => updateAuthor(index, e.target.value)}
												disabled={inferringMetadata}
											/>
											<button
												type="button"
												className="rounded-md p-3 text-neutral-600 hover:bg-neutral-100"
												onClick={() => removeAuthor(index)}
												disabled={inferringMetadata}
											>
												<X />
											</button>
										</div>
									))}
									<Button
										variant="outline"
										className="col-span-3 flex items-center gap-2"
										onClick={addAuthor}
										disabled={inferringMetadata}
									>
										<Plus />
										Add author
									</Button>
								</div>
							</div>
						</form>
						<div className="flex justify-end">
							<DialogClose
								className="mt-4 rounded-md bg-black px-8 py-2 text-white"
								disabled={inferringMetadata}
								onClick={onSubmit}
								asChild
							>
								<Button variant="default">Apply changes</Button>
							</DialogClose>
						</div>
					</section>
				</div>
			</DialogContent>
		);
	},
);

export const MetadataEditDialogContent = observer(
	({
		uploadId,
	}: {
		uploadId: UploadId;
	}) => {
		const appContext = useAppContext();
		const upload = appContext.getUploadById(uploadId);

		if (upload === null) {
			return null;
		}

		return <_MetadataEditDialogContent upload={upload} />;
	},
);
