import {
	MessagesRoot,
	ThreadViewer,
} from "@/components/layout/right-sidebar/messages";
import type { AppState } from "@/contexts/app-context/app-context";
import { createSyncedAction } from "@/contexts/synced-actions";
import { getNewEventId, getNewMessageId } from "@/id-generators";
import { pathObjectToString } from "@/paths";
import { syncFillTableActionRoute, syncUserEventRoute } from "@api/fastAPI";
import type {
	Event,
	EventType,
	Message,
	MessageId,
	SentMessageEvent,
	SessionAssistantId,
	SessionId,
	TableId,
	UserId,
} from "@api/schemas";
import { createMemoryRouter } from "react-router-dom";

type SessionEvent = Extract<Event, { data: { session_id: SessionId } }>;

export type AssistantEventMap = {
	[K in SessionEvent as K["type"]]: Omit<K, "data"> & {
		data: Omit<K["data"], "session_id"> & { session_id: SessionAssistantId };
	};
};

export type AssistantEvent = AssistantEventMap[keyof AssistantEventMap];

export type EventTypeMap = {
	[T in Event as T["type"]]: T;
};

type EventTypeAndData = {
	[K in keyof EventTypeMap]: {
		eventType: K;
		data: EventTypeMap[K]["data"];
	};
}[keyof EventTypeMap];

function createNewEvent<T extends EventType>({
	eventType,
	userId,
	data,
}: {
	eventType: T;
	userId: UserId;
	data: EventTypeMap[T]["data"];
}): EventTypeMap[T] {
	return {
		event_id: getNewEventId(),
		user_id: userId,
		type: eventType,
		created_at: new Date().toISOString(),
		data,
	} as EventTypeMap[T];
}

export function createNewMessage({
	content,
	sessionId,
	parentMessageId,
}: {
	content: string;
	sessionId: SessionId;
	parentMessageId: MessageId | null;
}): Message {
	return {
		message_id: getNewMessageId(),
		created_at: new Date().toISOString(),
		session_id: sessionId,
		content,
		parent_message_id: parentMessageId,
	};
}

export function handleEventLocally<T extends EventType>(
	this: AppState,
	event: EventTypeMap[T],
) {
	this.session?.events.set(event.event_id, event);
	switch (event.type) {
		case "sent_message": {
			this.createMessageLocally(event.data.message);
			break;
		}
		case "created_assistant_session": {
			this.createSessionAssistantLocally(event.data.assistant_session);
			break;
		}
		case "created_user_session": {
			this.createSessionUserLocally(event.data.session_user);
			break;
		}
		case "ended_session": {
			this.session?.activeAssistantSessionStatuses.delete(
				event.data.session_id as SessionAssistantId,
			);
			break;
		}
	}
}

export const handleEvent = createSyncedAction<
	AppState,
	EventTypeAndData,
	Event,
	void
>({
	async local({ eventType, data }) {
		const event = createNewEvent({
			eventType,
			userId: this.userId,
			data,
		});
		this.handleEventLocally(event);
		return event;
	},
	async remote(_, localResult) {
		await syncUserEventRoute({
			event: localResult,
		});
	},
	rollback(_args, localResult) {
		this.session?.events.delete(localResult.event_id);
		switch (localResult.type) {
			case "sent_message": {
				this.session?.messages.delete(localResult.data.message.message_id);
				break;
			}
			case "created_assistant_session": {
				this.session?.sessionsAssistant.delete(
					localResult.data.assistant_session.session_assistant_id,
				);
				break;
			}
		}
	},
	onRemoteSuccess() {},
});

export const fillTable = createSyncedAction<
	AppState,
	{
		tableId: TableId;
		tableName: string;
	},
	SentMessageEvent,
	void
>({
	async local({ tableId, tableName }) {
		const event = createNewEvent({
			eventType: "sent_message",
			userId: this.userId,
			data: {
				session_id: this.sessionUserId,
				message: createNewMessage({
					content: `<p>Fill out or update the existing cells in <a href='/table/${tableId}'>${tableName}</a> based on the column descriptions.</p>`,
					sessionId: this.sessionUserId,
					parentMessageId: null,
				}),
			},
		});
		this.handleEventLocally(event);
		return event;
	},
	async remote(_, localResult) {
		await syncFillTableActionRoute({
			message_event: localResult,
		});
	},
	rollback(_args, localResult) {
		this.session?.events.delete(localResult.event_id);
		this.session?.messages.delete(localResult.data.message.message_id);
	},
	onRemoteSuccess() {},
});

export const createMessageRouter = (initialMessageId: MessageId | null) => {
	return createMemoryRouter(
		[
			{ path: "/message", element: <MessagesRoot /> },
			{ path: "/message/:messageId", element: <ThreadViewer /> },
		],
		{
			initialEntries: [
				pathObjectToString({
					path: "message",
					messageId: initialMessageId,
				}),
			],
		},
	);
};
