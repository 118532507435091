import { UploadPreview } from "@/components/document-selectors/upload-preview";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { UploadCoverImage } from "@/components/upload-cover-image";
import { useAppContext } from "@/contexts/app-context/app-context";
import type {
	AnyDirectoryNode,
	DirectoryNode,
} from "@/contexts/app-context/tree-handlers";
import {
	MultiDocumentSelectorProvider,
	useMultiDocumentSelectorContext,
} from "@/contexts/document-selector-contexts";
import { formatUploadTitle } from "@/lib/utils";
import {
	type FeedItemId,
	type Folder,
	PageResolution,
	type Upload,
	type UploadId,
} from "@api/schemas";
import { FolderOpen, FolderSimple } from "@phosphor-icons/react";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useMemo, useState } from "react";
import { type NodeApi, type NodeRendererProps, Tree } from "react-arborist";
import useResizeObserver from "use-resize-observer";

export const FolderNode = observer(
	({
		node,
		folder,
	}: { node: NodeApi<DirectoryNode<"folder">>; folder: Folder }) => {
		const documentSelectorContext = useMultiDocumentSelectorContext();
		const descendantIds = node.data.descendants.map(
			(descendant) => descendant.id,
		);
		const numDescendantsSelected = descendantIds.filter((descendantId) =>
			documentSelectorContext.selectedUploadIds.has(descendantId as UploadId),
		).length;

		return (
			<div
				className={clsx(
					"group flex h-full w-full items-center rounded-md px-2 py-1",
				)}
			>
				<div className="flex grow items-center gap-1.5">
					<Checkbox
						checked={numDescendantsSelected === descendantIds.length}
						onCheckedChange={(checked) => {
							if (checked) {
								for (const descendant of descendantIds) {
									documentSelectorContext.selectedUploadIds.add(
										descendant as UploadId,
									);
								}
							} else {
								runInAction(() => {
									for (const descendant of descendantIds) {
										documentSelectorContext.selectedUploadIds.delete(
											descendant as UploadId,
										);
									}
								});
							}
						}}
						className={clsx(
							numDescendantsSelected === descendantIds.length
								? "opacity-100"
								: "opacity-0 group-hover:opacity-100",
						)}
					/>
					<button
						type="button"
						onClick={() => {
							node.toggle();
						}}
						className="flex w-4 items-center justify-center"
					>
						{node.isOpen ? (
							<FolderOpen
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						) : (
							<FolderSimple
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						)}
					</button>
					<span className="text-sm">{folder.file_name}</span>
				</div>
			</div>
		);
	},
);

export const UploadNode = observer(
	({
		node,
		upload,
	}: { node: NodeApi<DirectoryNode<"upload">>; upload: Upload }) => {
		const documentSelectorContext = useMultiDocumentSelectorContext();
		const isSelected = documentSelectorContext.selectedUploadIds.has(
			upload.upload_id,
		);

		const onSelect = () => {
			runInAction(() => {
				documentSelectorContext.previewedUploadId = upload.upload_id;
			});
		};

		return (
			<div
				className={clsx(
					"group flex h-full w-full items-center rounded-md px-2 py-1",
					node.isSelected ? "bg-blue-100" : "hover:bg-blue-50",
				)}
				key={upload.upload_id}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<div className="flex min-w-0 grow items-center gap-1.5 truncate pr-2 text-left">
					<Checkbox
						checked={isSelected}
						onCheckedChange={(checked) => {
							if (checked) {
								runInAction(() => {
									documentSelectorContext.selectedUploadIds.add(
										upload.upload_id,
									);
								});
							} else {
								runInAction(() => {
									documentSelectorContext.selectedUploadIds.delete(
										upload.upload_id,
									);
								});
							}
						}}
						className={clsx(
							isSelected ? "opacity-100" : "opacity-0 group-hover:opacity-100",
						)}
					/>
					<div className="flex w-4 shrink-0 justify-center">
						<UploadCoverImage
							className={(uploadStatus) =>
								clsx(
									"h-4 max-w-6 rounded-xs",
									uploadStatus === "ready" && "shadow-lg",
								)
							}
							upload_id={upload.upload_id}
							upload_status={upload.upload_status ?? "pending"}
							resolution={PageResolution.thumbnail}
						/>
					</div>

					<div className="min-w-0 truncate">
						<h2 className="flex min-w-0 items-center gap-1 truncate text-sm leading-4">
							{formatUploadTitle({
								title: upload.upload_title,
								subtitle: upload.upload_subtitle,
								filename: upload.file_name,
							})}
						</h2>
					</div>
				</div>
			</div>
		);
	},
);

const LibraryTreeNode = observer(
	({ node, style, dragHandle }: NodeRendererProps<AnyDirectoryNode>) => {
		return (
			<div
				style={style}
				ref={dragHandle}
				className="mr-2 ml-2 flex h-8 items-center pt-0.5"
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						node.toggle();
					}
				}}
			>
				{(new Array(node.level).fill(0) as number[]).map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="mr-2 ml-0.5 h-8 w-9 shrink-0 border-neutral-200 border-r-2"
					/>
				))}
				{node.data.file.file_type === "folder" ? (
					<FolderNode
						node={node as NodeApi<DirectoryNode<"folder">>}
						folder={node.data.file}
					/>
				) : (
					<UploadNode
						node={node as NodeApi<DirectoryNode<"upload">>}
						upload={node.data.file as Upload}
					/>
				)}
			</div>
		);
	},
);

interface MultiSelectProps {
	selectedUploadIds: Set<UploadId>;
	selectedFeedItemIds: Set<FeedItemId>;
	onChange: React.Dispatch<UploadId[]>;
	popoverTrigger: React.ReactNode;
	className?: string;
}

const _UploadSelector: React.FC<MultiSelectProps> = observer(
	({ selectedUploadIds, onChange, popoverTrigger, className, ...props }) => {
		const appContext = useAppContext();
		const documentSelectorContext = useMultiDocumentSelectorContext();
		const [open, setOpen] = React.useState(false);
		const { ref, height } = useResizeObserver<HTMLDivElement>();

		const [uploadsQuery, setUploadsQuery] = useState("");

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const matchedUploadIds = useMemo(() => {
			return appContext.searchUploadsByMetadata(uploadsQuery);
		}, [uploadsQuery]);

		return (
			<Dialog open={open} onOpenChange={setOpen} {...props}>
				<DialogTrigger asChild>
					<Button
						variant="ghost"
						aria-expanded={open}
						onClick={() => setOpen(!open)}
						className={"w-full p-0"}
					>
						{popoverTrigger}
					</Button>
				</DialogTrigger>
				<DialogContent
					className="flex max-w-screen-md flex-col gap-0 overflow-hidden p-0"
					style={{
						height: "var(--radix-popover-content-available-height)",
					}}
				>
					<section className="flex h-[50vh] min-h-0 w-full">
						<div className="flex max-h-full min-h-0 w-2/3 flex-col">
							<div className="w-full px-2 pt-2">
								<Input
									placeholder="Search uploads..."
									value={uploadsQuery}
									onChange={(e) => {
										setUploadsQuery(e.target.value);
									}}
								/>
							</div>
							<div className="max-h-full min-h-0 grow" ref={ref}>
								{appContext.workspace ? (
									<Tree
										data={appContext.fileNodeTree.rootNodes}
										openByDefault={false}
										width={"100%"}
										height={height}
										rowHeight={32}
										paddingTop={0}
										paddingBottom={0}
										padding={8}
										indent={0}
										disableEdit
										disableDrag
										disableDrop
										searchTerm={uploadsQuery}
										searchMatch={(node) => {
											if (node.data.file.file_type === "upload") {
												return matchedUploadIds.has(node.data.file.upload_id);
											}
											return false;
										}}
									>
										{LibraryTreeNode}
									</Tree>
								) : (
									<div className="mt-2 flex flex-col gap-1 p-2">
										<div className="flex items-center gap-2">
											<Skeleton className="h-6 w-6" />
											<Skeleton className="h-6 w-72" />
										</div>
										<div className="flex items-center gap-2">
											<Skeleton className="h-6 w-6" />
											<Skeleton className="h-6 w-72" />
										</div>
										<div className="flex items-center gap-2">
											<Skeleton className="h-6 w-6" />
											<Skeleton className="h-6 w-72" />
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="flex w-1/3">
							<UploadPreview upload={documentSelectorContext.previewedUpload} />
						</div>
					</section>
					<section>
						<div className="flex items-center justify-end gap-2 border-t p-2">
							<Button
								variant="outline"
								onClick={() => {
									setOpen(false);
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									onChange([...documentSelectorContext.selectedUploadIds]);
									setOpen(false);
								}}
							>
								Select
							</Button>
						</div>
					</section>
				</DialogContent>
			</Dialog>
		);
	},
);

export const MultipleDocumentsSelector = (props: MultiSelectProps) => {
	return (
		<MultiDocumentSelectorProvider
			selectedUploadIds={props.selectedUploadIds}
			selectedFeedItemIds={props.selectedFeedItemIds}
		>
			<_UploadSelector {...props} />
		</MultiDocumentSelectorProvider>
	);
};
