import { ToggleRightSidebarButton } from "@/components/layout/toggle-right-sidebar-button";
import { ToggleSidebarButton } from "@/components/layout/toggle-sidebar-button";
import { TopBarSearch } from "@/components/layout/top-bar-search";
import { useTabStore } from "@/contexts/tabs-context/tabs-context";

export const TopBar = () => {
	const tabStore = useTabStore();

	return (
		<div className="flex w-full shrink-0 items-center justify-between gap-2 border-b bg-white px-5 py-2">
			<div className="flex-1">
				<button
					type="button"
					className="flex items-center gap-2"
					onClick={() => {
						tabStore.navigate({ path: "search" });
					}}
				>
					<img src={"/gr_logo.png"} alt="Logo" className="h-6 w-6 rounded" />
					<span className="font-semibold text-neutral-950 text-sm">
						Greenmantle
					</span>
				</button>
			</div>

			<div
				className="flex basis-0 items-center gap-2"
				style={{
					flex: 2,
				}}
			>
				{/* <button
					type="button"
					onClick={() => {
						console.log(toJS(tabsContext.appState));
					}}
					className="text-nowrap text-neutral-500 text-xs"
				>
					<span>Log AppState</span>
				</button> */}
				{/* <button
					type="button"
					onClick={() => {
						navigate(-1 as To);
					}}
					className="disabled:opacity-50"
					// disabled={tabsContext.activeTab?.router.window?.history.length === 1}
				>
					<ArrowLeft />
				</button>
				<button
					type="button"
					onClick={() => {
						navigate(1 as To);
					}}
					className="disabled:opacity-50"
					// disabled={tabsContext.activeTab?.router.window?.history.length === 1}
				>
					<ArrowRight />
				</button> */}
				<TopBarSearch />
			</div>
			<div className="flex flex-1 items-center justify-end gap-2">
				<ToggleSidebarButton />
				<ToggleRightSidebarButton />
			</div>
		</div>
	);
};
