import {
	type AppState,
	useAppContext,
} from "@/contexts/app-context/app-context";
import {
	addBlockCategoryAction,
	createBlockAction,
	deleteBlockAction,
	updateBlockMetadataAction,
	updateBlockValueAction,
} from "@/contexts/page-context/page-handlers";
import { getPageLatestVersionRoute } from "@api/fastAPI";
import type {
	BlockId,
	MaterializedBlock,
	MaterializedPage,
	PageId,
	PageTransaction,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { useContext } from "react";

class Page {
	pageId: PageId;
	blocks: Map<BlockId, MaterializedBlock>;
	latestTransaction: PageTransaction;
	bodyBlockId: BlockId;

	constructor(props: {
		pageId: PageId;
		blocks: Map<BlockId, MaterializedBlock>;
		latestTransaction: PageTransaction;
		bodyBlockId: BlockId;
	}) {
		this.pageId = props.pageId;
		this.blocks = props.blocks;
		this.latestTransaction = props.latestTransaction;
		this.bodyBlockId = props.bodyBlockId;
		makeAutoObservable(this);
	}
}

export class PageState {
	pageId: PageId;
	page: Page;
	// ws
	isInitialized = false;
	// reconnectTimer
	// wsConnected
	// reconnectAttempts

	editable: boolean;

	constructor(props: {
		pageId: PageId;
		page: MaterializedPage;
		editable: boolean;
	}) {
		this.pageId = props.pageId;
		this.page = new Page({
			pageId: props.pageId,
			blocks: new Map(
				Object.entries(props.page.blocks).map(([key, value]) => [
					key as BlockId,
					value,
				]),
			),
			latestTransaction: props.page.latest_transaction,
			bodyBlockId: props.page.body_block_id,
		});
		this.editable = props.editable;
		makeAutoObservable(this);
	}

	static fromResponse(props: {
		pageId: PageId;
		pageData: MaterializedPage;
	}) {
		return new PageState({
			pageId: props.pageId,
			page: props.pageData,
			editable: true,
		});
	}

	createBlock = createBlockAction.bind(this);
	deleteBlock = deleteBlockAction.bind(this);
	updateBlockValue = updateBlockValueAction.bind(this);
	addBlockCategory = addBlockCategoryAction.bind(this);
	updateBlockMetadata = updateBlockMetadataAction.bind(this);
}

export class PagesState {
	appState: AppState;
	pages: Map<PageId, PageState> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	getPage(pageId: PageId): PageState | null {
		if (!this.pages.has(pageId)) {
			this.fetchPage(pageId);
			return null;
		}
		return this.pages.get(pageId) as PageState;
	}

	async fetchPage(pageId: PageId) {
		const pageData = await getPageLatestVersionRoute(pageId);
		const pageState = PageState.fromResponse({
			pageId,
			pageData: pageData.data,
		});
		this.pages.set(pageId, pageState);
		// pageState.init({
		// 	isReconnect: false,
		// 	getToken: this.appState.getToken,
		// });
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const PageContext = createContext<PageState>(null as any);

export const usePageContext = () => {
	const context = useContext(PageContext);
	if (!context) {
		throw new Error("PageContext must be used within a PageProvider");
	}
	return context;
};

export const usePagesContext = () => {
	const appContext = useAppContext();
	if (!appContext) {
		throw new Error("usePagesContext must be used within an AppProvider");
	}

	return appContext.pagesState;
};
