import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/app-context/app-context";
import { observer } from "mobx-react-lite";

export const ToggleRightSidebarButton = observer(() => {
	const appContext = useAppContext();

	return (
		<Tooltip>
			<TooltipTrigger asChild>
				<button
					type="button"
					className="flex shrink-0 items-center justify-center rounded-lg p-1 text-lg text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900"
					onClick={() => appContext.rightSidebarState.toggleRightSidebar()}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 256 256"
					>
						<title>Toggle sidebar icon</title>

						<rect width="256" height="256" fill="none" />
						<rect
							x="48"
							y="48"
							width="160"
							height="160"
							rx="8"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="16"
						/>
						<rect x="128" y="48" width="80" height="160" fill="currentColor" />
						<line
							x1="128"
							y1="48"
							x2="128"
							y2="208"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="16"
						/>
					</svg>
				</button>
			</TooltipTrigger>
			<TooltipContent side="right">Toggle right sidebar</TooltipContent>
		</Tooltip>
	);
});
