import { Outline } from "@/components/pdf/outline/outline";
import { observer } from "mobx-react-lite";

export const PDFSidebar = observer(() => {
	return (
		<div className="flex h-full w-52 shrink-0 flex-col gap-3 overflow-auto border-neutral-200 border-r bg-neutral-50 p-2">
			<Outline />

			{/** Sort by page? */}
			{/* <div className="flex flex-col space-y-2">
				{Array.from(pdfViewerContext.rawHighlights.entries()).map(
					([key, highlight]) => {
						return (
							<button
								type="button"
								key={key}
								className="line-clamp-4 border border-neutral-200 p-2 text-start text-xs"
							>
								{highlight.textToHighlight.textStart}
							</button>
						);
					},
				)}
			</div> */}
		</div>
	);
});
