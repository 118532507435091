/**
 * We have many paths in our app.
 *
 * Paths are just strings. Paths can be inserted into any editor as links, and
 * the proper action should be taken upon clicking those links.
 *
 * We need to be able to parse a path to know what to do with it.
 * We also, in our code, want to only have to deal with those typed, parsed paths.
 */

import type { SearchResult } from "@/contexts/tabs-context/tab-states/upload-state";
import type {
	FileId,
	MessageId,
	SessionAssistantId,
	UploadId,
} from "@api/schemas";

export type FilePathObject = {
	path: "file";
	fileId: FileId | null;
};

export type TabPathObject =
	| {
			path: "search";
	  }
	| {
			path: "manage-tables";
	  }
	| {
			path: "manage-feeds";
	  }
	| {
			path: "web-search";
	  }
	| FilePathObject
	| {
			path: "file";
			uploadId: UploadId;
			searchResult?: SearchResult;
	  };

export type MessagePathObject = {
	path: "message";
	messageId: MessageId | null;
};

export type AssistantSessionPathObject = {
	path: "assistant-session";
	sessionAssistantId: SessionAssistantId;
};

export type SidebarPathObject = MessagePathObject | AssistantSessionPathObject;

export type PathObject = TabPathObject | SidebarPathObject;

export function pathObjectToString(pathObject: PathObject): string {
	switch (pathObject.path) {
		case "file":
			if ("fileId" in pathObject) {
				if (pathObject.fileId) {
					return `/file/${pathObject.fileId}`;
				}
				return "/file";
			}
			// Note: we don't store the searchResult data in the URL, so this is
			// a lossy conversion.
			return `/file/${pathObject.uploadId}`;
		case "message":
			if (pathObject.messageId) {
				return `/${pathObject.path}/${pathObject.messageId}`;
			}
			return "/message";
		case "assistant-session":
			return `/${pathObject.path}/${pathObject.sessionAssistantId}`;
		case "search":
		case "manage-tables":
		case "manage-feeds":
		case "web-search":
			return `/${pathObject.path}`;
		default: {
			const _exhaustiveCheck: never = pathObject;
			return _exhaustiveCheck;
		}
	}
}

export function pathStringToObject(path: string): PathObject | Error {
	let url: URL;
	try {
		url = new URL(path, window.location.origin);
	} catch (e) {
		return new Error(`Invalid path: ${path}`);
	}
	const pathParts = url.pathname.split("/");

	if (pathParts.length < 2) {
		return new Error(`Invalid path: ${path}`);
	}

	const mainPath = pathParts[1];

	switch (mainPath) {
		case "file":
			if (pathParts.length === 2) {
				return { path: "file", fileId: null };
			}
			if (pathParts.length === 3) {
				const id = pathParts[2];
				return { path: "file", fileId: id as FileId };
			}
			break;

		case "message":
			if (pathParts.length === 3) {
				return {
					path: "message",
					messageId: pathParts[2] as MessageId,
				};
			}
			break;

		case "assistant-session":
			if (pathParts.length === 3) {
				return {
					path: "assistant-session",
					sessionAssistantId: pathParts[2] as SessionAssistantId,
				};
			}
			break;

		case "search":
		case "manage-tables":
		case "manage-feeds":
		case "web-search":
			return { path: mainPath };
	}

	return new Error(`Invalid or incomplete path: ${path}`);
}
