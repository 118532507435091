import { IS_DEV } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { webSearchHandler } from "@api/fastAPI";
import type { WebSearchResponse } from "@api/schemas";
import * as Sentry from "@sentry/react";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "sonner";

interface WebSearchConfig {
	query: string;
}

export function webSearchConfigKey(config: WebSearchConfig) {
	return JSON.stringify({
		query: config.query,
	});
}

class WebSearchResult {
	webSearchConfig: WebSearchConfig;
	webSearchResults: WebSearchResponse | null = null;
	webSearchLoading = true;

	constructor(searchConfig: WebSearchConfig) {
		this.webSearchConfig = searchConfig;
		makeAutoObservable(this);
	}

	fromResponse(response: WebSearchResponse) {
		this.webSearchResults = response;
		this.webSearchLoading = false;
	}
}

export class WebSearchStore {
	appState: AppState;
	webSearchHistory: Map<string, WebSearchResult> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	getWebSearchResult(
		webSearchConfig: WebSearchConfig | null,
	): WebSearchResult | null {
		if (!webSearchConfig) {
			return null;
		}
		const key = webSearchConfigKey(webSearchConfig);
		if (!this.webSearchHistory.has(key)) {
			// Initialize search result w/ loading
			const searchState = new WebSearchResult(webSearchConfig);
			this.webSearchHistory.set(key, searchState);

			// Fetch and initialize the table
			this.fetchWebSearchResult(webSearchConfig);
			return null;
		}
		return this.webSearchHistory.get(key) as WebSearchResult;
	}

	async fetchWebSearchResult(webSearchConfig: WebSearchConfig) {
		webSearchHandler({
			query: webSearchConfig.query,
		})
			.then((res) => {
				runInAction(() => {
					this.getWebSearchResult(webSearchConfig)?.fromResponse(res.data);
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to fetch web search results");
				}
			});
	}

	get uniqueSearchHistory() {
		const seen = new Set<string>();
		return Array.from(this.webSearchHistory.values())
			.map((webSearchResult) => webSearchResult.webSearchConfig)
			.filter((config) => {
				// If we haven't seen this configuration before, add it to the set and keep it
				if (!seen.has(webSearchConfigKey(config))) {
					seen.add(webSearchConfigKey(config));
					return true;
				}

				// If we've seen this configuration before, filter it out
				return false;
			})
			.reverse(); // Reverse to show most recent searches first
	}
}

export class WebSearchState {
	appState: AppState;
	type = "websearch" as const;

	webSearchConfig: WebSearchConfig = { query: "" };
	publishedWebSearchConfig: WebSearchConfig | null = null;
	showCommandList = false;
	searchInputElement: HTMLInputElement | null = null;

	setShowCommandList(show: boolean) {
		this.showCommandList = show;
	}

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	handleSearch() {
		this.publishedWebSearchConfig = { ...this.webSearchConfig };
	}
}
