import clsx from "clsx";
import makeCancellable from "make-cancellable-promise";
import { observer } from "mobx-react-lite";
import type { PDFDocumentProxy } from "pdfjs-dist";
import type { RefProxy } from "pdfjs-dist/types/src/display/api.js";
import { useEffect, useMemo } from "react";
import { useReducer } from "react";
import { useDocumentContext } from "react-pdf";
import invariant from "tiny-invariant";
import { v4 as uuidv4 } from "uuid";

import { OutlineItem } from "@/components/pdf/outline/outline-item";
import { usePDFViewerContext } from "@/contexts/pdfviewer-context";

function cancelRunningTask(runningTask?: { cancel?: () => void } | null) {
	if (runningTask?.cancel) runningTask.cancel();
}

type State<T> =
	| { value: T; error: undefined }
	| { value: false; error: Error }
	| { value: undefined; error: undefined };

type Action<T> =
	| { type: "RESOLVE"; value: T }
	| { type: "REJECT"; error: Error }
	| { type: "RESET" };

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
	switch (action.type) {
		case "RESOLVE":
			return { value: action.value, error: undefined };
		case "REJECT":
			return { value: false, error: action.error };
		case "RESET":
			return { value: undefined, error: undefined };
		default:
			return state;
	}
}

export function useResolver<T>() {
	return useReducer(reducer<T>, { value: undefined, error: undefined });
}

type PDFOutlineItemWithId = Awaited<
	ReturnType<PDFDocumentProxy["getOutline"]>
>[number] & {
	id: string;
};

export type PDFOutlineItem = PDFOutlineItemWithId & {
	startPage: number;
	endPage: number;
	level: number;
	items: PDFOutlineItem[];
};

export type PDFOutline = PDFOutlineItem[];

class Ref {
	num: number;
	gen: number;

	constructor({ num, gen }: { num: number; gen: number }) {
		this.num = num;
		this.gen = gen;
	}

	toString() {
		let str = `${this.num}R`;
		if (this.gen !== 0) {
			str += this.gen;
		}
		return str;
	}
}

type OutlineProps = {
	pdf?: PDFDocumentProxy | false;
};

/**
 * Displays an outline (table of contents).
 *
 * Should be placed inside `<Document />`. Alternatively, it can have `pdf` prop passed, which can be obtained from `<Document />`'s `onLoadSuccess` callback function.
 */
export const Outline = observer((props: OutlineProps) => {
	const documentContext = useDocumentContext();
	const { currentPageIndex } = usePDFViewerContext();

	const mergedProps = { ...documentContext, ...props };
	const { pdf } = mergedProps;

	invariant(
		pdf,
		"Attempted to load an outline, but no document was specified. Wrap <Outline /> in a <Document /> or pass explicit `pdf` prop.",
	);

	const [outlineState, outlineDispatch] = useResolver<PDFOutline | null>();
	const { value: outline, error: outlineError } = outlineState;

	function onLoadSuccess() {
		if (typeof outline === "undefined" || outline === false) {
			return;
		}
	}

	function onLoadError() {
		if (!outlineError) {
			// Impossible, but TypeScript doesn't know that
			return;
		}
	}

	function resetOutline() {
		outlineDispatch({ type: "RESET" });
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(resetOutline, [outlineDispatch, pdf]);

	function loadOutline() {
		if (!pdf) {
			// Impossible, but TypeScript doesn't know that
			return;
		}

		const cancellable = makeCancellable(pdf.getOutline());
		const runningTask = cancellable;

		cancellable.promise
			.then((nextOutline) => {
				// Maybe I add IDs to the outline items here?
				const outlineWithIds = nextOutline.map((item) => ({
					...item,
					id: uuidv4(),
					startPage: 0,
					level: 0, // Can't get level down to children
					endPage: Number.POSITIVE_INFINITY,
				}));

				flattenOutline(outlineWithIds).then((flattenedOutline) => {
					outlineDispatch({ type: "RESOLVE", value: flattenedOutline });
				});
			})
			.catch((error) => {
				outlineDispatch({ type: "REJECT", error });
			});

		return () => cancelRunningTask(runningTask);
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(loadOutline, [outlineDispatch, pdf]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(
		() => {
			if (outline === undefined) {
				return;
			}

			if (outline === false) {
				onLoadError();
				return;
			}

			onLoadSuccess();
		},
		// Ommitted callbacks so they are not called every time they change
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[outline],
	);

	async function flattenOutline(
		outline: PDFOutline,
	): Promise<PDFOutlineItem[]> {
		const flatList: PDFOutlineItem[] = [];

		async function processItem(
			items: PDFOutlineItem[],
			level: number,
			endPage: number = Number.POSITIVE_INFINITY,
		): Promise<number> {
			if (!pdf) {
				return Number.POSITIVE_INFINITY;
			}
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				const startPage = await getPageNumber(item, pdf);

				let itemEndPage: number;
				if (i < items.length - 1) {
					// If there's a next sibling, use its start page minus 1 as the end page
					itemEndPage = (await getPageNumber(items[i + 1], pdf)) - 1;
				} else {
					// If it's the last item, use the parent's end page
					itemEndPage = endPage;
				}

				flatList.push({
					...item,
					startPage,
					endPage: itemEndPage,
					level,
					id: uuidv4(),
				});

				if (item.items && item.items.length > 0) {
					// Process children, passing the item's end page as their upper bound
					await processItem(item.items, level + 1, itemEndPage);
				}
			}

			// Return the start page of the first item in this group
			return items.length > 0
				? await getPageNumber(items[0], pdf)
				: Number.POSITIVE_INFINITY;
		}

		if (!outline || !pdf) {
			return [];
		}
		await processItem(outline, 0);
		return flatList;
	}

	// Helper function to get page number
	async function getPageNumber(
		item: PDFOutlineItem,
		pdf: PDFDocumentProxy,
	): Promise<number> {
		const destination =
			typeof item.dest === "string"
				? await pdf.getDestination(item.dest)
				: item.dest;
		if (!destination) throw new Error("Destination not found.");
		const [ref] = destination as [RefProxy];
		const pageIndex = await pdf.getPageIndex(new Ref(ref));
		return pageIndex + 1;
	}

	const activeOutlineItemId = useMemo(() => {
		if (!outline) {
			return;
		}

		const getOutlineItemofPageAtLevel = (pageIndex: number, level: number) => {
			return outline.find(
				(item) =>
					item.level === level &&
					pageIndex >= item.startPage &&
					pageIndex <= item.endPage,
			);
		};

		const getOutlineItemofPageAtLevelRecursive = (
			pageIndex: number,
			level: number,
			parentId?: string,
		) => {
			const item = getOutlineItemofPageAtLevel(pageIndex, level);
			if (!item) {
				return parentId;
			}

			if (item.items.length === 0) {
				return item.id;
			}

			return getOutlineItemofPageAtLevelRecursive(
				pageIndex,
				level + 1,
				item.id,
			);
		};

		return getOutlineItemofPageAtLevelRecursive(currentPageIndex, 0);
	}, [currentPageIndex, outline]);

	if (!outline) {
		return null;
	}

	function renderOutline() {
		if (!outline) {
			return null;
		}

		return (
			<ul className="flex flex-col gap-2 font-semibold text-neutral-500 text-xs">
				{outline.map((item, itemIndex) => (
					<OutlineItem
						key={typeof item.dest === "string" ? item.dest : itemIndex}
						item={item}
						pdf={pdf}
						outline={outline}
						isActive={activeOutlineItemId === item.id}
					/>
				))}
			</ul>
		);
	}

	return <div className={clsx("")}>{renderOutline()}</div>;
});
