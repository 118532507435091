import type { BasePageBlockProps } from "@/components/page/page-generics";
import { usePageContext } from "@/contexts/page-context/page-context";
import type { TextCellValue } from "@api/schemas";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { observer } from "mobx-react-lite";

export const TextBlock = observer(
	({ blockId, blockValue }: BasePageBlockProps<"text">) => {
		let castValue: TextCellValue | null;
		if (blockValue && blockValue.cell_value_type !== "text") {
			castValue = null;
		} else {
			castValue = blockValue;
		}
		const currentValue = castValue?.cell_value;

		const pageContext = usePageContext();

		const editor = useEditor(
			{
				extensions: [
					StarterKit,
					Link,
					Placeholder.configure({
						placeholder: "Add text...",
						emptyEditorClass:
							"cursor-text before:content-[attr(data-placeholder)] before:absolute before:top-0 before:left-0 before:text-neutral-400 before-pointer-events-none",
					}),
				],
				content: currentValue ?? "",
				editable: true,
				onBlur: () => {
					updateCell();
				},
				editorProps: {
					attributes: {
						class: "prose prose-sm outline-none focus:outline-none",
					},
					handleDOMEvents: {
						keydown: (_, event) => {
							if (event.key === "Enter") {
								if (event.shiftKey) {
									return false;
								}
								event.preventDefault();
								updateCell();
								return true;
							}
						},
					},
				},
			},
			[currentValue],
		);

		const updateCell = () => {
			if (!editor) {
				return;
			}
			const newValue = editor.getHTML();
			if (newValue !== currentValue) {
				pageContext.updateBlockValue({
					blockId,
					blockValue: {
						cell_value: newValue,
						cell_value_type: "text",
					},
				});
			}
		};

		return <EditorContent editor={editor} />;
	},
);
