import { Command } from "@/components/ui/command";
import {
	type TabTypeStateMap,
	useTab,
} from "@/contexts/tabs-context/tabs-context";
import { SearchBody } from "@/pages/search/search-body";
import { SearchComboboxCommandList } from "@/pages/search/search-combobox-command-list";
import { SearchSettings } from "@/pages/search/search-settings";
import clsx from "clsx";
import { Command as CommandPrimitive } from "cmdk";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef } from "react";
import { BarLoader } from "react-spinners";

const SearchHeader = observer(() => {
	const tab = useTab();
	const searchState = tab.state as TabTypeStateMap["search"];
	const inputRef = useRef<HTMLInputElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const searchResult = searchState.appState.searchStore.getSearchResult(
		searchState.publishedSearchConfig,
	);
	const { searchLoading, searchResults } = searchResult ?? {};
	const query = searchState.searchConfig.query;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (
			inputRef.current &&
			!inputRef.current.contains(event.target as Node) &&
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			searchState.setShowCommandList(false);
		}
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const handleEscapeKey = useCallback((event: KeyboardEvent) => {
		if (event.key === "Escape") {
			searchState.setShowCommandList(false);
			if (inputRef.current) {
				inputRef.current.blur();
			}
		}
	}, []);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("keydown", handleEscapeKey);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("keydown", handleEscapeKey);
		};
	}, [handleClickOutside, handleEscapeKey]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (inputRef.current) {
			searchState.searchInputElement = inputRef.current;
		}
	}, [inputRef.current]);

	return (
		<div className="border-b bg-white">
			<div className="relative w-full min-w-0 px-4">
				<div className="flex w-full min-w-0 gap-1 pt-4">
					<div className="relative w-full min-w-0">
						<CommandPrimitive.Input
							placeholder="Search"
							value={query}
							className={clsx(
								"flex w-full grow items-center rounded-lg border py-2 pr-1 pl-3 outline-none ring-1",
								searchState.showCommandList
									? "border-blue-300 ring-blue-100"
									: "ring-transparent",
							)}
							onValueChange={(value) => {
								runInAction(() => {
									searchState.searchConfig.query = value;
								});
								searchState.setShowCommandList(true);
							}}
							onFocus={() => searchState.setShowCommandList(true)}
							ref={inputRef}
						/>
						{(searchResults || searchLoading) && (
							<div
								ref={dropdownRef}
								className={clsx(
									"absolute right-0 left-0 z-20 mt-2 max-h-[300px] overflow-y-auto rounded-lg border border-neutral-200 bg-white shadow-lg",
									searchState.showCommandList ? "block" : "hidden",
								)}
							>
								<SearchComboboxCommandList
									setShowCommandList={(show) => {
										searchState.setShowCommandList(show);
									}}
									disabled={!searchState.showCommandList}
								/>
							</div>
						)}
					</div>
				</div>

				<SearchSettings />
			</div>
			<div className="h-[2px]">
				{searchLoading && (
					<BarLoader color={"black"} loading={true} height={2} width={"100%"} />
				)}
			</div>
		</div>
	);
});

export const SearchPage = observer(() => {
	return (
		<div className="flex h-full min-h-0 w-full min-w-0 flex-col">
			<Command className="rounded-none bg-white outline-none">
				<SearchHeader />
				<SearchBody />
			</Command>
		</div>
	);
});
