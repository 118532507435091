import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { UploadCoverImage } from "@/components/upload-cover-image";
import { formatAuthors, formatUploadTitle } from "@/lib/utils";
import { ResultWithinLibraryGroup } from "@/pages/search/result-within-library-group";
import {
	PageResolution,
	type SearchLibraryResult,
	type Upload,
} from "@api/schemas";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { flushSync } from "react-dom";

export const SearchLibraryResultGroup = observer(
	({
		upload,
		results,
		containerId,
	}: {
		upload: Upload;
		results: SearchLibraryResult[];
		containerId: string;
	}) => {
		const [open, setOpen] = useState(false);
		const collapsibleRef = useRef<HTMLDivElement>(null);
		const triggerRef = useRef<HTMLButtonElement>(null);

		return (
			<div className="border-neutral-200 border-x border-b">
				<Collapsible
					open={open}
					onOpenChange={(open) => {
						const container = document.getElementById(containerId);
						if (!triggerRef.current || !collapsibleRef.current || !container)
							return;

						const containerWindowOffset = container.getBoundingClientRect().top;
						const triggerWindowOffset =
							triggerRef.current.getBoundingClientRect().top;

						const collapsibleScrollOffset = collapsibleRef.current.offsetTop;

						flushSync(() => {
							setOpen(open);
						});

						if (!open) {
							container.scrollTo({
								top:
									collapsibleScrollOffset +
									containerWindowOffset -
									triggerWindowOffset,
								behavior: "instant",
							});
						}
					}}
					ref={collapsibleRef}
				>
					<CollapsibleTrigger
						ref={triggerRef}
						className="group sticky top-0 flex w-full min-w-0 items-center justify-between gap-2 truncate border-neutral-200 border-t bg-white p-4 text-left shadow-xs"
					>
						<div
							className={clsx(
								"transform text-lg text-neutral-400 transition-transform group-hover:text-neutral-900",
								open ? "rotate-90" : "rotate-0",
							)}
						>
							<CaretRight weight="bold" />
						</div>
						<div className="flex min-w-0 grow items-center gap-2 truncate">
							<UploadCoverImage
								resolution={PageResolution.thumbnail}
								upload_id={upload.upload_id}
								upload_status={upload.upload_status}
								className={(uploadStatus) =>
									clsx(
										"h-10 max-w-8 rounded-xs",
										uploadStatus === "ready" && "shadow",
									)
								}
							/>
							<div className="min-w-0 truncate">
								<h2 className="min-w-0 truncate text-sm leading-4">
									{formatUploadTitle({
										title: upload.upload_title,
										subtitle: upload.upload_subtitle,
										filename: upload.file_name,
									})}
								</h2>
								<h3 className="mt-1 min-w-0 truncate text-neutral-500 text-sm leading-4">
									{formatAuthors(upload.upload_authors ?? [])}
									{upload.upload_year_published &&
										`, ${upload.upload_year_published}`}
								</h3>
							</div>
						</div>

						<h3 className="mt-0.5 min-w-5 max-w-max shrink-0 rounded-full bg-blue-50 px-1.5 py-0.5 text-center font-semibold text-blue-500 text-xs leading-4">
							{results.length}
						</h3>
					</CollapsibleTrigger>
					<CollapsibleContent>
						<div className="flex flex-col gap-3 bg-white p-4">
							{results.map((result) => (
								<ResultWithinLibraryGroup
									key={result.chunk_id}
									result={result}
									results={results}
									upload={upload}
								/>
							))}
						</div>
					</CollapsibleContent>
				</Collapsible>
			</div>
		);
	},
);
