import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";

const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
const DEFAULT_TIME_FORMAT = "HH:mm"; // 24-hour format

export const Calendar = ({
	selectedDate,
	setSelectedDate,
	showTime,
}: {
	selectedDate: dayjs.Dayjs | null;
	setSelectedDate: (date: dayjs.Dayjs | null) => void;
	showTime: boolean;
}) => {
	const today = dayjs();

	// State for the current displayed month and year
	const [currentDate, setCurrentDate] = useState(
		selectedDate ? selectedDate.date(1) : dayjs().date(1), // First day of the current or selected month
	);

	// State for the input field values
	const [inputValue, setInputValue] = useState(
		selectedDate ? selectedDate.format(DEFAULT_DATE_FORMAT) : "",
	);
	const [timeInputValue, setTimeInputValue] = useState(
		selectedDate ? selectedDate.format(DEFAULT_TIME_FORMAT) : "",
	);

	// Update inputValue whenever selectedDate changes
	useEffect(() => {
		setInputValue(selectedDate ? selectedDate.format(DEFAULT_DATE_FORMAT) : "");
	}, [selectedDate]);

	// Update timeInputValue whenever selectedDate changes
	useEffect(() => {
		setTimeInputValue(
			selectedDate ? selectedDate.format(DEFAULT_TIME_FORMAT) : "",
		);
	}, [selectedDate]);

	// Function to handle date input field changes
	const handleDateInput = () => {
		const parsedDate = dayjs(
			inputValue,
			["YYYY-MM-DD", "MM/DD/YYYY", "DD/MM/YYYY"],
			true,
		);
		if (parsedDate.isValid()) {
			const newSelectedDate = (selectedDate || dayjs())
				.year(parsedDate.year())
				.month(parsedDate.month())
				.date(parsedDate.date())
				.hour(selectedDate ? selectedDate.hour() : 0)
				.minute(selectedDate ? selectedDate.minute() : 0)
				.second(0); // Preserve time or default to 00:00

			setSelectedDate(newSelectedDate);
			setCurrentDate(parsedDate.date(1)); // Update currentDate to selected month
		} else {
			// If invalid, reset inputValue to the previous valid selectedDate or empty string
			setInputValue(
				selectedDate ? selectedDate.format(DEFAULT_DATE_FORMAT) : "",
			);
		}
	};

	// Function to handle time input field changes
	const handleTimeInput = () => {
		const parsedTime = dayjs(timeInputValue, ["HH:mm", "hh:mm A"], true);
		if (parsedTime.isValid()) {
			// Update selectedDate with new time, preserve date or default to today
			const newSelectedDate = (selectedDate || dayjs())
				.hour(parsedTime.hour())
				.minute(parsedTime.minute())
				.second(0);

			setSelectedDate(newSelectedDate);
		} else {
			// If invalid, reset timeInputValue to previous valid time or empty string
			setTimeInputValue(
				selectedDate ? selectedDate.format(DEFAULT_TIME_FORMAT) : "",
			);
		}
	};

	const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

	// Handle previous month navigation
	const prevMonth = () => {
		setCurrentDate(currentDate.subtract(1, "month"));
	};

	// Handle next month navigation
	const nextMonth = () => {
		setCurrentDate(currentDate.add(1, "month"));
	};

	// Handle date selection with time preservation
	const selectDate = (day: number) => {
		const datePart = currentDate
			.year(currentDate.year())
			.month(currentDate.month())
			.date(day);

		const newSelectedDate = selectedDate
			? selectedDate
					.year(currentDate.year())
					.month(currentDate.month())
					.date(day)
			: datePart.hour(0).minute(0).second(0);

		setSelectedDate(newSelectedDate);
		setInputValue(newSelectedDate.format(DEFAULT_DATE_FORMAT));
	};

	// Calculate the number of days in the current month
	const getDaysInMonth = () => {
		return currentDate.daysInMonth();
	};

	// Generate the days to display on the calendar
	const generateCalendar = () => {
		const daysInMonth = getDaysInMonth();
		const startDay = currentDate.startOf("month").day(); // Day of the week of the first day
		const calendarDays = [];

		// Add empty slots for days before the start of the month
		for (let i = 0; i < startDay; i++) {
			calendarDays.push(null);
		}

		// Add all days of the current month
		for (let day = 1; day <= daysInMonth; day++) {
			calendarDays.push(day);
		}

		return calendarDays;
	};

	const calendarDays = generateCalendar();

	return (
		<>
			{/* Input fields for date and time */}
			<div className="mb-4">
				<div className={clsx(showTime ? "flex space-x-2" : "")}>
					<input
						type="text"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						onBlur={handleDateInput}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleDateInput();
							}
						}}
						placeholder="Select a date"
						className={clsx(
							"rounded-md border px-2 py-1 text-left",
							showTime ? "w-1/2" : "w-full",
						)}
					/>
					{showTime && (
						<input
							type="text"
							value={timeInputValue}
							onChange={(e) => setTimeInputValue(e.target.value)}
							onBlur={handleTimeInput}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									handleTimeInput();
								}
							}}
							placeholder="Select a time"
							className="w-1/2 rounded-md border px-2 py-1 text-left"
						/>
					)}
				</div>
			</div>

			{/* Header with navigation and selection */}
			<div className="mb-4 flex items-center">
				<div className="flex-grow font-medium text-base">
					{currentDate.format("MMMM YYYY")}
				</div>
				<button
					type="button"
					onClick={prevMonth}
					className="px-1 py-1 text-neutral-500 text-sm hover:text-neutral-700"
				>
					<CaretLeft weight="bold" />
				</button>
				<button
					type="button"
					onClick={nextMonth}
					className="px-1 py-1 text-neutral-500 text-sm hover:text-neutral-700"
				>
					<CaretRight weight="bold" />
				</button>
			</div>

			{/* Days of the week */}
			<div className="mb-2 grid grid-cols-7 border-b pb-2 text-center font-medium text-neutral-500 text-sm">
				{daysOfWeek.map((day) => (
					<div key={day}>{day}</div>
				))}
			</div>

			{/* Calendar dates */}
			<div className="grid grid-cols-7 gap-0.5 text-center">
				{calendarDays.map((day, index) => {
					const isSelected =
						selectedDate != null &&
						selectedDate.date() === day &&
						selectedDate.month() === currentDate.month() &&
						selectedDate.year() === currentDate.year();

					const isWeekend =
						(index % 7 === 0 || index % 7 === 6) && day !== null;

					const isToday =
						day === today.date() &&
						currentDate.month() === today.month() &&
						currentDate.year() === today.year();

					if (day === null) {
						return (
							<div
								key={`empty-${
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									index
								}`}
							/>
						);
					}

					return (
						<button
							type="button"
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							key={index}
							className={clsx(
								"flex h-8 items-center justify-center rounded-md text-sm",
								isWeekend ? "text-neutral-400" : "text-neutral-800",
								isSelected
									? "bg-neutral-900 text-neutral-100"
									: "bg-white hover:bg-neutral-100",
								isToday && "underline",
							)}
							onClick={() => selectDate(day)}
						>
							{day}
						</button>
					);
				})}
			</div>
		</>
	);
};

export interface DateRangeDayjs {
	start: dayjs.Dayjs | null;
	end: dayjs.Dayjs | null;
}
export const CalendarWithRange = ({
	selectedRange,
	setSelectedRange,
	showTime,
}: {
	selectedRange: DateRangeDayjs;
	setSelectedRange: (range: DateRangeDayjs) => void;
	showTime: boolean;
}) => {
	const today = dayjs();

	// State for the current displayed month and year
	const [currentDate, setCurrentDate] = useState(
		dayjs().date(1), // First day of the current month
	);

	// State for the input field values
	const [startInputValue, setStartInputValue] = useState(
		selectedRange.start ? selectedRange.start.format(DEFAULT_DATE_FORMAT) : "",
	);
	const [endInputValue, setEndInputValue] = useState(
		selectedRange.end ? selectedRange.end.format(DEFAULT_DATE_FORMAT) : "",
	);

	// State for the time input field values
	const [startTimeInputValue, setStartTimeInputValue] = useState(
		selectedRange.start ? selectedRange.start.format(DEFAULT_TIME_FORMAT) : "",
	);
	const [endTimeInputValue, setEndTimeInputValue] = useState(
		selectedRange.end ? selectedRange.end.format(DEFAULT_TIME_FORMAT) : "",
	);

	// State to track which input is focused ('start', 'end', or null)
	const [focusedInput, setFocusedInput] = useState<"start" | "end" | null>(
		null,
	);

	// Update input values whenever selectedRange changes
	useEffect(() => {
		setStartInputValue(
			selectedRange.start
				? selectedRange.start.format(DEFAULT_DATE_FORMAT)
				: "",
		);
		setEndInputValue(
			selectedRange.end ? selectedRange.end.format(DEFAULT_DATE_FORMAT) : "",
		);
		setStartTimeInputValue(
			selectedRange.start
				? selectedRange.start.format(DEFAULT_TIME_FORMAT)
				: "",
		);
		setEndTimeInputValue(
			selectedRange.end ? selectedRange.end.format(DEFAULT_TIME_FORMAT) : "",
		);
	}, [selectedRange]);

	// Function to handle start date input field changes
	const handleStartDateInput = () => {
		const parsedStartDate = dayjs(
			startInputValue,
			["YYYY-MM-DD", "MM/DD/YYYY", "DD/MM/YYYY"],
			true,
		);
		const parsedStartTime = dayjs(
			startTimeInputValue,
			["HH:mm", "hh:mm A"],
			true,
		);

		if (parsedStartDate.isValid()) {
			// Set date part
			let newStartDate = parsedStartDate;

			// Set time part
			if (parsedStartTime.isValid()) {
				newStartDate = newStartDate
					.hour(parsedStartTime.hour())
					.minute(parsedStartTime.minute())
					.second(0);
			} else {
				// If time is invalid or not provided, default to 00:00
				newStartDate = newStartDate.startOf("day");
				setStartTimeInputValue(newStartDate.format(DEFAULT_TIME_FORMAT));
			}

			let newEndDate = selectedRange.end;

			// Ensure endDate is after startDate
			if (newEndDate?.isBefore(newStartDate)) {
				newEndDate = null;
				setEndInputValue("");
				setEndTimeInputValue("");
			}

			setSelectedRange({
				start: newStartDate,
				end: newEndDate,
			});
		} else {
			// Reset input value to previous valid date
			setStartInputValue(
				selectedRange.start
					? selectedRange.start.format(DEFAULT_DATE_FORMAT)
					: "",
			);
		}
	};

	// Function to handle end date input field changes
	const handleEndDateInput = () => {
		const parsedEndDate = dayjs(
			endInputValue,
			["YYYY-MM-DD", "MM/DD/YYYY", "DD/MM/YYYY"],
			true,
		);
		const parsedEndTime = dayjs(endTimeInputValue, ["HH:mm", "hh:mm A"], true);

		if (parsedEndDate.isValid()) {
			// Set date part
			let newEndDate = parsedEndDate;

			// Set time part
			if (parsedEndTime.isValid()) {
				newEndDate = newEndDate
					.hour(parsedEndTime.hour())
					.minute(parsedEndTime.minute())
					.second(0);
			} else {
				// If time is invalid or not provided, default to 23:59
				newEndDate = newEndDate.endOf("day");
				setEndTimeInputValue(newEndDate.format(DEFAULT_TIME_FORMAT));
			}

			let newStartDate = selectedRange.start;

			// Ensure startDate is before or equal to endDate
			if (newStartDate && newEndDate.isBefore(newStartDate)) {
				// Remove start date if end date is before it
				newStartDate = null;
				setStartInputValue("");
				setStartTimeInputValue("");
			}

			setSelectedRange({
				start: newStartDate,
				end: newEndDate,
			});
		} else {
			// Reset input value to previous valid date
			setEndInputValue(
				selectedRange.end ? selectedRange.end.format(DEFAULT_DATE_FORMAT) : "",
			);
		}
	};

	// Function to handle start time input field changes
	const handleStartTimeInput = () => {
		const parsedTime = dayjs(startTimeInputValue, ["HH:mm", "hh:mm A"], true);
		if (parsedTime.isValid() && selectedRange.start) {
			const newStartDate = selectedRange.start
				.hour(parsedTime.hour())
				.minute(parsedTime.minute())
				.second(0);

			let newEndDate = selectedRange.end;
			// Ensure that endDate is after startDate
			if (newEndDate?.isBefore(newStartDate)) {
				newEndDate = null;
				setEndInputValue("");
				setEndTimeInputValue("");
			}

			setSelectedRange({
				start: newStartDate,
				end: newEndDate,
			});
		} else {
			// Reset time input to previous value
			setStartTimeInputValue(
				selectedRange.start
					? selectedRange.start.format(DEFAULT_TIME_FORMAT)
					: "",
			);
		}
	};

	// Function to handle end time input field changes
	const handleEndTimeInput = () => {
		const parsedTime = dayjs(endTimeInputValue, ["HH:mm", "hh:mm A"], true);
		if (parsedTime.isValid() && selectedRange.end) {
			const newEndDate = selectedRange.end
				.hour(parsedTime.hour())
				.minute(parsedTime.minute())
				.second(0);

			let newStartDate = selectedRange.start;
			// Ensure that endDate is after startDate
			if (newStartDate && newEndDate.isBefore(newStartDate)) {
				newStartDate = null;
				setStartInputValue("");
				setStartTimeInputValue("");
			}

			setSelectedRange({
				start: newStartDate,
				end: newEndDate,
			});
		} else {
			// Reset time input to previous value
			setEndTimeInputValue(
				selectedRange.end ? selectedRange.end.format(DEFAULT_TIME_FORMAT) : "",
			);
		}
	};

	const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

	// Handle previous month navigation
	const prevMonth = () => {
		setCurrentDate(currentDate.subtract(1, "month"));
	};

	// Handle next month navigation
	const nextMonth = () => {
		setCurrentDate(currentDate.add(1, "month"));
	};

	// Handle date selection
	const selectDate = (day: number) => {
		const date = currentDate.date(day);

		if (focusedInput === "start") {
			let newStartDate = date;

			// Set time from existing time input or default to 00:00
			const parsedStartTime = dayjs(
				startTimeInputValue,
				["HH:mm", "hh:mm A"],
				true,
			);

			if (parsedStartTime.isValid()) {
				newStartDate = newStartDate
					.hour(parsedStartTime.hour())
					.minute(parsedStartTime.minute())
					.second(0);
			} else if (selectedRange.start) {
				newStartDate = newStartDate
					.hour(selectedRange.start.hour())
					.minute(selectedRange.start.minute())
					.second(0);
			} else {
				newStartDate = newStartDate.startOf("day");
			}

			let newEndDate = selectedRange.end;

			// Ensure endDate is after startDate
			if (newEndDate?.isBefore(newStartDate)) {
				newEndDate = null;
				setEndInputValue("");
				setEndTimeInputValue("");
			}

			setSelectedRange({
				start: newStartDate,
				end: newEndDate,
			});
			setStartInputValue(newStartDate.format(DEFAULT_DATE_FORMAT));
			setStartTimeInputValue(newStartDate.format(DEFAULT_TIME_FORMAT));
		} else if (focusedInput === "end") {
			let newEndDate = date;

			// Set time from existing time input or default to 23:59
			const parsedEndTime = dayjs(
				endTimeInputValue,
				["HH:mm", "hh:mm A"],
				true,
			);

			if (parsedEndTime.isValid()) {
				newEndDate = newEndDate
					.hour(parsedEndTime.hour())
					.minute(parsedEndTime.minute())
					.second(0);
			} else if (selectedRange.end) {
				newEndDate = newEndDate
					.hour(selectedRange.end.hour())
					.minute(selectedRange.end.minute())
					.second(0);
			} else {
				newEndDate = newEndDate.endOf("day");
			}

			let newStartDate = selectedRange.start;

			// Ensure startDate is before or equal to endDate
			if (newStartDate && newEndDate.isBefore(newStartDate)) {
				newStartDate = null;
				setStartInputValue("");
				setStartTimeInputValue("");
			}

			setSelectedRange({
				start: newStartDate,
				end: newEndDate,
			});
			setEndInputValue(newEndDate.format(DEFAULT_DATE_FORMAT));
			setEndTimeInputValue(newEndDate.format(DEFAULT_TIME_FORMAT));
		} else {
			// No input is focused; maintain existing behavior
			if (!selectedRange.start || (selectedRange.start && selectedRange.end)) {
				// Start a new range
				let newStartDate = date;

				// Set time from existing startTimeInputValue or default
				const parsedStartTime = dayjs(
					startTimeInputValue,
					["HH:mm", "hh:mm A"],
					true,
				);
				if (parsedStartTime.isValid()) {
					newStartDate = newStartDate
						.hour(parsedStartTime.hour())
						.minute(parsedStartTime.minute())
						.second(0);
				} else {
					newStartDate = newStartDate.startOf("day");
				}

				setSelectedRange({
					start: newStartDate,
					end: null,
				});
				setStartInputValue(newStartDate.format(DEFAULT_DATE_FORMAT));
				setStartTimeInputValue(newStartDate.format(DEFAULT_TIME_FORMAT));
				setEndInputValue("");
				setEndTimeInputValue("");
			} else if (selectedRange.start && !selectedRange.end) {
				// Set end date
				let newEndDate = date;

				// Set time from existing endTimeInputValue or default
				const parsedEndTime = dayjs(
					endTimeInputValue,
					["HH:mm", "hh:mm A"],
					true,
				);
				if (parsedEndTime.isValid()) {
					newEndDate = newEndDate
						.hour(parsedEndTime.hour())
						.minute(parsedEndTime.minute())
						.second(0);
				} else {
					newEndDate = newEndDate.endOf("day");
				}

				let newStartDate = selectedRange.start;

				// Ensure startDate is before or equal to endDate
				if (newEndDate.isBefore(newStartDate)) {
					// Swap dates
					[newStartDate, newEndDate] = [newEndDate, newStartDate];
					setStartInputValue(newStartDate.format(DEFAULT_DATE_FORMAT));
					setStartTimeInputValue(newStartDate.format(DEFAULT_TIME_FORMAT));
				}

				setSelectedRange({
					start: newStartDate,
					end: newEndDate,
				});
				setEndInputValue(newEndDate.format(DEFAULT_DATE_FORMAT));
				setEndTimeInputValue(newEndDate.format(DEFAULT_TIME_FORMAT));
			}
		}
	};

	// Calculate the number of days in the current month
	const getDaysInMonth = () => {
		return currentDate.daysInMonth();
	};

	// Generate the days to display on the calendar
	const generateCalendar = () => {
		const daysInMonth = getDaysInMonth();
		const startDay = currentDate.startOf("month").day(); // Day of the week of the first day
		const calendarDays = [];

		// Add empty slots for days before the start of the month
		for (let i = 0; i < startDay; i++) {
			calendarDays.push(null);
		}

		// Add all days of the current month
		for (let day = 1; day <= daysInMonth; day++) {
			calendarDays.push(day);
		}

		return calendarDays;
	};

	const calendarDays = generateCalendar();

	return (
		<>
			{/* Input fields for start and end dates and times */}
			<div className="mb-4">
				<div className={showTime ? "flex flex-col gap-2" : "flex gap-2"}>
					<div className={clsx(showTime ? "flex w-full gap-2" : "w-1/2")}>
						<input
							type="text"
							value={startInputValue}
							onChange={(e) => setStartInputValue(e.target.value)}
							onBlur={() => {
								handleStartDateInput();
								setFocusedInput(null);
							}}
							onFocus={() => setFocusedInput("start")}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									handleStartDateInput();
								}
							}}
							placeholder="Start date"
							className="w-full rounded-md border px-2 py-1 text-left"
						/>
						{showTime && (
							<input
								type="text"
								value={startTimeInputValue}
								onChange={(e) => setStartTimeInputValue(e.target.value)}
								onBlur={handleStartTimeInput}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handleStartTimeInput();
									}
								}}
								placeholder="Start time"
								className="w-full rounded-md border px-2 py-1 text-left"
							/>
						)}
					</div>
					<div className={clsx(showTime ? "flex w-full gap-2" : "w-1/2")}>
						<input
							type="text"
							value={endInputValue}
							onChange={(e) => setEndInputValue(e.target.value)}
							onBlur={() => {
								handleEndDateInput();
								setFocusedInput(null);
							}}
							onFocus={() => setFocusedInput("end")}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									handleEndDateInput();
								}
							}}
							placeholder="End date"
							className="w-full rounded-md border px-2 py-1 text-left"
						/>
						{showTime && (
							<input
								type="text"
								value={endTimeInputValue}
								onChange={(e) => setEndTimeInputValue(e.target.value)}
								onBlur={handleEndTimeInput}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handleEndTimeInput();
									}
								}}
								placeholder="End time"
								className="w-full rounded-md border px-2 py-1 text-left"
							/>
						)}
					</div>
				</div>
			</div>

			{/* Header with navigation */}
			<div className="mb-4 flex items-center">
				<div className="flex-grow font-medium text-base">
					{currentDate.format("MMMM YYYY")}
				</div>
				<button
					type="button"
					onClick={prevMonth}
					className="px-1 py-1 text-neutral-500 text-sm hover:text-neutral-700"
				>
					<CaretLeft weight="bold" />
				</button>
				<button
					type="button"
					onClick={nextMonth}
					className="px-1 py-1 text-neutral-500 text-sm hover:text-neutral-700"
				>
					<CaretRight weight="bold" />
				</button>
			</div>

			{/* Days of the week */}
			<div className="mb-2 grid grid-cols-7 border-b pb-2 text-center font-medium text-neutral-500 text-sm">
				{daysOfWeek.map((day) => (
					<div key={day}>{day}</div>
				))}
			</div>

			{/* Calendar dates */}
			<div className="grid grid-cols-7 text-center">
				{calendarDays.map((day, index) => {
					if (day === null) {
						return (
							<div
								key={`empty-${
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									index
								}`}
							/>
						);
					}

					const date = currentDate.date(day);
					const isToday = date.isSame(today, "day");

					const isStart =
						selectedRange.start && date.isSame(selectedRange.start, "day");
					const isEnd =
						selectedRange.end && date.isSame(selectedRange.end, "day");

					const isSelected = isStart || isEnd;

					const isInRange =
						selectedRange.start &&
						selectedRange.end &&
						date.isAfter(selectedRange.start, "day") &&
						date.isBefore(selectedRange.end, "day");

					const isWeekend = date.day() === 0 || date.day() === 6;

					const classNames = clsx(
						"mb-1 flex h-8 items-center justify-center text-sm",
						isWeekend ? "text-neutral-400" : "text-neutral-800",
						isSelected
							? "bg-neutral-900 text-neutral-100"
							: isInRange
								? "bg-neutral-100"
								: "bg-white hover:bg-neutral-100",
						isStart && "rounded-l-md",
						isEnd && "rounded-r-md",
						isToday && "underline",
					);

					return (
						<button
							type="button"
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							key={index}
							className={classNames}
							onClick={() => selectDate(day)}
						>
							{day}
						</button>
					);
				})}
			</div>
		</>
	);
};
