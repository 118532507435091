import {
	AlignCenterVerticalSimple,
	AlignLeftSimple,
} from "@phosphor-icons/react";
import {
	type NodeViewProps,
	mergeAttributes,
	nodeInputRule,
} from "@tiptap/core";
import Image, { inputRegex } from "@tiptap/extension-image";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { observer } from "mobx-react-lite";
import type React from "react";
import { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";

const IconPicCenter = () => {
	return <AlignCenterVerticalSimple />;
};

const IconPicLeft = () => {
	return <AlignLeftSimple />;
};

const IconPicRight = () => {
	return <IconPicRight />;
};

const IMAGE_RESIZE_ELEM_CLASS = "image-resizer";

export const ImageNode = observer((props: NodeViewProps) => {
	const [loaded, setLoaded] = useState(false);
	const [invalidImage, setInvalidImage] = useState(false);

	// Revisit
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setLoaded(false);
		setInvalidImage(false);
	}, [props.node.attrs.src]);

	const handler = (mouseDownEvent: React.MouseEvent<HTMLImageElement>) => {
		const target = mouseDownEvent.target as HTMLElement;
		mouseDownEvent.stopPropagation();

		const side = target.getAttribute("data-handle");

		const parent = (mouseDownEvent.target as HTMLElement).closest(
			`.${IMAGE_RESIZE_ELEM_CLASS}`,
		);
		const image = parent?.querySelector("figure img") ?? null;
		if (image === null) return;
		const startSize = { x: image.clientWidth, y: image.clientHeight };
		const startPosition = {
			x: mouseDownEvent.pageX,
			y: mouseDownEvent.pageY,
		};

		function onMouseMove(mouseMoveEvent: MouseEvent) {
			let newWidth = startSize.x;

			if (side === "left") {
				newWidth += -startPosition.x + mouseMoveEvent.pageX;
			} else {
				newWidth += startPosition.x - mouseMoveEvent.pageX;
			}

			const image = parent?.querySelector("figure img") ?? null;
			if (image === null) return;

			const currentDimensions = {
				x: image.clientWidth,
				y: image.clientHeight,
			};

			const newHeight = (currentDimensions.y / currentDimensions.x) * newWidth;

			if (newHeight < 128) return;

			props.updateAttributes({
				width: newWidth,
			});
		}
		function onMouseUp() {
			document.body.removeEventListener("mousemove", onMouseMove);
		}

		document.body.addEventListener("mousemove", onMouseMove);
		document.body.addEventListener("mouseup", onMouseUp, { once: true });
	};

	const updateAlignment = (newAlignment: string) => {
		props.updateAttributes({ alignment: newAlignment });
	};

	let imagePlaceholder: React.ReactNode;
	if (!loaded) {
		imagePlaceholder = (
			<div className={"h-32 w-64 overflow-hidden rounded-md"}>
				<div className="flex h-full w-full items-center justify-center bg-bg-2 ">
					<ScaleLoader
						height={16}
						width={4}
						radius={8}
						margin={3}
						color="#bbb"
					/>
				</div>
			</div>
		);
	} else if (!invalidImage) {
		imagePlaceholder = null;
	} else {
		imagePlaceholder = (
			<div className="flex h-full w-full flex-col items-center justify-center border border-border-1 px-32 py-16">
				<div className="text-2xl text-text-2">
					<IconPicCenter />
				</div>
				<div className="text-text-2">Invalid image</div>
			</div>
		);
	}

	return (
		<NodeViewWrapper className={`${props.node.attrs.alignment}`}>
			<div
				// eslint-disable-next-line tailwindcss/no-custom-classname,tailwindcss/classnames-order
				className={`${IMAGE_RESIZE_ELEM_CLASS} group relative inline-flex min-h-max grow-0 ${
					props.selected && "ring-2 ring-bg-selection"
				}`}
				// manually enable draggability
				// see https://github.com/ueberdosis/tiptap/issues/2597
				draggable={props.editor.isEditable && "true"}
				data-drag-handle={props.editor.isEditable && ""}
			>
				{props.selected && (
					<div className="absolute z-10 h-full w-full bg-bg-selection/25" />
				)}
				<figure className="my-0 w-full">
					{imagePlaceholder}
					<img
						{...props.node.attrs}
						alt={""}
						className={`min-h-[8rem] ${
							props.editor.isEditable && "cursor-pointer"
						}${(!loaded || invalidImage) && "hidden"}`}
						onLoad={() => {
							setLoaded(true);
						}}
						onError={() => {
							setLoaded(true);
							setInvalidImage(true);
						}}
					/>
				</figure>
				{props.editor.isEditable && (
					<>
						<div
							className={
								"absolute right-1.5 bottom-1/2 z-20 h-[6rem] w-2 translate-y-1/2 cursor-col-resize rounded-full border border-bg-main/75 bg-text-1/75 text-text-main/50 opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
							}
							data-handle="left"
							onMouseDown={handler}
						/>
						<div
							className={
								"absolute bottom-1/2 left-1.5 z-20 h-[6rem] w-2 translate-y-1/2 cursor-col-resize rounded-full border border-bg-main/75 bg-text-1/75 text-text-main/50 opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
							}
							data-handle="right"
							onMouseDown={handler}
						/>
						<div className="absolute top-2 right-2 flex max-h-min items-center space-x-0.5 overflow-hidden rounded bg-bg-main p-0.5 text-text-main opacity-0 shadow transition-opacity duration-300 ease-in-out group-hover:opacity-100">
							<button
								type="button"
								onClick={() => {
									updateAlignment("text-left");
								}}
								className="cursor-pointer rounded p-2 hover:bg-bg-3"
							>
								<IconPicLeft />
							</button>
							<button
								type="button"
								onClick={() => {
									updateAlignment("text-center");
								}}
								className="cursor-pointer rounded p-2 hover:bg-bg-3"
							>
								<IconPicCenter />
							</button>
							<button
								type="button"
								onClick={() => {
									updateAlignment("text-right");
								}}
								className="cursor-pointer rounded p-2 hover:bg-bg-3"
							>
								<IconPicRight />
							</button>
						</div>
					</>
				)}
			</div>
		</NodeViewWrapper>
	);
});

export interface ImageOptions {
	inline: boolean;
	allowBase64: boolean;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	HTMLAttributes: Record<string, any>;
}
declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		imageResize: {
			setImage: (options: {
				src: string;
				alt?: string;
				title?: string;
				width?: string | number;
				height?: string | number;
				alignment?: string;
			}) => ReturnType;
		};
	}
}
export const ImageResize = Image.extend<ImageOptions>({
	addAttributes() {
		return {
			src: {
				default: null,
			},
			alt: {
				default: null,
			},
			title: {
				default: null,
			},
			width: {
				default: "100%",
				renderHTML: (attributes) => {
					return {
						width: attributes.width,
					};
				},
			},
			height: {
				default: "auto",
				renderHTML: (attributes) => {
					return {
						height: attributes.height,
					};
				},
			},
			alignment: {
				default: "text-center",
			},
		};
	},

	renderHTML({ HTMLAttributes }) {
		return [
			"image",
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
		];
	},

	addNodeView() {
		return ReactNodeViewRenderer(ImageNode);
	},
	addInputRules() {
		return [
			nodeInputRule({
				find: inputRegex,
				type: this.type,
				getAttributes: (match) => {
					const [, , alt, src, title, height, width, alignment] = match;
					return { src, alt, title, height, width, alignment };
				},
			}),
		];
	},

	group() {
		return this.options.inline ? "inline" : "block";
	},

	inline: false,
	draggable: true,
	selectable: true,
});
