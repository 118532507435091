import type { BaseTableCellProps } from "@/components/table/table-generics";
import { Checkbox } from "@/components/ui/checkbox";
import { useTableContext } from "@/contexts/table-context/table-context";
import { observer } from "mobx-react-lite";

export const BooleanCell = observer(
	({
		rowId,
		columnId,
		cellValue,
		isProxied,
	}: BaseTableCellProps<"boolean">) => {
		const tableContext = useTableContext();

		return (
			<Checkbox
				className="ml-2 flex items-center"
				checked={cellValue?.cell_value ?? false}
				disabled={isProxied || !tableContext.editable}
				onCheckedChange={(checked) => {
					tableContext.updateCell({
						rowId,
						columnId,
						value: {
							cell_value: checked === true,
							cell_value_type: "boolean",
						},
					});
				}}
			/>
		);
	},
);
