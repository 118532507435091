import { CommandKDialog } from "@/components/command-k";
import { RightSidebar } from "@/components/layout/right-sidebar/right-sidebar";
import { Sidebar } from "@/components/layout/sidebar/sidebar";
import { TopBar } from "@/components/layout/top-bar";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable";
import { useAppContext } from "@/contexts/app-context/app-context";
import { observer } from "mobx-react-lite";
import { Toaster } from "sonner";

export const Layout = observer(
	({ children }: { children: React.ReactNode }) => {
		const appContext = useAppContext();
		return (
			<div className="flex h-screen w-screen flex-col">
				<CommandKDialog />
				<TopBar />
				<div className="flex h-full min-h-0 w-full">
					<ResizablePanelGroup direction="horizontal" className="">
						{appContext.sidebarState.showSidebar && (
							<>
								<ResizablePanel
									id={"sidebar"}
									defaultSize={15}
									minSize={10}
									order={1}
								>
									<Sidebar />
								</ResizablePanel>
								<ResizableHandle className="" />
							</>
						)}
						<ResizablePanel id={"content"} minSize={25} order={2}>
							<div className="flex h-full min-h-0 w-full min-w-0 shrink grow overflow-visible bg-neutral-50">
								{children}
							</div>
						</ResizablePanel>
						{appContext.rightSidebarState.showRightSidebar && (
							<>
								<ResizableHandle className="" />
								<ResizablePanel
									id={"right-sidebar"}
									defaultSize={30}
									minSize={25}
									order={3}
								>
									<RightSidebar />
								</ResizablePanel>
							</>
						)}
					</ResizablePanelGroup>
				</div>
				<Toaster />
			</div>
		);
	},
);
