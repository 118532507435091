import { BaseCellRenderer } from "@/components/table/cell/cell-renderer";
import type {
	MCellValue,
	MColumnMetadata,
	TableCellProps,
	TableCellPropsU,
} from "@/components/table/table-generics";
import { useTableContext } from "@/contexts/table-context/table-context";
import type { ColumnType } from "@api/schemas";
import { observer } from "mobx-react-lite";

export const GroupbyKeyCell = observer(
	<ProxiedTypeT extends ColumnType>({
		rowId,
		columnId,
		cellValue,
		columnMetadata,
	}: TableCellProps<"groupby_key">) => {
		const tableContext = useTableContext();

		if (!cellValue) {
			return null;
		}

		const proxiedColumnMetadata = tableContext.getProxiedColumnMetadataById(
			columnMetadata.proxied_column_id,
		) as MColumnMetadata<ProxiedTypeT> | undefined;

		if (!proxiedColumnMetadata) {
			return null;
		}

		const proxiedCellValue = cellValue.cell_value;
		if (
			proxiedCellValue.cell_value_type !== proxiedColumnMetadata.column_type
		) {
			throw new Error(
				"Proxied cell value type does not match proxied column type",
			);
		}

		const rawProps: TableCellProps<ProxiedTypeT> = {
			rowId,
			columnId,
			columnType: proxiedColumnMetadata.column_type,
			columnMetadata: proxiedColumnMetadata,
			cellValue: proxiedCellValue as MCellValue<ProxiedTypeT>,
		};

		const props = rawProps as TableCellPropsU;

		if (
			props.columnType === "proxy" ||
			props.columnType === "proxy_group" ||
			props.columnType === "groupby_key"
		) {
			throw new Error("Proxied cells cannot be proxies themselves");
		}

		return <BaseCellRenderer {...props} isProxied />;
	},
);
