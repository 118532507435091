import { computeGappedHighlight, computeHighlight } from "@/highlight";
// import type { HighlightResult } from "@/highlight";
import type { Upload } from "@api/schemas";
import { flow, makeAutoObservable, runInAction } from "mobx";
import type { PDFDocumentProxy } from "pdfjs-dist";
import type { TextItem } from "pdfjs-dist/types/src/display/api";
import { createContext, useContext, useEffect, useState } from "react";
import type { VariableSizeList } from "react-window";

export type RawHighlight = {
	textToHighlight: {
		textStart: string;
		textEnd?: string;
	};
	pageIndicesToSearch: number[];
};

export interface HighlightResult {
	firstSpan: {
		item: TextItem;
		pageIndex: number;
		itemIndex: number;
	};
	lastSpan: {
		item: TextItem;
		pageIndex: number;
		itemIndex: number;
	};
	firstSpanCharIdx: number;
	lastSpanCharIdx: number;
}

export class PDFViewerState {
	upload: Upload;
	// this should be read-only except from the virtualized list, which syncs it
	private _currentPageIndex = 0;

	rawHighlights: Map<string, RawHighlight> = new Map();
	highlightResults: Map<string, HighlightResult> = new Map();
	rawActiveHighlight: RawHighlight | null = null;
	activeHighlightResult: HighlightResult | null = null;

	pdf: {
		document: PDFDocumentProxy;
		pageDimensions: Map<number, [number, number]>;
		averagePageHeight: number;
	} | null = null;

	listRef: VariableSizeList | null = null;

	showSidebar = false;

	constructor(
		upload: Upload,
		highlights: RawHighlight[],
		activeHighlight?: RawHighlight,
	) {
		this.upload = upload;

		for (const highlight of highlights) {
			this.rawHighlights.set(highlight.textToHighlight.textStart, highlight);
		}

		if (activeHighlight) {
			this.rawActiveHighlight = activeHighlight;
		}

		makeAutoObservable(this);
	}

	toggleSidebar() {
		this.showSidebar = !this.showSidebar;
	}

	setHighlights(highlights: RawHighlight[]) {
		this.rawHighlights.clear();
		for (const highlight of highlights) {
			this.rawHighlights.set(highlight.textToHighlight.textStart, highlight);
		}
	}

	setCurrentPageIndex(index: number) {
		this._currentPageIndex = index;
	}

	setHighlightResult(id: string, result: HighlightResult) {
		this.highlightResults.set(id, result);
	}

	calculateHighlightResults = flow(function* (this: PDFViewerState) {
		if (!this.pdf) {
			return;
		}

		for (const [id, rawHighlight] of this.rawHighlights.entries()) {
			const { pageIndicesToSearch, textToHighlight } = rawHighlight;

			const pageTextsPromises = pageIndicesToSearch.map(async (pageIndex) => {
				if (!this.pdf) throw new Error("PDF not loaded");

				const page = await this.pdf.document.getPage(pageIndex + 1);
				const pageText = await page.getTextContent();
				return { pageIndex, pageText };
			});

			const pageTexts = yield Promise.all(pageTextsPromises);

			const { textStart, textEnd } = textToHighlight;
			const highlightResult = textEnd
				? computeGappedHighlight(textStart, textEnd, pageTexts)
				: computeHighlight(textStart, pageTexts);

			runInAction(() => {
				this.setHighlightResult(id, highlightResult);
			});
		}
	});

	calculateActiveHighlightResult = flow(function* (this: PDFViewerState) {
		if (!this.pdf) {
			return;
		}

		if (!this.rawActiveHighlight) return;

		const { pageIndicesToSearch, textToHighlight } = this.rawActiveHighlight;

		const pageTextsPromises = pageIndicesToSearch.map(async (pageIndex) => {
			if (!this.pdf) throw new Error("PDF not loaded");

			const page = await this.pdf.document.getPage(pageIndex + 1);
			const pageText = await page.getTextContent();
			return { pageIndex, pageText };
		});

		const pageTexts = yield Promise.all(pageTextsPromises);

		const { textStart, textEnd } = textToHighlight;

		const highlightResult = textEnd
			? computeGappedHighlight(textStart, textEnd, pageTexts)
			: computeHighlight(textStart, pageTexts);

		runInAction(() => {
			this.activeHighlightResult = highlightResult;
		});
	});

	get currentPageIndex() {
		return this._currentPageIndex;
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const PDFViewerContext = createContext<PDFViewerState>(null as any);

export const usePDFViewerContext = () => {
	const context = useContext(PDFViewerContext);
	if (!context) {
		throw new Error("useViewerContext must be used within a ViewerProvider");
	}
	return context;
};

export const PDFViewerProvider: React.FC<{
	children: React.ReactNode;
	upload: Upload;
	highlights: RawHighlight[];
	activeHighlight?: RawHighlight;
}> = ({ children, upload, highlights, activeHighlight }) => {
	const [viewerState] = useState(
		() => new PDFViewerState(upload, highlights, activeHighlight),
	);

	useEffect(
		function propagateHighlightParams() {
			viewerState.setHighlights(highlights);
		},
		[viewerState, highlights],
	);

	// Update active highlight
	useEffect(
		function updateActiveHighlight() {
			if (activeHighlight) viewerState.rawActiveHighlight = activeHighlight;
		},
		[viewerState, activeHighlight],
	);

	return (
		<PDFViewerContext.Provider value={viewerState}>
			{children}
		</PDFViewerContext.Provider>
	);
};
