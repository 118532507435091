import { Skeleton } from "@/components/ui/skeleton";
import {
	type TabTypeStateMap,
	useTab,
} from "@/contexts/tabs-context/tabs-context";
import { WebSearchComboboxCommandList } from "@/pages/web-search/web-search-combobox-command-list";
import type { WebSearchResponse } from "@api/schemas";
import { observer } from "mobx-react-lite";
import { useId } from "react";

const WebSearchResult = observer(
	({ result }: { result: WebSearchResponse["results"][number] }) => {
		return (
			<div className="flex flex-col p-2">
				<a
					className="text-neutral-500 text-xs"
					href={result.url}
					target="_blank"
					rel="noreferrer"
				>
					{result.url}
				</a>
				<a
					className="text-base text-neutral-900"
					href={result.url}
					target="_blank"
					rel="noreferrer"
				>
					{result.title}
				</a>
				<div className="text-neutral-500 text-sm">{result.snippet}</div>
			</div>
		);
	},
);

interface WebSearchResultsProps {
	webSearchResults: WebSearchResponse;
}

const WebSearchResults = observer(
	({ webSearchResults }: WebSearchResultsProps) => {
		const containerId = useId();

		const results = webSearchResults.results;

		if (results.length === 0) {
			return (
				<div className="flex h-full w-full items-center justify-center">
					<h1 className="text-neutral-500">No results found</h1>
				</div>
			);
		}

		const resultsHeader = (
			<div className="flex items-center justify-between border-b bg-neutral-50 px-2 py-1.5 text-neutral-500 text-sm">
				<div>
					{results.length} result
					{results.length > 1 ? "s" : ""}
				</div>
			</div>
		);

		return (
			<>
				{resultsHeader}
				<div
					// the key forces a rerender and a scroll reset when we switch between grouped and ungrouped results
					// or when the results change as indicated by result_id
					key={"web-search-results"}
					className="relative mt-2 flex grow flex-col gap-2 overflow-y-auto bg-white px-2 pb-2"
					id={containerId}
				>
					{results.map((result) => {
						return <WebSearchResult key={result.url} result={result} />;
					})}
				</div>
			</>
		);
	},
);

export const WebSearchBody = observer(() => {
	const tab = useTab();
	const webSearchState = tab.state as TabTypeStateMap["websearch"];
	const webSearchResult =
		webSearchState.appState.webSearchStore.getWebSearchResult(
			webSearchState.publishedWebSearchConfig,
		);
	const { webSearchLoading, webSearchResults } = webSearchResult ?? {};

	if (webSearchResults) {
		return <WebSearchResults webSearchResults={webSearchResults} />;
	}

	if (webSearchLoading) {
		return (
			<div className="relative flex grow flex-col overflow-y-auto bg-white">
				{[...Array(1)].map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="flex w-full flex-col space-y-2 p-6"
					>
						<div className="flex space-x-2">
							<Skeleton className="h-8 w-6" />
							<div className="flex min-w-0 grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full max-w-48" />
							</div>
						</div>
						<div className="flex w-full flex-col">
							<div className="flex grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	return <WebSearchComboboxCommandList />;
});
