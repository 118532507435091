import clsx from "clsx";
import { observer } from "mobx-react-lite";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { useDocumentContext } from "react-pdf";
import invariant from "tiny-invariant";

import type {
	PDFOutline,
	PDFOutlineItem,
} from "@/components/pdf/outline/outline";

type OutlineItemProps = {
	item: PDFOutlineItem;
	outline: PDFOutline;
	isActive?: boolean;
	pdf?: PDFDocumentProxy | false;
};

export const OutlineItem = observer((props: OutlineItemProps) => {
	const documentContext = useDocumentContext();

	const mergedProps = { ...documentContext, ...props };
	const { item, linkService, pdf } = mergedProps;

	invariant(
		pdf,
		"Attempted to load an outline, but no document was specified. Wrap <Outline /> in a <Document /> or pass explicit `pdf` prop.",
	);

	function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();

		invariant(
			linkService,
			"Either onItemClick callback or linkService must be defined in order to navigate to an outline item.",
		);

		if (linkService) {
			linkService.goToDestination(item.dest);
		}
	}

	return (
		<li
			className=""
			style={{
				marginLeft: `${item.level * 1}rem`,
			}}
		>
			<button
				className={clsx(
					"w-full min-w-0 truncate rounded p-0.5 text-left text-neutral-700",
					props.isActive ? "bg-neutral-200" : "",
				)}
				type="button"
				onClick={onClick}
			>
				{item.title}
			</button>
		</li>
	);
});
