import { FeedItemPreview } from "@/components/document-selectors/feed-item-preview";
import { UploadPreview } from "@/components/document-selectors/upload-preview";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { UploadCoverImage } from "@/components/upload-cover-image";
import { useAppContext } from "@/contexts/app-context/app-context";
import type {
	FeedChannelNode,
	FeedItemNode,
	FeedNode,
} from "@/contexts/app-context/feed-tree-handlers";
import type {
	AnyDirectoryNode,
	DirectoryNode,
} from "@/contexts/app-context/tree-handlers";
import {
	DocumentSelectorTab,
	SingleDocumentSelectorProvider,
	useSingleDocumentSelectorContext,
} from "@/contexts/document-selector-contexts";
import { formatUploadTitle } from "@/lib/utils";
import {
	type DocumentCellValueCellValue,
	type FeedChannel,
	type FeedItemMetadata,
	type Folder,
	PageResolution,
	type Upload,
} from "@api/schemas";
import { FolderOpen, FolderSimple } from "@phosphor-icons/react";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useMemo, useState } from "react";
import { type NodeApi, type NodeRendererProps, Tree } from "react-arborist";
import useResizeObserver from "use-resize-observer";

export const UploadTreeNode = observer(
	({ upload }: { node: NodeApi<DirectoryNode<"upload">>; upload: Upload }) => {
		const documentSelectorContext = useSingleDocumentSelectorContext();

		const isSelected = useMemo(
			() =>
				documentSelectorContext.selectedDocumentId?.document_id ===
				upload.upload_id,
			[documentSelectorContext.selectedDocumentId, upload.upload_id],
		);

		const onSelect = () => {
			runInAction(() => {
				documentSelectorContext.previewedUploadId = upload.upload_id;
			});

			runInAction(() => {
				documentSelectorContext.selectedDocumentId = {
					document_id: upload.upload_id,
					document_type: "upload",
				};
			});
		};

		return (
			<div
				className={clsx(
					"group flex h-full w-full items-center rounded-md px-2 py-1",
					isSelected ? "bg-blue-100" : "hover:bg-blue-50",
				)}
				key={upload.upload_id}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<div className="flex min-w-0 grow items-center gap-1.5 truncate pr-2 text-left">
					<div className="flex w-4 shrink-0 justify-center">
						<UploadCoverImage
							className={(uploadStatus) =>
								clsx(
									"h-4 max-w-6 rounded-xs",
									uploadStatus === "ready" && "shadow-lg",
								)
							}
							upload_id={upload.upload_id}
							upload_status={upload.upload_status ?? "pending"}
							resolution={PageResolution.thumbnail}
						/>
					</div>

					<div className="min-w-0 truncate">
						<h2 className="flex min-w-0 items-center gap-1 truncate text-sm leading-4">
							{formatUploadTitle({
								title: upload.upload_title,
								subtitle: upload.upload_subtitle,
								filename: upload.file_name,
							})}
						</h2>
					</div>
				</div>
			</div>
		);
	},
);

export const FolderTreeNode = observer(
	({
		node,
		folder,
	}: { node: NodeApi<DirectoryNode<"folder">>; folder: Folder }) => {
		return (
			<div
				className={clsx(
					"group flex h-full w-full items-center rounded-md px-2 py-1",
				)}
			>
				<div className="flex grow items-center gap-1.5">
					<button
						type="button"
						onClick={() => {
							node.toggle();
						}}
						className="flex w-4 items-center justify-center"
					>
						{node.isOpen ? (
							<FolderOpen
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						) : (
							<FolderSimple
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						)}
					</button>
					<span className="text-sm">{folder.file_name}</span>
				</div>
			</div>
		);
	},
);

const LibraryTreeNode = observer(
	({ node, style, dragHandle }: NodeRendererProps<AnyDirectoryNode>) => {
		return (
			<div
				style={style}
				ref={dragHandle}
				className="mr-2 ml-2 flex h-8 items-center pt-0.5"
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						node.toggle();
					}
				}}
			>
				{(new Array(node.level).fill(0) as number[]).map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="mr-2 ml-0.5 h-8 w-4 shrink-0 border-neutral-200 border-r-2"
					/>
				))}
				{node.data.file.file_type === "folder" ? (
					<FolderTreeNode
						node={node as NodeApi<DirectoryNode<"folder">>}
						folder={node.data.file}
					/>
				) : (
					<UploadTreeNode
						node={node as NodeApi<DirectoryNode<"upload">>}
						upload={node.data.file as Upload}
					/>
				)}
			</div>
		);
	},
);

export const FeedItemNodeComponent = observer(
	({
		feedItem,
	}: { node: NodeApi<FeedItemNode>; feedItem: FeedItemMetadata }) => {
		const documentSelectorContext = useSingleDocumentSelectorContext();
		const isSelected = useMemo(
			() =>
				documentSelectorContext.selectedDocumentId?.document_id ===
				feedItem.feed_item_id,
			[documentSelectorContext.selectedDocumentId, feedItem.feed_item_id],
		);

		const onSelect = () => {
			runInAction(() => {
				documentSelectorContext.previewedFeedItemId = feedItem.feed_item_id;
				documentSelectorContext.selectedDocumentId = {
					document_id: feedItem.feed_item_id,
					document_type: "feed_item",
				};
			});
		};

		return (
			<div
				className={clsx(
					"group flex h-full w-full min-w-0 items-center truncate rounded-md px-1 py-1",
					isSelected ? "bg-blue-100" : "hover:bg-blue-50",
				)}
				key={feedItem.feed_item_id}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<div className="flex min-w-0 grow items-center gap-1.5 truncate pr-2 text-left">
					<div className="flex shrink-0 justify-center">
						{/* {feedItem.feed_item_status ?? "pending"} */}
						{feedItem.feed_item_og_image ? (
							<img
								src={feedItem.feed_item_og_image}
								alt={feedItem.file_name ?? ""}
								className="flex h-6 w-9 shrink-0 items-center justify-center rounded object-cover text-xs"
							/>
						) : (
							<></>
						)}
					</div>

					<div className="min-w-0 truncate">
						<h2 className="flex min-w-0 items-center gap-1 truncate text-sm leading-4">
							{feedItem.file_name}
						</h2>
					</div>
				</div>
			</div>
		);
	},
);

export const FeedChannelNodeComponent = observer(
	({
		node,
		feedChannel,
	}: { node: NodeApi<FeedChannelNode>; feedChannel: FeedChannel }) => {
		return (
			<div
				className={clsx(
					"group flex h-full w-full items-center rounded-md px-2 py-1",
				)}
			>
				<div className="flex grow items-center gap-1.5">
					<button
						type="button"
						onClick={() => {
							node.toggle();
						}}
						className="flex w-4 items-center justify-center"
					>
						{node.isOpen ? (
							<FolderOpen
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						) : (
							<FolderSimple
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						)}
					</button>
					<h1 className="min-w-0 truncate text-sm">{feedChannel.file_name}</h1>
				</div>
			</div>
		);
	},
);

const FeedsTreeNode = observer(
	({ node, style, dragHandle }: NodeRendererProps<FeedNode>) => {
		return (
			<div
				style={style}
				ref={dragHandle}
				className="mr-2 ml-2 flex h-8 min-w-0 items-center truncate pt-0.5"
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						node.toggle();
					}
				}}
			>
				{(new Array(node.level).fill(0) as number[]).map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="mr-2 ml-0.5 h-8 w-4 shrink-0 border-neutral-200 border-r-2"
					/>
				))}
				{node.data.item.type === "feed_channel" ? (
					<FeedChannelNodeComponent
						node={node}
						feedChannel={node.data.item.data}
					/>
				) : (
					<FeedItemNodeComponent node={node} feedItem={node.data.item.data} />
				)}
			</div>
		);
	},
);

type SingleSelectProps = {
	selectedDocumentId: DocumentCellValueCellValue | null;
	onSelect: React.Dispatch<DocumentCellValueCellValue | null>;
	popoverTrigger: ({ open }: { open: boolean }) => React.ReactNode;
	className?: string;
	disabled?: boolean;
};

const _DocumentSelector: React.FC<SingleSelectProps> = observer(
	({
		selectedDocumentId,
		onSelect,
		popoverTrigger,
		className,
		disabled,
		...props
	}) => {
		const appContext = useAppContext();
		const documentSelectorContext = useSingleDocumentSelectorContext();
		const [open, setOpen] = React.useState(false);
		const { ref, height } = useResizeObserver<HTMLDivElement>();
		const { ref: feedRef, height: feedHeight } =
			useResizeObserver<HTMLDivElement>();

		const [documentsQuery, setDocumentsQuery] = useState("");

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const matchedUploadIds = useMemo(() => {
			return appContext.searchUploadsByMetadata(documentsQuery);
		}, [documentsQuery]);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const matchedFeedItemIds = useMemo(() => {
			return appContext.searchFeedItemsByMetadata(documentsQuery);
		}, [documentsQuery]);

		return (
			// For some reason `disabled` doesn't work for the trigger, so we have to
			// manually check if it's disabled and set the open state to false
			<Dialog
				open={disabled ? false : open}
				onOpenChange={(newOpen) => {
					!disabled && setOpen(newOpen);
				}}
				{...props}
			>
				<DialogTrigger asChild disabled={disabled}>
					{popoverTrigger({ open })}
				</DialogTrigger>
				<DialogContent
					className="flex max-w-screen-md flex-col gap-0 overflow-hidden p-0"
					style={{
						height: "var(--radix-popover-content-available-height)",
					}}
				>
					<section className="flex h-[50vh] min-h-0 w-full flex-col">
						<div className="w-full max-w-96 px-2 pt-2">
							<Input
								placeholder="Search documents..."
								value={documentsQuery}
								onChange={(e) => {
									setDocumentsQuery(e.target.value);
								}}
							/>
						</div>
						<Tabs
							className="flex min-h-0 grow flex-col"
							value={documentSelectorContext.openTab}
							onValueChange={(newValue) => {
								documentSelectorContext.setOpenTab(
									newValue as DocumentSelectorTab,
								);
							}}
						>
							<div className="w-full border-b px-2 pt-2 pb-2">
								<TabsList className="">
									<TabsTrigger value={DocumentSelectorTab.uploads}>
										Uploads
									</TabsTrigger>
									<TabsTrigger value={DocumentSelectorTab.feed_items}>
										Feed Items
									</TabsTrigger>
								</TabsList>
							</div>

							<TabsContent
								value={DocumentSelectorTab.uploads}
								ref={ref}
								className="m-0 flex min-h-0 w-full grow"
							>
								<div className="w-2/3">
									{appContext.workspace ? (
										<Tree
											data={appContext.fileNodeTree.rootNodes}
											idAccessor={(node) => node.id}
											openByDefault={false}
											width={"100%"}
											height={height}
											rowHeight={32}
											paddingTop={0}
											paddingBottom={0}
											padding={8}
											indent={0}
											disableEdit
											disableDrag
											disableDrop
											searchTerm={documentsQuery}
											searchMatch={(node) => {
												if (node.data.file.file_type === "upload") {
													return matchedUploadIds.has(node.data.file.upload_id);
												}
												return false;
											}}
										>
											{LibraryTreeNode}
										</Tree>
									) : (
										<div className="mt-2 flex flex-col gap-1 p-2">
											<div className="flex items-center gap-2">
												<Skeleton className="h-6 w-6" />
												<Skeleton className="h-6 w-72" />
											</div>
											<div className="flex items-center gap-2">
												<Skeleton className="h-6 w-6" />
												<Skeleton className="h-6 w-72" />
											</div>
											<div className="flex items-center gap-2">
												<Skeleton className="h-6 w-6" />
												<Skeleton className="h-6 w-72" />
											</div>
										</div>
									)}
								</div>
								<div className="flex w-1/3">
									<UploadPreview
										upload={documentSelectorContext.previewedUpload}
									/>
								</div>
							</TabsContent>
							<TabsContent
								value={DocumentSelectorTab.feed_items}
								ref={feedRef}
								className="m-0 flex min-h-0 w-full grow"
							>
								{/** Feed items */}
								<div className="w-2/3 min-w-0 shrink-0 truncate">
									{appContext.feedChannelNodes ? (
										<Tree
											data={appContext.feedChannelNodes}
											idAccessor={(node) => node.node_id}
											childrenAccessor={(node) =>
												appContext.feedTreeChildrenAccessor(node)
											}
											openByDefault={true}
											width={"100%"}
											height={feedHeight}
											rowHeight={32}
											paddingTop={0}
											paddingBottom={0}
											padding={8}
											indent={0}
											disableEdit
											disableDrag
											disableDrop
											disableMultiSelection
											searchTerm={documentsQuery}
											searchMatch={(node) => {
												if (node.data.item.type === "feed_item") {
													return matchedFeedItemIds.has(
														node.data.item.data.feed_item_id,
													);
												}
												return false;
											}}
										>
											{FeedsTreeNode}
										</Tree>
									) : (
										<div className="mt-2 flex flex-col gap-1 p-2">
											<div className="flex items-center gap-2">
												<Skeleton className="h-6 w-6" />
												<Skeleton className="h-6 w-72" />
											</div>
											<div className="flex items-center gap-2">
												<Skeleton className="h-6 w-6" />
												<Skeleton className="h-6 w-72" />
											</div>
											<div className="flex items-center gap-2">
												<Skeleton className="h-6 w-6" />
												<Skeleton className="h-6 w-72" />
											</div>
										</div>
									)}
								</div>
								<div className="flex w-1/3">
									<FeedItemPreview
										feedItem={documentSelectorContext.previewedFeedItem}
									/>
								</div>
							</TabsContent>
						</Tabs>
					</section>

					<section>
						<div className="flex items-center justify-end gap-2 border-t p-2">
							<Button
								variant="outline"
								onClick={() => {
									setOpen(false);
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									onSelect(documentSelectorContext.selectedDocumentId);
									setOpen(false);
								}}
							>
								Select
							</Button>
						</div>
					</section>
				</DialogContent>
			</Dialog>
		);
	},
);

export const SingleDocumentSelector = (props: SingleSelectProps) => {
	return (
		<SingleDocumentSelectorProvider
			selectedDocumentId={props.selectedDocumentId}
		>
			<_DocumentSelector {...props} />
		</SingleDocumentSelectorProvider>
	);
};
