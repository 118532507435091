import { Extension } from "@tiptap/core";

export const Outline = Extension.create({
	name: "Outline",

	addGlobalAttributes() {
		return [
			{
				types: ["heading"],
				attributes: {
					id: {
						default: null,
					},
				},
			},
		];
	},
});
