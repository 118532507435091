import type {
	BlockId,
	CellId,
	ColumnId,
	EventId,
	FeedChannelId,
	FeedItemId,
	FileId,
	FilterId,
	FolderId,
	MessageId,
	PageId,
	RowId,
	SessionUserId,
	StepId,
	TableId,
	UploadId,
	UserId,
} from "@api/schemas";
import { ulid } from "ulidx";

type IdType =
	| "step"
	| "feed-channel"
	| "feed-item"
	| "folder"
	| "table"
	| "column"
	| "row"
	| "cell"
	| "upload"
	| "page"
	| "block"
	| "file"
	// Session types
	| "session-user"
	| "event"
	| "message"
	| "filter";

type IdMap = {
	step: StepId;
	"feed-channel": FeedChannelId;
	"feed-item": FeedItemId;
	folder: FolderId;
	table: TableId;
	column: ColumnId;
	row: RowId;
	cell: CellId;
	upload: UploadId;
	page: PageId;
	block: BlockId;
	user: UserId;
	file: FileId;
	// Session types
	"session-user": SessionUserId;
	message: MessageId;
	event: EventId;
	filter: FilterId;
};

const generateId = <T extends IdType>(prefix: T): IdMap[T] => {
	return `${prefix}_${ulid()}` as IdMap[T];
};

export const newStepId = () => generateId("step");
export const newFeedChannelId = () => generateId("feed-channel");
export const newFeedItemId = () => generateId("feed-item");
export const newFolderId = () => generateId("folder");
export const newTableId = () => generateId("table");
export const newColumnId = () => generateId("column");
export const newRowId = () => generateId("row");
export const newPageId = () => generateId("page");
export const newBlockId = () => generateId("block");
export const formatCellId = ({
	rowId,
	columnId,
}: { rowId: RowId; columnId: ColumnId }) => `${rowId}:${columnId}` as CellId;
export const newUploadId = () => generateId("upload");
export const newFilterId = () => generateId("filter");
// Session types
export const getNewSessionUserId = () => generateId("session-user");
export const getNewMessageId = () => generateId("message");
export const getNewEventId = () => generateId("event");
