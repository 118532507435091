import { API_ENDPOINT_WS, IS_DEV } from "@/config";
import {
	type CommandKPage,
	getLastCmdKPage,
	openCmdKPage,
	popCmdKPage,
	pushCmdKPage,
	setCmdKOpen,
} from "@/contexts/app-context/cmd-k";
import { AppContext } from "@/contexts/app-context/context";
import {
	feedChannelImmediateChildren,
	feedChannelNodes,
	feedTreeChildrenAccessor,
} from "@/contexts/app-context/feed-tree-handlers";
import {
	addFeedChannelAction,
	feedChannelsById,
	feedItemsById,
	searchFeedItemsByMetadata,
	sortedFeedChannels,
	sortedFeedItemsByChannel,
} from "@/contexts/app-context/feeds";
import {
	createFolderAction,
	deleteFilesAction,
	moveFilesAction,
	renameFileAction,
} from "@/contexts/app-context/files";
import { RightSidebarState } from "@/contexts/app-context/right-sidebar-state";
import {
	type AssistantEvent,
	type AssistantEventMap,
	createMessageRouter,
	createNewMessage,
	fillTable,
	handleEvent,
	handleEventLocally,
} from "@/contexts/app-context/session";
import { SidebarState } from "@/contexts/app-context/sidebar-state";
import { fileNodeTree } from "@/contexts/app-context/tree-handlers";
import {
	createUpload,
	downloadOriginalUploadFile,
	downloadUploadPdf,
	searchUploadsByMetadata,
	sortedIndexedUploads,
	updateUploadMetadataAction,
} from "@/contexts/app-context/uploads";
import {
	attemptReconnect,
	handleWorkspaceUpdate,
} from "@/contexts/app-context/workspace-updates";
import { PagesState } from "@/contexts/page-context/page-context";
import { createPageAction } from "@/contexts/page-context/page-handlers";
import { TablesState } from "@/contexts/table-context/table-context";
import {
	createComputedTableAction,
	createTableAction,
} from "@/contexts/table-context/table-handlers";
import { SearchStore } from "@/contexts/tabs-context/tab-states/search-state";
import { WebSearchStore } from "@/contexts/tabs-context/tab-states/web-search-state";
import { TabStore } from "@/contexts/tabs-context/tabs-context";
import { getNewSessionUserId } from "@/id-generators";
import { bootstrapSession } from "@api/fastAPI";
import type {
	ActiveAssistantSessionStatus,
	AgentType,
	AssistantStatus,
	Event,
	EventId,
	FeedChannel,
	FeedChannelId,
	FeedItem,
	FeedItemId,
	FeedItemMetadata,
	File,
	FileId,
	Folder,
	FolderId,
	Message,
	MessageId,
	MessageWithMetadata,
	OpenedThreadEvent,
	Page,
	PageId,
	SessionAssistant,
	SessionAssistantId,
	SessionId,
	SessionUser,
	SessionUserId,
	Step,
	StepId,
	TableId,
	TableMetadata,
	Upload,
	UploadId,
	UserId,
	WorkspaceUpdateEvent,
} from "@api/schemas";
import type { User } from "@api/schemas/user";
import { useAuth, useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import flexsearch from "flexsearch";
import { makeAutoObservable, runInAction } from "mobx";
import { type ReactNode, useContext, useEffect, useState } from "react";
import { toast } from "sonner";

interface Workspace {
	userId: UserId;
	uploads: Map<UploadId, Upload>;
	folders: Map<FolderId, Folder>;
	feedChannels: Map<FeedChannelId, FeedChannel>;
	feedItems: Map<FeedItemId, FeedItemMetadata>;
	tables: Map<TableId, TableMetadata>;
	pages: Map<PageId, Page>;
	users: Map<UserId, User>;
}

interface Session {
	sessionsUser: Map<SessionUserId, SessionUser>;
	sessionsAssistant: Map<SessionAssistantId, SessionAssistant>;
	messages: Map<MessageId, Message>;
	events: Map<EventId, Event>;
	steps: Map<StepId, Step>;
	activeAssistantSessionStatuses: Map<SessionAssistantId, AssistantStatus>;
}

export class AppState {
	userId: UserId;
	workspace: Workspace | null = null;
	tabStore: TabStore = new TabStore(this);
	tablesState: TablesState = new TablesState(this);
	pagesState: PagesState = new PagesState(this);
	searchStore: SearchStore = new SearchStore(this);
	webSearchStore: WebSearchStore = new WebSearchStore(this);

	sessionUserId: SessionUserId;
	session: Session | null = null;
	uploadsFlexsearchIndex = new flexsearch.Document<Upload>({
		tokenize: "full",
		document: {
			id: "upload_id",
			index: ["upload_title", "upload_subtitle", "upload_authors", "file_name"],
		},
	});
	feedItemsFlexsearchIndex = new flexsearch.Document<FeedItemMetadata>({
		tokenize: "full",
		document: {
			id: "feed_item_id",
			index: [
				"file_name",
				"feed_item_subtitle",
				"feed_item_description",
				"feed_item_link",
				"feed_item_author",
			],
		},
	});

	// UI State
	sidebarState: SidebarState = new SidebarState(this);
	rightSidebarState: RightSidebarState = new RightSidebarState(this);

	pdfScale = 1;

	// navigate: ReturnType<typeof useNavigate>;
	getToken: ReturnType<typeof useAuth>["getToken"];

	// Command K
	cmdKOpen = false;
	cmdKPages: CommandKPage[] = [];
	showAddFeedDialog = false;
	showCreateTableDialog = false;
	showCreateComputedTableDialog = false;

	// WebSocket for workspace-level updates
	ws: WebSocket | null = null;
	wsConnected = false;
	reconnectAttempts = 0;
	reconnectTimeoutId: Timer | null = null;

	// Files currently being uploaded by the current user, not persisted across sessions
	recentUploads: Map<UploadId, Upload> = new Map();
	get recentUploadsArray() {
		return Array.from(this.recentUploads.values()).sort((a, b) =>
			a.upload_id.localeCompare(b.upload_id),
		);
	}

	constructor({
		userId,
		getToken,
	}: {
		userId: UserId;
		getToken: ReturnType<typeof useAuth>["getToken"];
	}) {
		this.userId = userId;
		this.getToken = getToken;
		makeAutoObservable(this);
		this.sessionUserId = this.#initializeSessionUserId(userId);
	}

	#initializeSessionUserId(userId: UserId): SessionUserId {
		const STORAGE_KEY = "userIdSessionUserIdRecord";
		let record: Record<UserId, SessionUserId> = {};

		const recordString = localStorage.getItem(STORAGE_KEY);
		if (recordString) {
			try {
				record = JSON.parse(recordString) as Record<UserId, SessionUserId>;
			} catch (error) {
				console.warn("Failed to parse userIdSessionUserIdRecord:", error);
				localStorage.removeItem(STORAGE_KEY);
			}
		}

		if (userId in record) {
			return record[userId];
		}

		const newSessionUserId = getNewSessionUserId();
		record[userId] = newSessionUserId;
		try {
			localStorage.setItem(STORAGE_KEY, JSON.stringify(record));
		} catch (error) {
			console.error("Failed to set userIdSessionUserIdRecord:", error);
		}

		return newSessionUserId;
	}

	async init({
		isReconnect,
	}: {
		isReconnect: boolean;
	}) {
		const token = await this.getToken();

		if (!token) {
			Sentry.captureMessage("No token found in AppContext", "error");
			toast.error("Unable to authenticate. Please refresh the page.");
			return;
		}

		bootstrapSession()
			.then((res) => {
				runInAction(() => {
					// TODO(John): add other indexes
					for (const upload of res.data.uploads) {
						if (!upload.file_deleted_at && upload.upload_status !== "failed") {
							this.uploadsFlexsearchIndex.add(upload);
						}
					}
					for (const feed_item of res.data.feed_items) {
						if (!feed_item.file_deleted_at) {
							this.feedItemsFlexsearchIndex.add(feed_item);
						}
					}

					this.workspace = {
						uploads: new Map(
							res.data.uploads.map((upload) => [upload.upload_id, upload]),
						),
						folders: new Map(
							res.data.folders.map((folder) => [folder.folder_id, folder]),
						),
						feedChannels: new Map(
							res.data.feed_channels.map((feedChannel) => [
								feedChannel.feed_channel_id,
								feedChannel,
							]),
						),
						feedItems: new Map(
							res.data.feed_items.map((feedItem) => [
								feedItem.feed_item_id,
								feedItem,
							]),
						),
						tables: new Map(
							res.data.tables.map((table) => [table.table_id, table]),
						),
						pages: new Map(res.data.pages.map((page) => [page.page_id, page])),
						users: new Map(
							res.data.users.map((user) => [user.user_id as UserId, user]),
						),
						userId: this.userId as UserId,
					};
					this.session = {
						sessionsUser: new Map(
							res.data.session_users.map((user) => [
								user.session_user_id,
								user,
							]),
						),
						sessionsAssistant: new Map(
							res.data.session_assistants.map((assistant) => [
								assistant.session_assistant_id,
								assistant,
							]),
						),
						messages: new Map(
							res.data.messages.map((message) => [message.message_id, message]),
						),
						events: new Map(
							res.data.events.map((event) => [event.event_id, event]),
						),
						steps: new Map(res.data.steps.map((step) => [step.step_id, step])),
						activeAssistantSessionStatuses: new Map(
							res.data.assistant_session_statuses.map((status) => [
								status.assistant_session_id,
								status.status,
							]),
						),
					};
				});
				runInAction(() => {
					this.rightSidebarState.setMessagesRouter(
						createMessageRouter(this.threadEvents[0]?.data.thread_id ?? null),
					);
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to initialize session. Please refresh the page.");
				}
			});

		const ws = new WebSocket(
			`${API_ENDPOINT_WS}/sessions/ws?token=${token}&session_user_id=${this.sessionUserId}`,
		);

		ws.onopen = () => {
			runInAction(() => {
				this.ws = ws;
				this.wsConnected = true;
				if (isReconnect) {
					this.reconnectAttempts = 0;
					toast.success("Reconnected to workspace.");
				}
			});
		};

		ws.onmessage = (event) => {
			try {
				const data:
					| WorkspaceUpdateEvent
					| Event
					| Step
					| ActiveAssistantSessionStatus = JSON.parse(event.data);
				if ("event_id" in data) {
					this.handleEventLocally(data);
				} else if ("step_id" in data) {
					this.createStepLocally(data);
				} else if ("assistant_session_id" in data) {
					this.session?.activeAssistantSessionStatuses.set(
						data.assistant_session_id as SessionAssistantId,
						data.status as AssistantStatus,
					);
				} else {
					this.#handleWorkspaceUpdate(data);
				}
			} catch (e) {
				console.error("Error parsing websocket response JSON:", e);
				return;
			}
		};

		ws.onclose = (e) => {
			// 1000 is the code for "normal closure"
			if (e.code === 1000) {
				return;
			}
			runInAction(() => {
				this.wsConnected = false;
				this.ws = null;
			});

			this.#attemptReconnect();
		};

		ws.onerror = (error) => {
			Sentry.captureException(error);
			this.#attemptReconnect();
		};
	}

	/*
	Workspace updates channel methods
	*/
	#attemptReconnect = attemptReconnect.bind(this);
	#handleWorkspaceUpdate = handleWorkspaceUpdate.bind(this);
	/*
	Command K-related methods
	*/
	popCmdKPage = popCmdKPage.bind(this);
	pushCmdKPage = pushCmdKPage.bind(this);
	setCmdKOpen = setCmdKOpen.bind(this);
	openCmdKPage = openCmdKPage.bind(this);
	get lastCmdKPage() {
		return getLastCmdKPage.bind(this)();
	}

	/*
	Library-related methods
	*/
	get workspaceHasLoaded() {
		return this.workspace !== null;
	}
	get sortedIndexedUploads() {
		return sortedIndexedUploads.bind(this)();
	}
	getUploadById(uploadId: UploadId) {
		return this.workspace?.uploads.get(uploadId) ?? null;
	}
	getFolderById(folderId: FolderId) {
		return this.workspace?.folders.get(folderId) ?? null;
	}
	updateUploadMetadata = updateUploadMetadataAction.bind(this);
	createFolder = createFolderAction.bind(this);
	createUpload = createUpload.bind(this);
	downloadUploadPdf = downloadUploadPdf.bind(this);
	downloadOriginalUploadFile = downloadOriginalUploadFile.bind(this);
	searchUploadsByMetadata = searchUploadsByMetadata.bind(this);
	renameFile = renameFileAction.bind(this);
	deleteFiles = deleteFilesAction.bind(this);
	moveFiles = moveFilesAction.bind(this);

	/*
	Library tree-related methods
	*/
	get fileNodeTree() {
		return fileNodeTree.bind(this)();
	}

	/*
	Feed-related methods
	*/
	addFeedChannel = addFeedChannelAction.bind(this);
	get feedItemsById() {
		return feedItemsById.bind(this)();
	}
	getFeedItemById(feedItemId: FeedItemId) {
		return this.workspace?.feedItems.get(feedItemId) ?? null;
	}
	get feedChannelsById() {
		return feedChannelsById.bind(this)();
	}
	get sortedFeedChannels() {
		return sortedFeedChannels.bind(this)();
	}
	get sortedFeedItemsByChannel() {
		return sortedFeedItemsByChannel.bind(this)();
	}
	searchFeedItemsByMetadata = searchFeedItemsByMetadata.bind(this);
	feedTreeChildrenAccessor = feedTreeChildrenAccessor.bind(this);
	get feedChannelNodes() {
		return feedChannelNodes.bind(this)();
	}
	get feedChannelImmediateChildren() {
		return feedChannelImmediateChildren.bind(this)();
	}

	/*
	User-related methods
	*/
	getUserById(userId: UserId) {
		return this.workspace?.users.get(userId) ?? null;
	}

	/*
	Table-related methods
	*/
	createTable = createTableAction.bind(this);
	createComputedTable = createComputedTableAction.bind(this);

	get tablesAsArray() {
		const allTables = this.workspace
			? Array.from(this.workspace.tables.values())
			: null;
		if (!allTables) {
			return null;
		}
		return allTables.filter((table) => !table.file_deleted_at);
	}
	getTableById(tableId: TableId) {
		return this.workspace?.tables.get(tableId) ?? null;
	}

	/*
	Page-related methods
	*/
	createPage = createPageAction.bind(this);
	getPageById(pageId: PageId) {
		return this.workspace?.pages.get(pageId) ?? null;
	}

	/*
	Session Methods
	*/
	get allMessagesWithMetadata(): Map<MessageId, MessageWithMetadata> {
		if (!this.session) {
			return new Map();
		}

		const getMessageMetadata = (
			message: Message,
		): { agent_type: AgentType; user: User; session_id: SessionId } | null => {
			const maybeSessionUser = this.session?.sessionsUser.get(
				message.session_id as SessionUserId,
			);
			if (maybeSessionUser) {
				const user = this.getUserById(maybeSessionUser.user_id);
				if (!user) {
					const errorMsg = `User ${maybeSessionUser.user_id} for session user ${maybeSessionUser.session_user_id} not found`;
					console.error(errorMsg);
					Sentry.captureMessage(errorMsg, "error");
					return null;
				}
				return {
					agent_type: "user",
					user,
					session_id: maybeSessionUser.session_user_id,
				};
			}
			const maybeSessionAssistant = this.session?.sessionsAssistant.get(
				message.session_id as SessionAssistantId,
			);
			if (maybeSessionAssistant) {
				const user = this.getUserById(maybeSessionAssistant.user_id);
				if (!user) {
					const errorMsg = `User ${maybeSessionAssistant.user_id} for session assistant ${maybeSessionAssistant.session_assistant_id} not found`;
					console.error(errorMsg);
					Sentry.captureMessage(errorMsg, "error");
					return null;
				}
				return {
					agent_type: "assistant",
					user,
					session_id: maybeSessionAssistant.session_assistant_id,
				};
			}
			const errorMsg = `Message ${message.message_id} has session_id ${message.session_id} but no session user or session assistant found`;
			console.error(errorMsg);
			Sentry.captureMessage(errorMsg, "error");
			return null;
		};

		const messagesWithMetadata: Map<MessageId, MessageWithMetadata> = new Map(
			Array.from(this.session?.messages.values() ?? [])
				.map((message) => {
					const metadata = getMessageMetadata(message);
					if (!metadata) return null;
					const messageWithMetadata: MessageWithMetadata = {
						...message,
						...metadata,
						replies: [],
					};
					return [message.message_id, messageWithMetadata];
				})
				.filter(
					(entry): entry is [MessageId, MessageWithMetadata] => entry !== null,
				),
		);

		// Handle the replies
		for (const message of messagesWithMetadata.values()) {
			if (message.parent_message_id !== null) {
				const parentMessage = messagesWithMetadata.get(
					message.parent_message_id,
				);
				if (parentMessage) {
					parentMessage.replies.push(message);
				} else {
					Sentry.captureMessage(
						`Parent message not found for message ${message.message_id}. Should never happen.`,
						"warning",
					);
					console.warn(
						`Parent message not found for message ${message.message_id}. Should never happen.`,
					);
				}
			}
		}

		// Sort replies
		for (const message of messagesWithMetadata.values()) {
			message.replies.sort(
				(a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
			);
		}

		return messagesWithMetadata;
	}

	/**
	 * Gets the list of ancestor messages for a message, in order from root to target.
	 */
	getMessageAncestors(messageId: MessageId): MessageWithMetadata[] | null {
		const ancestors: MessageWithMetadata[] = [];
		let currentMessage = this.allMessagesWithMetadata.get(messageId);

		if (!currentMessage) {
			Sentry.captureMessage(
				`Message with ID ${messageId} not found.`,
				"warning",
			);
			console.warn(`Message with ID ${messageId} not found.`);
			return null;
		}
		ancestors.push(currentMessage);
		while (currentMessage.parent_message_id !== null) {
			const parentMessage = this.allMessagesWithMetadata.get(
				currentMessage.parent_message_id,
			);
			if (!parentMessage) {
				Sentry.captureMessage(
					`Parent message with ID ${currentMessage.parent_message_id} not found.`,
					"warning",
				);
				console.warn(
					`Parent message with ID ${currentMessage.parent_message_id} not found.`,
				);
				return null;
			}
			currentMessage = parentMessage;
			ancestors.push(currentMessage);
		}
		return ancestors.reverse();
	}

	get allRootMessages(): MessageWithMetadata[] {
		return Array.from(this.allMessagesWithMetadata.values()).filter(
			(message) => message.parent_message_id === null,
		);
	}

	getMessage(messageId: MessageId): MessageWithMetadata | null {
		const message = this.allMessagesWithMetadata.get(messageId);
		if (!message) {
			Sentry.captureMessage(
				`No message found for message ${messageId}`,
				"warning",
			);
			console.warn(`No message found for message ${messageId}`);
			return null;
		}
		return message;
	}

	/**
	 * Returns the thread events for the current session user. Sorted from most
	 * recent to least recent.
	 */
	get threadEvents(): OpenedThreadEvent[] {
		return Array.from(this.session?.events.values() ?? [])
			.filter(
				(event): event is OpenedThreadEvent => event.type === "opened_thread",
			)
			.filter((event) => event.data.session_id === this.sessionUserId)
			.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
	}

	createStepLocally = (step: Step) => {
		this.session?.steps.set(step.step_id, step);
	};
	createMessageLocally = (message: Message) => {
		this.session?.messages.set(message.message_id, message);
	};
	createSessionUserLocally = (sessionUser: SessionUser) => {
		this.session?.sessionsUser.set(sessionUser.session_user_id, sessionUser);
	};
	createSessionAssistantLocally = (sessionAssistant: SessionAssistant) => {
		this.session?.sessionsAssistant.set(
			sessionAssistant.session_assistant_id,
			sessionAssistant,
		);
	};
	handleEventLocally = handleEventLocally.bind(this);
	handleEvent = handleEvent.bind(this);

	fillTable = fillTable.bind(this);

	/**
	 * Sends a message. Assumes the message is being sent from the current open
	 * message.
	 */
	sendMessage(content: string) {
		const newMessage = createNewMessage({
			content,
			sessionId: this.sessionUserId,
			parentMessageId: this.rightSidebarState.currentActiveMessageId,
		});
		return this.handleEvent(
			{
				eventType: "sent_message",
				data: {
					session_id: this.sessionUserId,
					message: newMessage,
				},
			},
			() => {
				if (this.rightSidebarState.currentActiveMessageId === null) {
					this.rightSidebarState.navigateMessages(newMessage.message_id);
				}
			},
		);
	}

	get sortedAssistantActivity(): (AssistantEvent | Step)[] {
		const filteredEvents = Array.from(
			this.session?.events.values() ?? [],
		).filter((event): event is AssistantEvent => {
			if (!("session_id" in event.data)) {
				return false;
			}
			const sessionAssistant = this.session?.sessionsAssistant.get(
				event.data.session_id as SessionAssistantId,
			);
			if (!sessionAssistant) {
				return false;
			}
			return sessionAssistant.user_id === this.workspace?.userId;
		});
		const filteredSteps = Array.from(this.session?.steps.values() ?? []);
		return [...filteredEvents, ...filteredSteps].sort(
			(a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
		);
	}

	/**
	 * Returns an array of objects, each containing a session assistant id and
	 * the activity for that session assistant.
	 *
	 * This array is sorted by the timestamp of the first activity item in each
	 * session assistant's activity.
	 */
	get sortedAssistantActivityGroupedBySession(): Map<
		SessionAssistantId,
		(AssistantEvent | Step)[]
	> {
		const groupedBySession = new Map<
			SessionAssistantId,
			(AssistantEvent | Step)[]
		>();

		// First group all activities by session
		for (const eventOrStep of this.sortedAssistantActivity) {
			const sessionId =
				"event_id" in eventOrStep
					? eventOrStep.data.session_id
					: eventOrStep.session_assistant_id;

			const existing = groupedBySession.get(sessionId) ?? [];
			groupedBySession.set(sessionId, [...existing, eventOrStep]);
		}

		// Create a sorted array of entries based on first activity timestamp
		const sortedEntries = Array.from(groupedBySession.entries()).sort(
			([, activitiesA], [, activitiesB]) => {
				const firstActivityA = activitiesA[0];
				const firstActivityB = activitiesB[0];
				return (
					Date.parse(firstActivityA.created_at) -
					Date.parse(firstActivityB.created_at)
				);
			},
		);

		// Create a new Map with the sorted entries
		return new Map(sortedEntries);
	}

	// This is an arrow function because we pass it to ObjectLink.configure,
	// which calls it with a different this context.
	searchUploadsAndTables = (
		query: string,
	): {
		id: string;
		label: string;
	}[] => {
		const tableMetadatas = (this.tablesAsArray ?? []).filter((table) =>
			table.file_name.toLowerCase().includes(query.toLowerCase()),
		);
		const uploadResults = this.searchUploadsByMetadata(query);
		const uploadMetadatas = Array.from(uploadResults)
			.map((uploadId) => this.getUploadById(uploadId as UploadId))
			.filter((upload): upload is Upload => upload !== null);
		return [
			...tableMetadatas.map((table) => ({
				id: `/table/${table.table_id}`,
				label: table.file_name,
			})),
			...uploadMetadatas.map((upload) => ({
				id: `/upload/${upload.upload_id}`,
				label: upload.file_name,
			})),
		];
	};

	get files() {
		const filesMap = new Map<FileId, File>();

		if (!this.workspace) {
			return filesMap;
		}

		for (const table of this.workspace.tables.values()) {
			if (!table.file_deleted_at) {
				filesMap.set(table.table_id, table);
			}
		}

		for (const upload of this.workspace.uploads.values()) {
			if (!upload.file_deleted_at && upload.upload_status !== "failed") {
				filesMap.set(upload.upload_id, upload);
			}
		}

		for (const folder of this.workspace.folders.values()) {
			if (!folder.file_deleted_at) {
				filesMap.set(folder.folder_id, folder);
			}
		}
		for (const channel of this.workspace.feedChannels.values()) {
			if (!channel.file_deleted_at) {
				filesMap.set(channel.feed_channel_id, channel);
			}
		}

		for (const item of this.workspace.feedItems.values()) {
			if (!item.file_deleted_at) {
				filesMap.set(item.feed_item_id, item);
			}
		}

		for (const page of this.workspace.pages.values()) {
			if (!page.file_deleted_at) {
				filesMap.set(page.page_id, page);
			}
		}
		return filesMap;
	}

	searchFiles(query: string): {
		uploads: Upload[];
		feedItems: FeedItem[];
	} {
		const feedItemResults = this.feedItemsFlexsearchIndex.search(query);
		const uploadResults = this.uploadsFlexsearchIndex.search(query);

		const feedItemIds = feedItemResults.flatMap(
			(result) => result.result as FeedItemId[],
		);
		const uploadIds = uploadResults.flatMap(
			(result) => result.result as UploadId[],
		);

		const uploads = uploadIds
			.map((uploadId) => this.getUploadById(uploadId as UploadId))
			.filter((upload): upload is Upload => upload !== null);
		const feedItems = feedItemIds
			.map((feedItemId) => this.getFeedItemById(feedItemId as FeedItemId))
			.filter((feedItem): feedItem is FeedItem => feedItem !== null);

		return {
			uploads,
			feedItems,
		};
	}

	activeAssistantSessionsViewingThread(
		threadId: MessageId,
	): SessionAssistantId[] {
		if (!this.session) {
			return [];
		}
		const activeAssistantSessionsViewingThread: SessionAssistantId[] = [];
		for (const assistantSession of this.session.sessionsAssistant.values()) {
			if (assistantSession.ended_at !== null) {
				continue;
			}
			const assistantSessionEvents =
				this.sortedAssistantActivityGroupedBySession.get(
					assistantSession.session_assistant_id,
				);
			if (!assistantSessionEvents) {
				continue;
			}
			const latestThreadOpenEvent = assistantSessionEvents
				.slice()
				.reverse()
				.find(
					(event): event is AssistantEventMap["opened_thread"] =>
						"event_id" in event &&
						event.type === "opened_thread" &&
						event.data.thread_id === threadId,
				);
			if (latestThreadOpenEvent?.data.thread_id === threadId) {
				activeAssistantSessionsViewingThread.push(
					assistantSession.session_assistant_id,
				);
			}
		}
		return activeAssistantSessionsViewingThread;
	}
}

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error("useAppContext must be used within an AppProvider");
	}

	return context;
};

type AppProviderProps = {
	userId: UserId;
	children: ReactNode;
};

let didInit = false;

export const AppProvider = ({ userId, children }: AppProviderProps) => {
	const { getToken } = useAuth();
	const { user } = useUser();
	const [appState] = useState<AppState>(
		() =>
			new AppState({
				getToken,
				userId,
			}),
	);
	const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

	useEffect(() => {
		runInAction(() => {
			appState.sidebarState.setShowSidebar(!isSmallDevice);
		});
	}, [appState, isSmallDevice]);

	useEffect(() => {
		if (!user) {
			return;
		}

		if (!didInit) {
			didInit = true;
			appState.init({ isReconnect: false });
		}
	}, [appState, user]);

	return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};
