import { useTabStore } from "@/contexts/tabs-context/tabs-context";
import { CommonExtensionsWithoutHeading } from "@/lib/editor";
import { markdownToTiptap } from "@/lib/serializers";
import type { SearchFeedItemsResult } from "@api/schemas";
import { Editor, EditorContent } from "@tiptap/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

export const ResultWithinFeedItemsGroup = observer(
	({
		result,
	}: {
		result: SearchFeedItemsResult;
	}) => {
		const tabStore = useTabStore();
		const editor = new Editor({
			content: markdownToTiptap(result.feed_item_chunk_text),
			extensions: CommonExtensionsWithoutHeading,
			editable: false,
			editorProps: {
				attributes: {
					class: "prose-sm prose-neutral",
				},
			},
		});

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const onSelect = useCallback(() => {
			tabStore.createTabInActiveTabSet({
				path: "file",
				fileId: result.feed_item_id,
			});
		}, [result]);

		return (
			<div
				key={result.feed_item_chunk_id}
				className={clsx(
					"group flex w-full cursor-pointer items-start justify-center",
					"hover:bg-blue-100",
				)}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<EditorContent className="" editor={editor} />
			</div>
		);
	},
);
