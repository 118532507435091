import {
	CategoryBackgroundColors,
	CategoryBorderColors,
	chooseRandomCategoryColor,
} from "@/components/table/category-colors";
import type { BaseTableCellProps } from "@/components/table/table-generics";
import { Badge } from "@/components/ui/badge";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useTableContext } from "@/contexts/table-context/table-context";
import { Check, Plus, X } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const CategoryCell = observer(
	({
		rowId,
		columnId,
		columnMetadata,
		cellValue,
		isProxied,
	}: BaseTableCellProps<"category">) => {
		const tableContext = useTableContext();

		const [open, setOpen] = useState(false);
		const [query, setQuery] = useState("");

		const columnCategories = columnMetadata?.categories ?? [];

		const currentCategoryMetadata =
			(cellValue?.cell_value && columnCategories[cellValue.cell_value]) ?? null;

		return (
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger
					disabled={isProxied || !tableContext.editable}
					className={clsx(
						"flex h-full w-full min-w-0 items-start truncate p-1 text-left text-neutral-800 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
				>
					{currentCategoryMetadata && (
						<Badge
							variant="outline"
							className={clsx(
								CategoryBackgroundColors[currentCategoryMetadata.color],
								CategoryBorderColors[currentCategoryMetadata.color],
								"min-w-0 truncate text-neutral-800",
							)}
						>
							{currentCategoryMetadata.value}
						</Badge>
					)}
				</PopoverTrigger>
				<PopoverContent className="w-48 p-0" align="start">
					<Command>
						<CommandInput
							value={query}
							onValueChange={setQuery}
							placeholder="Search options..."
						/>
						<CommandList>
							<CommandEmpty>No options found.</CommandEmpty>
							<CommandGroup>
								{query && !columnCategories[query] && (
									<>
										<CommandItem
											value={query}
											onSelect={() => {
												tableContext.addColumnCategory({
													columnId,
													newCategory: {
														value: query,
														color: chooseRandomCategoryColor(),
													},
													cellToSet: { rowId },
												});
												setOpen(false);
											}}
										>
											<Plus className={clsx("mr-2 h-4 w-4")} />
											Add "{query}"
										</CommandItem>
										<CommandSeparator />
									</>
								)}

								{Object.values(columnCategories).map((category) => (
									<CommandItem
										key={category.value}
										value={category.value}
										onSelect={(newValue) => {
											tableContext.updateCell({
												rowId,
												columnId,
												value: {
													cell_value_type: "category",
													cell_value: newValue,
												},
											});
											setOpen(false);
										}}
									>
										<Check
											className={clsx(
												"mr-2 h-4 w-4",
												currentCategoryMetadata === category.value
													? "opacity-100"
													: "opacity-0",
											)}
										/>
										<Badge
											className={clsx(
												CategoryBackgroundColors[category.color],
												CategoryBorderColors[category.color],
												"text-neutral-800",
											)}
										>
											{category.value}
										</Badge>
									</CommandItem>
								))}

								{currentCategoryMetadata && (
									<>
										<CommandSeparator className="mt-1 mb-1" />
										<CommandItem
											value={`Remove "${currentCategoryMetadata.value}"`}
											onSelect={() => {
												tableContext.updateCell({
													rowId,
													columnId,
													value: {
														cell_value_type: "category",
														cell_value: null,
													},
												});
												setOpen(false);
											}}
										>
											<X className={clsx("mr-2 h-4 w-4")} />
											Clear{" "}
											<Badge
												className={clsx(
													CategoryBackgroundColors[
														currentCategoryMetadata.color
													],
													CategoryBorderColors[currentCategoryMetadata.color],
													"ml-2 text-neutral-800",
												)}
											>
												{currentCategoryMetadata.value}
											</Badge>
										</CommandItem>
									</>
								)}
							</CommandGroup>
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
		);
	},
);
