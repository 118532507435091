import type { AppState } from "@/contexts/app-context/app-context";
import type { AnyDirectoryNode } from "@/contexts/app-context/tree-handlers";
import { createSyncedAction } from "@/contexts/synced-actions";
import { newFolderId } from "@/id-generators";
import {
	createFolder,
	deleteFilesRoute,
	moveDirectoryNodes,
	renameFileRoute,
} from "@api/fastAPI";
import type { FileId, Folder, FolderId } from "@api/schemas";
import { toast } from "sonner";

export const createFolderAction = createSyncedAction<
	AppState,
	{ parentFolderId: FolderId | null; folderName: string },
	Folder,
	void
>({
	async local(args) {
		if (this.workspace === null) {
			throw new Error("Tried to create folder before workspace loaded");
		}

		const folderId = newFolderId();
		const newFolder: Folder = {
			folder_id: folderId,
			file_id: folderId,
			file_name: args.folderName,
			file_created_at: new Date().toISOString(),
			file_updated_at: new Date().toISOString(),
			file_deleted_at: null,
			file_creator_id: this.workspace.userId,
			file_parent_id: args.parentFolderId,
			file_type: "folder",
		};

		this.workspace.folders.set(folderId, newFolder);
		return newFolder;
	},
	async remote(args, localResult) {
		await createFolder({
			folder_id: localResult.folder_id,
			folder_name: args.folderName,
			folder_parent_id: args.parentFolderId,
		});
	},
	rollback(_, localResult) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		this.workspace.folders.delete(localResult.folder_id);
	},
	onRemoteSuccess() {
		toast.success("Folder created successfully.");
	},
});

export const renameFileAction = createSyncedAction<
	AppState,
	{ fileId: FileId; newName: string },
	{ oldName: string },
	void
>({
	async local({ fileId, newName }) {
		const file = this.files.get(fileId);
		if (!file) {
			throw new Error(`Tried to rename non-existent file: ${fileId}`);
		}
		const oldName = file.file_name;
		file.file_name = newName;
		return { oldName };
	},
	async remote({ fileId, newName }) {
		await renameFileRoute({ file_id: fileId, file_name: newName });
	},
	rollback({ fileId }, { oldName }) {
		const file = this.files.get(fileId);
		if (!file) {
			return;
		}
		file.file_name = oldName;
	},
});

/**
 * Deletes files. Also automatically deletes all descendants.
 */
export const deleteFilesAction = createSyncedAction<
	AppState,
	{ fileIds: FileId[] },
	{ fileIdDeletedAtMap: Record<FileId, string> },
	void
>({
	async local({ fileIds }) {
		const fileIdsWithDescendants: Set<FileId> = new Set();
		for (const fileId of fileIds) {
			const node = this.fileNodeTree.nodeMap.get(fileId);
			if (!node) {
				console.error(`Tried to delete non-existent file: ${fileId}`);
				continue;
			}
			fileIdsWithDescendants.add(fileId);
			for (const descendant of node.descendants) {
				fileIdsWithDescendants.add(descendant.id);
			}
		}
		const fileIdDeletedAtMap: Record<FileId, string> = {};
		const deletedAt = new Date().toISOString();
		for (const fileId of fileIdsWithDescendants) {
			const file = this.files.get(fileId);
			if (file) {
				fileIdDeletedAtMap[fileId] = deletedAt;
			}
		}
		return { fileIdDeletedAtMap };
	},
	async remote(_, { fileIdDeletedAtMap }) {
		await deleteFilesRoute({ file_id_deleted_at_map: fileIdDeletedAtMap });
	},
	rollback(_, { fileIdDeletedAtMap }) {
		for (const [fileId, deletedAt] of Object.entries(fileIdDeletedAtMap)) {
			const file = this.files.get(fileId as FileId);
			if (file) {
				file.file_deleted_at = deletedAt;
			}
		}
	},
	onRemoteSuccess() {
		toast.success("Files deleted successfully.");
	},
});

export const moveFilesAction = createSyncedAction<
	AppState,
	{
		fileIds: FileId[];
		newParentId: FileId | null;
	},
	{
		fileId: FileId;
		oldParentId: FileId | null;
	}[],
	void
>({
	async local({ fileIds, newParentId }) {
		let parentNode: AnyDirectoryNode | null;
		if (newParentId !== null) {
			parentNode = this.fileNodeTree.nodeMap.get(newParentId) ?? null;
			if (!parentNode) {
				console.warn(
					`Tried to move files to non-existent parent: ${newParentId}`,
				);
				return [];
			}
		} else {
			parentNode = null;
		}

		const filesMoved: {
			fileId: FileId;
			oldParentId: FileId | null;
		}[] = [];
		for (const id of fileIds) {
			const fileNode = this.fileNodeTree.nodeMap.get(id);
			if (!fileNode) {
				console.warn(`Tried to move file that doesn't exist: ${id}`);
				continue;
			}
			if (fileNode.file.file_parent_id === newParentId) {
				console.warn(
					`Tried to move file ${id} into its current parent: ${newParentId}`,
				);
				continue;
			}
			if (
				newParentId !== null &&
				fileNode.descendants.some((descendant) => descendant.id === newParentId)
			) {
				console.warn(
					`Tried to move file ${id} into one of its descendants: ${newParentId}`,
				);
				continue;
			}
			filesMoved.push({
				fileId: id,
				oldParentId: fileNode.file.file_parent_id,
			});
			fileNode.file.file_parent_id = newParentId;
		}
		return filesMoved;
	},
	async remote({ newParentId }, filesMoved) {
		if (filesMoved.length === 0) {
			return;
		}
		await moveDirectoryNodes({
			file_ids: filesMoved.map((file) => file.fileId),
			new_parent_id: newParentId,
		});
	},
	rollback(_, filesMoved) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		for (const { fileId, oldParentId } of filesMoved) {
			const file = this.files.get(fileId);
			if (!file) {
				console.warn(`Tried to move file that doesn't exist: ${fileId}`);
				continue;
			}
			file.file_parent_id = oldParentId;
		}
	},
});
