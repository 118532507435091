import { BaseCellRenderer } from "@/components/table/cell/cell-renderer";
import type {
	MColumnMetadata,
	TableCellProps,
	TableCellPropsU,
} from "@/components/table/table-generics";
import { useTableContext } from "@/contexts/table-context/table-context";
import type { ColumnType } from "@api/schemas";
import { observer } from "mobx-react-lite";

export const ProxiedCell = observer(
	<ProxiedTypeT extends ColumnType>({
		rowId,
		columnId,
		cellValue,
	}: TableCellProps<"proxy">) => {
		const tableContext = useTableContext();

		if (!cellValue) {
			return null;
		}

		const proxiedColumnMetadata = tableContext.getProxiedColumnMetadataById(
			cellValue.cell_value.table_column_id,
		) as MColumnMetadata<ProxiedTypeT> | undefined;

		if (!proxiedColumnMetadata) {
			return null;
		}

		const proxiedCellValue = tableContext.getProxiedCellValue(
			cellValue.cell_value.table_row_id,
			cellValue.cell_value.table_column_id,
			proxiedColumnMetadata.column_type,
		);

		const rawProps: TableCellProps<ProxiedTypeT> = {
			rowId,
			columnId,
			columnType: proxiedColumnMetadata.column_type,
			columnMetadata: proxiedColumnMetadata,
			cellValue: proxiedCellValue,
		};

		const props = rawProps as TableCellPropsU;

		if (
			props.columnType === "proxy" ||
			props.columnType === "proxy_group" ||
			props.columnType === "groupby_key"
		) {
			throw new Error("Proxied cells cannot be proxies themselves");
		}

		return <BaseCellRenderer {...props} isProxied />;
	},
);
