import type { BasePageBlockProps } from "@/components/page/page-generics";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { usePageContext } from "@/contexts/page-context/page-context";
import { X } from "@phosphor-icons/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const DateBlock = observer(
	({ blockId, blockValue }: BasePageBlockProps<"date">) => {
		const pageContext = usePageContext();
		const [open, setOpen] = useState(false);

		const currentValue = blockValue?.cell_value;

		const [newValue, setNewValue] = useState(
			// don't use `new Date(currentValue)` because it will convert the date to the local timezone,
			// potentially adding/subtracting a day
			currentValue ? dayjs.tz(currentValue, "UTC") : null,
		);

		useEffect(() => {
			setNewValue(currentValue ? dayjs.tz(currentValue, "UTC") : null);
		}, [currentValue]);

		return (
			<Popover
				open={open}
				onOpenChange={(newOpen) => {
					setOpen(newOpen);
					if (!newOpen && newValue) {
						const newDateString = newValue.utc().format("YYYY-MM-DD");
						if (newDateString === currentValue) {
							return;
						}
						pageContext.updateBlockValue({
							blockId,
							blockValue: {
								cell_value: newDateString,
								cell_value_type: "date",
							},
						});
					}
				}}
			>
				<PopoverTrigger
					disabled={!pageContext.editable}
					className={clsx(
						"flex h-full w-full min-w-0 justify-start truncate p-1 text-neutral-600 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
				>
					{newValue ? newValue.format("MMMM D, YYYY") : null}
				</PopoverTrigger>
				<PopoverContent className="w-72 p-2" align="start">
					<Calendar
						selectedDate={newValue}
						setSelectedDate={setNewValue}
						showTime={false}
					/>
					<Separator />
					<Button
						onClick={() => {
							pageContext.updateBlockValue({
								blockId,
								blockValue: {
									cell_value: null,
									cell_value_type: "date",
								},
							});
						}}
						variant="ghost"
						className="mt-1 flex w-full items-center justify-start gap-1"
					>
						<X className="" />
						Clear date
					</Button>
				</PopoverContent>
			</Popover>
		);
	},
);
