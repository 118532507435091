import { IS_DEV } from "@/config";
import * as Sentry from "@sentry/react";
import { action, runInAction } from "mobx";
import { toast } from "sonner";

export interface SyncedAction<State, LocalArgs, LocalResult, RemoteResult> {
	local: (this: State, args: LocalArgs) => Promise<LocalResult>;
	remote: (
		this: State,
		localArgs: LocalArgs,
		localResult: LocalResult,
	) => Promise<RemoteResult>;
	rollback: (
		this: State,
		localArgs: LocalArgs,
		localResult: LocalResult,
	) => void;
	onRemoteSuccess?: (
		this: State,
		localArgs: LocalArgs,
		localResult: LocalResult,
		remoteResult: RemoteResult,
	) => void;
}

export class DisplayedActionError extends Error {
	constructor(message: string) {
		super(message);
		this.name = "DisplayedActionError";

		// This line is necessary for proper prototype chain setup in some environments
		Object.setPrototypeOf(this, DisplayedActionError.prototype);
	}
}

export function createSyncedAction<State, Args, LocalResult, RemoteResult>(
	syncedAction: SyncedAction<State, Args, LocalResult, RemoteResult>,
) {
	return action(async function (
		this: State,
		args: Args,
		onLocalSuccess?: (localResult: LocalResult) => void,
	) {
		let localResult: LocalResult;
		try {
			localResult = await runInAction(async () => {
				return await syncedAction.local.call(this, args);
			});
		} catch (error) {
			Sentry.captureException(error);
			if (error instanceof DisplayedActionError) {
				toast.error(error.message);
			} else {
				toast.error("Failed to perform action locally.");
				IS_DEV && console.error(error);
			}
			return;
		}

		if (onLocalSuccess) {
			onLocalSuccess(localResult);
		}

		let remoteResult: RemoteResult | null;

		try {
			const res = await syncedAction.remote.call(this, args, localResult);

			// Execute optional onRemoteSuccess logic
			if (syncedAction.onRemoteSuccess) {
				runInAction(() => {
					syncedAction.onRemoteSuccess?.call(this, args, localResult, res);
				});
			}

			remoteResult = res;
		} catch (error) {
			remoteResult = null;
			Sentry.captureException(error);
			IS_DEV
				? toast.error(
						`Failed to sync with the server. Reverting changes: ${error}`,
					)
				: toast.error("Failed to sync with the server. Reverting changes.");
			runInAction(() => {
				syncedAction.rollback.call(this, args, localResult);
			});
		}

		return { localResult, remoteResult };
	});
}
