/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export type TableDisconnectCode = typeof TableDisconnectCode[keyof typeof TableDisconnectCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TableDisconnectCode = {
  NUMBER_4040: 4040,
  NUMBER_4000: 4000,
} as const;
