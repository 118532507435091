import { BooleanCell } from "@/components/table/cell/boolean-cell";
import { CategoryCell } from "@/components/table/cell/category-cell";
import { DateCell } from "@/components/table/cell/date-cell";
import { DatetimeCell } from "@/components/table/cell/datetime-cell";
import { DocumentCell } from "@/components/table/cell/document-cell";
import { NumberCell } from "@/components/table/cell/number-cell";
import { TextCell } from "@/components/table/cell/text-cell";
import type { BaseTableCellPropsU } from "@/components/table/table-generics";

export function BaseCellRenderer(props: BaseTableCellPropsU) {
	switch (props.columnType) {
		case "boolean":
			return <BooleanCell {...props} />;
		case "category":
			return <CategoryCell {...props} />;
		case "date":
			return <DateCell {...props} />;
		case "datetime":
			return <DatetimeCell {...props} />;
		case "document":
			return <DocumentCell {...props} />;
		case "number":
			return <NumberCell {...props} />;
		case "text":
			return <TextCell {...props} />;
		default: {
			const exhaustiveCheck: never = props;
			return exhaustiveCheck;
		}
	}
}
