import type { SearchLibraryResult, Upload } from "@api/schemas";

import { IS_DEV } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { searchHandler } from "@api/fastAPI";
import {
	type FeedChannelId,
	type SearchResponse,
	TextSearchMode,
	type UploadId,
} from "@api/schemas";
import { Circuitry, Key } from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "sonner";

export interface SearchLibraryResultWithUpload extends SearchLibraryResult {
	upload: Upload;
}

export const SearchModeMeta: Record<
	TextSearchMode,
	{
		title: string;
		description: string;
		icon: React.ReactNode;
	}
> = {
	[TextSearchMode.keyword]: {
		title: "Keyword",
		description: "Matches exact quotes and phrases",
		icon: <Key className=" text-neutral-500" weight="duotone" />,
	},
	[TextSearchMode.semantic]: {
		title: "Semantic",
		description: "Finds content with similar meaning",
		icon: <Circuitry className="text-neutral-500" weight="duotone" />,
	},
	[TextSearchMode.hybrid]: {
		title: "Neural",
		description: "Finds content based on meaning",
		icon: <Circuitry className="text-neutral-500" weight="duotone" />,
	},
};

class SearchConfig {
	query = "";
	search_mode: TextSearchMode = TextSearchMode.hybrid;
	include_library = true;
	included_upload_ids: UploadId[] = [];
	include_feeds = true;
	included_feed_channel_ids: FeedChannelId[] = [];
	result_id?: string;

	constructor() {
		makeAutoObservable(this);
	}
}

function searchConfigKey(config: SearchConfig) {
	return JSON.stringify({
		query: config.query,
		search_mode: config.search_mode,
		include_library: config.include_library,
		included_upload_ids: (config.included_upload_ids ?? []).slice().sort(),
		include_feeds: config.include_feeds,
		included_feed_channel_ids: (config.included_feed_channel_ids ?? [])
			.slice()
			.sort(),
	});
}

class SearchResult {
	searchConfig: SearchConfig;
	searchResults: SearchResponse | null = null;
	searchLoading = true;

	constructor(searchConfig: SearchConfig) {
		this.searchConfig = searchConfig;
		makeAutoObservable(this);
	}

	fromResponse(response: SearchResponse) {
		this.searchResults = response;
		this.searchLoading = false;
	}
}

export class SearchStore {
	appState: AppState;
	searchHistory: Map<string, SearchResult> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	getSearchResult(searchConfig: SearchConfig | null): SearchResult | null {
		if (!searchConfig) {
			return null;
		}
		const key = searchConfigKey(searchConfig);
		if (!this.searchHistory.has(key)) {
			// Initialize search result w/ loading
			const searchState = new SearchResult(searchConfig);
			this.searchHistory.set(key, searchState);

			// Fetch and initialize the table
			this.fetchSearchResult(searchConfig);
			return null;
		}
		return this.searchHistory.get(key) as SearchResult;
	}

	async fetchSearchResult(searchConfig: SearchConfig) {
		searchHandler({
			query: searchConfig.query,
			search_mode: searchConfig.search_mode,
			include_library: searchConfig.include_library ?? true,
			included_upload_ids: searchConfig.included_upload_ids ?? [],
			include_feeds: searchConfig.include_feeds ?? true,
			included_feed_channel_ids: searchConfig.included_feed_channel_ids ?? [],
		})
			.then((res) => {
				runInAction(() => {
					this.getSearchResult(searchConfig)?.fromResponse(res.data);
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to fetch search results");
				}
			});
	}

	get uniqueSearchHistory() {
		const seen = new Set<string>();
		return Array.from(this.searchHistory.values())
			.map((searchResult) => searchResult.searchConfig)
			.filter((config) => {
				// If we haven't seen this configuration before, add it to the set and keep it
				if (!seen.has(searchConfigKey(config))) {
					seen.add(searchConfigKey(config));
					return true;
				}

				// If we've seen this configuration before, filter it out
				return false;
			})
			.reverse(); // Reverse to show most recent searches first
	}
}

export class SearchState {
	appState: AppState;
	type = "search" as const;
	searchConfig: SearchConfig = new SearchConfig();
	publishedSearchConfig: SearchConfig | null = null;
	showCommandList = false;
	searchInputElement: HTMLInputElement | null = null;

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	setShowCommandList(show: boolean) {
		this.showCommandList = show;
	}

	handleSearch() {
		this.publishedSearchConfig = { ...this.searchConfig };
	}
}
