// import { Button } from "@/components/ui/button";
import {
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { SearchModeMeta } from "@/contexts/tabs-context/tab-states/search-state";
import {
	type TabTypeStateMap,
	useTab,
} from "@/contexts/tabs-context/tabs-context";
// import { X } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";

interface SearchComboboxCommandListProps {
	setShowCommandList?: (show: boolean) => void;
	disabled?: boolean;
}

export const SearchComboboxCommandList = observer(
	({ setShowCommandList, disabled }: SearchComboboxCommandListProps) => {
		const tab = useTab();
		const searchState = tab.state as TabTypeStateMap["search"];
		const {
			query,
			search_mode: searchMode,
			included_upload_ids,
		} = searchState.searchConfig;
		const includedUploadIds = new Set(included_upload_ids);

		return (
			<CommandList className="max-h-full">
				{query && (
					<CommandGroup className="p-2">
						<CommandItem
							key={"current_search"}
							value={query}
							className="flex w-full items-center gap-2 rounded-md"
							onSelect={() => {
								if (disabled) return;
								if (!searchState.showCommandList) return;
								runInAction(() => {
									searchState.handleSearch();
								});
								setShowCommandList?.(false);
							}}
						>
							<div className="w-full min-w-0">
								<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
									Search for "{query}"
								</h3>
								<p className="flex items-center gap-1 text-neutral-500 text-xs">
									<span className="text-sm">
										{SearchModeMeta[searchMode].icon}
									</span>
									{includedUploadIds.size > 0 ? (
										<>
											{includedUploadIds.size}
											{includedUploadIds.size === 1 ? "" : "s"}
										</>
									) : (
										"All uploads"
									)}
								</p>
							</div>
						</CommandItem>
					</CommandGroup>
				)}

				<CommandGroup
					className="p-2"
					// heading={
					// 	<span className="flex items-baseline justify-between gap-2">
					// 		<h2 className="flex items-center gap-1">
					// 			<span className="text-xs">Recent searches</span>
					// 		</h2>{" "}
					// 		<Button
					// 			className="h-max rounded p-1 text-xs"
					// 			variant="ghost"
					// 			onClick={() => {
					// 				runInAction(() => {
					// 					searchState.clearSearchHistory();
					// 				});
					// 			}}
					// 		>
					// 			Clear
					// 		</Button>
					// 	</span>
					// }
				>
					<CommandEmpty>No recent searches</CommandEmpty>
					{searchState.appState.searchStore.uniqueSearchHistory.map(
						(search, idx) => {
							return (
								<CommandItem
									key={search.result_id}
									// we use the index to ensure that the value is unique,
									// otherwise hovering on one item will highlight all items with the same query
									// (but possibly different search modes)
									value={`${search.query} ${idx}`}
									className="group flex w-full items-center gap-2 rounded-md"
									onSelect={() => {
										if (disabled) return;
										runInAction(() => {
											searchState.searchConfig = {
												query: search.query,
												search_mode: search.search_mode,
												included_upload_ids: search.included_upload_ids ?? [],
												include_library: search.include_library ?? true,
												include_feeds: search.include_feeds ?? true,
												included_feed_channel_ids:
													search.included_feed_channel_ids ?? [],
											};
										});
										searchState.handleSearch();
										setShowCommandList?.(false);
									}}
								>
									<div className="w-full min-w-0">
										<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
											{search.query}
										</h3>
										<p className="flex items-center gap-1 text-neutral-500 text-xs">
											<span className="text-sm">
												{SearchModeMeta[search.search_mode].icon}
											</span>
											{(search.included_upload_ids ?? []).length > 0 ? (
												<>
													{(search.included_upload_ids ?? []).length} upload
													{(search.included_upload_ids ?? []).length === 1
														? ""
														: "s"}
												</>
											) : (
												"All uploads"
											)}
										</p>
									</div>
									{/* <button
										type="button"
										className="rounded-md p-2 opacity-0 hover:bg-neutral-200 group-hover:opacity-100"
										onClick={(e) => {
											e.stopPropagation();
											runInAction(() => {
												searchState.removeSearchHistoryItem(search);
											});
										}}
									>
										<X size={16} />
									</button> */}
								</CommandItem>
							);
						},
					)}
				</CommandGroup>
			</CommandList>
		);
	},
);
