import { PDFViewer } from "@/components/pdf/pdfviewer";
import type { SearchResult } from "@/contexts/tabs-context/tab-states/upload-state";
import type { TabTypeStateMap } from "@/contexts/tabs-context/tabs-context";
import { useTab } from "@/contexts/tabs-context/tabs-context";
import { observer } from "mobx-react-lite";

// TODO(John): clean up search result highlights
export const UploadPage = observer(() => {
	const tab = useTab();
	const tabState = tab.state as TabTypeStateMap["upload"];
	const searchResult: SearchResult | null = tabState.searchResult;

	const highlights =
		searchResult?.activeGroupedSearchResults.map((chunk) => {
			return {
				textToHighlight: {
					textStart: chunk.chunk_text,
				},
				pageIndicesToSearch: chunk.chunk_page_indices,
			};
		}) || [];

	const activeHighlight = searchResult?.activeSearchResult
		? {
				textToHighlight: {
					textStart: searchResult.activeSearchResult.chunk_text,
				},
				pageIndicesToSearch: searchResult.activeSearchResult.chunk_page_indices,
			}
		: undefined;

	return (
		<PDFViewer
			upload={tabState.upload}
			highlights={highlights}
			activeHighlight={activeHighlight}
		/>
	);
});
