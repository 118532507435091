import type { BasePageBlockProps } from "@/components/page/page-generics";
import { Checkbox } from "@/components/ui/checkbox";
import { usePageContext } from "@/contexts/page-context/page-context";
import { observer } from "mobx-react-lite";

export const BooleanBlock = observer(
	({ blockId, blockValue }: BasePageBlockProps<"boolean">) => {
		const pageContext = usePageContext();

		return (
			<Checkbox
				className="flex items-center"
				checked={blockValue?.cell_value ?? false}
				disabled={!pageContext.editable}
				onCheckedChange={(checked) => {
					pageContext.updateBlockValue({
						blockId,
						blockValue: {
							cell_value: checked === true,
							cell_value_type: "boolean",
						},
					});
				}}
			/>
		);
	},
);
