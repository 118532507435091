import { TreeState } from "@/components/tree/tree-state";
import type { AppState } from "@/contexts/app-context/app-context";
import type {
	AnyDirectoryNode,
	DirectoryNode,
} from "@/contexts/app-context/tree-handlers";
import { makeAutoObservable } from "mobx";

export class FolderState {
	type = "folder" as const;
	appState: AppState;

	treeState: TreeState;
	// Null indicates root folder
	folderNode: DirectoryNode<"folder"> | null = null;

	constructor(appState: AppState, folderNode: DirectoryNode<"folder"> | null) {
		this.appState = appState;
		this.folderNode = folderNode;
		this.treeState = new TreeState(appState, () => this.data, false);
		makeAutoObservable(this);
	}

	get data(): AnyDirectoryNode[] {
		if (this.folderNode === null) {
			return this.appState.fileNodeTree.rootNodes;
		}
		return this.folderNode.children;
	}

	get node():
		| {
				id: null;
				ancestors: [];
				children: AnyDirectoryNode[];
				path: "/file";
		  }
		| DirectoryNode<"folder"> {
		if (this.folderNode === null) {
			return {
				id: null,
				ancestors: [],
				children: this.appState.fileNodeTree.rootNodes,
				path: "/file",
			};
		}
		return this.folderNode;
	}
}
