import { TableView } from "@/components/table/table-component";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { Switch } from "@/components/ui/switch";
import { IS_DEV } from "@/config";
import { useAppContext } from "@/contexts/app-context/app-context";
import {
	TableContext,
	useTableContext,
	useTablesContext,
} from "@/contexts/table-context/table-context";
import {
	type TabTypeStateMap,
	useTab,
} from "@/contexts/tabs-context/tabs-context";
import { formatRelativeDate } from "@/lib/formatting";
import type { TableId, TableMetadata } from "@api/schemas";
import { Play, RectangleDashed } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AutosizeInput from "react-input-autosize";
import { toast } from "sonner";

export const LoadingState = observer(() => {
	return (
		<div className="relative flex h-full min-h-0 w-full flex-col">
			<Helmet>
				<title>Table - Village</title>
			</Helmet>
			<div className="flex w-full flex-col space-y-2 p-4">
				{[...Array(3)].map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="flex space-x-2"
					>
						<Skeleton
							className="h-3"
							style={{
								flexGrow: 1,
							}}
						/>
						<Skeleton
							className="h-3"
							style={{
								flexGrow: 3,
							}}
						/>
						<Skeleton
							className="h-3"
							style={{
								flexGrow: 2,
							}}
						/>
					</div>
				))}
			</div>
		</div>
	);
});

// Separate headers for loading and loaded states because the loading state
// uses appContext to show the table name, but the loaded state needs
// the tableContext to show additional info and toggles
export const LoadingTableHeader = observer(
	({ tableMetadata }: { tableMetadata: TableMetadata }) => {
		return (
			<div className="flex h-14 w-full shrink-0 items-center px-2">
				<div className="rounded-md border border-transparent bg-white px-2 font-semibold text-lg ring-blue-100 hover:border-blue-200 focus:border-blue-200 focus:outline-none focus:ring-1">
					{tableMetadata.file_name}
				</div>
			</div>
		);
	},
);

export const LoadedTableHeader = observer(
	({ tableMetadata }: { tableMetadata: TableMetadata }) => {
		const appContext = useAppContext();
		const tableContext = useTableContext();

		const [newName, setNewName] = useState(tableMetadata.file_name);
		useEffect(() => {
			setNewName(tableMetadata.file_name);
		}, [tableMetadata.file_name]);

		const onSubmit = () => {
			if (newName === tableMetadata.file_name) {
				return;
			}
			if (newName.trim() === "") {
				toast.error("Table name cannot be empty");
				setNewName(tableMetadata.file_name);
				return;
			}
			appContext.renameFile({
				fileId: tableMetadata.table_id,
				newName: newName,
			});
		};

		return (
			<>
				<div className="flex h-14 w-full shrink-0 items-center px-2">
					<AutosizeInput
						value={newName}
						className="rounded-md border border-transparent bg-white px-2 font-semibold text-lg ring-blue-100 hover:border-blue-200 focus:border-blue-200 focus:outline-none focus:ring-1"
						placeholder="Untitled table"
						onChange={(e) => {
							setNewName(e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								onSubmit();
							}
						}}
						onBlur={onSubmit}
					/>
				</div>

				<div className="flex shrink-0 items-center border-b px-4 pb-2 text-neutral-500 text-sm">
					Updated{" "}
					{formatRelativeDate(
						tableContext.table.root.latestTransaction
							.table_transaction_created_at,
					)}
					{IS_DEV && (
						<div className="ml-2 flex max-w-max items-center gap-2 rounded-md border bg-neutral-50 p-1">
							<Switch
								checked={tableContext.devMode}
								onCheckedChange={(checked) => {
									runInAction(() => {
										tableContext.devMode = checked;
									});
								}}
							/>{" "}
							<Label>Dev mode</Label>
						</div>
					)}
					<Button
						onClick={() =>
							appContext.fillTable({
								tableId: tableMetadata.table_id,
								tableName: tableMetadata.file_name,
							})
						}
						className="ml-2 flex h-7 items-center gap-1"
					>
						Fill table
						<Play weight="fill" />
					</Button>
				</div>
			</>
		);
	},
);

export const _Table: React.FC<{
	tableId: TableId;
	tableMetadata: TableMetadata;
}> = observer(({ tableId, tableMetadata }) => {
	const tablesState = useTablesContext();
	const tableState = tablesState.getTable(tableId);
	if (!tableState) {
		return (
			<>
				<LoadingTableHeader tableMetadata={tableMetadata} />
				<LoadingState />
			</>
		);
	}
	return (
		<TableContext.Provider value={tableState}>
			<LoadedTableHeader tableMetadata={tableMetadata} />
			<TableView />
		</TableContext.Provider>
	);
});

export const TablePage = observer(() => {
	const tab = useTab();
	const tabState = tab.state as TabTypeStateMap["table"];
	const appContext = tab.tabStore.appState;
	const tableMetadata = appContext.getTableById(tabState.tableNode.id);

	if (!tableMetadata) {
		return (
			<div className="flex h-full w-full flex-col items-center justify-center">
				<RectangleDashed className="h-24 w-24 text-neutral-300" />
				<h1 className="mt-4 font-semibold text-xl">Table not found</h1>
				<p className="mt-2 text-center text-neutral-500">
					We couldn't find the table you're looking for.
					<br />
					If you think this is a mistake, please contact us.
				</p>

				<button
					type="button"
					onClick={() => tab.navigate({ path: "manage-tables" })}
					className="mt-4"
				>
					<Button variant="outline">View all tables</Button>
				</button>
			</div>
		);
	}

	return (
		<div className="flex h-full flex-col">
			<_Table
				tableId={tabState.tableNode.id}
				tableMetadata={tableMetadata}
				key={tabState.tableNode.id}
			/>
		</div>
	);
});
