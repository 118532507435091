import {
	CategoryBackgroundColors,
	CategoryBorderColors,
} from "@/components/table/category-colors";
import { ColumnTypeIcons } from "@/components/table/column-type-indicators";
import { TableView } from "@/components/table/table-component";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
	Calendar,
	CalendarWithRange,
	type DateRangeDayjs,
} from "@/components/ui/calendar";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/app-context/app-context";
import {
	TableContext,
	TableState,
	renderComputedTable,
} from "@/contexts/table-context/table-context";
import { useTabStore } from "@/contexts/tabs-context/tabs-context";
import { newFilterId } from "@/id-generators";
import {
	getTableLatestVersionRoute,
	previewComputedTableRoute,
} from "@api/fastAPI";
import type {
	CategoryFilter,
	ColumnId,
	ColumnType,
	DateFilter,
	DateRange,
	DateRangeFilter,
	DatetimeFilter,
	DatetimeRange,
	DatetimeRangeFilter,
	Filter,
	FilterId,
	GetTableLatestVersionResponse,
	MaterializedColumn,
	NumberFilter,
	TableId,
	TextFilter,
} from "@api/schemas";
import { Check, Cursor, Plus, Spinner, Trash, X } from "@phosphor-icons/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { autorun, makeAutoObservable, reaction, runInAction, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { createContext, useContext, useEffect, useState } from "react";
import type { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const filterLabels: {
	[key in Filter["filter_type"]]: string;
} = {
	// Text filters
	text_contains: "contains",
	text_does_not_contain: "does not contain",
	text_equals: "is",
	text_does_not_equal: "is not",
	text_starts_with: "starts with",
	text_ends_with: "ends with",

	// Number filters
	number_equals: "is equal to",
	number_does_not_equal: "is not equal to",
	number_greater_than: "is greater than",
	number_less_than: "is less than",
	number_greater_than_or_equal: "is greater than or equal to",
	number_less_than_or_equal: "is less than or equal to",

	// Category filters
	category_equals: "is",
	category_does_not_equal: "is not",

	// Date filters
	date_equals: "is",
	date_does_not_equal: "is not",
	date_after: "is after",
	date_before: "is before",
	date_after_or_equal: "is on or after",
	date_before_or_equal: "is on or before",
	date_is_between: "is between",

	// Datetime filters
	datetime_equals: "is",
	datetime_does_not_equal: "is not",
	datetime_after: "is after",
	datetime_before: "is before",
	datetime_after_or_equal: "is on or after",
	datetime_before_or_equal: "is on or before",
	datetime_is_between: "is between",

	// Boolean filters
	boolean_is_true: "is true",
	boolean_is_false: "is false",

	// Document filters
	exists: "is not empty",
	does_not_exist: "is empty",
};

const signatureDefaults: {
	[key in Filter["filter_parameter_signature"]]: () => Filter["filter_value"];
} = {
	text: () => "",
	number: () => 0,
	category: () => [],
	date: () => dayjs().toISOString(),
	date_range: () => ({
		start_date: dayjs().toISOString(),
		end_date: dayjs().toISOString(),
	}),
	datetime: () => dayjs().toISOString(),
	datetime_range: () => ({
		start_datetime: dayjs().toISOString(),
		end_datetime: dayjs().toISOString(),
	}),
	boolean: () => null,
	existential: () => null,
};

type FilterTypeToSignature = {
	[P in Filter as P["filter_type"]]: P["filter_parameter_signature"];
};

const filterTypeToSignature: FilterTypeToSignature = {
	text_contains: "text",
	text_does_not_contain: "text",
	text_equals: "text",
	text_does_not_equal: "text",
	text_starts_with: "text",
	text_ends_with: "text",
	number_equals: "number",
	number_does_not_equal: "number",
	number_greater_than: "number",
	number_less_than: "number",
	number_greater_than_or_equal: "number",
	number_less_than_or_equal: "number",
	category_equals: "category",
	category_does_not_equal: "category",
	date_equals: "date",
	date_does_not_equal: "date",
	date_after: "date",
	date_before: "date",
	date_after_or_equal: "date",
	date_before_or_equal: "date",
	date_is_between: "date_range",
	datetime_equals: "datetime",
	datetime_does_not_equal: "datetime",
	datetime_after: "datetime",
	datetime_before: "datetime",
	datetime_after_or_equal: "datetime",
	datetime_before_or_equal: "datetime",
	datetime_is_between: "datetime_range",
	boolean_is_true: "boolean",
	boolean_is_false: "boolean",
	exists: "existential",
	does_not_exist: "existential",
};

const filtersByType: {
	[key in ColumnType]: Filter["filter_type"][];
} = {
	text: [
		"text_contains",
		"text_does_not_contain",
		"text_equals",
		"text_does_not_equal",
		"text_starts_with",
		"text_ends_with",
		"exists",
		"does_not_exist",
	],
	number: [
		"number_equals",
		"number_does_not_equal",
		"number_greater_than",
		"number_less_than",
		"number_greater_than_or_equal",
		"number_less_than_or_equal",
		"exists",
		"does_not_exist",
	],
	category: [
		"category_equals",
		"category_does_not_equal",
		"exists",
		"does_not_exist",
	],
	date: [
		"date_equals",
		"date_does_not_equal",
		"date_after",
		"date_before",
		"date_after_or_equal",
		"date_before_or_equal",
		"date_is_between",
		"exists",
		"does_not_exist",
	],
	datetime: [
		"datetime_equals",
		"datetime_does_not_equal",
		"datetime_after",
		"datetime_before",
		"datetime_after_or_equal",
		"datetime_before_or_equal",
		"datetime_is_between",
		"exists",
		"does_not_exist",
	],
	boolean: ["boolean_is_true", "boolean_is_false", "exists", "does_not_exist"],
	document: ["exists", "does_not_exist"],
	proxy: [],
	proxy_group: [],
	groupby_key: [],
};

const TextFilterInput = ({
	filter,
}: {
	filter: TextFilter;
}) => {
	return (
		<input
			className="h-full grow px-2 text-sm outline-none"
			value={filter.filter_value}
			onChange={(e) => {
				filter.filter_value = e.target.value;
			}}
		/>
	);
};

const NumberFilterInput = ({
	filter,
}: {
	filter: NumberFilter;
}) => {
	return (
		<input
			className="h-full grow px-2 text-sm outline-none"
			value={filter.filter_value}
			onChange={(e) => {
				filter.filter_value = Number(e.target.value);
			}}
		/>
	);
};

const CategoryFilterInput = ({ filter }: { filter: CategoryFilter }) => {
	const [open, setOpen] = useState(false);
	const [query, setQuery] = useState("");

	const filterCategories = filter.filter_value;

	const creatorContext = useCreatorContext();
	const config = creatorContext.config;
	if (!config) {
		return null;
	}

	const column = config.getColumnById(filter.filter_column_id);

	if (column.column_metadata.column_type !== "category") {
		return null;
	}

	const categories = column.column_metadata.categories;

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 grow items-start truncate p-1 text-left text-neutral-800 text-sm",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{filterCategories.map((category) => {
					const categoryColor = categories[category]?.color;
					return (
						<Badge
							key={category}
							className={clsx(
								CategoryBackgroundColors[categoryColor],
								CategoryBorderColors[categoryColor],
								"min-w-0 truncate text-neutral-800",
							)}
						>
							{category}
						</Badge>
					);
				})}
			</PopoverTrigger>
			<PopoverContent className="w-48 p-0" align="start">
				<Command>
					<CommandInput
						value={query}
						onValueChange={setQuery}
						placeholder="Search options..."
					/>
					<CommandList>
						<CommandEmpty>No options found.</CommandEmpty>
						<CommandGroup>
							{Object.values(categories).map((category) => (
								<CommandItem
									key={category.value}
									value={category.value}
									onSelect={(newValue) => {
										runInAction(() => {
											if (filterCategories.includes(newValue)) {
												filter.filter_value = filterCategories.filter(
													(category) => category !== newValue,
												);
											} else {
												filter.filter_value.push(newValue);
											}
										});
										setOpen(false);
									}}
								>
									<Check
										className={clsx(
											"mr-2 h-4 w-4",
											filterCategories.includes(category.value)
												? "opacity-100"
												: "opacity-0",
										)}
									/>
									<Badge
										className={clsx(
											CategoryBackgroundColors[category.color],
											CategoryBorderColors[category.color],
											"text-neutral-800",
										)}
									>
										{category.value}
									</Badge>
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

const DateFilterInput = ({
	filter,
}: {
	filter: DateFilter;
}) => {
	const [open, setOpen] = useState(false);
	const currentValue = filter.filter_value;

	const [newValue, setNewValue] = useState(
		// don't use `new Date(currentValue)` because it will convert the date to the local timezone,
		// potentially adding/subtracting a day
		currentValue ? dayjs.tz(currentValue, "UTC") : null,
	);

	return (
		<Popover
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen);
				if (!newOpen && newValue) {
					const newDateString = newValue.utc().format("YYYY-MM-DD");
					if (newDateString === currentValue) {
						return;
					}
					runInAction(() => {
						filter.filter_value = newDateString;
					});
				}
			}}
		>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 grow items-center justify-start truncate px-2 py-0.5 text-neutral-600 text-xs",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{newValue ? newValue.format("MMMM D, YYYY") : "Select a date..."}
			</PopoverTrigger>
			<PopoverContent className="w-72 p-4" align="start">
				<Calendar
					selectedDate={newValue}
					setSelectedDate={setNewValue}
					showTime={false}
				/>
			</PopoverContent>
		</Popover>
	);
};

const DateRangeFilterInput = ({
	filter,
}: {
	filter: DateRangeFilter;
}) => {
	const [open, setOpen] = useState(false);

	const currentValue = filter.filter_value;

	const [newValue, setNewValue] = useState<DateRangeDayjs>({
		start: currentValue?.start_date
			? dayjs.tz(currentValue.start_date, "UTC")
			: null,
		end: currentValue?.end_date ? dayjs.tz(currentValue.end_date, "UTC") : null,
	});

	return (
		<Popover
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen);
				if (!newOpen && newValue) {
					runInAction(() => {
						if (!newValue.start || !newValue.end) {
							return;
						}
						filter.filter_value = {
							start_date: newValue.start?.utc().toISOString(),
							end_date: newValue.end?.utc().toISOString(),
						};
					});
				}
			}}
		>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 grow items-center justify-start truncate px-2 py-0.5 text-neutral-600 text-xs",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{newValue.start ? newValue.start.format("YYYY-MM-DD") : "Start date"} -{" "}
				{newValue.end ? newValue.end.format("YYYY-MM-DD") : "end date"}
			</PopoverTrigger>
			<PopoverContent className="w-72 p-4">
				<CalendarWithRange
					selectedRange={newValue}
					setSelectedRange={setNewValue}
					showTime={false}
				/>
			</PopoverContent>
		</Popover>
	);
};

const DatetimeFilterInput = ({
	filter,
}: {
	filter: DatetimeFilter;
}) => {
	const [open, setOpen] = useState(false);

	const currentValue = filter.filter_value;

	const [newValue, setNewValue] = useState(
		currentValue ? dayjs.tz(currentValue, "UTC") : null,
	);

	return (
		<Popover
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen);
				if (!newOpen && newValue) {
					if (dayjs(currentValue).isSame(newValue)) return;
					runInAction(() => {
						filter.filter_value = newValue.utc().toISOString();
					});
				}
			}}
		>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 grow items-center justify-start truncate px-2 py-0.5 text-neutral-600 text-xs",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{newValue
					? newValue.local().format("YYYY-MM-DD HH:mm:ss")
					: "Select a time..."}
			</PopoverTrigger>
			<PopoverContent className="w-72 p-4">
				<Calendar
					selectedDate={newValue}
					setSelectedDate={setNewValue}
					showTime
				/>
			</PopoverContent>
		</Popover>
	);
};

const DatetimeRangeFilterInput = ({
	filter,
}: {
	filter: DatetimeRangeFilter;
}) => {
	const [open, setOpen] = useState(false);

	const currentValue = filter.filter_value;

	const [newValue, setNewValue] = useState<DateRangeDayjs>({
		start: currentValue?.start_datetime
			? dayjs.tz(currentValue.start_datetime, "UTC")
			: null,
		end: currentValue?.end_datetime
			? dayjs.tz(currentValue.end_datetime, "UTC")
			: null,
	});

	return (
		<Popover
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen);
				if (!newOpen && newValue) {
					runInAction(() => {
						if (!newValue.start || !newValue.end) {
							return;
						}
						filter.filter_value = {
							start_datetime: newValue.start?.utc().toISOString(),
							end_datetime: newValue.end?.utc().toISOString(),
						};
					});
				}
			}}
		>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 grow items-center justify-start truncate px-2 py-0.5 text-neutral-600 text-xs",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{newValue.start
					? newValue.start.format("YYYY-MM-DD HH:mm:ss")
					: "Start time"}{" "}
				-{" "}
				{newValue.end ? newValue.end.format("YYYY-MM-DD HH:mm:ss") : "end time"}
			</PopoverTrigger>
			<PopoverContent className="w-72 p-4">
				<CalendarWithRange
					selectedRange={newValue}
					setSelectedRange={setNewValue}
					showTime
				/>
			</PopoverContent>
		</Popover>
	);
};

const FilterInputComponent = ({ filter }: { filter: Filter }) => {
	switch (filter.filter_parameter_signature) {
		case "text": {
			return <TextFilterInput filter={filter} />;
		}
		case "number": {
			return <NumberFilterInput filter={filter} />;
		}
		case "category": {
			return <CategoryFilterInput filter={filter} />;
		}
		case "date": {
			return <DateFilterInput filter={filter} />;
		}
		case "date_range": {
			return <DateRangeFilterInput filter={filter} />;
		}
		case "datetime": {
			return <DatetimeFilterInput filter={filter} />;
		}
		case "datetime_range": {
			return <DatetimeRangeFilterInput filter={filter} />;
		}
		case "boolean": {
			return null;
		}
		case "existential": {
			return null;
		}
		default: {
			const _exhaustiveCheck: never = filter;
			return _exhaustiveCheck;
		}
	}
};

const TableSelect = () => {
	const creatorContext = useCreatorContext();
	const tables = useAppContext().workspace?.tables;

	if (!tables) {
		return null;
	}

	return (
		<Select
			onValueChange={(value) => {
				// Confirm if user wants to clear existing filters & group by
				// Remember stored table id in dialog state
				runInAction(() => {
					creatorContext.selectedTableId = value as TableId;
				});
			}}
		>
			<SelectTrigger>
				<SelectValue placeholder="Select table..." />
			</SelectTrigger>
			<SelectContent>
				{[...tables.keys()].map((tableId) => {
					const table = tables.get(tableId);
					if (!table) {
						return null;
					}
					return (
						<SelectItem key={table.table_id} value={table.table_id}>
							<span>{table.file_name}</span>
						</SelectItem>
					);
				})}
			</SelectContent>
		</Select>
	);
};

class ComputedTableConfig {
	baseTableId: TableId;
	baseTableState: TableState;
	computedTableState: TableState;
	filters: Map<FilterId, Filter> = new Map();
	groupbyColumns: ColumnId[] = [];
	applyFilters: () => void;

	constructor(
		tableId: TableId,
		tableData: GetTableLatestVersionResponse,
		navigate: ReturnType<typeof useNavigate>,
	) {
		this.baseTableId = tableId;
		this.baseTableState = TableState.fromResponse({
			tableId,
			tableData,
			navigate,
			editable: false,
		});
		this.computedTableState = this.baseTableState;

		this.applyFilters = reaction(
			() => ({
				filters: toJS(this.filters),
				groupbyColumns: toJS(this.groupbyColumns),
			}),
			({ filters, groupbyColumns }) => {
				if (filters.size === 0 && groupbyColumns.length === 0) {
					this.computedTableState = renderComputedTable({
						baseTable: this.baseTableState.table,
						operation: {
							operation_type: "filter_only",
							filtered_row_ids: [...this.baseTableState.table.root.rows.keys()],
							proxied_column_ids: this.sortedColumns.map(
								(column) => column.column_id,
							),
						},
					});
				} else {
					previewComputedTableRoute({
						table_id: this.baseTableId,
						filters: Array.from(filters.values()),
						groupby_column_ids: groupbyColumns,
						proxied_column_ids: this.sortedColumns
							.map((column) => column.column_id)
							.filter((columnId) => !groupbyColumns.includes(columnId)),
					}).then((res) => {
						runInAction(() => {
							this.computedTableState = renderComputedTable({
								baseTable: this.baseTableState.table,
								operation: res.data.preview,
							});
						});
					});
				}
			},
		);

		makeAutoObservable(this);
	}

	swapFilter(filterId: FilterId, newFilter: Filter) {
		const oldFilter = this.filters.get(filterId);
		if (!oldFilter) {
			throw new Error(`Filter with id ${filterId} not found`);
		}
		if (oldFilter.filter_column_type !== newFilter.filter_column_type) {
			throw new Error("Cannot swap filters with different column types");
		}
		this.filters.set(filterId, newFilter);
	}

	getColumnById(columnId: ColumnId) {
		const column = this.baseTableState.table.root.columns.get(columnId);
		if (!column) {
			throw new Error(`Column with id ${columnId} not found`);
		}
		return column;
	}

	get sortedColumns() {
		return [...this.baseTableState.table.root.columns.values()].sort((a, b) =>
			a.column_order.localeCompare(b.column_order),
		);
	}

	get filtersByColumn() {
		const filtersByColumn = new Map<ColumnId, Filter[]>();
		for (const filter of this.filters.values()) {
			if (!filtersByColumn.has(filter.filter_column_id)) {
				filtersByColumn.set(filter.filter_column_id, []);
			}
			filtersByColumn.get(filter.filter_column_id)?.push(filter);
		}
		return filtersByColumn;
	}

	initFilter(column: MaterializedColumn) {
		let filter: Filter;
		switch (column.column_metadata.column_type) {
			case "text": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "text",
					filter_type: "text_contains",
					filter_column_type: "text",
					filter_value: "",
				};
				break;
			}
			case "number": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "number",
					filter_type: "number_equals",
					filter_column_type: "number",
					filter_value: 0,
				};
				break;
			}
			case "boolean": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "boolean",
					filter_type: "boolean_is_false",
					filter_column_type: "boolean",
					filter_value: null,
				};
				break;
			}
			case "category": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "category",
					filter_type: "category_equals",
					filter_column_type: "category",
					filter_value: [],
				};
				break;
			}
			case "date": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "date",
					filter_type: "date_equals",
					filter_column_type: "date",
					filter_value: "",
				};
				break;
			}
			case "datetime": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "datetime",
					filter_type: "datetime_equals",
					filter_column_type: "datetime",
					filter_value: "",
				};
				break;
			}
			case "document": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "existential",
					filter_type: "exists",
					filter_column_type: null,
					filter_value: null,
				};
				break;
			}
			case "proxy": {
				// This is going to be rough
				throw new Error("Proxy column not supported");
			}
			case "proxy_group": {
				// This is going to be rough
				throw new Error("Proxy group column not supported");
			}
			case "groupby_key": {
				// This is going to be rough
				throw new Error("Proxy group column not supported");
			}
			default: {
				const _exhaustiveCheck: never = column.column_metadata;
				return _exhaustiveCheck;
			}
		}

		this.filters.set(filter.filter_id as FilterId, filter);
	}

	removeFilter(filterId: FilterId) {
		this.filters.delete(filterId);
	}

	groupBy: string | null = null; // Property ID
}

class CreatorState {
	selectedTableId: TableId | null = null;
	config: ComputedTableConfig | null = null;
	selectedTableLoading = false;

	get configMatchesSelectedTable() {
		return this.selectedTableId === this.config?.baseTableId;
	}

	constructor() {
		makeAutoObservable(this);
	}
}

const creatorContext = // biome-ignore lint/suspicious/noExplicitAny: <explanation>
	createContext<CreatorState>(null as any);

const useCreatorContext = () => {
	const context = useContext(creatorContext);
	if (!context) {
		throw new Error(
			"useCreatorContext must be used within a CreateComputedTableProvider",
		);
	}
	return context;
};

export const CreatorProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const tabsContext = useTabStore();
	const navigate = tabsContext.navigate;
	const [creatorState] = useState(() => new CreatorState());

	useEffect(() => {
		return autorun(() => {
			const selectedTableId = creatorState.selectedTableId;
			if (!selectedTableId) {
				return;
			}
			creatorState.selectedTableLoading = true;
			getTableLatestVersionRoute(selectedTableId)
				.then((res) => {
					runInAction(() => {
						creatorState.config = new ComputedTableConfig(
							selectedTableId,
							res.data,
							navigate as ReturnType<typeof useNavigate>,
						);
					});
				})
				.finally(() => {
					runInAction(() => {
						creatorState.selectedTableLoading = false;
					});
				});
		});
	}, [creatorState, navigate]);

	return (
		<creatorContext.Provider value={creatorState}>
			{children}
		</creatorContext.Provider>
	);
};

const Filters = observer(() => {
	const creatorContext = useCreatorContext();

	if (!creatorContext.config) {
		return (
			<div className="w-full rounded-lg border bg-neutral-50 p-8 text-center text-neutral-500 text-sm">
				Select a table to view filters
			</div>
		);
	}

	return (
		<>
			{creatorContext.config.sortedColumns.map((column) => {
				return (
					<div className="flex flex-col px-4" key={column.column_id}>
						<div className="flex w-full items-center justify-between gap-2">
							<div className="flex items-center gap-2">
								{ColumnTypeIcons[column.column_metadata.column_type]({
									className: "text-neutral-500",
								})}
								<span className="font-medium text-neutral-700 text-sm">
									{column.column_metadata.column_name}
								</span>
							</div>

							<button
								type="button"
								className="flex items-center gap-1 rounded-md px-2 py-1 font-medium text-neutral-500 text-xs hover:bg-neutral-100 hover:text-neutral-800"
								onClick={() => {
									creatorContext.config?.initFilter(column);
								}}
							>
								<Plus weight="bold" />
								Add filter
							</button>
						</div>
						<div className="mt-1 flex flex-col gap-1">
							{creatorContext.config?.filtersByColumn
								.get(column.column_id)
								?.map((filter) => {
									const hasInputContent =
										filter.filter_parameter_signature !== "boolean" &&
										filter.filter_parameter_signature !== "existential";

									return (
										<div
											key={filter.filter_id}
											className={clsx(
												"flex items-center overflow-hidden rounded-md border",
												hasInputContent ? "w-full" : "max-w-max",
											)}
										>
											<Select
												value={filter.filter_type}
												onValueChange={(newValue: Filter["filter_type"]) => {
													// special cases where we need to change the parameter signature:
													// - from existential (no params) -> anything else: do nothing
													// - from anything else -> existential: do nothing
													// - from boolean (no params) -> anything else: do nothing
													// - from anything else -> boolean: do nothing
													// - from date_range <-> date: change
													// - from datetime_range <-> datetime: change

													const oldSignature = toJS(
														filter.filter_parameter_signature,
													);
													const newSignature = filterTypeToSignature[newValue];

													let newFilterValue = filter.filter_value;

													if (newSignature === oldSignature) {
														// no change in signature
													} else if (
														oldSignature === "date" &&
														newSignature === "date_range"
													) {
														newFilterValue = {
															start_date: filter.filter_value || null,
															end_date: filter.filter_value || null,
														} as DateRange;
													} else if (
														oldSignature === "date_range" &&
														newSignature === "date"
													) {
														newFilterValue = (filter as DateRangeFilter)
															.filter_value?.start_date;
													} else if (
														oldSignature === "datetime" &&
														newSignature === "datetime_range"
													) {
														newFilterValue = {
															start_datetime: filter.filter_value || null,
															end_datetime: filter.filter_value || null,
														} as DatetimeRange;
													} else if (
														oldSignature === "datetime_range" &&
														newSignature === "datetime"
													) {
														newFilterValue = (filter as DatetimeRangeFilter)
															.filter_value?.start_datetime;
													} else {
														// reinit filter value
														newFilterValue = signatureDefaults[newSignature]();
													}

													// @ts-ignore
													const newFilter: Filter = {
														filter_column_type: filter.filter_column_type,
														filter_column_id: filter.filter_column_id,
														filter_type: newValue as Filter["filter_type"],
														filter_parameter_signature: newSignature,
														filter_id: filter.filter_id,
														filter_value: newFilterValue,
													};

													creatorContext.config?.swapFilter(
														filter.filter_id as FilterId,
														newFilter,
													);
												}}
											>
												<SelectTrigger
													className={clsx(
														"h-full max-w-max rounded-none border-y-0 border-l-0 py-0 text-xs",
														hasInputContent ? "border-r" : "border-r-0",
													)}
												>
													<SelectValue>
														{filterLabels[filter.filter_type]}
													</SelectValue>
												</SelectTrigger>
												<SelectContent>
													{filtersByType[
														column.column_metadata.column_type
													].map((filterType) => {
														return (
															<SelectItem
																value={filterType}
																key={filterType}
																onClick={() => {
																	runInAction(() => {
																		filter.filter_type = filterType;
																	});
																}}
															>
																{filterLabels[filterType]}
															</SelectItem>
														);
													})}
												</SelectContent>
											</Select>

											<FilterInputComponent filter={filter} />

											<button
												type="button"
												className="flex h-full max-w-max items-center gap-1 border-l p-2 font-medium text-neutral-500 text-sm hover:bg-neutral-100 hover:text-neutral-800"
												onClick={() => {
													creatorContext.config?.removeFilter(
														filter.filter_id as FilterId,
													);
												}}
											>
												<X />
											</button>
										</div>
									);
								})}
						</div>
					</div>
				);
			})}
		</>
	);
});

const Groupbys = observer(() => {
	const creatorContext = useCreatorContext();
	const config = creatorContext.config;

	const [showTail, setShowTail] = useState(false);

	useEffect(() => {
		if (config?.groupbyColumns.length === 0) {
			setShowTail(true);
		}
	});

	if (!config) {
		return (
			<div className="w-full rounded-lg border bg-neutral-50 p-8 text-center text-neutral-500 text-sm">
				Select a table to view group by options
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-2">
			{config.groupbyColumns.map((groupbyColumnId, idx) => (
				<Select
					key={groupbyColumnId}
					value={groupbyColumnId}
					onValueChange={(newValue) => {
						runInAction(() => {
							config.groupbyColumns[idx] = newValue as ColumnId;
						});
					}}
				>
					<SelectTrigger>
						<SelectValue placeholder="Select column..." />
					</SelectTrigger>
					<SelectContent>
						{config.sortedColumns.map((column) => {
							if (
								(column.column_id !== groupbyColumnId &&
									config.groupbyColumns.includes(column.column_id)) ||
								column.column_metadata.column_type === "document" ||
								column.column_metadata.column_type === "text"
							) {
								return null;
							}

							return (
								<SelectItem key={column.column_id} value={column.column_id}>
									<div className="flex items-center gap-2">
										{ColumnTypeIcons[column.column_metadata.column_type]({
											className: "shrink-0 text-base text-neutral-700",
										})}
										<span className="text-sm">
											{column.column_metadata.column_name}
										</span>
									</div>
								</SelectItem>
							);
						})}
						<Separator />
						<Button
							variant="ghost"
							className="mt-1 w-full"
							onClick={() => {
								runInAction(() => {
									config.groupbyColumns.splice(idx, 1);
								});
							}}
						>
							<div className="flex items-center justify-start gap-2">
								<Trash />
								<span className="text-sm">Remove group by</span>
							</div>
						</Button>
					</SelectContent>
				</Select>
			))}
			{!showTail && (
				<Button
					onClick={() => {
						setShowTail(true);
					}}
					className="flex w-full items-center justify-start gap-1"
					variant="ghost"
				>
					<Plus />
					Add group by
				</Button>
			)}
			{showTail ? (
				<Select
					onValueChange={(columnId) => {
						setShowTail(false);
						runInAction(() => {
							config.groupbyColumns.push(columnId as ColumnId);
						});
					}}
				>
					<SelectTrigger>
						<SelectValue placeholder="Select column..." />
					</SelectTrigger>
					<SelectContent>
						{config.sortedColumns.map((column) => {
							if (
								config.groupbyColumns.includes(column.column_id) ||
								column.column_metadata.column_type === "document" ||
								column.column_metadata.column_type === "text"
							) {
								return null;
							}

							return (
								<SelectItem key={column.column_id} value={column.column_id}>
									<div className="flex items-center gap-2">
										{ColumnTypeIcons[column.column_metadata.column_type]({
											className: "shrink-0 text-base text-neutral-700",
										})}
										<span className="text-sm">
											{column.column_metadata.column_name}
										</span>
									</div>
								</SelectItem>
							);
						})}
					</SelectContent>
				</Select>
			) : null}
		</div>
	);
});

const Content = observer(() => {
	const appContext = useAppContext();
	const creatorContext = useCreatorContext();
	const tabStore = useTabStore();

	const [creationPending, setCreationPending] = useState(false);

	return (
		<>
			<section className="flex items-center justify-between border-neutral-200 border-b p-4">
				<DialogTitle className="text-base">
					Create new computed table
				</DialogTitle>
				<div className="flex gap-2">
					<Button
						variant={"outline"}
						onClick={() => {
							runInAction(() => {
								appContext.showCreateComputedTableDialog = false;
								appContext.showCreateTableDialog = true;
							});
						}}
					>
						Cancel
					</Button>
					<Tooltip>
						<TooltipTrigger asChild>
							<span>
								<Button
									disabled={
										!creatorContext.config ||
										(!creatorContext.config?.groupbyColumns.length &&
											!creatorContext.config?.filters.size) ||
										creationPending
									}
									onClick={() => {
										const config = creatorContext.config;
										if (!config) {
											toast.error(
												"Please select a table to create a computed view",
											);
											return;
										}
										if (!config.groupbyColumns.length && !config.filters.size) {
											toast.error("Please add at least one filter or group by");
											return;
										}
										const tableMetadata = appContext.getTableById(
											config.baseTableId,
										);
										if (!tableMetadata) {
											return;
										}
										setCreationPending(true);
										appContext
											.createComputedTable({
												filters: Array.from(config.filters.values()),
												groupbyColumnIds:
													config.groupbyColumns.length > 0
														? config.groupbyColumns
														: null,
												proxiedColumnIds: config.sortedColumns
													.map((column) => column.column_id)
													.filter(
														(columnId) =>
															!config.groupbyColumns.includes(columnId),
													),
												parentTableId: config.baseTableId,
												tableName: `Computed view of '${tableMetadata.file_name}'`,
											})
											.then((res) => {
												if (res?.remoteResult) {
													runInAction(() => {
														appContext.showCreateComputedTableDialog = false;
													});
													tabStore.navigate({
														path: "file",
														fileId: res.remoteResult.table_id,
													});
												}
											})
											.finally(() => {
												setCreationPending(false);
											});
									}}
									className="flex items-center gap-1"
								>
									{creationPending && <Spinner className="animate-spin" />}
									Create
								</Button>
							</span>
						</TooltipTrigger>
						<TooltipContent>
							{!creatorContext.config
								? "Please select a table to create a computed view"
								: null}
							{!creatorContext.config?.groupbyColumns.length &&
							!creatorContext.config?.filters.size
								? "Please add at least one filter or group by"
								: null}
						</TooltipContent>
					</Tooltip>
				</div>
			</section>
			<section className="flex min-h-0 w-full min-w-0 grow">
				<div className="flex h-full w-96 shrink-0 flex-col gap-4 overflow-y-auto border-neutral-200 border-r p-4">
					<div className="flex flex-col gap-2">
						<h2 className="font-medium text-neutral-950">Input table</h2>
						<p className="text-neutral-500 text-sm">
							Select a table to perform computations such as calculations,
							transformations, or aggregations.
						</p>
						<TableSelect />
					</div>

					<hr />

					<div className="flex flex-col gap-1">
						<h2 className="font-medium text-sm">Filter by</h2>
						<Filters />
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="font-medium text-sm">Group by</h2>
						<Groupbys />
					</div>
				</div>
				<div className="flex h-full w-full min-w-0 flex-col gap-2 bg-neutral-50 p-4">
					<div className="relative flex h-full w-full grow flex-col overflow-hidden rounded-lg border border-neutral-200">
						{creatorContext.config ? (
							<TableContext.Provider
								// The key is used to force the table to re-render when the config changes
								// Otherwise, we run into weird issues where the table doesn't update
								key={creatorContext.config.computedTableState.tableId}
								value={creatorContext.config.computedTableState}
							>
								<TableView />
							</TableContext.Provider>
						) : (
							<div className="flex h-full w-full flex-col items-center justify-center">
								<Cursor className="h-16 w-16 text-neutral-300" />
								<h1 className="mt-4 font-semibold text-xl">
									Select a table to preview it here
								</h1>
								<p className="mt-2 text-center text-neutral-500">
									Your table will automatically update as you apply filters.
								</p>
							</div>
						)}
						{creatorContext.selectedTableLoading && (
							<div className="absolute inset-0 flex items-center justify-center rounded-lg bg-white bg-opacity-50">
								<Spinner className="h-12 w-12 animate-spin text-neutral-300" />
							</div>
						)}
					</div>
				</div>
			</section>
		</>
	);
});

export const CreateComputedTableDialog = observer(() => {
	const appContext = useAppContext();

	return (
		<Dialog
			open={appContext.showCreateComputedTableDialog}
			onOpenChange={() => {
				runInAction(() => {
					appContext.showCreateComputedTableDialog = false;
				});
			}}
		>
			{/* This weird nesting is to make the provider unmount reset every time the modal is closed */}
			<DialogContent
				hideCloseButton={true}
				className="!rounded-none flex h-screen w-screen max-w-full flex-col gap-0 space-y-0 border-none p-0 transition-none"
			>
				<CreatorProvider>
					<Content />
				</CreatorProvider>
			</DialogContent>
		</Dialog>
	);
});
