import { TreeState } from "@/components/tree/tree-state";
import type { AppState } from "@/contexts/app-context/app-context";
import { FEED_CHANNELS_FOLDER_ID } from "@/contexts/app-context/tree-handlers";
import { makeAutoObservable } from "mobx";

export class SidebarState {
	appState: AppState;

	treeState: TreeState;

	showSidebar = true;

	constructor(appState: AppState) {
		this.appState = appState;
		this.treeState = new TreeState(
			appState,
			() =>
				this.appState.fileNodeTree.rootNodes.filter(
					(node) => node.file.file_id !== FEED_CHANNELS_FOLDER_ID,
				),
			false,
		);
		makeAutoObservable(this);
	}

	setShowSidebar = (show: boolean) => {
		this.showSidebar = show;
	};
}
