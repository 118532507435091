import type { Content } from "@tiptap/react";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import { unified } from "unified";

function compiler() {
	// @ts-ignore
	this.Compiler = (root) => root;
}

export function markdownToAst(markdown: string) {
	const stream = unified().use(remarkParse).use(remarkGfm);

	const file = stream.use(compiler).processSync(markdown);
	return file.result;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const handlers: { [key: string]: (node: any, type?: string) => any } = {
	root: (node) => ({
		type: "doc",
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		content: node.children ? node.children.map((node: any) => visit(node)) : [],
	}),
	heading: (node) => ({
		type: "heading",
		attrs: {
			level: node.depth,
		},
		content: node.children.map(visit),
	}),
	paragraph: (node) => ({
		type: "paragraph",
		content: node.children.map(visit),
	}),
	hr: () => ({
		type: "horizontalRule",
	}),
	text: (node) => ({
		type: "text",
		text: node.value,
	}),
	list: (node) => ({
		type: node.ordered ? "orderedList" : "bulletList",
		content: node.children.map(visit),
	}),
	listItem: (node) => ({
		type: "listItem",
		content: node.children.map(visit),
	}),
	markNode: (node, type) => {
		const _node = node.children ? visit(node.children[0]) : node;
		_node.marks = _node.marks || [];
		_node.marks.push({ type, attrs: node.props });
		return _node;
	},
	emphasis: (node) => handlers.markNode(node, "italic"),
	strong: (node) => handlers.markNode(node, "bold"),
	link: (link) => handlers.markNode(link, "link"),
	delete: (node) => handlers.markNode(node, "strike"),
	em: (node) => handlers.markNode(node, "italic"),
	// If output is prosemirror, replace with code node
	// Parsing errors if not replaced
	html: (node) => {
		const _node = {
			type: "text",
			text: node.value,
		};
		return handlers.markNode(_node, "code");
	},
	code: (node) => {
		const _node = {
			type: "codeBlock",
			content: [
				{
					type: "text",
					text: node.value,
				},
			],
		};

		return _node;
	},
	inlineCode: (node) => {
		const _node = {
			type: "text",
			marks: [{ type: "code" }],
			text: node.value,
		};
		return _node;
	},
	linkReference: (node) => {
		const _node = {
			type: "text",
			text: `[${node.children[0].value}][${node.label}]`,
		};
		return _node;
	},
	definition: (node) => {
		const _node = {
			type: "text",
			text: `[${node.label}]: ${node.url}`,
		};
		return _node;
	},
	// New code
	break: () => ({
		type: "hardBreak",
	}),
	thematicBreak: () => ({
		type: "horizontalRule",
	}),
	image: (node) => ({
		type: "image",
		attrs: {
			src: node.url,
			title: node.title,
			alt: node.alt,
		},
	}),
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function visit(node: any): Content {
	const type = node.type;
	if (handlers[type]) {
		return handlers[type](node);
	}
	return node;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function astToTiptap(tree: any): Content {
	return visit(tree);
}

export function markdownToTiptap(markdown: string): Content {
	return astToTiptap(markdownToAst(markdown));
}
