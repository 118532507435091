import type { AppState } from "@/contexts/app-context/app-context";
import type { PageState } from "@/contexts/page-context/page-context";
import {
	DisplayedActionError,
	createSyncedAction,
} from "@/contexts/synced-actions";
import { newBlockId, newPageId } from "@/id-generators";
import {
	addBlockCategoryRoute,
	createBlockRoute,
	createPageRoute,
	deleteBlockRoute,
	updateBlockMetadataRoute,
	updateBlockValueRoute,
} from "@api/fastAPI";
import type {
	BlockId,
	BlockMetadata,
	CategoryMetadata,
	CellValue,
	FolderId,
	MaterializedBlock,
	Page,
} from "@api/schemas";
import { generateJitteredKeyBetween } from "fractional-indexing-jittered";
import { toast } from "sonner";

export const createPageAction = createSyncedAction<
	AppState,
	{
		parentFolderId: FolderId | null;
		pageName: string;
	},
	Page,
	Page
>({
	async local(args) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		const pageId = newPageId();
		const blockId = newBlockId();
		const newPage: Page = {
			page_id: pageId,
			file_name: args.pageName,
			file_parent_id: args.parentFolderId,
			file_creator_id: this.workspace.userId,
			file_created_at: new Date().toISOString(),
			file_updated_at: new Date().toISOString(),
			file_deleted_at: null,
			file_type: "page",
			body_block_id: blockId,
			current_block_ids: [],
			file_id: pageId,
		};
		this.workspace.pages.set(pageId, newPage);
		return newPage;
	},
	async remote(args, localResult) {
		const page = await createPageRoute({
			file_name: args.pageName,
			file_parent_id: args.parentFolderId,
			body_block_id: localResult.body_block_id as BlockId,
			body_block_metadata: {
				column_type: "text",
				column_name: "Body",
				column_description: "",
			},
			page_id: localResult.page_id,
		});
		return page.data;
	},
	rollback(_, localResult) {
		this.workspace?.pages.delete(localResult.page_id);
	},
	onRemoteSuccess(_localArgs, _localResult, remoteResult) {
		this.workspace?.pages.set(remoteResult.page_id, remoteResult);
	},
});

export const createBlockAction = createSyncedAction<
	PageState,
	{
		previous_block_id: BlockId | null;
		blockMetadata: BlockMetadata;
	},
	MaterializedBlock,
	void
>({
	async local(args) {
		const blockId = newBlockId();
		const newBlock: MaterializedBlock = {
			block_id: blockId,
			block_order: generateJitteredKeyBetween(args.previous_block_id, null),
			// @ts-expect-error
			block_value: {
				cell_value_type: args.blockMetadata.column_type,
				cell_value: "",
			},
			block_metadata: args.blockMetadata,
		};
		this.page.blocks.set(blockId, newBlock);
		return newBlock;
	},
	async remote(_, localResult) {
		await createBlockRoute({
			block_id: localResult.block_id,
			block_value: {
				cell_value_type: "text",
				cell_value: "",
			},
			block_metadata: localResult.block_metadata,
			page_id: this.page.pageId,
		});
	},
	rollback(_, localResult) {
		toast.error("Failed to create block");
		this.page.blocks.delete(localResult.block_id);
	},
});

export const deleteBlockAction = createSyncedAction<
	PageState,
	{
		blockId: BlockId;
	},
	{ oldBlock: MaterializedBlock },
	void
>({
	async local({ blockId }) {
		const oldBlock = this.page.blocks.get(blockId);
		if (!oldBlock) {
			throw new DisplayedActionError("Block not found");
		}
		this.page.blocks.delete(blockId);
		return { oldBlock };
	},
	async remote(localArgs) {
		await deleteBlockRoute({
			block_id: localArgs.blockId,
			page_id: this.page.pageId,
		});
	},
	rollback({ blockId }, { oldBlock }) {
		toast.error("Failed to delete block");
		this.page.blocks.set(blockId, oldBlock);
	},
});

export const updateBlockValueAction = createSyncedAction<
	PageState,
	{
		blockId: BlockId;
		blockValue: CellValue;
	},
	{ oldBlockValue: CellValue },
	void
>({
	async local({ blockId, blockValue }) {
		const block = this.page.blocks.get(blockId);
		if (!block) {
			throw new DisplayedActionError("Block not found");
		}
		block.block_value = blockValue;
		return { oldBlockValue: block.block_value };
	},
	async remote(localArgs) {
		await updateBlockValueRoute({
			block_id: localArgs.blockId,
			block_value: localArgs.blockValue,
			page_id: this.page.pageId,
		});
	},
	rollback({ blockId }, { oldBlockValue }) {
		const block = this.page.blocks.get(blockId);
		if (!block) {
			throw new DisplayedActionError("Block not found");
		}
		block.block_value = oldBlockValue;
	},
});

export const addBlockCategoryAction = createSyncedAction<
	PageState,
	{
		blockId: BlockId;
		newCategory: CategoryMetadata;
	},
	void,
	void
>({
	async local({ blockId, newCategory }) {
		const block = this.page.blocks.get(blockId);
		if (!block) {
			throw new DisplayedActionError("Block not found");
		}
		if (block.block_metadata.column_type !== "category") {
			throw new DisplayedActionError("Block is not a categorical column");
		}
		block.block_metadata.categories[newCategory.value] = newCategory;
	},
	async remote(localArgs) {
		await addBlockCategoryRoute({
			block_id: localArgs.blockId,
			new_category: localArgs.newCategory,
			page_id: this.page.pageId,
		});
	},
	rollback(localArgs) {
		const block = this.page.blocks.get(localArgs.blockId);
		if (!block) {
			throw new DisplayedActionError("Block not found");
		}
	},
});

export const updateBlockMetadataAction = createSyncedAction<
	PageState,
	{
		blockId: BlockId;
		blockMetadata: BlockMetadata;
	},
	void,
	void
>({
	async local({ blockId, blockMetadata }) {
		const block = this.page.blocks.get(blockId);
		if (!block) {
			throw new DisplayedActionError("Block not found");
		}
		block.block_metadata = blockMetadata;
	},
	async remote(localArgs) {
		await updateBlockMetadataRoute({
			block_id: localArgs.blockId,
			block_description: localArgs.blockMetadata.column_description,
			block_name: localArgs.blockMetadata.column_name,
			page_id: this.page.pageId,
		});
	},
	rollback(localArgs) {
		const block = this.page.blocks.get(localArgs.blockId);
		if (!block) {
			throw new DisplayedActionError("Block not found");
		}
	},
});
