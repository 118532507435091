import { CategoryColor } from "@api/schemas";

export const chooseRandomCategoryColor = () => {
	const colors = Object.values(CategoryColor);
	return colors[Math.floor(Math.random() * colors.length)];
};

export const CategoryBackgroundColors: {
	[key in CategoryColor]: string;
} = {
	[CategoryColor.gray]: "bg-gray-100",
	[CategoryColor.red]: "bg-red-100",
	[CategoryColor.orange]: "bg-orange-100",
	[CategoryColor.amber]: "bg-amber-100",
	[CategoryColor.yellow]: "bg-yellow-100",
	[CategoryColor.lime]: "bg-lime-100",
	[CategoryColor.green]: "bg-green-100",
	[CategoryColor.emerald]: "bg-emerald-100",
	[CategoryColor.teal]: "bg-teal-100",
	[CategoryColor.cyan]: "bg-cyan-100",
	[CategoryColor.sky]: "bg-sky-100",
	[CategoryColor.blue]: "bg-blue-100",
	[CategoryColor.indigo]: "bg-indigo-100",
	[CategoryColor.violet]: "bg-violet-100",
	[CategoryColor.purple]: "bg-purple-100",
	[CategoryColor.fuchsia]: "bg-fuchsia-100",
	[CategoryColor.pink]: "bg-pink-100",
	[CategoryColor.rose]: "bg-rose-100",
};

export const CategoryBorderColors: {
	[key in CategoryColor]: string;
} = {
	[CategoryColor.gray]: "border-gray-200",
	[CategoryColor.red]: "border-red-200",
	[CategoryColor.orange]: "border-orange-200",
	[CategoryColor.amber]: "border-amber-200",
	[CategoryColor.yellow]: "border-yellow-200",
	[CategoryColor.lime]: "border-lime-200",
	[CategoryColor.green]: "border-green-200",
	[CategoryColor.emerald]: "border-emerald-200",
	[CategoryColor.teal]: "border-teal-200",
	[CategoryColor.cyan]: "border-cyan-200",
	[CategoryColor.sky]: "border-sky-200",
	[CategoryColor.blue]: "border-blue-200",
	[CategoryColor.indigo]: "border-indigo-200",
	[CategoryColor.violet]: "border-violet-200",
	[CategoryColor.purple]: "border-purple-200",
	[CategoryColor.fuchsia]: "border-fuchsia-200",
	[CategoryColor.pink]: "border-pink-200",
	[CategoryColor.rose]: "border-rose-200",
};
