import { ImageResize } from "@/plugins/image-resize";
import { Outline } from "@/plugins/outline-plugin";
import Blockquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Code from "@tiptap/extension-code";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
// import { DisplayMath, InlineMath } from "@/plugins/MathPlugin";

export const CommonExtensions = [
	Paragraph,
	Text,
	Link.configure({
		openOnClick: false,
	}),
	Bold.configure({
		HTMLAttributes: {
			class: "font-semibold",
		},
	}),
	Underline,
	Italic,
	Strike,

	Code,
	Heading,
	Blockquote,
	TextAlign.configure({
		types: ["heading", "paragraph"],
		alignments: ["left", "center", "right", "justify"],
		defaultAlignment: "left",
	}),
	BulletList,
	// CustomListItem,
	ListItem,
	OrderedList,
	TaskList,
	TaskItem.configure({
		nested: true,
	}),
	ImageResize,
	Dropcursor,
	// InlineMath,
	// DisplayMath,
	Outline,
	Table.configure({
		resizable: true,
	}),
	TableRow,
	TableHeader,
	TableCell,
	// CustomCodeBlock,
	// Details.configure({
	//     HTMLAttributes: {
	//         class: 'details',
	//     },
	// }),
	// DetailsSummary,
	// DetailsContent,
	// New
	HorizontalRule,
	HardBreak,
];

export const CommonExtensionsWithoutHeading = [Document, ...CommonExtensions];
