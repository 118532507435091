import type { BasePageBlockProps } from "@/components/page/page-generics";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { usePageContext } from "@/contexts/page-context/page-context";
import type { DatetimeCellValue } from "@api/schemas";
import { X } from "@phosphor-icons/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const DatetimeBlock = observer(
	({ blockId, blockValue }: BasePageBlockProps<"datetime">) => {
		const pageContext = usePageContext();
		const [open, setOpen] = useState(false);

		let castValue: DatetimeCellValue | null;
		if (blockValue && blockValue.cell_value_type !== "datetime") {
			castValue = null;
		} else {
			castValue = blockValue;
		}
		const currentValue = castValue?.cell_value;

		const [newValue, setNewValue] = useState(
			currentValue ? dayjs.tz(currentValue, "UTC") : null,
		);
		useEffect(() => {
			setNewValue(currentValue ? dayjs.tz(currentValue, "UTC") : null);
		}, [currentValue]);

		return (
			<Popover
				open={open}
				onOpenChange={(newOpen) => {
					setOpen(newOpen);
					if (!newOpen && newValue) {
						if (dayjs(currentValue).isSame(newValue)) return;
						pageContext.updateBlockValue({
							blockId,
							blockValue: {
								cell_value: newValue ? newValue.utc().toISOString() : null,
								cell_value_type: "datetime",
							},
						});
					}
				}}
			>
				<PopoverTrigger
					disabled={!pageContext.editable}
					className={clsx(
						"flex h-full w-full min-w-0 justify-start truncate p-1 text-neutral-600 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
				>
					{newValue ? newValue.local().format("YYYY-MM-DD HH:mm:ss") : null}
				</PopoverTrigger>
				<PopoverContent className="w-72 p-2">
					<Calendar
						selectedDate={newValue}
						setSelectedDate={setNewValue}
						showTime
					/>
					<Button
						onClick={() => {
							pageContext.updateBlockValue({
								blockId,
								blockValue: {
									cell_value: null,
									cell_value_type: "datetime",
								},
							});
						}}
						variant="ghost"
						className="mt-1 flex w-full items-center justify-start gap-1"
					>
						<X className="" />
						Clear date
					</Button>
				</PopoverContent>
			</Popover>
		);
	},
);
