import { Favicon } from "@/components/favicon";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { Dialog } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { useAppContext } from "@/contexts/app-context/app-context";
import { useTabStore } from "@/contexts/tabs-context/tabs-context";
import type { FeedChannel } from "@api/schemas";
import { Plus, Trash } from "@phosphor-icons/react";
import {
	type RowSelectionState,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const FeedsEmptyState = observer(() => {
	const appContext = useAppContext();
	return (
		<div className="flex h-full flex-col items-center justify-center gap-4">
			<h1 className="px-4 text-center text-neutral-500">
				Add a feed to get started!
			</h1>
			<Button
				onClick={() => {
					runInAction(() => {
						appContext.showAddFeedDialog = true;
					});
				}}
			>
				Add feed
			</Button>
		</div>
	);
});

export const FeedChannelComponent = observer(
	({ feedChannel }: { feedChannel: FeedChannel }) => {
		const [contextOpen, setContextOpen] = useState(false);
		const appContext = useAppContext();

		return (
			<Dialog>
				<AlertDialog>
					<ContextMenu onOpenChange={setContextOpen}>
						<ContextMenuTrigger
							asChild
							className={clsx(
								"group flex w-full cursor-pointer items-center rounded-md border px-2 py-1 hover:bg-neutral-100",
								contextOpen ? "border-blue-300" : "border-transparent",
							)}
						>
							<tr>
								<td>
									<Link
										to={`/feed/${feedChannel.feed_channel_id}`}
										className="flex min-w-0 grow items-center gap-2 truncate pr-2 text-left"
									>
										{feedChannel.feed_channel_link ? (
											<Favicon
												url={feedChannel.feed_channel_link}
												alt={feedChannel.file_name}
												className="h-8 w-8 shrink-0 rounded-md bg-neutral-100"
											/>
										) : (
											<div className="h-8 w-8 shrink-0 rounded-md bg-neutral-200" />
										)}

										<div className="flex min-w-0 flex-col gap-1.5 truncate">
											<h2 className="min-w-0 truncate text-sm leading-4">
												{feedChannel.file_name}
											</h2>
										</div>
									</Link>
								</td>
								<td>test</td>
							</tr>
						</ContextMenuTrigger>
						<ContextMenuContent>
							<ContextMenuItem className="flex items-center gap-2">
								<AlertDialogTrigger className="flex items-center gap-2">
									<Trash weight="bold" />
									Delete feed
								</AlertDialogTrigger>
							</ContextMenuItem>
						</ContextMenuContent>
					</ContextMenu>
					<AlertDialogContent>
						<AlertDialogHeader>
							<AlertDialogTitle>Delete feed?</AlertDialogTitle>
							<AlertDialogDescription>
								This will remove the feed.
							</AlertDialogDescription>
						</AlertDialogHeader>
						<AlertDialogFooter>
							<AlertDialogCancel>Cancel</AlertDialogCancel>
							<AlertDialogAction
								onClick={() => {
									appContext.deleteFiles({
										fileIds: [feedChannel.feed_channel_id],
									});
								}}
							>
								Continue
							</AlertDialogAction>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>
			</Dialog>
		);
	},
);
const columnHelper = createColumnHelper<FeedChannel>();

const columns = [
	columnHelper.display({
		id: "select",
		header: ({ table }) => (
			<div className="flex h-full w-full items-center justify-center">
				<Checkbox
					checked={
						table.getIsAllRowsSelected()
							? true
							: table.getIsSomeRowsSelected()
								? "indeterminate"
								: false
					}
					onCheckedChange={(checked) =>
						table.toggleAllRowsSelected(checked === true)
					}
				/>
			</div>
		),
		cell: ({ row }) => (
			<div
				className="absolute inset-0 flex items-center justify-center"
				onClick={(e) => {
					e.stopPropagation();
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.stopPropagation();
					}
				}}
			>
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(checked) => row.toggleSelected(checked === true)}
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			</div>
		),
	}),
	columnHelper.display({
		id: "feed_channel_title",
		header: "Publication",
		cell: (props) => (
			<div className="flex items-center gap-2 p-2 text-sm">
				{props.row.original.feed_channel_link ? (
					<Favicon
						url={props.row.original.feed_channel_link}
						alt={props.row.original.file_name}
						className="h-10 w-10 shrink-0 rounded-md bg-neutral-200"
					/>
				) : (
					<div className="h-10 w-10 shrink-0 rounded-md bg-neutral-200" />
				)}
				<div className="w-full min-w-0 truncate">
					<h2 className="font-semibold">{props.row.original.file_name}</h2>
					<h3 className="w-full min-w-0 truncate text-neutral-500">
						{props.row.original.feed_channel_subtitle}
					</h3>
				</div>
			</div>
		),
	}),
	columnHelper.display({
		id: "description",
		header: "Description",
		cell: (props) => {
			return (
				<h3 className="max-w-64 p-2 text-neutral-500 text-sm">
					<span className="line-clamp-2">
						{props.row.original.feed_channel_description}
					</span>
				</h3>
			);
		},
	}),
	columnHelper.display({
		id: "article",
		header: "Articles",
		cell: (props) => {
			const appContext = useAppContext();
			const numItems =
				appContext.sortedFeedItemsByChannel?.get(
					props.row.original.feed_channel_id,
				)?.length ?? 0;
			return <h3 className="p-2 text-neutral-500 text-sm">{numItems}</h3>;
		},
	}),
];

export const ManageFeeds = observer(() => {
	const appContext = useAppContext();
	const feedChannels = appContext.sortedFeedChannels;
	const tabsContext = useTabStore();
	const navigate = tabsContext.navigate;

	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

	const table = useReactTable({
		data: feedChannels ?? [],
		columns,
		getCoreRowModel: getCoreRowModel(),
		enableRowSelection: true, //enable row selection for all rows
		onRowSelectionChange: setRowSelection,
		state: {
			rowSelection,
		},
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const selectedFeedChannelIds = useMemo(() => {
		return table
			.getSelectedRowModel()
			.rows.map((row) => row.original.feed_channel_id);
	}, [rowSelection]);

	return (
		<>
			<Helmet>
				<title>Feeds - Village</title>
			</Helmet>
			<div className="flex h-full max-h-full min-h-0 w-full flex-col">
				<div
					className={clsx(
						"flex h-14 w-full shrink-0 items-center justify-between gap-2 border-b bg-white/90 px-2 backdrop-blur",
					)}
				>
					<div className="flex items-center gap-2">
						<Button
							onClick={() => {
								runInAction(() => {
									appContext.showAddFeedDialog = true;
								});
							}}
							type="button"
							variant="ghost"
							className="gap-2"
						>
							<Plus className="text-lg" weight="regular" />
						</Button>
					</div>
					{selectedFeedChannelIds.length > 0 && (
						<>
							<span className="rounded-lg bg-neutral-100 px-3 py-1 text-neutral-700 text-sm">
								{selectedFeedChannelIds.length} selected
							</span>
							<Button
								onClick={() => {
									table.resetRowSelection();
									appContext.deleteFiles({
										fileIds: selectedFeedChannelIds,
									});
								}}
								variant="ghost"
								className="gap-2"
							>
								<Trash weight="bold" />
								Delete
							</Button>
						</>
					)}
				</div>
				{appContext.workspaceHasLoaded ? (
					feedChannels && feedChannels.length > 0 ? (
						<div className="flex-1 overflow-auto">
							<table className="w-full">
								<thead className="sticky top-0 border-b bg-neutral-100 text-neutral-700 text-sm">
									{table.getHeaderGroups().map((headerGroup) => (
										<tr key={headerGroup.id}>
											{headerGroup.headers.map((header) => (
												<th
													className="p-2 text-left font-semibold"
													key={header.id}
												>
													{header.isPlaceholder
														? null
														: flexRender(
																header.column.columnDef.header,
																header.getContext(),
															)}
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody className="max-h-full min-h-0 grow">
									{table.getRowModel().rows.map((row) => (
										<tr
											className={clsx(
												"cursor-pointer border-b",
												row.getIsSelected()
													? "bg-blue-50"
													: "hover:bg-neutral-100",
											)}
											key={row.id}
											onClick={() => {
												navigate({
													path: "file",
													fileId: row.original.feed_channel_id,
												});
											}}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													navigate({
														path: "file",
														fileId: row.original.feed_channel_id,
													});
												}
											}}
										>
											{row.getVisibleCells().map((cell) => (
												<td key={cell.id} className="relative">
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext(),
													)}
												</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<FeedsEmptyState />
					)
				) : (
					<div className="flex w-full flex-col gap-1.5 p-3">
						<Skeleton className="h-6 w-full" />
						<Skeleton className="h-6 w-full" />
						<Skeleton className="h-6 w-full" />
					</div>
				)}
			</div>
		</>
	);
});
