import { Layout } from "@/components/layout/layout";
import { AppProvider } from "@/contexts/app-context/app-context";
import "@/index.css";
import { Tabs } from "@/pages/tabs";
import type { UserId } from "@api/schemas";
import { useAuth, useUser } from "@clerk/clerk-react";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useRef } from "react";

let usedInterceptor = false;

// from https://github.com/nextauthjs/next-auth/discussions/3550
function useInterceptor() {
	const { getToken } = useAuth();

	if (usedInterceptor) return;

	axios.interceptors.request.use(async (config) => {
		const token = await getToken();
		config.headers.Authorization = `Bearer ${token}`;

		return config;
	});

	usedInterceptor = true;
}

const queryClient = new QueryClient();

export const Root = () => {
	useInterceptor();

	const { user } = useUser();
	const identified = useRef(false);

	useEffect(() => {
		if (user && !identified.current) {
			identified.current = true;

			Sentry.setUser({
				id: user.id,
				email: user.primaryEmailAddress?.emailAddress,
			});
		}
	}, [user]);

	if (!user) {
		return null;
	}

	return (
		<QueryClientProvider client={queryClient}>
			<AppProvider userId={user.id as UserId}>
				<TooltipProvider delayDuration={100} skipDelayDuration={500}>
					<Layout>
						<Tabs />
					</Layout>
				</TooltipProvider>
			</AppProvider>
		</QueryClientProvider>
	);
};
