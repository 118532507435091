import { SidebarLibrary } from "@/components/layout/sidebar/sidebar-library";
import { Button } from "@/components/ui/button";
import { useAppContext } from "@/contexts/app-context/app-context";
import { useTabStore } from "@/contexts/tabs-context/tabs-context";
import { cn } from "@/lib/utils";
import type { TabPathObject } from "@/paths";
import { SignedIn, UserButton } from "@clerk/clerk-react";
import {
	Globe,
	type Icon,
	MagnifyingGlass,
	Rss,
	Table,
} from "@phosphor-icons/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

const Overlay = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	if (!isOpen) {
		return null;
	}

	return (
		<div
			role="button"
			aria-label="Close sidebar"
			tabIndex={0}
			className="absolute top-0 right-0 bottom-0 left-0 z-40 bg-black/10 opacity-80"
			onClick={onClose}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					onClose();
				}
			}}
		/>
	);
};

const SidebarNavigationButton = ({
	ButtonIcon,
	label,
	pathObject,
	navigate,
}: {
	ButtonIcon: Icon;
	label: string;
	pathObject: TabPathObject;
	navigate: (pathObject: TabPathObject) => void;
}) => {
	return (
		<Button
			variant="ghost"
			className={clsx(
				"flex h-8 w-full shrink-0 items-center justify-start gap-2 px-2 py-1.5 font-medium text-neutral-950 text-sm hover:bg-neutral-50",
			)}
			onClick={() => {
				navigate(pathObject);
			}}
		>
			<ButtonIcon weight="duotone" className="text-base text-neutral-950" />
			{label}
		</Button>
	);
};

export const Sidebar = observer(() => {
	const appContext = useAppContext();
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
	const tabsContext = useTabStore();

	return (
		<>
			{isSmallDevice && (
				<Overlay
					isOpen={appContext.sidebarState.showSidebar}
					onClose={() => {
						appContext.sidebarState.setShowSidebar(false);
					}}
				/>
			)}
			<div
				className={cn(
					"z-50 h-full w-full min-w-0 shrink-0 overflow-hidden",
					isSmallDevice
						? "absolute top-0 bottom-0 border-r shadow-xl"
						: "relative",
				)}
			>
				<nav
					className={cn(
						"group/sidebar flex h-full w-full flex-col divide-y divide-neutral-100 bg-white backdrop-blur",
					)}
				>
					<div className="flex min-h-0 w-full flex-col gap-4 ">
						<div className="flex min-h-0 w-full grow flex-col gap-0.5">
							<SidebarNavigationButton
								ButtonIcon={MagnifyingGlass}
								label="Search"
								pathObject={{ path: "search" }}
								navigate={tabsContext.navigate}
							/>
							<SidebarNavigationButton
								ButtonIcon={Globe}
								label="Web Search"
								pathObject={{ path: "web-search" }}
								navigate={tabsContext.navigate}
							/>
							<SidebarNavigationButton
								ButtonIcon={Rss}
								label="Feeds"
								pathObject={{ path: "manage-feeds" }}
								navigate={tabsContext.navigate}
							/>
							<SidebarNavigationButton
								ButtonIcon={Table}
								label="Tables"
								pathObject={{ path: "manage-tables" }}
								navigate={tabsContext.navigate}
							/>
						</div>
					</div>

					<div className="flex min-h-0 w-full grow flex-col">
						<SidebarLibrary />
					</div>

					<SignedIn>
						<div className="flex items-center bg-white p-2">
							<UserButton />
						</div>
					</SignedIn>
				</nav>
			</div>
		</>
	);
});
