import { Skeleton } from "@/components/ui/skeleton";
import {
	type TabTypeStateMap,
	useTab,
} from "@/contexts/tabs-context/tabs-context";
import { SearchComboboxCommandList } from "@/pages/search/search-combobox-command-list";
import { SearchFeedItemResultGroup } from "@/pages/search/search-feed-item-result-group";
import { SearchLibraryResultGroup } from "@/pages/search/search-library-result-group";
import type {
	// FeedChannelId,
	FeedItemId,
	SearchFeedItemsResult,
	SearchLibraryResult,
	SearchResponse,
	UploadId,
} from "@api/schemas";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useId, useMemo } from "react";

interface SearchResultsProps {
	searchResults: SearchResponse;
}

const SearchResults = observer(({ searchResults }: SearchResultsProps) => {
	const tab = useTab();
	const appContext = tab.tabStore.appState;
	const containerId = useId();

	const mergedResults = useMemo(() => {
		const merged = [
			...searchResults.library_results,
			...(searchResults.feed_items_results ?? []),
		];
		merged.sort((a, b) => (b.score ?? 0) - (a.score ?? 0));
		return merged;
	}, [searchResults.library_results, searchResults.feed_items_results]);

	if (mergedResults.length === 0) {
		return (
			<div className="flex h-full w-full items-center justify-center">
				<h1 className="text-neutral-500">No results found</h1>
			</div>
		);
	}

	const resultsHeader = (
		<div className="flex items-center justify-between border-b bg-neutral-50 px-2 py-1.5 text-neutral-500 text-sm">
			<div>
				{mergedResults.length} result
				{mergedResults.length > 1 ? "s" : ""}
			</div>
		</div>
	);

	const groupedResults = mergedResults.reduce((acc, result) => {
		if (result.type === "feed_items") {
			const feedItemResults = acc.get(result.feed_item_id);

			if (!feedItemResults) {
				acc.set(result.feed_item_id, [result]);
			} else {
				acc.set(result.feed_item_id, [...feedItemResults, result]);
			}
		} else if (result.type === "library") {
			const uploadResults = acc.get(result.upload_id);

			if (!uploadResults) {
				acc.set(result.upload_id, [result]);
			} else {
				acc.set(result.upload_id, [...uploadResults, result]);
			}
		}
		return acc;
	}, new Map<string, (SearchLibraryResult | SearchFeedItemsResult)[]>());

	return (
		<>
			{resultsHeader}
			<div
				// the key forces a rerender and a scroll reset when we switch between grouped and ungrouped results
				// or when the results change as indicated by result_id
				key={`grouped-${searchResults.result_id}`}
				className="relative mt-2 flex grow flex-col gap-2 overflow-y-auto bg-white px-2 pb-2"
				id={containerId}
			>
				{[...groupedResults].map(([resultId, results]) => {
					if (results[0].type === "feed_items") {
						const feedItem = appContext.workspace?.feedItems.get(
							resultId as FeedItemId,
						);

						if (!feedItem) {
							Sentry.captureMessage(
								"Feed item from search result not found in appContext",
								"error",
							);
							return null;
						}

						return (
							<SearchFeedItemResultGroup
								key={resultId}
								feedItem={feedItem}
								results={results as SearchFeedItemsResult[]}
								containerId={containerId}
							/>
						);
					}
					if (results[0].type === "library") {
						const upload = appContext.getUploadById(resultId as UploadId);
						if (!upload) {
							console.error(
								"Upload from search result not found in appContext",
							);
							Sentry.captureMessage(
								"Upload from search result not found in appContext",
								"error",
							);
							return null;
						}
						return (
							<SearchLibraryResultGroup
								key={resultId}
								upload={upload}
								results={results as SearchLibraryResult[]}
								containerId={containerId}
							/>
						);
					}
					return null;
				})}
			</div>
		</>
	);
});

// TODO(John): take a look at how the search result is happening
export const SearchBody = observer(() => {
	const tab = useTab();
	const searchState = tab.state as TabTypeStateMap["search"];
	const searchResult = searchState.appState.searchStore.getSearchResult(
		searchState.publishedSearchConfig,
	);
	const { searchLoading, searchResults } = searchResult ?? {};

	if (searchResults) {
		return <SearchResults searchResults={searchResults} />;
	}

	if (searchLoading) {
		return (
			<div className="relative flex grow flex-col overflow-y-auto bg-white">
				{[...Array(1)].map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="flex w-full flex-col space-y-2 p-6"
					>
						<div className="flex space-x-2">
							<Skeleton className="h-8 w-6" />
							<div className="flex min-w-0 grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full max-w-48" />
							</div>
						</div>
						<div className="flex w-full flex-col">
							<div className="flex grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	return <SearchComboboxCommandList />;
});
