import {
	ActiveAssistantStatusIndicator,
	MessageInput,
	Messages,
} from "@/components/layout/right-sidebar/messages";
import { Button } from "@/components/ui/button";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAppContext } from "@/contexts/app-context/app-context";
import type { AssistantEvent } from "@/contexts/app-context/session";
import type {
	AssistantSessionPathObject,
	FilePathObject,
	MessagePathObject,
} from "@/paths";
import {
	CustomLink,
	ObjectLink,
	ObjectLinkComponent,
	ObjectLinkContent,
} from "@/plugins/object-link";
import type {
	Action,
	CreatedAssistantSessionEvent,
	Event,
	FileId,
	MessageId,
	SentMessageEvent,
	Step,
	UpdatedCellEvent,
} from "@api/schemas";
import { CaretDown, Keyboard, MouseSimple, X } from "@phosphor-icons/react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { observer } from "mobx-react-lite";

const SentMessageEventDataComponent = observer(
	({ event }: { event: SentMessageEvent["data"] }) => {
		const editor = useEditor({
			extensions: [StarterKit, CustomLink, ObjectLink],
			content: event.message.content,
			editable: false,
		});
		return (
			<div className="inline-flex w-full flex-col">
				<span className="mb-1 ml-1 font-mono text-neutral-500 text-xs">
					Content
				</span>
				<EditorContent className="border p-1" editor={editor} />
			</div>
		);
	},
);

const CreatedAssistantSessionEventDataComponent = observer(
	({ event }: { event: CreatedAssistantSessionEvent["data"] }) => {
		const editor = useEditor({
			extensions: [StarterKit, ObjectLink],
			content: event.assistant_session.goal,
			editable: false,
		});
		return (
			<div className="inline-flex w-full flex-col">
				<span className="mb-1 ml-1 font-mono text-neutral-500 text-xs">
					Goal
				</span>
				<EditorContent className="border p-1" editor={editor} />
			</div>
		);
	},
);

const TableCellValueComponent = observer(
	({ event }: { event: UpdatedCellEvent["data"] }) => {
		const editor = useEditor({
			extensions: [StarterKit, CustomLink, ObjectLink],
			// TODO(John): other cell types
			// @ts-ignore
			content: event.table_cell_value.cell_value,
			editable: false,
		});
		return (
			<div className="inline-flex w-full flex-col">
				<span className="mb-1 ml-1 font-mono text-neutral-500 text-xs">
					Cell Value
				</span>
				<EditorContent className="border p-1" editor={editor} />
			</div>
		);
	},
);

const EventComponent = observer(({ event }: { event: AssistantEvent }) => {
	const renderEventData = (
		event: Event,
	): {
		header: React.ReactNode;
		content: React.ReactNode;
	} => {
		switch (event.type) {
			case "sent_message": {
				const messageId = event.data.message.message_id;
				const pathObject: MessagePathObject = {
					path: "message",
					messageId: messageId as MessageId,
				};
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Sent</span>
							<ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent pathObject={pathObject} label="Message" />
							</ObjectLinkComponent>
						</>
					),
					content: <SentMessageEventDataComponent event={event.data} />,
				};
			}

			case "created_assistant_session": {
				const pathObject: AssistantSessionPathObject = {
					path: "assistant-session",
					sessionAssistantId: event.data.assistant_session.session_assistant_id,
				};
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Created</span>
							<ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent
									pathObject={pathObject}
									label="Assistant Session"
								/>
							</ObjectLinkComponent>
						</>
					),
					content: (
						<CreatedAssistantSessionEventDataComponent event={event.data} />
					),
				};
			}

			case "opened_thread": {
				const pathObject: MessagePathObject = {
					path: "message",
					messageId: event.data.thread_id as MessageId,
				};
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Opened</span>
							<ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent pathObject={pathObject} label="Thread" />
							</ObjectLinkComponent>
						</>
					),
					content: null,
				};
			}

			case "opened_tab": {
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Opened</span>
							{/* TODO(John): merge path objects */}
							{/* <ObjectLinkComponent pathObject={event.data.tab.path_object} className="min-w-0">
								<ObjectLinkContent pathObject={event.data.tab.path_object} />
							</ObjectLinkComponent> */}
						</>
					),
					content: null,
				};
			}

			case "navigated_tab": {
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Opened</span>
							{/* TODO(John): merge path objects */}
							{/* <ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent pathObject={pathObject} />
							</ObjectLinkComponent> */}
						</>
					),
					content: null,
				};
			}

			case "closed_tab": {
				return {
					// TODO(John): more information about this tab
					header: <span>Closed Tab</span>,
					content: null,
				};
			}

			case "created_table": {
				// TODO(John): implement
				return {
					header: <span>Created Table</span>,
					content: null,
				};
			}

			case "updated_cell": {
				const tableId = event.data.table_id;
				const pathObject: FilePathObject = {
					path: "file",
					fileId: tableId as FileId,
				};
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Updated</span>
							<ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent pathObject={pathObject} label="Table" />
							</ObjectLinkComponent>
							<span className="ml-1.5 flex-none">Cell</span>
						</>
					),
					content: <TableCellValueComponent event={event.data} />,
				};
			}

			case "created_user_session": {
				console.error("Should never happen");
				return {
					header: <span>Created User Session</span>,
					content: null,
				};
			}

			case "ended_session": {
				return {
					header: <span>Ended Session</span>,
					content: null,
				};
			}

			default: {
				const _exhaustiveCheck: never = event;
				return _exhaustiveCheck;
			}
		}
	};

	const { header, content } = renderEventData(event);
	return (
		<div className="flex flex-col gap-1 p-2">
			{/* Header */}
			<div className="flex flex-nowrap items-center justify-between gap-2">
				<div className="flex min-w-0 grow flex-nowrap items-center">
					{header}
				</div>
				<span className="flex-none text-neutral-500">
					{new Date(event.created_at).toLocaleString("en-US", {
						month: "numeric",
						day: "numeric",
						hour: "numeric",
						minute: "numeric",
					})}
				</span>
			</div>
			<div className="">{content}</div>
		</div>
	);
});

const StepComponent = observer(({ step }: { step: Step }) => {
	const renderAction = (action: Action, index: number) => {
		switch (action.type) {
			case "edit_text":
				return (
					<div className="flex items-center gap-2" key={index}>
						<Keyboard size={16} weight="fill" />
						<span>{action.replacement}</span>
					</div>
				);
			case "click":
				return (
					<div className="flex items-center gap-2" key={index}>
						<MouseSimple size={16} weight="fill" />
						<span>Click</span>
					</div>
				);
		}
	};
	const editor = useEditor({
		extensions: [StarterKit, ObjectLink, CustomLink],
		content: step.thinking,
		editable: false,
	});
	return (
		<div className="flex flex-col gap-2 px-2">
			<span>Thinking</span>
			<EditorContent className="border p-1" editor={editor} />
			{step.actions.map((action, index) => renderAction(action, index))}
		</div>
	);
});

/**
 * Component that shows the activity of the assistant.
 */
const AssistantActivityViewer = observer(() => {
	const appContext = useAppContext();
	if (
		appContext.rightSidebarState.activityViewerActiveSessionAssistantId === null
	) {
		if (appContext.sortedAssistantActivityGroupedBySession.size === 0) {
			return <div>No assistant sessions</div>;
		}
		return (
			<div className="flex h-full w-full flex-col gap-4 overflow-y-auto py-2">
				<div className="flex flex-col gap-2">
					<div className="font-semibold">Currently Active</div>
					<div className="flex flex-wrap gap-2">
						{appContext.session?.activeAssistantSessionStatuses.size ? (
							Array.from(
								appContext.session?.activeAssistantSessionStatuses.entries() ??
									[],
							).map(([sessionAssistantId]) => (
								<ActiveAssistantStatusIndicator
									key={sessionAssistantId}
									sessionAssistantId={sessionAssistantId}
								/>
							))
						) : (
							<div className="text-neutral-500">
								No active assistants. Try sending a message!
							</div>
						)}
					</div>
				</div>
				<Collapsible className="flex flex-col gap-2">
					<CollapsibleTrigger className="flex items-center gap-2 text-left font-semibold">
						<span>Recently Ended</span>
						<CaretDown size={16} className="[[data-state=open]_&]:rotate-180" />
					</CollapsibleTrigger>
					<CollapsibleContent className="flex flex-col">
						{Array.from(
							appContext.sortedAssistantActivityGroupedBySession.entries(),
						).map(([sessionAssistantId, activity]) => (
							<Button
								key={sessionAssistantId}
								variant="ghost"
								className="justify-between rounded-none"
								onClick={() => {
									appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
										sessionAssistantId;
								}}
							>
								<span className="text-sm">
									Session {sessionAssistantId.slice(-4)}
								</span>
								<span>
									{/* TODO(John): this is probably not the right created_at */}
									{new Date(activity[0].created_at).toLocaleString("en-US", {
										month: "numeric",
										day: "numeric",
										hour: "numeric",
										minute: "numeric",
									})}
								</span>
							</Button>
						))}
					</CollapsibleContent>
				</Collapsible>
			</div>
		);
	}

	const activeAssistantActivity =
		appContext.sortedAssistantActivityGroupedBySession.get(
			appContext.rightSidebarState.activityViewerActiveSessionAssistantId,
		);
	if (!activeAssistantActivity) {
		console.error("Could not find active assistant activity");
		return <div>Could not find active assistant activity</div>;
	}
	return (
		<div className="flex h-full w-full flex-col">
			{/* Header */}
			<div className="flex flex-none items-center justify-between gap-2 border-b px-2 py-2">
				<span className="font-medium">
					Session{" "}
					{appContext.rightSidebarState.activityViewerActiveSessionAssistantId.slice(
						-4,
					)}
				</span>
				<Button
					variant="ghost"
					onClick={() => {
						appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
							null;
					}}
					className="h-min w-min items-start p-1"
				>
					<X size={16} />
				</Button>
			</div>
			{/* Activity */}
			<div className="flex w-full grow flex-col gap-2 overflow-y-auto">
				{activeAssistantActivity.map((eventOrStep) => {
					if ("event_id" in eventOrStep) {
						return (
							<EventComponent key={eventOrStep.event_id} event={eventOrStep} />
						);
					}
					return <StepComponent key={eventOrStep.step_id} step={eventOrStep} />;
				})}
			</div>
		</div>
	);
});

/**
 * The right sidebar, which shows messages and session activity.
 */
export const RightSidebar = observer(() => {
	const appContext = useAppContext();
	return (
		appContext.rightSidebarState.showRightSidebar && (
			<div className="flex h-full w-full flex-col bg-white px-2 py-2">
				<Tabs
					value={appContext.rightSidebarState.rightSidebarTab}
					onValueChange={(value) => {
						appContext.rightSidebarState.rightSidebarTab = value as
							| "messages"
							| "assistant_activity";
					}}
					className="flex h-full w-full flex-col"
				>
					<TabsList className="grid flex-none grid-cols-2">
						<TabsTrigger value="messages">Messages</TabsTrigger>
						<TabsTrigger value="assistant_activity">Activity</TabsTrigger>
					</TabsList>
					{/* Radix keeps inactive tabs around in the DOM, so we need to
				hide them with data-[state=inactive]:hidden to make sure the
				children's grows don't interfere. */}
					{/* Also, these min-h-0's are important. Otherwise, the "grow"
				elements have a min-h of their contents, which causes them to
				grow beyond the bounds of their parents if their children are
				large. */}
					<TabsContent
						value="messages"
						className="flex min-h-0 grow flex-col data-[state=inactive]:hidden"
					>
						<div className="min-h-0 grow">
							<Messages />
						</div>
						<div className="w-full flex-none">
							<MessageInput />
						</div>
					</TabsContent>
					<TabsContent
						value="assistant_activity"
						className="min-h-0 grow text-sm data-[state=inactive]:hidden"
					>
						<AssistantActivityViewer />
					</TabsContent>
				</Tabs>
			</div>
		)
	);
});
