/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AddBlockCategoryRequest,
  AddColumnCategoryRequest,
  BodyCreateUploadRoute,
  BootstrapSessionResponse,
  ChangeCategoryColorRequest,
  CreateBlockRequest,
  CreateColumnRequest,
  CreateComputedTableRequest,
  CreateFolderRequest,
  CreatePageRequest,
  CreateRowRequest,
  CreateTableRequest,
  CrossrefResponse,
  DeleteBlockRequest,
  DeleteColumnRequest,
  DeleteFilesRequest,
  DeleteRowsRequest,
  DescendantColumn,
  FeedItem,
  FeedItemId,
  FeedItemOutline,
  File,
  GetFeedItemParams,
  GetFilteredRowsRequest,
  GetPagePastVersionRouteParams,
  GetTableLatestVersionResponse,
  GetTablePastVersionRouteParams,
  GoogleBooksResponse,
  GuessRssUrlParams,
  HTTPValidationError,
  IndexFeedRequest,
  InferUploadMetadataRequest,
  InferUploadMetadataResponse,
  ListDescendantColumnsRouteParams,
  ListTablesAndColumnsRouteParams,
  MaterializedColumn,
  MaterializedPage,
  MaterializedTable,
  MessageWithMetadata,
  MoveColumnRequest,
  MoveDirectoryNodesRequest,
  MoveRowsRequest,
  PDFOutline,
  Page,
  PageId,
  PageResolution,
  PreviewComputedTableRequest,
  PreviewComputedTableResponse,
  PreviewRssFeedParams,
  PreviewRssFeedResponse,
  QueryCrossrefRequest,
  QueryGoogleBooksRequest,
  RefetchFeedChannelParams,
  RemoveColumnCategoryRequest,
  RenameCategoryRequest,
  RenameColumnCategoryRequest,
  RenameFileRequest,
  ResizeColumnRequest,
  RowId,
  SearchFeedItemsRequest,
  SearchFeedItemsResponse,
  SearchLibraryRequest,
  SearchLibraryResponse,
  SearchRequest,
  SearchResponse,
  SyncUserEventRequest,
  SyncUserFillTableRequest,
  TableId,
  TableMetadata,
  TableResponse,
  UpdateBlockMetadataRequest,
  UpdateBlockOrderRequest,
  UpdateBlockValueRequest,
  UpdateCellRequest,
  UpdateColumnMetadataRequest,
  UpdateUploadMetadataRequest,
  UploadId,
  WebSearchRequest,
  WebSearchResponse,
  WorkspaceUpdateEvent
} from './schemas'




/**
 * @summary Search Library Handler
 */
export const searchLibraryHandler = (
    searchLibraryRequest: SearchLibraryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchLibraryResponse>> => {
    
    return axios.default.post(
      `/search/search_uploads`,
      searchLibraryRequest,options
    );
  }



export const getSearchLibraryHandlerMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchLibraryHandler>>, TError,{data: SearchLibraryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchLibraryHandler>>, TError,{data: SearchLibraryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchLibraryHandler>>, {data: SearchLibraryRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchLibraryHandler(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchLibraryHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof searchLibraryHandler>>>
    export type SearchLibraryHandlerMutationBody = SearchLibraryRequest
    export type SearchLibraryHandlerMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Library Handler
 */
export const useSearchLibraryHandler = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchLibraryHandler>>, TError,{data: SearchLibraryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchLibraryHandler>>,
        TError,
        {data: SearchLibraryRequest},
        TContext
      > => {

      const mutationOptions = getSearchLibraryHandlerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Search Feed Items Handler
 */
export const searchFeedItemsHandler = (
    searchFeedItemsRequest: SearchFeedItemsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchFeedItemsResponse>> => {
    
    return axios.default.post(
      `/search/search_feed_items`,
      searchFeedItemsRequest,options
    );
  }



export const getSearchFeedItemsHandlerMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchFeedItemsHandler>>, TError,{data: SearchFeedItemsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchFeedItemsHandler>>, TError,{data: SearchFeedItemsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchFeedItemsHandler>>, {data: SearchFeedItemsRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchFeedItemsHandler(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchFeedItemsHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof searchFeedItemsHandler>>>
    export type SearchFeedItemsHandlerMutationBody = SearchFeedItemsRequest
    export type SearchFeedItemsHandlerMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Feed Items Handler
 */
export const useSearchFeedItemsHandler = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchFeedItemsHandler>>, TError,{data: SearchFeedItemsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchFeedItemsHandler>>,
        TError,
        {data: SearchFeedItemsRequest},
        TContext
      > => {

      const mutationOptions = getSearchFeedItemsHandlerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Search Handler
 */
export const searchHandler = (
    searchRequest: SearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchResponse>> => {
    
    return axios.default.post(
      `/search`,
      searchRequest,options
    );
  }



export const getSearchHandlerMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchHandler>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchHandler>>, TError,{data: SearchRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchHandler>>, {data: SearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchHandler(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof searchHandler>>>
    export type SearchHandlerMutationBody = SearchRequest
    export type SearchHandlerMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Handler
 */
export const useSearchHandler = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchHandler>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchHandler>>,
        TError,
        {data: SearchRequest},
        TContext
      > => {

      const mutationOptions = getSearchHandlerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Download Pdf
 */
export const downloadPdf = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/pdf/${uploadId}`,options
    );
  }


export const getDownloadPdfQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/pdf/${uploadId}`] as const;
    }

    
export const getDownloadPdfQueryOptions = <TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadPdfQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadPdf>>> = ({ signal }) => downloadPdf(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadPdfQueryResult = NonNullable<Awaited<ReturnType<typeof downloadPdf>>>
export type DownloadPdfQueryError = AxiosError<HTTPValidationError>


export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Download Pdf
 */

export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getDownloadPdfQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Download Original
 */
export const downloadOriginal = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/original/${uploadId}`,options
    );
  }


export const getDownloadOriginalQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/original/${uploadId}`] as const;
    }

    
export const getDownloadOriginalQueryOptions = <TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadOriginalQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadOriginal>>> = ({ signal }) => downloadOriginal(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadOriginalQueryResult = NonNullable<Awaited<ReturnType<typeof downloadOriginal>>>
export type DownloadOriginalQueryError = AxiosError<HTTPValidationError>


export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Download Original
 */

export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getDownloadOriginalQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Page Image Route
 */
export const getPageImageRoute = (
    uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/page_image/${uploadId}/${pageIndex}/${resolution}.jpg`,options
    );
  }


export const getGetPageImageRouteQueryKey = (uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution,) => {
    return [`/uploads/page_image/${uploadId}/${pageIndex}/${resolution}.jpg`] as const;
    }

    
export const getGetPageImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPageImageRouteQueryKey(uploadId,pageIndex,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPageImageRoute>>> = ({ signal }) => getPageImageRoute(uploadId,pageIndex,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && pageIndex && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPageImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getPageImageRoute>>>
export type GetPageImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetPageImageRoute<TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPageImageRoute<TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPageImageRoute<TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Page Image Route
 */

export function useGetPageImageRoute<TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPageImageRouteQueryOptions(uploadId,pageIndex,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Upload Metadata Route
 */
export const updateUploadMetadataRoute = (
    updateUploadMetadataRequest: UpdateUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/uploads/update_metadata`,
      updateUploadMetadataRequest,options
    );
  }



export const getUpdateUploadMetadataRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError,{data: UpdateUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError,{data: UpdateUploadMetadataRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, {data: UpdateUploadMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateUploadMetadataRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUploadMetadataRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateUploadMetadataRoute>>>
    export type UpdateUploadMetadataRouteMutationBody = UpdateUploadMetadataRequest
    export type UpdateUploadMetadataRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Upload Metadata Route
 */
export const useUpdateUploadMetadataRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError,{data: UpdateUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateUploadMetadataRoute>>,
        TError,
        {data: UpdateUploadMetadataRequest},
        TContext
      > => {

      const mutationOptions = getUpdateUploadMetadataRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Cover Image Route
 */
export const getCoverImageRoute = (
    uploadId: UploadId,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/cover_image/${uploadId}/${resolution}.jpg`,options
    );
  }


export const getGetCoverImageRouteQueryKey = (uploadId: UploadId,
    resolution: PageResolution,) => {
    return [`/uploads/cover_image/${uploadId}/${resolution}.jpg`] as const;
    }

    
export const getGetCoverImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCoverImageRouteQueryKey(uploadId,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCoverImageRoute>>> = ({ signal }) => getCoverImageRoute(uploadId,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCoverImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getCoverImageRoute>>>
export type GetCoverImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCoverImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCoverImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Cover Image Route
 */

export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCoverImageRouteQueryOptions(uploadId,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Upload Route
 */
export const createUploadRoute = (
    bodyCreateUploadRoute: BodyCreateUploadRoute, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UploadId>> => {const formData = new FormData();
formData.append('file', bodyCreateUploadRoute.file)
formData.append('upload_id', bodyCreateUploadRoute.upload_id)
if(bodyCreateUploadRoute.file_parent_id !== undefined && bodyCreateUploadRoute.file_parent_id !== null) {
 formData.append('file_parent_id', bodyCreateUploadRoute.file_parent_id)
 }
formData.append('infer_metadata', bodyCreateUploadRoute.infer_metadata.toString())

    
    return axios.default.post(
      `/uploads/upload_to_root`,
      formData,options
    );
  }



export const getCreateUploadRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadRoute>>, TError,{data: BodyCreateUploadRoute}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createUploadRoute>>, TError,{data: BodyCreateUploadRoute}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUploadRoute>>, {data: BodyCreateUploadRoute}> = (props) => {
          const {data} = props ?? {};

          return  createUploadRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUploadRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createUploadRoute>>>
    export type CreateUploadRouteMutationBody = BodyCreateUploadRoute
    export type CreateUploadRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Upload Route
 */
export const useCreateUploadRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadRoute>>, TError,{data: BodyCreateUploadRoute}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createUploadRoute>>,
        TError,
        {data: BodyCreateUploadRoute},
        TContext
      > => {

      const mutationOptions = getCreateUploadRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Crossref
 */
export const queryCrossref = (
    queryCrossrefRequest: QueryCrossrefRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CrossrefResponse>> => {
    
    return axios.default.post(
      `/uploads/query_crossref`,
      queryCrossrefRequest,options
    );
  }



export const getQueryCrossrefMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryCrossref>>, {data: QueryCrossrefRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryCrossref(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QueryCrossrefMutationResult = NonNullable<Awaited<ReturnType<typeof queryCrossref>>>
    export type QueryCrossrefMutationBody = QueryCrossrefRequest
    export type QueryCrossrefMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Crossref
 */
export const useQueryCrossref = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof queryCrossref>>,
        TError,
        {data: QueryCrossrefRequest},
        TContext
      > => {

      const mutationOptions = getQueryCrossrefMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Google Books
 */
export const queryGoogleBooks = (
    queryGoogleBooksRequest: QueryGoogleBooksRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GoogleBooksResponse>> => {
    
    return axios.default.post(
      `/uploads/query_google_books`,
      queryGoogleBooksRequest,options
    );
  }



export const getQueryGoogleBooksMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryGoogleBooks>>, {data: QueryGoogleBooksRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryGoogleBooks(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QueryGoogleBooksMutationResult = NonNullable<Awaited<ReturnType<typeof queryGoogleBooks>>>
    export type QueryGoogleBooksMutationBody = QueryGoogleBooksRequest
    export type QueryGoogleBooksMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Google Books
 */
export const useQueryGoogleBooks = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof queryGoogleBooks>>,
        TError,
        {data: QueryGoogleBooksRequest},
        TContext
      > => {

      const mutationOptions = getQueryGoogleBooksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Infer Upload Metadata
 */
export const inferUploadMetadata = (
    inferUploadMetadataRequest: InferUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InferUploadMetadataResponse>> => {
    
    return axios.default.post(
      `/uploads/infer_metadata`,
      inferUploadMetadataRequest,options
    );
  }



export const getInferUploadMetadataMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inferUploadMetadata>>, {data: InferUploadMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  inferUploadMetadata(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type InferUploadMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof inferUploadMetadata>>>
    export type InferUploadMetadataMutationBody = InferUploadMetadataRequest
    export type InferUploadMetadataMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Infer Upload Metadata
 */
export const useInferUploadMetadata = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof inferUploadMetadata>>,
        TError,
        {data: InferUploadMetadataRequest},
        TContext
      > => {

      const mutationOptions = getInferUploadMetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Upload Sections
 */
export const getUploadSections = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PDFOutline>> => {
    
    return axios.default.get(
      `/uploads/sections/${uploadId}`,options
    );
  }


export const getGetUploadSectionsQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/sections/${uploadId}`] as const;
    }

    
export const getGetUploadSectionsQueryOptions = <TData = Awaited<ReturnType<typeof getUploadSections>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadSections>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadSectionsQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadSections>>> = ({ signal }) => getUploadSections(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUploadSections>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUploadSectionsQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadSections>>>
export type GetUploadSectionsQueryError = AxiosError<HTTPValidationError>


export function useGetUploadSections<TData = Awaited<ReturnType<typeof getUploadSections>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadSections>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadSections>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUploadSections<TData = Awaited<ReturnType<typeof getUploadSections>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadSections>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadSections>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUploadSections<TData = Awaited<ReturnType<typeof getUploadSections>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadSections>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Upload Sections
 */

export function useGetUploadSections<TData = Awaited<ReturnType<typeof getUploadSections>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadSections>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUploadSectionsQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Bootstrap Session
 */
export const bootstrapSession = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BootstrapSessionResponse>> => {
    
    return axios.default.get(
      `/user/bootstrap_session`,options
    );
  }


export const getBootstrapSessionQueryKey = () => {
    return [`/user/bootstrap_session`] as const;
    }

    
export const getBootstrapSessionQueryOptions = <TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBootstrapSessionQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof bootstrapSession>>> = ({ signal }) => bootstrapSession({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData> & { queryKey: QueryKey }
}

export type BootstrapSessionQueryResult = NonNullable<Awaited<ReturnType<typeof bootstrapSession>>>
export type BootstrapSessionQueryError = AxiosError<unknown>


export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Bootstrap Session
 */

export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getBootstrapSessionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Guess the RSS feed URL from a given URL by fetching the HTML
and looking for the RSS feed link in the header
 * @summary Guess Rss Url
 */
export const guessRssUrl = (
    params: GuessRssUrlParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.post(
      `/feeds/guess_rss_url`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGuessRssUrlMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof guessRssUrl>>, {params: GuessRssUrlParams}> = (props) => {
          const {params} = props ?? {};

          return  guessRssUrl(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GuessRssUrlMutationResult = NonNullable<Awaited<ReturnType<typeof guessRssUrl>>>
    
    export type GuessRssUrlMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Guess Rss Url
 */
export const useGuessRssUrl = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof guessRssUrl>>,
        TError,
        {params: GuessRssUrlParams},
        TContext
      > => {

      const mutationOptions = getGuessRssUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Preview an RSS feed by fetching the feed and returning the parsed feed
 * @summary Preview Rss Feed
 */
export const previewRssFeed = (
    params: PreviewRssFeedParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PreviewRssFeedResponse>> => {
    
    return axios.default.post(
      `/feeds/preview_rss_feed`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPreviewRssFeedMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof previewRssFeed>>, {params: PreviewRssFeedParams}> = (props) => {
          const {params} = props ?? {};

          return  previewRssFeed(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PreviewRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof previewRssFeed>>>
    
    export type PreviewRssFeedMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Preview Rss Feed
 */
export const usePreviewRssFeed = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof previewRssFeed>>,
        TError,
        {params: PreviewRssFeedParams},
        TContext
      > => {

      const mutationOptions = getPreviewRssFeedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Index a new RSS feed into Vespa and Postgres
 * @summary Index Rss Feed
 */
export const indexRssFeed = (
    indexFeedRequest: IndexFeedRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/feeds/index_rss_feed`,
      indexFeedRequest,options
    );
  }



export const getIndexRssFeedMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexRssFeed>>, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof indexRssFeed>>, TError,{data: IndexFeedRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof indexRssFeed>>, {data: IndexFeedRequest}> = (props) => {
          const {data} = props ?? {};

          return  indexRssFeed(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IndexRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof indexRssFeed>>>
    export type IndexRssFeedMutationBody = IndexFeedRequest
    export type IndexRssFeedMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Index Rss Feed
 */
export const useIndexRssFeed = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexRssFeed>>, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof indexRssFeed>>,
        TError,
        {data: IndexFeedRequest},
        TContext
      > => {

      const mutationOptions = getIndexRssFeedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Refetch a feed channel by fetching the feed again and updating the feed items
 * @summary Refetch Feed Channel
 */
export const refetchFeedChannel = (
    params: RefetchFeedChannelParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/feeds/refetch_feed_channel`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRefetchFeedChannelMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refetchFeedChannel>>, {params: RefetchFeedChannelParams}> = (props) => {
          const {params} = props ?? {};

          return  refetchFeedChannel(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RefetchFeedChannelMutationResult = NonNullable<Awaited<ReturnType<typeof refetchFeedChannel>>>
    
    export type RefetchFeedChannelMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Refetch Feed Channel
 */
export const useRefetchFeedChannel = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof refetchFeedChannel>>,
        TError,
        {params: RefetchFeedChannelParams},
        TContext
      > => {

      const mutationOptions = getRefetchFeedChannelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Retrieve the full feed item by ID
 * @summary Get Feed Item
 */
export const getFeedItem = (
    params: GetFeedItemParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FeedItem>> => {
    
    return axios.default.get(
      `/feeds/feed_item`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetFeedItemQueryKey = (params: GetFeedItemParams,) => {
    return [`/feeds/feed_item`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFeedItemQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItem>>> = ({ signal }) => getFeedItem(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedItemQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItem>>>
export type GetFeedItemQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItem>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItem>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Feed Item
 */

export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetFeedItemQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Feed Item Sections
 */
export const getFeedItemSections = (
    feedItemId: FeedItemId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FeedItemOutline>> => {
    
    return axios.default.get(
      `/feeds/feed_item/sections/${feedItemId}`,options
    );
  }


export const getGetFeedItemSectionsQueryKey = (feedItemId: FeedItemId,) => {
    return [`/feeds/feed_item/sections/${feedItemId}`] as const;
    }

    
export const getGetFeedItemSectionsQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItemSections>>, TError = AxiosError<HTTPValidationError>>(feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemSections>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemSectionsQueryKey(feedItemId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItemSections>>> = ({ signal }) => getFeedItemSections(feedItemId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(feedItemId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItemSections>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedItemSectionsQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItemSections>>>
export type GetFeedItemSectionsQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItemSections<TData = Awaited<ReturnType<typeof getFeedItemSections>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemSections>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemSections>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetFeedItemSections<TData = Awaited<ReturnType<typeof getFeedItemSections>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemSections>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemSections>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetFeedItemSections<TData = Awaited<ReturnType<typeof getFeedItemSections>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemSections>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Feed Item Sections
 */

export function useGetFeedItemSections<TData = Awaited<ReturnType<typeof getFeedItemSections>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemSections>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetFeedItemSectionsQueryOptions(feedItemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new table with a name and a primary column.
 * @summary Create Table Route
 */
export const createTableRoute = (
    createTableRequest: CreateTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableMetadata>> => {
    
    return axios.default.post(
      `/create`,
      createTableRequest,options
    );
  }



export const getCreateTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTableRoute>>, {data: CreateTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  createTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createTableRoute>>>
    export type CreateTableRouteMutationBody = CreateTableRequest
    export type CreateTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Table Route
 */
export const useCreateTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createTableRoute>>,
        TError,
        {data: CreateTableRequest},
        TContext
      > => {

      const mutationOptions = getCreateTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the latest version of a table.

Also lists table metadata and materialized columns for any parent tables.
 * @summary Get Table Latest Version Route
 */
export const getTableLatestVersionRoute = (
    tableId: TableId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetTableLatestVersionResponse>> => {
    
    return axios.default.get(
      `/get_latest/${tableId}`,options
    );
  }


export const getGetTableLatestVersionRouteQueryKey = (tableId: TableId,) => {
    return [`/get_latest/${tableId}`] as const;
    }

    
export const getGetTableLatestVersionRouteQueryOptions = <TData = Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTableLatestVersionRouteQueryKey(tableId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTableLatestVersionRoute>>> = ({ signal }) => getTableLatestVersionRoute(tableId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tableId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTableLatestVersionRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getTableLatestVersionRoute>>>
export type GetTableLatestVersionRouteQueryError = AxiosError<HTTPValidationError>


export function useGetTableLatestVersionRoute<TData = Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableLatestVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTableLatestVersionRoute<TData = Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableLatestVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTableLatestVersionRoute<TData = Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Table Latest Version Route
 */

export function useGetTableLatestVersionRoute<TData = Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTableLatestVersionRouteQueryOptions(tableId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a past version of a table.
 * @summary Get Table Past Version Route
 */
export const getTablePastVersionRoute = (
    params: GetTablePastVersionRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MaterializedTable>> => {
    
    return axios.default.get(
      `/get_past_version`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetTablePastVersionRouteQueryKey = (params: GetTablePastVersionRouteParams,) => {
    return [`/get_past_version`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTablePastVersionRouteQueryOptions = <TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(params: GetTablePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTablePastVersionRouteQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTablePastVersionRoute>>> = ({ signal }) => getTablePastVersionRoute(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTablePastVersionRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getTablePastVersionRoute>>>
export type GetTablePastVersionRouteQueryError = AxiosError<HTTPValidationError>


export function useGetTablePastVersionRoute<TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTablePastVersionRouteParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTablePastVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTablePastVersionRoute<TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTablePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTablePastVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTablePastVersionRoute<TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTablePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Table Past Version Route
 */

export function useGetTablePastVersionRoute<TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTablePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTablePastVersionRouteQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Inserts a new row at the end of the table.
 * @summary Create Row Route
 */
export const createRowRoute = (
    createRowRequest: CreateRowRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/create_row`,
      createRowRequest,options
    );
  }



export const getCreateRowRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRowRoute>>, TError,{data: CreateRowRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createRowRoute>>, TError,{data: CreateRowRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRowRoute>>, {data: CreateRowRequest}> = (props) => {
          const {data} = props ?? {};

          return  createRowRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateRowRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createRowRoute>>>
    export type CreateRowRouteMutationBody = CreateRowRequest
    export type CreateRowRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Row Route
 */
export const useCreateRowRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRowRoute>>, TError,{data: CreateRowRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createRowRoute>>,
        TError,
        {data: CreateRowRequest},
        TContext
      > => {

      const mutationOptions = getCreateRowRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create a new column in a table. The column is inserted at the end of the table.
 * @summary Create Column Route
 */
export const createColumnRoute = (
    createColumnRequest: CreateColumnRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/create_column`,
      createColumnRequest,options
    );
  }



export const getCreateColumnRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createColumnRoute>>, TError,{data: CreateColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createColumnRoute>>, TError,{data: CreateColumnRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createColumnRoute>>, {data: CreateColumnRequest}> = (props) => {
          const {data} = props ?? {};

          return  createColumnRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateColumnRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createColumnRoute>>>
    export type CreateColumnRouteMutationBody = CreateColumnRequest
    export type CreateColumnRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Column Route
 */
export const useCreateColumnRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createColumnRoute>>, TError,{data: CreateColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createColumnRoute>>,
        TError,
        {data: CreateColumnRequest},
        TContext
      > => {

      const mutationOptions = getCreateColumnRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Rows Route
 */
export const deleteRowsRoute = (
    deleteRowsRequest: DeleteRowsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/delete_rows`,
      deleteRowsRequest,options
    );
  }



export const getDeleteRowsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRowsRoute>>, TError,{data: DeleteRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRowsRoute>>, TError,{data: DeleteRowsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRowsRoute>>, {data: DeleteRowsRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteRowsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRowsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRowsRoute>>>
    export type DeleteRowsRouteMutationBody = DeleteRowsRequest
    export type DeleteRowsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Rows Route
 */
export const useDeleteRowsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRowsRoute>>, TError,{data: DeleteRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRowsRoute>>,
        TError,
        {data: DeleteRowsRequest},
        TContext
      > => {

      const mutationOptions = getDeleteRowsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a single column from a table.
 * @summary Delete Column Route
 */
export const deleteColumnRoute = (
    deleteColumnRequest: DeleteColumnRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/delete_column`,
      deleteColumnRequest,options
    );
  }



export const getDeleteColumnRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteColumnRoute>>, TError,{data: DeleteColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteColumnRoute>>, TError,{data: DeleteColumnRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteColumnRoute>>, {data: DeleteColumnRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteColumnRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteColumnRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteColumnRoute>>>
    export type DeleteColumnRouteMutationBody = DeleteColumnRequest
    export type DeleteColumnRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Column Route
 */
export const useDeleteColumnRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteColumnRoute>>, TError,{data: DeleteColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteColumnRoute>>,
        TError,
        {data: DeleteColumnRequest},
        TContext
      > => {

      const mutationOptions = getDeleteColumnRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Cell Route
 */
export const updateCellRoute = (
    updateCellRequest: UpdateCellRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/update_cell`,
      updateCellRequest,options
    );
  }



export const getUpdateCellRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCellRoute>>, TError,{data: UpdateCellRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateCellRoute>>, TError,{data: UpdateCellRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCellRoute>>, {data: UpdateCellRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateCellRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCellRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateCellRoute>>>
    export type UpdateCellRouteMutationBody = UpdateCellRequest
    export type UpdateCellRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Cell Route
 */
export const useUpdateCellRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCellRoute>>, TError,{data: UpdateCellRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCellRoute>>,
        TError,
        {data: UpdateCellRequest},
        TContext
      > => {

      const mutationOptions = getUpdateCellRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Column Metadata Route
 */
export const updateColumnMetadataRoute = (
    updateColumnMetadataRequest: UpdateColumnMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/update_column_metadata`,
      updateColumnMetadataRequest,options
    );
  }



export const getUpdateColumnMetadataRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateColumnMetadataRoute>>, TError,{data: UpdateColumnMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateColumnMetadataRoute>>, TError,{data: UpdateColumnMetadataRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateColumnMetadataRoute>>, {data: UpdateColumnMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateColumnMetadataRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateColumnMetadataRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateColumnMetadataRoute>>>
    export type UpdateColumnMetadataRouteMutationBody = UpdateColumnMetadataRequest
    export type UpdateColumnMetadataRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Column Metadata Route
 */
export const useUpdateColumnMetadataRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateColumnMetadataRoute>>, TError,{data: UpdateColumnMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateColumnMetadataRoute>>,
        TError,
        {data: UpdateColumnMetadataRequest},
        TContext
      > => {

      const mutationOptions = getUpdateColumnMetadataRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Adds a new category to a categorical column.
 * @summary Add Column Category Route
 */
export const addColumnCategoryRoute = (
    addColumnCategoryRequest: AddColumnCategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/add_column_category`,
      addColumnCategoryRequest,options
    );
  }



export const getAddColumnCategoryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addColumnCategoryRoute>>, TError,{data: AddColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addColumnCategoryRoute>>, TError,{data: AddColumnCategoryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addColumnCategoryRoute>>, {data: AddColumnCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  addColumnCategoryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddColumnCategoryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addColumnCategoryRoute>>>
    export type AddColumnCategoryRouteMutationBody = AddColumnCategoryRequest
    export type AddColumnCategoryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Column Category Route
 */
export const useAddColumnCategoryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addColumnCategoryRoute>>, TError,{data: AddColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addColumnCategoryRoute>>,
        TError,
        {data: AddColumnCategoryRequest},
        TContext
      > => {

      const mutationOptions = getAddColumnCategoryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Renames a category in a categorical column. Updates all cells with the old category to the new category.
 * @summary Rename Column Category Route
 */
export const renameColumnCategoryRoute = (
    renameColumnCategoryRequest: RenameColumnCategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/rename_column_category`,
      renameColumnCategoryRequest,options
    );
  }



export const getRenameColumnCategoryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameColumnCategoryRoute>>, TError,{data: RenameColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameColumnCategoryRoute>>, TError,{data: RenameColumnCategoryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameColumnCategoryRoute>>, {data: RenameColumnCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  renameColumnCategoryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameColumnCategoryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof renameColumnCategoryRoute>>>
    export type RenameColumnCategoryRouteMutationBody = RenameColumnCategoryRequest
    export type RenameColumnCategoryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Column Category Route
 */
export const useRenameColumnCategoryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameColumnCategoryRoute>>, TError,{data: RenameColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof renameColumnCategoryRoute>>,
        TError,
        {data: RenameColumnCategoryRequest},
        TContext
      > => {

      const mutationOptions = getRenameColumnCategoryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Change the color of a category in a categorical column.
 * @summary Change Category Color Route
 */
export const changeCategoryColorRoute = (
    changeCategoryColorRequest: ChangeCategoryColorRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/change_category_color`,
      changeCategoryColorRequest,options
    );
  }



export const getChangeCategoryColorRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeCategoryColorRoute>>, TError,{data: ChangeCategoryColorRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof changeCategoryColorRoute>>, TError,{data: ChangeCategoryColorRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeCategoryColorRoute>>, {data: ChangeCategoryColorRequest}> = (props) => {
          const {data} = props ?? {};

          return  changeCategoryColorRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangeCategoryColorRouteMutationResult = NonNullable<Awaited<ReturnType<typeof changeCategoryColorRoute>>>
    export type ChangeCategoryColorRouteMutationBody = ChangeCategoryColorRequest
    export type ChangeCategoryColorRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Change Category Color Route
 */
export const useChangeCategoryColorRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeCategoryColorRoute>>, TError,{data: ChangeCategoryColorRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof changeCategoryColorRoute>>,
        TError,
        {data: ChangeCategoryColorRequest},
        TContext
      > => {

      const mutationOptions = getChangeCategoryColorRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Remove a category from a categorical column. Updates all cells with the category to remove to None.
 * @summary Remove Column Category Route
 */
export const removeColumnCategoryRoute = (
    removeColumnCategoryRequest: RemoveColumnCategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/remove_column_category`,
      removeColumnCategoryRequest,options
    );
  }



export const getRemoveColumnCategoryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeColumnCategoryRoute>>, TError,{data: RemoveColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof removeColumnCategoryRoute>>, TError,{data: RemoveColumnCategoryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeColumnCategoryRoute>>, {data: RemoveColumnCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  removeColumnCategoryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveColumnCategoryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof removeColumnCategoryRoute>>>
    export type RemoveColumnCategoryRouteMutationBody = RemoveColumnCategoryRequest
    export type RemoveColumnCategoryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Remove Column Category Route
 */
export const useRemoveColumnCategoryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeColumnCategoryRoute>>, TError,{data: RemoveColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof removeColumnCategoryRoute>>,
        TError,
        {data: RemoveColumnCategoryRequest},
        TContext
      > => {

      const mutationOptions = getRemoveColumnCategoryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Move rows in a table.
 * @summary Move Rows Route
 */
export const moveRowsRoute = (
    moveRowsRequest: MoveRowsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/move_rows`,
      moveRowsRequest,options
    );
  }



export const getMoveRowsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveRowsRoute>>, TError,{data: MoveRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof moveRowsRoute>>, TError,{data: MoveRowsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveRowsRoute>>, {data: MoveRowsRequest}> = (props) => {
          const {data} = props ?? {};

          return  moveRowsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MoveRowsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof moveRowsRoute>>>
    export type MoveRowsRouteMutationBody = MoveRowsRequest
    export type MoveRowsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Move Rows Route
 */
export const useMoveRowsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveRowsRoute>>, TError,{data: MoveRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof moveRowsRoute>>,
        TError,
        {data: MoveRowsRequest},
        TContext
      > => {

      const mutationOptions = getMoveRowsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Move a column in a table to a new position.
 * @summary Move Column Route
 */
export const moveColumnRoute = (
    moveColumnRequest: MoveColumnRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/move_column`,
      moveColumnRequest,options
    );
  }



export const getMoveColumnRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveColumnRoute>>, TError,{data: MoveColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof moveColumnRoute>>, TError,{data: MoveColumnRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveColumnRoute>>, {data: MoveColumnRequest}> = (props) => {
          const {data} = props ?? {};

          return  moveColumnRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MoveColumnRouteMutationResult = NonNullable<Awaited<ReturnType<typeof moveColumnRoute>>>
    export type MoveColumnRouteMutationBody = MoveColumnRequest
    export type MoveColumnRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Move Column Route
 */
export const useMoveColumnRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveColumnRoute>>, TError,{data: MoveColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof moveColumnRoute>>,
        TError,
        {data: MoveColumnRequest},
        TContext
      > => {

      const mutationOptions = getMoveColumnRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update the width of a column in a table.
 * @summary Resize Column Route
 */
export const resizeColumnRoute = (
    resizeColumnRequest: ResizeColumnRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/resize_column`,
      resizeColumnRequest,options
    );
  }



export const getResizeColumnRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resizeColumnRoute>>, TError,{data: ResizeColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof resizeColumnRoute>>, TError,{data: ResizeColumnRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resizeColumnRoute>>, {data: ResizeColumnRequest}> = (props) => {
          const {data} = props ?? {};

          return  resizeColumnRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ResizeColumnRouteMutationResult = NonNullable<Awaited<ReturnType<typeof resizeColumnRoute>>>
    export type ResizeColumnRouteMutationBody = ResizeColumnRequest
    export type ResizeColumnRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Resize Column Route
 */
export const useResizeColumnRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resizeColumnRoute>>, TError,{data: ResizeColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof resizeColumnRoute>>,
        TError,
        {data: ResizeColumnRequest},
        TContext
      > => {

      const mutationOptions = getResizeColumnRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * List the columns in a table.
 * @summary List Table Columns Route
 */
export const listTableColumnsRoute = (
    tableId: TableId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MaterializedColumn[]>> => {
    
    return axios.default.get(
      `/tables/${tableId}/columns`,options
    );
  }


export const getListTableColumnsRouteQueryKey = (tableId: TableId,) => {
    return [`/tables/${tableId}/columns`] as const;
    }

    
export const getListTableColumnsRouteQueryOptions = <TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTableColumnsRouteQueryKey(tableId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTableColumnsRoute>>> = ({ signal }) => listTableColumnsRoute(tableId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tableId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTableColumnsRouteQueryResult = NonNullable<Awaited<ReturnType<typeof listTableColumnsRoute>>>
export type ListTableColumnsRouteQueryError = AxiosError<HTTPValidationError>


export function useListTableColumnsRoute<TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listTableColumnsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListTableColumnsRoute<TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listTableColumnsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListTableColumnsRoute<TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List Table Columns Route
 */

export function useListTableColumnsRoute<TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getListTableColumnsRouteQueryOptions(tableId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * List specified tables and their columns.
 * @summary List Tables And Columns Route
 */
export const listTablesAndColumnsRoute = (
    params: ListTablesAndColumnsRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MaterializedColumn[]>> => {
    
    return axios.default.post(
      `/tables/columns`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getListTablesAndColumnsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>, TError,{params: ListTablesAndColumnsRouteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>, TError,{params: ListTablesAndColumnsRouteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>, {params: ListTablesAndColumnsRouteParams}> = (props) => {
          const {params} = props ?? {};

          return  listTablesAndColumnsRoute(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ListTablesAndColumnsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>>
    
    export type ListTablesAndColumnsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary List Tables And Columns Route
 */
export const useListTablesAndColumnsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>, TError,{params: ListTablesAndColumnsRouteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof listTablesAndColumnsRoute>>,
        TError,
        {params: ListTablesAndColumnsRouteParams},
        TContext
      > => {

      const mutationOptions = getListTablesAndColumnsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the rows that match the filters.
 * @summary Get Filtered Rows Route
 */
export const getFilteredRowsRoute = (
    getFilteredRowsRequest: GetFilteredRowsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RowId[]>> => {
    
    return axios.default.post(
      `/tables/get_filtered_rows`,
      getFilteredRowsRequest,options
    );
  }



export const getGetFilteredRowsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getFilteredRowsRoute>>, TError,{data: GetFilteredRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof getFilteredRowsRoute>>, TError,{data: GetFilteredRowsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getFilteredRowsRoute>>, {data: GetFilteredRowsRequest}> = (props) => {
          const {data} = props ?? {};

          return  getFilteredRowsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetFilteredRowsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof getFilteredRowsRoute>>>
    export type GetFilteredRowsRouteMutationBody = GetFilteredRowsRequest
    export type GetFilteredRowsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Get Filtered Rows Route
 */
export const useGetFilteredRowsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getFilteredRowsRoute>>, TError,{data: GetFilteredRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof getFilteredRowsRoute>>,
        TError,
        {data: GetFilteredRowsRequest},
        TContext
      > => {

      const mutationOptions = getGetFilteredRowsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Preview the computed table.
 * @summary Preview Computed Table Route
 */
export const previewComputedTableRoute = (
    previewComputedTableRequest: PreviewComputedTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PreviewComputedTableResponse>> => {
    
    return axios.default.post(
      `/tables/preview_computed_table`,
      previewComputedTableRequest,options
    );
  }



export const getPreviewComputedTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewComputedTableRoute>>, TError,{data: PreviewComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof previewComputedTableRoute>>, TError,{data: PreviewComputedTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof previewComputedTableRoute>>, {data: PreviewComputedTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  previewComputedTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PreviewComputedTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof previewComputedTableRoute>>>
    export type PreviewComputedTableRouteMutationBody = PreviewComputedTableRequest
    export type PreviewComputedTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Preview Computed Table Route
 */
export const usePreviewComputedTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewComputedTableRoute>>, TError,{data: PreviewComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof previewComputedTableRoute>>,
        TError,
        {data: PreviewComputedTableRequest},
        TContext
      > => {

      const mutationOptions = getPreviewComputedTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create a computed table from a parent table.
 * @summary Create Computed Table Route
 */
export const createComputedTableRoute = (
    createComputedTableRequest: CreateComputedTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableMetadata>> => {
    
    return axios.default.post(
      `/tables/create_computed_table`,
      createComputedTableRequest,options
    );
  }



export const getCreateComputedTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createComputedTableRoute>>, TError,{data: CreateComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createComputedTableRoute>>, TError,{data: CreateComputedTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createComputedTableRoute>>, {data: CreateComputedTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  createComputedTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateComputedTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createComputedTableRoute>>>
    export type CreateComputedTableRouteMutationBody = CreateComputedTableRequest
    export type CreateComputedTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Computed Table Route
 */
export const useCreateComputedTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createComputedTableRoute>>, TError,{data: CreateComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createComputedTableRoute>>,
        TError,
        {data: CreateComputedTableRequest},
        TContext
      > => {

      const mutationOptions = getCreateComputedTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * List all descendant columns of the specified column.
 * @summary List Descendant Columns Route
 */
export const listDescendantColumnsRoute = (
    params: ListDescendantColumnsRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DescendantColumn[]>> => {
    
    return axios.default.get(
      `/columns/descendants`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getListDescendantColumnsRouteQueryKey = (params: ListDescendantColumnsRouteParams,) => {
    return [`/columns/descendants`, ...(params ? [params]: [])] as const;
    }

    
export const getListDescendantColumnsRouteQueryOptions = <TData = Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(params: ListDescendantColumnsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDescendantColumnsRouteQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDescendantColumnsRoute>>> = ({ signal }) => listDescendantColumnsRoute(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDescendantColumnsRouteQueryResult = NonNullable<Awaited<ReturnType<typeof listDescendantColumnsRoute>>>
export type ListDescendantColumnsRouteQueryError = AxiosError<HTTPValidationError>


export function useListDescendantColumnsRoute<TData = Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: ListDescendantColumnsRouteParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listDescendantColumnsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListDescendantColumnsRoute<TData = Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: ListDescendantColumnsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listDescendantColumnsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListDescendantColumnsRoute<TData = Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: ListDescendantColumnsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List Descendant Columns Route
 */

export function useListDescendantColumnsRoute<TData = Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: ListDescendantColumnsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDescendantColumnsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getListDescendantColumnsRouteQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Syncs a client execution of a message action.
 * @summary Sync User Event Route
 */
export const syncUserEventRoute = (
    syncUserEventRequest: SyncUserEventRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<null>> => {
    
    return axios.default.post(
      `/sessions/sync_user_event`,
      syncUserEventRequest,options
    );
  }



export const getSyncUserEventRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncUserEventRoute>>, TError,{data: SyncUserEventRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof syncUserEventRoute>>, TError,{data: SyncUserEventRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncUserEventRoute>>, {data: SyncUserEventRequest}> = (props) => {
          const {data} = props ?? {};

          return  syncUserEventRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SyncUserEventRouteMutationResult = NonNullable<Awaited<ReturnType<typeof syncUserEventRoute>>>
    export type SyncUserEventRouteMutationBody = SyncUserEventRequest
    export type SyncUserEventRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync User Event Route
 */
export const useSyncUserEventRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncUserEventRoute>>, TError,{data: SyncUserEventRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof syncUserEventRoute>>,
        TError,
        {data: SyncUserEventRequest},
        TContext
      > => {

      const mutationOptions = getSyncUserEventRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This route has a special case for message events that indicate a table fill.
Perhaps we could detect it somehow though.
 * @summary Sync Fill Table Action Route
 */
export const syncFillTableActionRoute = (
    syncUserFillTableRequest: SyncUserFillTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<null>> => {
    
    return axios.default.post(
      `/sessions/sync_fill_table_request`,
      syncUserFillTableRequest,options
    );
  }



export const getSyncFillTableActionRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncFillTableActionRoute>>, TError,{data: SyncUserFillTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof syncFillTableActionRoute>>, TError,{data: SyncUserFillTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncFillTableActionRoute>>, {data: SyncUserFillTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  syncFillTableActionRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SyncFillTableActionRouteMutationResult = NonNullable<Awaited<ReturnType<typeof syncFillTableActionRoute>>>
    export type SyncFillTableActionRouteMutationBody = SyncUserFillTableRequest
    export type SyncFillTableActionRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync Fill Table Action Route
 */
export const useSyncFillTableActionRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncFillTableActionRoute>>, TError,{data: SyncUserFillTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof syncFillTableActionRoute>>,
        TError,
        {data: SyncUserFillTableRequest},
        TContext
      > => {

      const mutationOptions = getSyncFillTableActionRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Workspace
 */
export const dummyWorkspace = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkspaceUpdateEvent>> => {
    
    return axios.default.post(
      `/dummy/workspace`,undefined,options
    );
  }



export const getDummyWorkspaceMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyWorkspace>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyWorkspace>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyWorkspace>>, void> = () => {
          

          return  dummyWorkspace(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyWorkspaceMutationResult = NonNullable<Awaited<ReturnType<typeof dummyWorkspace>>>
    
    export type DummyWorkspaceMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Workspace
 */
export const useDummyWorkspace = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyWorkspace>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyWorkspace>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyWorkspaceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Tables
 */
export const dummyTables = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableResponse>> => {
    
    return axios.default.post(
      `/dummy/tables`,undefined,options
    );
  }



export const getDummyTablesMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyTables>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyTables>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyTables>>, void> = () => {
          

          return  dummyTables(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyTablesMutationResult = NonNullable<Awaited<ReturnType<typeof dummyTables>>>
    
    export type DummyTablesMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Tables
 */
export const useDummyTables = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyTables>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyTables>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyTablesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Message With Agent Type
 */
export const dummyMessageWithAgentType = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MessageWithMetadata>> => {
    
    return axios.default.post(
      `/dummy/message-with-agent-type`,undefined,options
    );
  }



export const getDummyMessageWithAgentTypeMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, void> = () => {
          

          return  dummyMessageWithAgentType(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyMessageWithAgentTypeMutationResult = NonNullable<Awaited<ReturnType<typeof dummyMessageWithAgentType>>>
    
    export type DummyMessageWithAgentTypeMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Message With Agent Type
 */
export const useDummyMessageWithAgentType = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyMessageWithAgentType>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyMessageWithAgentTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy File
 */
export const dummyFile = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<File>> => {
    
    return axios.default.post(
      `/dummy/file`,undefined,options
    );
  }



export const getDummyFileMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyFile>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyFile>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyFile>>, void> = () => {
          

          return  dummyFile(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyFileMutationResult = NonNullable<Awaited<ReturnType<typeof dummyFile>>>
    
    export type DummyFileMutationError = AxiosError<unknown>

    /**
 * @summary Dummy File
 */
export const useDummyFile = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyFile>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyFile>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Folder
 */
export const createFolder = (
    createFolderRequest: CreateFolderRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/files/create_folder`,
      createFolderRequest,options
    );
  }



export const getCreateFolderMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{data: CreateFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{data: CreateFolderRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFolder>>, {data: CreateFolderRequest}> = (props) => {
          const {data} = props ?? {};

          return  createFolder(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateFolderMutationResult = NonNullable<Awaited<ReturnType<typeof createFolder>>>
    export type CreateFolderMutationBody = CreateFolderRequest
    export type CreateFolderMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Folder
 */
export const useCreateFolder = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{data: CreateFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createFolder>>,
        TError,
        {data: CreateFolderRequest},
        TContext
      > => {

      const mutationOptions = getCreateFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Files Route
 */
export const deleteFilesRoute = (
    deleteFilesRequest: DeleteFilesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/files/delete_files`,
      deleteFilesRequest,options
    );
  }



export const getDeleteFilesRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFilesRoute>>, TError,{data: DeleteFilesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFilesRoute>>, TError,{data: DeleteFilesRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFilesRoute>>, {data: DeleteFilesRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteFilesRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFilesRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFilesRoute>>>
    export type DeleteFilesRouteMutationBody = DeleteFilesRequest
    export type DeleteFilesRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Files Route
 */
export const useDeleteFilesRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFilesRoute>>, TError,{data: DeleteFilesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFilesRoute>>,
        TError,
        {data: DeleteFilesRequest},
        TContext
      > => {

      const mutationOptions = getDeleteFilesRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rename File Route
 */
export const renameFileRoute = (
    renameFileRequest: RenameFileRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/files/rename_file`,
      renameFileRequest,options
    );
  }



export const getRenameFileRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFileRoute>>, TError,{data: RenameFileRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameFileRoute>>, TError,{data: RenameFileRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameFileRoute>>, {data: RenameFileRequest}> = (props) => {
          const {data} = props ?? {};

          return  renameFileRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameFileRouteMutationResult = NonNullable<Awaited<ReturnType<typeof renameFileRoute>>>
    export type RenameFileRouteMutationBody = RenameFileRequest
    export type RenameFileRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename File Route
 */
export const useRenameFileRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFileRoute>>, TError,{data: RenameFileRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof renameFileRoute>>,
        TError,
        {data: RenameFileRequest},
        TContext
      > => {

      const mutationOptions = getRenameFileRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Move Directory Nodes
 */
export const moveDirectoryNodes = (
    moveDirectoryNodesRequest: MoveDirectoryNodesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/files/move_directory_nodes`,
      moveDirectoryNodesRequest,options
    );
  }



export const getMoveDirectoryNodesMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError,{data: MoveDirectoryNodesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError,{data: MoveDirectoryNodesRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveDirectoryNodes>>, {data: MoveDirectoryNodesRequest}> = (props) => {
          const {data} = props ?? {};

          return  moveDirectoryNodes(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MoveDirectoryNodesMutationResult = NonNullable<Awaited<ReturnType<typeof moveDirectoryNodes>>>
    export type MoveDirectoryNodesMutationBody = MoveDirectoryNodesRequest
    export type MoveDirectoryNodesMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Move Directory Nodes
 */
export const useMoveDirectoryNodes = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError,{data: MoveDirectoryNodesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof moveDirectoryNodes>>,
        TError,
        {data: MoveDirectoryNodesRequest},
        TContext
      > => {

      const mutationOptions = getMoveDirectoryNodesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Web Search Handler
 */
export const webSearchHandler = (
    webSearchRequest: WebSearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WebSearchResponse>> => {
    
    return axios.default.post(
      `/web_search`,
      webSearchRequest,options
    );
  }



export const getWebSearchHandlerMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof webSearchHandler>>, TError,{data: WebSearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof webSearchHandler>>, TError,{data: WebSearchRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof webSearchHandler>>, {data: WebSearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  webSearchHandler(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WebSearchHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof webSearchHandler>>>
    export type WebSearchHandlerMutationBody = WebSearchRequest
    export type WebSearchHandlerMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Web Search Handler
 */
export const useWebSearchHandler = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof webSearchHandler>>, TError,{data: WebSearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof webSearchHandler>>,
        TError,
        {data: WebSearchRequest},
        TContext
      > => {

      const mutationOptions = getWebSearchHandlerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create a new page
 * @summary Create Page Route
 */
export const createPageRoute = (
    createPageRequest: CreatePageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Page>> => {
    
    return axios.default.post(
      `/pages/create`,
      createPageRequest,options
    );
  }



export const getCreatePageRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPageRoute>>, TError,{data: CreatePageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createPageRoute>>, TError,{data: CreatePageRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPageRoute>>, {data: CreatePageRequest}> = (props) => {
          const {data} = props ?? {};

          return  createPageRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreatePageRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createPageRoute>>>
    export type CreatePageRouteMutationBody = CreatePageRequest
    export type CreatePageRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Page Route
 */
export const useCreatePageRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPageRoute>>, TError,{data: CreatePageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createPageRoute>>,
        TError,
        {data: CreatePageRequest},
        TContext
      > => {

      const mutationOptions = getCreatePageRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create a new block in a page. The block is inserted at the end of the page
 * @summary Create Block Route
 */
export const createBlockRoute = (
    createBlockRequest: CreateBlockRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/pages/create_block`,
      createBlockRequest,options
    );
  }



export const getCreateBlockRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBlockRoute>>, TError,{data: CreateBlockRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createBlockRoute>>, TError,{data: CreateBlockRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBlockRoute>>, {data: CreateBlockRequest}> = (props) => {
          const {data} = props ?? {};

          return  createBlockRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateBlockRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createBlockRoute>>>
    export type CreateBlockRouteMutationBody = CreateBlockRequest
    export type CreateBlockRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Block Route
 */
export const useCreateBlockRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBlockRoute>>, TError,{data: CreateBlockRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createBlockRoute>>,
        TError,
        {data: CreateBlockRequest},
        TContext
      > => {

      const mutationOptions = getCreateBlockRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Block Order Route
 */
export const updateBlockOrderRoute = (
    updateBlockOrderRequest: UpdateBlockOrderRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/pages/update_block_order`,
      updateBlockOrderRequest,options
    );
  }



export const getUpdateBlockOrderRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBlockOrderRoute>>, TError,{data: UpdateBlockOrderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateBlockOrderRoute>>, TError,{data: UpdateBlockOrderRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBlockOrderRoute>>, {data: UpdateBlockOrderRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateBlockOrderRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateBlockOrderRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateBlockOrderRoute>>>
    export type UpdateBlockOrderRouteMutationBody = UpdateBlockOrderRequest
    export type UpdateBlockOrderRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Block Order Route
 */
export const useUpdateBlockOrderRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBlockOrderRoute>>, TError,{data: UpdateBlockOrderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateBlockOrderRoute>>,
        TError,
        {data: UpdateBlockOrderRequest},
        TContext
      > => {

      const mutationOptions = getUpdateBlockOrderRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Block Value Route
 */
export const updateBlockValueRoute = (
    updateBlockValueRequest: UpdateBlockValueRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/pages/update_block_value`,
      updateBlockValueRequest,options
    );
  }



export const getUpdateBlockValueRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBlockValueRoute>>, TError,{data: UpdateBlockValueRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateBlockValueRoute>>, TError,{data: UpdateBlockValueRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBlockValueRoute>>, {data: UpdateBlockValueRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateBlockValueRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateBlockValueRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateBlockValueRoute>>>
    export type UpdateBlockValueRouteMutationBody = UpdateBlockValueRequest
    export type UpdateBlockValueRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Block Value Route
 */
export const useUpdateBlockValueRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBlockValueRoute>>, TError,{data: UpdateBlockValueRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateBlockValueRoute>>,
        TError,
        {data: UpdateBlockValueRequest},
        TContext
      > => {

      const mutationOptions = getUpdateBlockValueRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Block Route
 */
export const deleteBlockRoute = (
    deleteBlockRequest: DeleteBlockRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/pages/delete_block`,
      deleteBlockRequest,options
    );
  }



export const getDeleteBlockRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBlockRoute>>, TError,{data: DeleteBlockRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteBlockRoute>>, TError,{data: DeleteBlockRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteBlockRoute>>, {data: DeleteBlockRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteBlockRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteBlockRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteBlockRoute>>>
    export type DeleteBlockRouteMutationBody = DeleteBlockRequest
    export type DeleteBlockRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Block Route
 */
export const useDeleteBlockRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBlockRoute>>, TError,{data: DeleteBlockRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteBlockRoute>>,
        TError,
        {data: DeleteBlockRequest},
        TContext
      > => {

      const mutationOptions = getDeleteBlockRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Block Metadata Route
 */
export const updateBlockMetadataRoute = (
    updateBlockMetadataRequest: UpdateBlockMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/pages/update_block_metadata`,
      updateBlockMetadataRequest,options
    );
  }



export const getUpdateBlockMetadataRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBlockMetadataRoute>>, TError,{data: UpdateBlockMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateBlockMetadataRoute>>, TError,{data: UpdateBlockMetadataRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBlockMetadataRoute>>, {data: UpdateBlockMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateBlockMetadataRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateBlockMetadataRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateBlockMetadataRoute>>>
    export type UpdateBlockMetadataRouteMutationBody = UpdateBlockMetadataRequest
    export type UpdateBlockMetadataRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Block Metadata Route
 */
export const useUpdateBlockMetadataRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBlockMetadataRoute>>, TError,{data: UpdateBlockMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateBlockMetadataRoute>>,
        TError,
        {data: UpdateBlockMetadataRequest},
        TContext
      > => {

      const mutationOptions = getUpdateBlockMetadataRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Block Category Route
 */
export const addBlockCategoryRoute = (
    addBlockCategoryRequest: AddBlockCategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/pages/add_block_category`,
      addBlockCategoryRequest,options
    );
  }



export const getAddBlockCategoryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addBlockCategoryRoute>>, TError,{data: AddBlockCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addBlockCategoryRoute>>, TError,{data: AddBlockCategoryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addBlockCategoryRoute>>, {data: AddBlockCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  addBlockCategoryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddBlockCategoryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addBlockCategoryRoute>>>
    export type AddBlockCategoryRouteMutationBody = AddBlockCategoryRequest
    export type AddBlockCategoryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Block Category Route
 */
export const useAddBlockCategoryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addBlockCategoryRoute>>, TError,{data: AddBlockCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addBlockCategoryRoute>>,
        TError,
        {data: AddBlockCategoryRequest},
        TContext
      > => {

      const mutationOptions = getAddBlockCategoryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rename Category Route
 */
export const renameCategoryRoute = (
    renameCategoryRequest: RenameCategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/pages/rename_category`,
      renameCategoryRequest,options
    );
  }



export const getRenameCategoryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameCategoryRoute>>, TError,{data: RenameCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameCategoryRoute>>, TError,{data: RenameCategoryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameCategoryRoute>>, {data: RenameCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  renameCategoryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameCategoryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof renameCategoryRoute>>>
    export type RenameCategoryRouteMutationBody = RenameCategoryRequest
    export type RenameCategoryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Category Route
 */
export const useRenameCategoryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameCategoryRoute>>, TError,{data: RenameCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof renameCategoryRoute>>,
        TError,
        {data: RenameCategoryRequest},
        TContext
      > => {

      const mutationOptions = getRenameCategoryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the latest version of a page.
 * @summary Get Page Latest Version Route
 */
export const getPageLatestVersionRoute = (
    pageId: PageId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MaterializedPage>> => {
    
    return axios.default.get(
      `/pages/get_latest/${pageId}`,options
    );
  }


export const getGetPageLatestVersionRouteQueryKey = (pageId: PageId,) => {
    return [`/pages/get_latest/${pageId}`] as const;
    }

    
export const getGetPageLatestVersionRouteQueryOptions = <TData = Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPageLatestVersionRouteQueryKey(pageId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPageLatestVersionRoute>>> = ({ signal }) => getPageLatestVersionRoute(pageId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(pageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPageLatestVersionRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getPageLatestVersionRoute>>>
export type GetPageLatestVersionRouteQueryError = AxiosError<HTTPValidationError>


export function useGetPageLatestVersionRoute<TData = Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPageLatestVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPageLatestVersionRoute<TData = Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPageLatestVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPageLatestVersionRoute<TData = Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Page Latest Version Route
 */

export function useGetPageLatestVersionRoute<TData = Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageLatestVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPageLatestVersionRouteQueryOptions(pageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a past version of a page.
 * @summary Get Page Past Version Route
 */
export const getPagePastVersionRoute = (
    params: GetPagePastVersionRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MaterializedPage>> => {
    
    return axios.default.get(
      `/pages/get_past_version`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetPagePastVersionRouteQueryKey = (params: GetPagePastVersionRouteParams,) => {
    return [`/pages/get_past_version`, ...(params ? [params]: [])] as const;
    }

    
export const getGetPagePastVersionRouteQueryOptions = <TData = Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(params: GetPagePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPagePastVersionRouteQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPagePastVersionRoute>>> = ({ signal }) => getPagePastVersionRoute(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPagePastVersionRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getPagePastVersionRoute>>>
export type GetPagePastVersionRouteQueryError = AxiosError<HTTPValidationError>


export function useGetPagePastVersionRoute<TData = Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetPagePastVersionRouteParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPagePastVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPagePastVersionRoute<TData = Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetPagePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPagePastVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPagePastVersionRoute<TData = Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetPagePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Page Past Version Route
 */

export function useGetPagePastVersionRoute<TData = Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetPagePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPagePastVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPagePastVersionRouteQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Ping
 */
export const ping = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.get(
      `/ping`,options
    );
  }


export const getPingQueryKey = () => {
    return [`/ping`] as const;
    }

    
export const getPingQueryOptions = <TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ping>>> = ({ signal }) => ping({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData> & { queryKey: QueryKey }
}

export type PingQueryResult = NonNullable<Awaited<ReturnType<typeof ping>>>
export type PingQueryError = AxiosError<unknown>


export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Ping
 */

export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




