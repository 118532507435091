import { useTabStore } from "@/contexts/tabs-context/tabs-context";
import type { SearchLibraryResult, Upload } from "@api/schemas";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

export const ResultWithinLibraryGroup = observer(
	({
		result,
		results,
		upload,
	}: {
		result: SearchLibraryResult;
		results: SearchLibraryResult[];
		upload: Upload;
	}) => {
		const tabStore = useTabStore();
		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const onSelect = useCallback(() => {
			const searchResult = {
				activeGroupedSearchResults: results.map((r) => {
					return {
						...r,
						upload,
					};
				}),
				activeSearchResult: {
					...result,
					upload,
				},
			};
			tabStore.createTabInActiveTabSet({
				path: "file",
				uploadId: upload.upload_id,
				searchResult,
			});
		}, [result, upload]);

		return (
			<div
				key={result.chunk_id}
				className={clsx("group flex cursor-pointer flex-col gap-2")}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<span className="whitespace-pre-wrap break-words text-neutral-600 text-sm leading-6">
					<mark className={clsx("bg-blue-50", "hover:bg-blue-100")}>
						{result.chunk_text}
					</mark>
				</span>
				<div className={clsx("text-neutral-600 text-xs")}>
					{result.chunk_page_indices.length > 1 ? (
						<>
							Pages {result.chunk_page_indices[0] + 1}-
							{result.chunk_page_indices[result.chunk_page_indices.length - 1] +
								1}
						</>
					) : (
						<>Page {result.chunk_page_indices[0] + 1}</>
					)}
				</div>
			</div>
		);
	},
);
