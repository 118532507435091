// import { Button } from "@/components/ui/button";
import {
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { webSearchConfigKey } from "@/contexts/tabs-context/tab-states/web-search-state";
import {
	type TabTypeStateMap,
	useTab,
} from "@/contexts/tabs-context/tabs-context";
// import { X } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";

interface WebSearchComboboxCommandListProps {
	setShowCommandList?: (show: boolean) => void;
	disabled?: boolean;
}

export const WebSearchComboboxCommandList = observer(
	({ setShowCommandList, disabled }: WebSearchComboboxCommandListProps) => {
		const tab = useTab();
		const webSearchState = tab.state as TabTypeStateMap["websearch"];
		const { query } = webSearchState.webSearchConfig;

		return (
			<CommandList className="max-h-full">
				{query && (
					<CommandGroup className="p-2">
						<CommandItem
							key={"current_search"}
							value={query}
							className="flex w-full items-center gap-2 rounded-md"
							onSelect={() => {
								if (disabled) return;
								if (!webSearchState.showCommandList) return;
								runInAction(() => {
									webSearchState.handleSearch();
								});
								setShowCommandList?.(false);
							}}
						>
							<div className="w-full min-w-0">
								<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
									Search for "{query}"
								</h3>
							</div>
						</CommandItem>
					</CommandGroup>
				)}

				<CommandGroup
					className="p-2"
					// heading={
					// 	<span className="flex items-baseline justify-between gap-2">
					// 		<h2 className="flex items-center gap-1">
					// 			<span className="text-xs">Recent searches</span>
					// 		</h2>{" "}
					// 		<Button
					// 			className="h-max rounded p-1 text-xs"
					// 			variant="ghost"
					// 			onClick={() => {
					// 				runInAction(() => {
					// 					searchContext.clearSearchHistory();
					// 				});
					// 			}}
					// 		>
					// 			Clear
					// 		</Button>
					// 	</span>
					// }
				>
					<CommandEmpty>No recent searches</CommandEmpty>
					{webSearchState.appState.webSearchStore.uniqueSearchHistory.map(
						(webSearch, idx) => {
							return (
								<CommandItem
									key={webSearchConfigKey(webSearch)}
									// we use the index to ensure that the value is unique,
									// otherwise hovering on one item will highlight all items with the same query
									// (but possibly different search modes)
									value={`${webSearch.query} ${idx}`}
									className="group flex w-full items-center gap-2 rounded-md"
									onSelect={() => {
										if (disabled) return;
										runInAction(() => {
											webSearchState.webSearchConfig = {
												query: webSearch.query,
											};
										});
										webSearchState.handleSearch();
										setShowCommandList?.(false);
									}}
								>
									<div className="w-full min-w-0">
										<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
											{webSearch.query}
										</h3>
									</div>
									{/* <button
										type="button"
										className="rounded-md p-2 opacity-0 hover:bg-neutral-200 group-hover:opacity-100"
										onClick={(e) => {
											e.stopPropagation();
											runInAction(() => {
												searchContext.removeSearchHistoryItem(search);
											});
										}}
									>
										<X size={16} />
									</button> */}
								</CommandItem>
							);
						},
					)}
				</CommandGroup>
			</CommandList>
		);
	},
);
