import type { AppState } from "@/contexts/app-context/app-context";
import type { FileId, WorkspaceUpdateEvent } from "@api/schemas";
import * as Sentry from "@sentry/react";
import { toast } from "sonner";

const MAX_RECONNECT_ATTEMPTS = 5;

export function attemptReconnect(this: AppState) {
	Sentry.captureMessage("WebSocket connection lost", "error");

	if (this.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
		console.error("Max reconnection attempts reached");
		toast.error("Unable to reconnect. Please refresh the page.");
		return;
	}
	toast.error("Connection lost. Reconnecting...");

	const delay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);

	this.reconnectTimeoutId = setTimeout(() => {
		this.reconnectAttempts++;
		this.init({
			isReconnect: true,
		});
	}, delay);
}

export function handleWorkspaceUpdate(
	this: AppState,
	update: WorkspaceUpdateEvent,
) {
	switch (update.type) {
		case "update_upload_status": {
			// update library
			const upload = this.workspace?.uploads.get(update.upload_id);
			if (upload) {
				upload.upload_status = update.status;
			}
			// if upload is in pending uploads, update that too
			const recentUpload = this.recentUploads.get(update.upload_id);
			if (recentUpload) {
				recentUpload.upload_status = update.status;
			}
			break;
		}

		case "upsert_uploads": {
			for (const upload of update.uploads) {
				// upsert upload in library
				this.workspace?.uploads.set(upload.upload_id, upload);
				this.uploadsFlexsearchIndex.add(upload);
				// if upload is in pending uploads, update that too
				const oldUpload = this.recentUploads.get(upload.upload_id);
				if (oldUpload) {
					this.recentUploads.set(upload.upload_id, upload);
				}
			}
			break;
		}

		case "upsert_folders": {
			for (const folder of update.folders) {
				this.workspace?.folders.set(folder.folder_id, folder);
			}
			break;
		}

		case "move_files": {
			for (const file_id of update.file_ids) {
				const file = this.files.get(file_id);
				if (file) {
					file.file_parent_id = update.new_parent_id;
				}
			}
			break;
		}

		case "delete_files": {
			for (const [file_id, deleted_at] of Object.entries(
				update.file_id_deleted_at_map,
			)) {
				const file = this.files.get(file_id as FileId);
				if (file) {
					file.file_deleted_at = deleted_at;

					switch (file.file_type) {
						case "upload":
							this.uploadsFlexsearchIndex.remove(file);
							break;
						case "feed_item":
							this.feedItemsFlexsearchIndex.remove(file);
							break;
					}
				}
			}
			break;
		}

		case "rename_file": {
			const file = this.files.get(update.file_id);
			if (file) {
				file.file_name = update.new_name;
			}
			break;
		}

		case "upsert_feed_channel": {
			const channel = update.feed_channel;
			this.workspace?.feedChannels.set(channel.feed_channel_id, channel);
			break;
		}

		case "update_feed_channel_refetching": {
			const channel = this.workspace?.feedChannels.get(update.feed_channel_id);
			if (channel) {
				channel.feed_channel_refetching = update.feed_channel_refetching;
			}
			break;
		}

		case "upsert_feed_items": {
			for (const item of update.feed_items) {
				this.workspace?.feedItems.set(item.feed_item_id, item);
				this.feedItemsFlexsearchIndex.add(item);
			}
			break;
		}

		case "upsert_table": {
			const table = update.table;
			this.workspace?.tables.set(table.table_id, table);
			break;
		}

		default: {
			const _exhaustiveCheck: never = update;
			return _exhaustiveCheck;
		}
	}
}
