import { BaseBlockRenderer } from "@/components/page/page-block-renderer";
import { PageCreationHeader } from "@/components/page/page-creation-header";
import type { BasePageBlockPropsU } from "@/components/page/page-generics";
import { ColumnTypeIcons } from "@/components/table/column-type-indicators";
import { Skeleton } from "@/components/ui/skeleton";
import {
	PageContext,
	usePageContext,
	usePagesContext,
} from "@/contexts/page-context/page-context";
import {
	type TabTypeStateMap,
	useTab,
} from "@/contexts/tabs-context/tabs-context";
import { CommonExtensionsWithoutHeading } from "@/lib/editor";
import type { ColumnType, MaterializedBlock, Page, PageId } from "@api/schemas";
import { X } from "@phosphor-icons/react";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

const DescriptionEditor = observer(
	({ block }: { block: MaterializedBlock }) => {
		const pageContext = usePageContext();
		const currentValue = block.block_metadata.column_description || "";
		const editor = useEditor({
			content: currentValue,
			extensions: [
				...CommonExtensionsWithoutHeading,
				Placeholder.configure({
					placeholder: "Add a description...",
					emptyEditorClass:
						"cursor-text before:content-[attr(data-placeholder)] before:absolute before:top-0 before:left-0 before:text-neutral-400 before-pointer-events-none",
				}),
			],
			onBlur: () => {
				updateCell();
			},
			editorProps: {
				attributes: {
					class: clsx("prose prose-sm outline-none focus:outline-none "),
				},
			},
		});

		const updateCell = () => {
			if (!editor) {
				return;
			}
			const newValue = editor.getHTML();
			if (newValue !== currentValue) {
				pageContext.updateBlockMetadata({
					blockId: block.block_id,
					blockMetadata: {
						...block.block_metadata,
						column_description: newValue,
					},
				});
			}
		};

		return (
			<div className="flex gap-1">
				<div className="w-24">
					<span className="h-fit w-fit rounded-full bg-neutral-100 px-2 py-1 text-neutral-500 text-xs">
						Description
					</span>
				</div>

				<div className="flex-1">
					<EditorContent editor={editor} />
				</div>
			</div>
		);
	},
);

export const PageView = observer(() => {
	const pageContext = usePageContext();
	const blocks = Array.from(pageContext.page.blocks.values());
	// Body block
	const bodyBlock = blocks.find(
		(block) => block.block_id === pageContext.page.bodyBlockId,
	);
	// All other blocks
	const otherBlocks = blocks.filter(
		(block) => block.block_id !== pageContext.page.bodyBlockId,
	);

	const RenderColumnIcon = (columnType: ColumnType) => {
		const ColumnIcon = ColumnTypeIcons[columnType];
		return <ColumnIcon className="shrink-0 text-neutral-500" />;
	};

	// Blocks in order
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col">
				{otherBlocks.map((block) => (
					<div
						key={block.block_id}
						className="flex flex-col gap-4 border-neutral-100 border-b p-4"
					>
						<span className="flex items-center gap-2">
							<span className="flex grow items-center gap-2">
								{RenderColumnIcon(block.block_metadata.column_type)}
								<span className="font-semibold text-neutral-700 text-sm">
									{block.block_metadata.column_name}
								</span>
							</span>

							<button
								type="button"
								onClick={() => {
									pageContext.deleteBlock({ blockId: block.block_id });
								}}
							>
								<X className="h-4 w-4 text-neutral-500" />
							</button>
						</span>

						<div className="flex flex-col gap-2">
							<DescriptionEditor block={block} />
							<div className="flex flex-row gap-1">
								<div className="w-24">
									<span className="h-fit w-fit rounded-full bg-neutral-100 px-2 py-1 text-neutral-500 text-xs">
										Value
									</span>
								</div>
								<div className="flex-1">
									<BaseBlockRenderer
										{...({
											blockId: block.block_id,
											blockType: block.block_metadata.column_type,
											blockMetadata: block.block_metadata,
											blockValue: block.block_value,
										} as BasePageBlockPropsU)}
									/>
								</div>
							</div>
						</div>
					</div>
				))}
				<div className="border-neutral-100 border-b p-4">
					<PageCreationHeader />
				</div>
			</div>

			{bodyBlock && (
				<div className="flex flex-col px-4 pb-24">
					<BaseBlockRenderer
						{...({
							blockId: bodyBlock.block_id,
							blockType: bodyBlock.block_metadata.column_type,
							blockMetadata: bodyBlock.block_metadata,
							blockValue: bodyBlock.block_value,
						} as BasePageBlockPropsU)}
					/>
				</div>
			)}
		</div>
	);
});

const LoadingState = () => {
	return (
		<div className="flex w-full flex-col space-y-2 p-4">
			{[...Array(3)].map((_, i) => (
				<div
					// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
					key={i}
					className="flex space-x-2"
				>
					<Skeleton className="h-3 grow" />
				</div>
			))}
		</div>
	);
};

export const _Page: React.FC<{
	pageId: PageId;
	pageMetadata: Page;
}> = observer(({ pageId, pageMetadata }) => {
	const pagesState = usePagesContext();
	const pageState = pagesState.getPage(pageId);

	if (!pageState) {
		return (
			<div className="flex flex-col">
				<div className="flex flex-col border-neutral-100 border-b p-4">
					<h1 className="font-semibold text-2xl text-neutral-800">
						{pageMetadata.file_name}
					</h1>
				</div>

				<LoadingState />
			</div>
		);
	}

	return (
		<div className="flex h-full min-h-0 flex-col overflow-y-auto">
			<PageContext.Provider value={pageState}>
				<div className="flex flex-col border-neutral-100 border-b p-4">
					<h1 className="font-semibold text-2xl text-neutral-800">
						{pageMetadata.file_name}
					</h1>
				</div>

				<PageView />
			</PageContext.Provider>
		</div>
	);
});

export const PagePage = observer(() => {
	const tab = useTab();

	const tabState = tab.state as TabTypeStateMap["page"];
	const appContext = tab.tabStore.appState;
	const pageMetadata = appContext.getPageById(tabState.pageNode.id);

	if (!pageMetadata) {
		return <div>Page not found</div>;
	}

	return <_Page pageId={tabState.pageNode.id} pageMetadata={pageMetadata} />;
});
