import type {
	MCellValue,
	MColumn,
	MRow,
} from "@/components/table/table-generics";
import { API_ENDPOINT_WS } from "@/config";
import {
	type AppState,
	useAppContext,
} from "@/contexts/app-context/app-context";
import { DisplayedActionError } from "@/contexts/synced-actions";
import {
	addColumnCategoryAction,
	changeCategoryColorAction,
	createColumnAction,
	createRowAction,
	deleteColumnAction,
	deleteRowsAction,
	moveColumnAction,
	moveRowsAction,
	removeColumnCategoryAction,
	renameColumnCategoryAction,
	resizeColumnAction,
	updateCellAction,
	updateColumnMetadataAction,
} from "@/contexts/table-context/table-handlers";
import {
	formatCellId,
	newColumnId,
	newRowId,
	newTableId,
} from "@/id-generators";
import { getTableLatestVersionRoute } from "@api/fastAPI";
import type {
	CellId,
	CellValue,
	ColumnId,
	ColumnMetadata,
	MaterializedColumn,
	MaterializedRow,
	PreviewComputedTableResponsePreview,
	ProxiedCellValue,
	RowId,
	RowMetadata,
	TableId,
	TableTransaction,
} from "@api/schemas";
import {
	type ColumnType,
	type DeleteColumn,
	type DeleteRows,
	type GetTableLatestVersionResponse,
	type MoveColumn,
	type MoveRows,
	TableDisconnectCode,
	type TableResponse,
	type UpdateCellValues,
	type UpdateColumnWidth,
	type UpdateProxiedCellValues,
	type UpsertColumn,
	type UpsertColumnMetadata,
	type UpsertRow,
	type UpsertRowMetadata,
} from "@api/schemas";
import type { useAuth } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import {
	generateJitteredKeyBetween,
	generateNJitteredKeysBetween,
} from "fractional-indexing-jittered";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import type { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const MAX_RECONNECT_ATTEMPTS = 5;

interface RootTableFields {
	tableId: TableId;
	rows: Map<RowId, MaterializedRow>;
	columns: Map<ColumnId, MaterializedColumn>;
	cellValues: Map<CellId, CellValue>;
	latestTransaction: TableTransaction;
	primaryColumnId: ColumnId;
}

interface TableLineageFields {
	parentTableIds: Set<TableId>;
	parentTableColumns: Map<TableId, Map<ColumnId, MaterializedColumn>>;
	childTableIds: Set<TableId>;
}

interface ProxiedTableFields {
	proxiedRowMetadata: Map<RowId, RowMetadata>;
	proxiedColumnMetadata: Map<ColumnId, ColumnMetadata>;
	proxiedCellValues: Map<CellId, CellValue>;
	deletedProxiedRowIds: Set<RowId>;
	deletedProxiedColumnIds: Set<ColumnId>;
}

class Table {
	constructor(
		public root: RootTableFields,
		public lineage: TableLineageFields,
		public proxied: ProxiedTableFields,
	) {
		makeAutoObservable(this);
	}

	static fromResponse(materializedTable: GetTableLatestVersionResponse) {
		const tableId = materializedTable.table.table_id;
		const rows = new Map(
			Object.entries(materializedTable.table.rows).map(([key, value]) => [
				key as RowId,
				value,
			]),
		);
		const proxiedRowMetadata = new Map(
			Object.entries(materializedTable.table.proxied_row_metadata).map(
				([key, value]) => [key as RowId, value],
			),
		);
		const columns = new Map(
			Object.entries(materializedTable.table.columns).map(([key, value]) => [
				key as ColumnId,
				value,
			]),
		);
		const proxiedColumnMetadata = new Map(
			Object.entries(materializedTable.table.proxied_column_metadata).map(
				([key, value]) => [key as ColumnId, value],
			),
		);
		const cellValues = new Map(
			Object.entries(materializedTable.table.cell_values).map(
				([cellId, cell]) => [cellId as CellId, cell],
			),
		);
		const proxiedCellValues = new Map(
			Object.entries(materializedTable.table.proxied_cell_values).map(
				([cellId, cell]) => [cellId as CellId, cell],
			),
		);
		const primaryColumnId = materializedTable.table.primary_column_id;
		const latestTransaction = materializedTable.table.latest_transaction;
		const parentTableIds = new Set(materializedTable.table.table_parent_ids);
		const childTableIds = new Set(materializedTable.table.table_child_ids);
		const parentTableColumns = new Map(
			Object.entries(materializedTable.parent_table_columns).map(
				([tableId, columns]) => [
					tableId as TableId,
					new Map(
						columns.map((column) => [column.column_id as ColumnId, column]),
					),
				],
			),
		);
		const deletedProxiedRowIds = new Set(
			materializedTable.table.deleted_proxied_row_ids,
		);
		const deletedProxiedColumnIds = new Set(
			materializedTable.table.deleted_proxied_column_ids,
		);

		return new Table(
			{
				tableId,
				rows,
				columns,
				cellValues,
				latestTransaction,
				primaryColumnId,
			},
			{
				parentTableIds,
				parentTableColumns,
				childTableIds,
			},
			{
				proxiedRowMetadata,
				proxiedColumnMetadata,
				proxiedCellValues,
				deletedProxiedRowIds,
				deletedProxiedColumnIds,
			},
		);
	}
}

export const renderComputedTable = ({
	baseTable,
	operation,
}: {
	baseTable: Table;
	operation: PreviewComputedTableResponsePreview;
}): TableState => {
	const tableId = newTableId();

	if (operation.operation_type === "filter_only") {
		const filteredRows = operation.filtered_row_ids;

		const proxiedRowMetadata: Map<RowId, RowMetadata> = new Map();
		const newProxiedRows: Map<RowId, MRow<"proxy">> = new Map();

		for (const [rowId, row] of baseTable.root.rows.entries()) {
			if (filteredRows.includes(rowId)) {
				const newId = newRowId();
				newProxiedRows.set(newId, {
					row_id: newId,
					row_metadata: {
						proxied_row_ids: [rowId],
						row_type: "proxy",
					},
					row_order: row.row_order,
				});
				proxiedRowMetadata.set(rowId, row.row_metadata);
			}
		}

		let newPrimaryColumnId: ColumnId | null = null;

		const proxiedColumnMetadata: Map<ColumnId, ColumnMetadata> = new Map();
		const newProxiedColumns: Map<ColumnId, MColumn<"proxy">> = new Map();

		for (const [columnId, column] of baseTable.root.columns.entries()) {
			const newId = newColumnId();
			newProxiedColumns.set(newId, {
				column_id: newId,
				column_metadata: {
					column_name: column.column_metadata.column_name,
					column_description: column.column_metadata.column_description,
					proxied_column_ids: [columnId],
					column_type: "proxy",
				},
				column_order: column.column_order,
				column_width: column.column_width,
			});
			if (columnId === baseTable.root.primaryColumnId) {
				newPrimaryColumnId = newId;
			}
			proxiedColumnMetadata.set(columnId, column.column_metadata);
		}

		if (!newPrimaryColumnId) {
			throw new Error("Primary column not found");
		}

		const newCellValues: Map<CellId, ProxiedCellValue> = new Map();
		for (const [rowId, newRow] of newProxiedRows.entries()) {
			for (const [columnId, newColumn] of newProxiedColumns.entries()) {
				const baseCellId = formatCellId({ rowId, columnId });

				newCellValues.set(baseCellId, {
					cell_value_type: "proxy",
					cell_value: {
						table_id: baseTable.root.tableId,
						table_row_id: newRow.row_metadata.proxied_row_ids[0],
						table_column_id: newColumn.column_metadata.proxied_column_ids[0],
					},
				});
			}
		}

		const newRoot: RootTableFields = {
			rows: newProxiedRows,
			columns: newProxiedColumns,
			cellValues: newCellValues,
			latestTransaction: baseTable.root.latestTransaction,
			primaryColumnId: newPrimaryColumnId,
			tableId,
		};
		const newLineage: TableLineageFields = {
			parentTableIds: new Set([baseTable.root.tableId]),
			parentTableColumns: new Map([
				[baseTable.root.tableId, baseTable.root.columns],
			]),
			childTableIds: new Set(),
		};
		const newProxied: ProxiedTableFields = {
			proxiedRowMetadata,
			proxiedColumnMetadata,
			proxiedCellValues: baseTable.root.cellValues,
			deletedProxiedRowIds: new Set(),
			deletedProxiedColumnIds: new Set(),
		};

		return new TableState({
			tableId,
			table: new Table(newRoot, newLineage, newProxied),
			navigate: () => {},
			editable: false,
		});
		// biome-ignore lint/style/noUselessElse: <explanation>
	} else if (operation.operation_type === "groupby") {
		const proxiedRowMetadata: Map<RowId, RowMetadata> = new Map();
		const newProxiedRows: Map<RowId, MRow<"group">> = new Map();
		const proxiedColumnMetadata: Map<ColumnId, ColumnMetadata> = new Map();
		const groupbyColumns: Map<ColumnId, MColumn<"groupby_key">> = new Map();
		const proxiedColumns: Map<ColumnId, MColumn<"proxy_group">> = new Map();

		const oldToNewColumnId = new Map<ColumnId, ColumnId>();

		// track the latest column order as we maintain the same order
		// for groupby and then proxied columns
		let lastColumnOrder = generateJitteredKeyBetween(null, null);

		// add groupby columns
		for (const groupbyColumnId of operation.groupby_column_ids) {
			const newId = newColumnId();
			const column = baseTable.root.columns.get(groupbyColumnId);
			if (!column) {
				throw new Error(`Column with ID ${groupbyColumnId} not found`);
			}
			groupbyColumns.set(newId, {
				column_id: newId,
				column_order: lastColumnOrder,
				column_metadata: {
					column_type: "groupby_key",
					proxied_column_id: groupbyColumnId,
					column_name: column.column_metadata.column_name,
					column_description: column.column_metadata.column_description,
				},
				column_width: column.column_width,
			});

			lastColumnOrder = generateJitteredKeyBetween(lastColumnOrder, null);
			proxiedColumnMetadata.set(groupbyColumnId, column.column_metadata);

			oldToNewColumnId.set(groupbyColumnId, newId);
		}

		let newPrimaryColumnId: ColumnId | null = null;

		// add proxied columns
		for (const proxiedColumnId of operation.proxied_column_ids) {
			const newId = newColumnId();
			const column = baseTable.root.columns.get(proxiedColumnId);
			if (!column) {
				throw new Error(`Column with ID ${proxiedColumnId} not found`);
			}
			proxiedColumns.set(newId, {
				column_id: newId,
				column_metadata: {
					column_name: column.column_metadata.column_name,
					column_description: column.column_metadata.column_description,
					proxied_column_ids: [proxiedColumnId as ColumnId],
					column_type: "proxy_group",
				},
				column_order: lastColumnOrder,
				column_width: column.column_width,
			});

			lastColumnOrder = generateJitteredKeyBetween(lastColumnOrder, null);
			proxiedColumnMetadata.set(proxiedColumnId, column.column_metadata);

			if (proxiedColumnId === baseTable.root.primaryColumnId) {
				newPrimaryColumnId = newId;
			}

			oldToNewColumnId.set(proxiedColumnId, newId);
		}

		if (!newPrimaryColumnId) {
			throw new Error("Primary column not found");
		}

		const newRowOrders = generateNJitteredKeysBetween(
			null,
			null,
			operation.groups.length,
		);

		const newKeyCellValues: Map<CellId, CellValue> = new Map();
		const newProxyGroupCellValues: Map<
			CellId,
			MCellValue<"proxy_group">
		> = new Map();

		// add rows (per group) and cells
		for (const [groupIndex, group] of operation.groups.entries()) {
			const rowId = newRowId();
			newProxiedRows.set(rowId, {
				row_id: rowId,
				row_metadata: {
					proxied_row_ids: group.proxied_row_ids,
					row_type: "group",
					// Fake values for now, these are not used in the UI
					group_key: [],
					group_key_hash: "",
				},
				row_order: newRowOrders[groupIndex],
			});
			for (const rowId of group.proxied_row_ids) {
				const row = baseTable.root.rows.get(rowId);
				if (!row) {
					throw new Error(`Row with ID ${rowId} not found`);
				}
				proxiedRowMetadata.set(rowId, row.row_metadata);
			}

			// insert key cells
			for (const groupbyColumnId of operation.groupby_column_ids) {
				const keyValue = group.key_values[groupbyColumnId];
				if (!keyValue) {
					continue;
				}

				const columnId = oldToNewColumnId.get(groupbyColumnId);
				if (!columnId) {
					throw new Error(`Column with ID ${groupbyColumnId} not found`);
				}

				const baseCellId = formatCellId({
					rowId,
					columnId,
				});
				newKeyCellValues.set(baseCellId, {
					cell_value: keyValue,
					cell_value_type: "groupby_key",
				});
			}

			// insert value cells
			for (const proxiedColumnId of operation.proxied_column_ids) {
				const columnId = oldToNewColumnId.get(proxiedColumnId);
				if (!columnId) {
					throw new Error(`Column with ID ${proxiedColumnId} not found`);
				}

				const baseCellId = formatCellId({
					rowId,
					columnId,
				});
				newProxyGroupCellValues.set(baseCellId, {
					cell_value_type: "proxy_group",
					cell_value: group.proxied_row_ids.map((rowId) => ({
						table_id: baseTable.root.tableId,
						table_row_id: rowId,
						table_column_id: proxiedColumnId,
					})),
				});
			}
		}

		const newRoot: RootTableFields = {
			rows: newProxiedRows,
			columns: new Map([
				...(groupbyColumns as Map<ColumnId, MaterializedColumn>),
				...(proxiedColumns as Map<ColumnId, MaterializedColumn>),
			]),
			cellValues: new Map([...newKeyCellValues, ...newProxyGroupCellValues]),
			latestTransaction: baseTable.root.latestTransaction,
			primaryColumnId: newPrimaryColumnId,
			tableId,
		};

		const newLineage: TableLineageFields = {
			parentTableIds: new Set([baseTable.root.tableId]),
			parentTableColumns: new Map([
				[baseTable.root.tableId, baseTable.root.columns],
			]),
			childTableIds: new Set(),
		};

		const newProxied: ProxiedTableFields = {
			proxiedRowMetadata,
			proxiedColumnMetadata,
			proxiedCellValues: baseTable.root.cellValues,
			// these can be empty for the preview
			deletedProxiedRowIds: new Set(),
			deletedProxiedColumnIds: new Set(),
		};

		return new TableState({
			tableId,
			table: new Table(newRoot, newLineage, newProxied),
			navigate: () => {},
			editable: false,
		});
	}
	throw new Error("Unimplemented");
};

export class TableState {
	tableId: TableId;
	table: Table;

	/*
	Enable debug mode
	*/
	devMode = false;

	/* 
	React hooks
	*/
	navigate: ReturnType<typeof useNavigate>;

	/* 
	Reconnect logic
	*/
	ws: WebSocket | null = null;
	isInitialized = false;
	reconnectTimer: Timer | null = null;
	wsConnected = false;
	reconnectAttempts = 0;

	editable: boolean;

	constructor(props: {
		tableId: TableId;
		table: Table;
		navigate: ReturnType<typeof useNavigate>;
		editable: boolean;
	}) {
		this.tableId = props.tableId;
		this.table = props.table;
		this.navigate = props.navigate;
		this.editable = props.editable;
		makeAutoObservable(this);
	}

	static fromResponse(props: {
		tableId: TableId;
		tableData: GetTableLatestVersionResponse;
		navigate: ReturnType<typeof useNavigate>;
		editable: boolean;
	}) {
		return new TableState({
			tableId: props.tableId,
			table: Table.fromResponse(props.tableData),
			navigate: props.navigate,
			editable: props.editable,
		});
	}

	checkEditable(this: TableState) {
		if (!this.editable) {
			throw new DisplayedActionError("Table is not editable");
		}
	}

	get isComputedTable() {
		return this.table.lineage.parentTableIds.size > 0;
	}

	get parentTableColumns() {
		if (this.table.lineage.parentTableColumns.size === 0) {
			return null;
		}
		if (this.table.lineage.parentTableColumns.size > 1) {
			throw new Error("Computed table has multiple parents");
		}
		return [...this.table.lineage.parentTableColumns.values()][0];
	}

	getParentColumnById(tableId: TableId, columnId: ColumnId) {
		const column = this.table.lineage.parentTableColumns
			.get(tableId)
			?.get(columnId);
		if (!column) {
			throw new Error(`Column with ID ${columnId} not found`);
		}
		return column;
	}

	getTableOfProxiedColumn(columnId: ColumnId) {
		for (const [tableId, columns] of this.table.lineage.parentTableColumns) {
			if (columns.has(columnId)) {
				return tableId;
			}
		}
		return null;
	}

	getColumnById(columnId: ColumnId) {
		const column = this.table.root.columns.get(columnId);
		if (!column) {
			throw new Error(`Column with ID ${columnId} not found`);
		}
		return column;
	}

	getProxiedColumnMetadataById(columnId: ColumnId) {
		const column = this.table.proxied.proxiedColumnMetadata.get(columnId);
		return column;
	}

	getRowById(rowId: RowId) {
		const row = this.table.root.rows.get(rowId);
		if (!row) {
			throw new Error(`Row with ID ${rowId} not found`);
		}
		return row;
	}

	getProxiedRowMetadataById(rowId: RowId) {
		const row = this.table.proxied.proxiedRowMetadata.get(rowId);
		return row;
	}

	getCellValue<T extends ColumnType>(
		rowId: RowId,
		columnId: ColumnId,
		cellValueType: T,
	): MCellValue<T> | null {
		const cellValue =
			this.table.root.cellValues.get(formatCellId({ rowId, columnId })) ?? null;

		if (cellValue === null) {
			return null;
		}

		if (cellValue.cell_value_type !== cellValueType) {
			Sentry.captureMessage(
				`Expected cell value type ${cellValueType} but got ${cellValue.cell_value_type}`,
				"error",
			);
			return null;
		}

		return cellValue as MCellValue<T>;
	}

	getProxiedCellValue<T extends ColumnType>(
		rowId: RowId,
		columnId: ColumnId,
		cellValueType: T,
	): MCellValue<T> | null {
		const cellValue =
			this.table.proxied.proxiedCellValues.get(
				formatCellId({ rowId, columnId }),
			) ?? null;

		if (cellValue === null) {
			return null;
		}

		if (cellValue.cell_value_type !== cellValueType) {
			Sentry.captureMessage(
				`Expected cell value type ${cellValueType} but got ${cellValue.cell_value_type}`,
				"error",
			);
			return null;
		}

		return cellValue as MCellValue<T>;
	}

	get sortedColumns() {
		return [...this.table.root.columns.values()].sort((a, b) => {
			if (a.column_order < b.column_order) {
				return -1;
			}
			if (a.column_order > b.column_order) {
				return 1;
			}
			return 0;
		});
	}

	get sortedRows() {
		return [...this.table.root.rows.values()]
			.filter((row) => {
				if (row.row_metadata.row_type === "proxy") {
					const proxiedRowIds = row.row_metadata.proxied_row_ids;
					if (
						proxiedRowIds.every((rowId) =>
							this.table.proxied.deletedProxiedRowIds.has(rowId),
						)
					) {
						return false;
					}
				}

				return true;
			})
			.sort((a, b) => {
				if (a.row_order < b.row_order) {
					return -1;
				}
				if (a.row_order > b.row_order) {
					return 1;
				}
				return 0;
			});
	}

	getRowBefore(rowId: RowId) {
		const row = this.table.root.rows.get(rowId);
		if (!row) {
			throw new Error(`Row with ID ${rowId} not found`);
		}
		const prevRow = this.sortedRows.findLast(
			(r) => r.row_order < row.row_order,
		);
		return prevRow;
	}

	getRowAfter(rowId: RowId) {
		const row = this.table.root.rows.get(rowId);
		if (!row) {
			throw new Error(`Row with ID ${rowId} not found`);
		}
		const nextRow = this.sortedRows.find((r) => r.row_order > row.row_order);
		return nextRow;
	}

	getColumnBefore(columnId: ColumnId) {
		const column = this.table.root.columns.get(columnId);
		if (!column) {
			throw new Error(`Column with ID ${columnId} not found`);
		}
		const prevColumn = this.sortedColumns.findLast(
			(c) => c.column_order < column.column_order,
		);
		return prevColumn;
	}

	getColumnAfter(columnId: ColumnId) {
		const column = this.table.root.columns.get(columnId);
		if (!column) {
			throw new Error(`Column with ID ${columnId} not found`);
		}
		const nextColumn = this.sortedColumns.find(
			(c) => c.column_order > column.column_order,
		);
		return nextColumn;
	}

	get lastColumn() {
		return this.sortedColumns.length
			? this.sortedColumns[this.sortedColumns.length - 1]
			: null;
	}

	get lastRow() {
		return this.sortedRows.length
			? this.sortedRows[this.sortedRows.length - 1]
			: null;
	}

	async init({
		isReconnect,
		getToken,
	}: {
		isReconnect?: boolean;
		getToken: ReturnType<typeof useAuth>["getToken"];
	}) {
		if (this.isInitialized) {
			return;
		}
		this.isInitialized = true;
		const token = await getToken();

		if (!token) {
			Sentry.captureMessage("No token found in TableContext", "error");
			toast.error("Unable to authenticate. Please refresh the page.");
			return;
		}

		const ws = new WebSocket(
			`${API_ENDPOINT_WS}/tables/${this.tableId}/ws?token=${token}`,
		);

		ws.onopen = () => {
			runInAction(() => {
				this.ws = ws;
				this.wsConnected = true;
				if (isReconnect) {
					this.reconnectAttempts = 0;
					toast.success("Reconnected to table.");
				}
			});
		};
		ws.onclose = (e) => {
			// 4040 is the code for "table deleted"
			if (e.code === TableDisconnectCode.NUMBER_4040) {
				this.navigate("/tables");
				toast.error("Table has been deleted.");
				return;
			}

			// 1000 is the code for "normal closure"
			if (e.code === 1000) {
				return;
			}

			runInAction(() => {
				this.wsConnected = false;
				this.ws = null;
			});

			this._attemptReconnect(getToken);
		};

		ws.onerror = (error) => {
			Sentry.captureException(error);
			this._attemptReconnect(getToken);
		};

		ws.onmessage = (event) => {
			try {
				const data: TableResponse = JSON.parse(event.data);
				this._handleWsResponse(data);
			} catch (e) {
				console.error("Error parsing websocket response JSON:", e);
				return;
			}
		};
	}

	private _attemptReconnect(getToken: ReturnType<typeof useAuth>["getToken"]) {
		Sentry.captureMessage("WebSocket connection lost", "error");

		if (this.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
			console.error("Max reconnection attempts reached");
			toast.error("Unable to reconnect. Please refresh the page.");
			return;
		}
		toast.error("Connection lost. Reconnecting...");

		const delay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);

		this.reconnectTimer = setTimeout(() => {
			this.reconnectAttempts++;
			this.init({
				isReconnect: true,
				getToken,
			});
		}, delay);
	}

	cleanup() {
		if (this.reconnectTimer !== null) {
			clearTimeout(this.reconnectTimer);
		}
		this.ws?.close(1000, "Client closed connection");
	}

	private _handleWsResponse(data: TableResponse) {
		switch (data.type) {
			case "upsert_row": {
				this.upsertRowLocally(data);
				break;
			}
			case "upsert_column": {
				this.upsertColumnLocally(data);
				break;
			}
			case "delete_column": {
				this.deleteColumnLocally(data);
				break;
			}
			case "delete_rows": {
				this.deleteRowsLocally(data);
				break;
			}
			case "update_cell_values": {
				this.updateCellValuesLocally(data);
				break;
			}
			case "upsert_column_metadata": {
				this.upsertColumnMetadata(data);
				break;
			}
			case "upsert_row_metadata": {
				this.upsertRowMetadata(data);
				break;
			}
			case "move_rows": {
				this.moveRowsLocally(data);
				break;
			}
			case "move_column": {
				this.moveColumnLocally(data);
				break;
			}
			case "update_column_width": {
				this.resizeColumnLocally(data);
				break;
			}
			case "update_proxied_cell_values": {
				this.updateProxiedCellValuesLocally(data);
				break;
			}
			default: {
				const _exhaustiveCheck: never = data;
				return _exhaustiveCheck;
			}
		}
	}

	upsertRowLocally(row: UpsertRow): void {
		const rowId = row.row.row_id;

		this.table.root.rows.set(rowId, row.row);
	}

	upsertColumnLocally(column: UpsertColumn): void {
		this.table.root.columns.set(column.column.column_id, column.column);
	}

	moveRowsLocally(data: MoveRows): void {
		for (const [rowId, rowOrder] of Object.entries(data.new_row_orders)) {
			const row = this.table.root.rows.get(rowId as RowId);
			if (!row) {
				continue;
			}
			row.row_order = rowOrder;
		}
	}

	moveColumnLocally(column: MoveColumn): void {
		const columnId = column.column_id;
		const newColumnOrder = column.new_column_order;
		const targetColumn = this.table.root.columns.get(columnId);
		if (!targetColumn) {
			return;
		}
		targetColumn.column_order = newColumnOrder;
	}

	resizeColumnLocally(data: UpdateColumnWidth): void {
		const column = this.table.root.columns.get(data.column_id);
		if (!column) {
			return;
		}
		column.column_width = data.new_width;
	}

	upsertColumnMetadata(data: UpsertColumnMetadata): void {
		const columnId = data.column_id;
		const column = this.table.root.columns.get(columnId);
		if (!column) {
			return;
		}
		column.column_metadata = data.column_metadata;
	}

	upsertRowMetadata(data: UpsertRowMetadata): void {
		const rowId = data.row_id;
		const row = this.table.root.rows.get(rowId);
		if (!row) {
			return;
		}
		row.row_metadata = data.row_metadata;
	}

	deleteColumnLocally(column: DeleteColumn): void {
		this.table.root.columns.delete(column.column_id);
	}

	deleteRowsLocally(rows: DeleteRows): void {
		for (const rowId of rows.row_ids) {
			this.table.root.rows.delete(rowId);
		}
	}

	updateCellValuesLocally(cells: UpdateCellValues): void {
		for (const cell of cells.cells) {
			const rowId = cell.row_id;
			const columnId = cell.column_id;
			this.table.root.cellValues.set(
				formatCellId({ rowId, columnId }),
				cell.cell_value,
			);
		}
	}

	updateProxiedCellValuesLocally(cells: UpdateProxiedCellValues): void {
		for (const cell of cells.cells) {
			const rowId = cell.row_id;
			const columnId = cell.column_id;
			this.table.proxied.proxiedCellValues.set(
				formatCellId({ rowId, columnId }),
				cell.cell_value,
			);
		}
	}

	createRow = createRowAction.bind(this);
	deleteRows = deleteRowsAction.bind(this);
	createColumn = createColumnAction.bind(this);
	deleteColumn = deleteColumnAction.bind(this);
	updateCell = updateCellAction.bind(this);
	updateColumnMetadata = updateColumnMetadataAction.bind(this);
	addColumnCategory = addColumnCategoryAction.bind(this);
	renameColumnCategory = renameColumnCategoryAction.bind(this);
	removeColumnCategory = removeColumnCategoryAction.bind(this);
	changeCategoryColor = changeCategoryColorAction.bind(this);
	moveRows = moveRowsAction.bind(this);
	moveColumn = moveColumnAction.bind(this);
	resizeColumn = resizeColumnAction.bind(this);
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const TableContext = createContext<TableState>(null as any);

export const useTableContext = () => {
	const context = useContext(TableContext);
	if (!context) {
		throw new Error("TableContext must be used within a TableProvider");
	}
	return context;
};

export const useTablesContext = () => {
	const appContext = useAppContext();
	if (!appContext) {
		throw new Error("useTableContext must be used within an AppProvider");
	}

	return appContext.tablesState;
};

export class TablesState {
	appState: AppState;
	tables: Map<TableId, TableState> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	getTable(tableId: TableId): TableState | null {
		if (!this.tables.has(tableId)) {
			// Fetch and initialize the table
			this.fetchTable(tableId);
			return null;
		}
		return this.tables.get(tableId) as TableState;
	}

	async fetchTable(tableId: TableId) {
		// Fetch and initialize the table
		const tableData = await getTableLatestVersionRoute(tableId);
		const tableState = TableState.fromResponse({
			tableId,
			tableData: tableData.data,
			navigate: this.appState.tabStore.navigate as ReturnType<
				typeof useNavigate
			>,
			editable: true,
		});
		this.tables.set(tableId, tableState);
		tableState.init({
			isReconnect: false,
			getToken: this.appState.getToken,
		});
	}
}
