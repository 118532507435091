import type { AppState } from "@/contexts/app-context/app-context";
import type { FilePathObject } from "@/paths";
import type { File, FileId, FolderId } from "@api/schemas";

export const FEED_CHANNELS_FOLDER_ID = "FEED_CHANNELS" as FolderId;

export type FileType = File["file_type"];
type FileTypeMap = {
	[K in FileType]: Extract<File, { file_type: K }>;
};
export type AnyDirectoryNode = DirectoryNode<keyof FileTypeMap>;

const READONLY_FILE_TYPES: Set<FileType> = new Set([
	"feed_channel",
	"feed_item",
]);

const READONLY_FILE_IDS: Set<FileId> = new Set([FEED_CHANNELS_FOLDER_ID]);

export function fileIsReadonly(file: File): boolean {
	return (
		READONLY_FILE_TYPES.has(file.file_type) ||
		READONLY_FILE_IDS.has(file.file_id)
	);
}

// Options
// This only stores files. Depending on the type of the file, it will have different data.
export class DirectoryNode<T extends keyof FileTypeMap> {
	id: FileTypeMap[T]["file_id"];
	file: FileTypeMap[T];
	parent: AnyDirectoryNode | null;
	children: AnyDirectoryNode[];

	constructor(
		node_id: FileTypeMap[T]["file_id"],
		file: FileTypeMap[T],
		parent: AnyDirectoryNode | null,
		children: AnyDirectoryNode[],
	) {
		this.id = node_id;
		this.file = file;
		this.parent = parent;
		this.children = children;
	}

	/**
	 * Returns the node's ancestors from the root to this node.
	 */
	get ancestors(): AnyDirectoryNode[] {
		const path: AnyDirectoryNode[] = [];
		let currentNode: AnyDirectoryNode | null = this;
		while (currentNode) {
			path.unshift(currentNode);
			currentNode = currentNode.parent;
		}
		return path;
	}

	/**
	 * Returns all descendants of this node, including this node.
	 */
	get descendants(): AnyDirectoryNode[] {
		const ids: AnyDirectoryNode[] = [this];
		const stack: AnyDirectoryNode[] = [...this.children];
		while (stack.length > 0) {
			const current = stack.pop();
			if (current) {
				ids.push(current);
				stack.push(...current.children);
			}
		}

		return ids;
	}

	get pathObject(): FilePathObject {
		return {
			path: "file",
			fileId: this.id,
		};
	}
}

/**
 * Constructs a tree of all files using directory nodes, then returns a map of
 * all nodes and the root nodes.
 */
export function fileNodeTree(this: AppState): {
	nodeMap: Map<FileId, AnyDirectoryNode>;
	rootNodes: AnyDirectoryNode[];
} {
	// First pass: create all nodes in the map
	const nodeMap: Map<FileId, AnyDirectoryNode> = new Map(
		Array.from(this.files.entries()).map(([fileId, file]) => [
			fileId,
			new DirectoryNode(fileId, file, null, []),
		]),
	);

	// Second pass: add children to each node
	// Also collect root nodes
	const rootNodes: AnyDirectoryNode[] = [];
	for (const node of nodeMap.values()) {
		if (node.file.file_parent_id === null) {
			rootNodes.push(node);
			continue;
		}
		const parentNode = nodeMap.get(node.file.file_parent_id);
		if (!parentNode) {
			console.warn(
				`Parent node not found for ${node.file.file_name} (${node.id}). Should never happen.`,
			);
			continue;
		}
		node.parent = parentNode;
		parentNode.children.push(node);
	}
	return { nodeMap, rootNodes };
}
