import type { TableCellProps } from "@/components/table/table-generics";
import { observer } from "mobx-react-lite";

export const ProxiedGroupCell = observer(
	({ cellValue }: TableCellProps<"proxy_group">) => {
		return (
			<div className="flex p-1 text-neutral-500 text-sm">
				{cellValue?.cell_value.length} elements
			</div>
		);
	},
);
