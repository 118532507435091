import { PageErrorBoundaryFallback } from "@/components/page-error-boundary-fallback";
import { FilePage } from "@/pages/file/file-page";
import { ManageFeeds } from "@/pages/manage-feeds";
import { ManageTables } from "@/pages/manage-tables";
import { SearchPage } from "@/pages/search/search-page";
import { WebSearchPage } from "@/pages/web-search/web-search-page";
import type { TabPathObject } from "@/paths";
import { pathObjectToString } from "@/paths";
import { ErrorBoundary } from "@sentry/react";
import { Outlet, createMemoryRouter } from "react-router-dom";

export const createTabRouter = (initialPathObject: TabPathObject) => {
	return createMemoryRouter(
		[
			{
				path: "/",
				element: <Outlet />,
				errorElement: (
					<ErrorBoundary
						fallback={(props) => <PageErrorBoundaryFallback {...props} />}
						showDialog
					/>
				),
				children: [
					{
						path: "search",
						element: <SearchPage />,
					},
					{
						path: "manage-tables",
						element: <ManageTables />,
					},
					{
						path: "manage-feeds",
						element: <ManageFeeds />,
					},
					{
						path: "file/:fileId?",
						element: <FilePage />,
					},
					{
						path: "web-search",
						element: <WebSearchPage />,
					},
				],
			},
		],
		{
			initialEntries: [
				{
					pathname: pathObjectToString(initialPathObject),
					state:
						"searchResult" in initialPathObject
							? initialPathObject.searchResult
							: undefined,
				},
			],
		},
	);
};
