import { ColumnType } from "@api/schemas";
import {
	Calendar,
	CaretCircleDown,
	CheckSquare,
	Clock,
	File,
	Hash,
	type IconProps,
	Link,
	TextAa,
} from "@phosphor-icons/react";

export const ColumnTypeIcons: {
	[key in ColumnType]: (props: IconProps) => JSX.Element;
} = {
	[ColumnType.text]: (props: IconProps) => <TextAa {...props} />,
	[ColumnType.document]: (props: IconProps) => <File {...props} />,
	[ColumnType.boolean]: (props: IconProps) => <CheckSquare {...props} />,
	[ColumnType.category]: (props: IconProps) => <CaretCircleDown {...props} />,
	[ColumnType.date]: (props: IconProps) => <Calendar {...props} />,
	[ColumnType.datetime]: (props: IconProps) => <Clock {...props} />,
	[ColumnType.number]: (props: IconProps) => <Hash {...props} />,
	[ColumnType.proxy]: (props: IconProps) => <Link {...props} />,
	[ColumnType.proxy_group]: (props: IconProps) => <Link {...props} />,
	[ColumnType.groupby_key]: (props: IconProps) => <Link {...props} />,
};

export const ColumnTypeLabels: {
	[key in ColumnType]: string;
} = {
	[ColumnType.text]: "Text",
	[ColumnType.document]: "Document",
	[ColumnType.boolean]: "Checkbox",
	[ColumnType.category]: "Select",
	[ColumnType.date]: "Date",
	[ColumnType.datetime]: "Date and time",
	[ColumnType.number]: "Number",
	[ColumnType.proxy]: "Rollup",
	[ColumnType.proxy_group]: "Rollup",
	[ColumnType.groupby_key]: "Group by",
};
