import * as React from "react";

import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
} from "@/components/ui/dialog";

// import { FeedSelector } from "@/components/FeedSelector";
import { Favicon } from "@/components/favicon";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandInputWithBadge,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "@/components/ui/command";
import { DialogClose } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAppContext } from "@/contexts/app-context/app-context";
import { CommandKPage } from "@/contexts/app-context/cmd-k";
import { useTabStore } from "@/contexts/tabs-context/tabs-context";
import { cleanXmlText } from "@/lib/formatting";
import { guessRssUrl, previewRssFeed } from "@api/fastAPI";
import type { PreviewRssFeedResponse } from "@api/schemas";
import { useAuth } from "@clerk/clerk-react";
import {
	ArrowUpRight,
	Book,
	Books,
	Folder,
	Gear,
	Info,
	// LightbulbFilament,
	MagnifyingGlass,
	Plus,
	// PuzzlePiece,
	Rss,
	Spinner,
	Table,
	Warning,
} from "@phosphor-icons/react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { toast } from "sonner";

export const CommandKButton = observer(() => {
	const appContext = useAppContext();
	const setCmdKOpen = (open: boolean) => {
		runInAction(() => {
			appContext.cmdKOpen = open;
		});
	};

	return (
		<Button
			variant={"outline"}
			className="gap-1 text-neutral-600"
			onClick={() => setCmdKOpen(true)}
		>
			<span className="font-medium text-sm">Command bar</span>{" "}
			<kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-medium font-mono text-[10px] text-muted-foreground opacity-100">
				<span className="text-xs">⌘</span>K
			</kbd>
		</Button>
	);
});

const AddFeedDialog = observer(() => {
	const appContext = useAppContext();
	const [url, setUrl] = useState("");
	const [rssUrl, setRssUrl] = useState("");

	const [guessFailed, setGuessFailed] = useState(false);

	const [previewChannelResponse, setPreviewChannelResponse] =
		useState<PreviewRssFeedResponse | null>(null);

	const [loadingPreview, setLoadingPreview] = useState(false);

	return (
		<Dialog
			open={appContext.showAddFeedDialog}
			onOpenChange={(open) => {
				runInAction(() => {
					appContext.showAddFeedDialog = open;
				});
			}}
		>
			<DialogContent>
				<DialogTitle>Add Feed</DialogTitle>
				<DialogDescription className="text-neutral-500">
					Enter the URL of the publication you want to add. We'll try to find
					the associated RSS feed.
				</DialogDescription>

				<Input
					type="text"
					placeholder="Publication URL"
					value={url}
					onChange={(e) => {
						setUrl(e.target.value);
					}}
				/>
				<div>
					<Label
						className={clsx(
							"font-normal",
							guessFailed ? "text-red-500" : "text-neutral-500",
						)}
					>
						{guessFailed
							? "We couldn't find an RSS endpoint. Please enter it directly."
							: "Alternatively, enter the RSS URL directly."}
					</Label>
					<Input
						type="text"
						placeholder="RSS URL"
						value={rssUrl}
						onChange={(e) => {
							setRssUrl(e.target.value);
						}}
					/>
				</div>

				<Button
					disabled={loadingPreview}
					variant="default"
					onClick={() => {
						setLoadingPreview(true);

						if (rssUrl) {
							previewRssFeed({ rss_url: rssUrl, url })
								.then((res) => {
									setPreviewChannelResponse(res.data);
									setLoadingPreview(false);
								})
								.catch(() => {
									toast.error("Could not retrieve RSS feed");
									setLoadingPreview(false);
								});
						} else {
							if (!url) {
								toast.error("Please enter at least one URL");
								setLoadingPreview(false);
								return;
							}

							guessRssUrl({ url: url })
								.then((urlRes) => {
									previewRssFeed({ rss_url: urlRes.data, url })
										.then((res) => {
											setPreviewChannelResponse(res.data);
											setLoadingPreview(false);
										})
										.catch(() => {
											toast.error("Could not retrieve RSS feed");
											setLoadingPreview(false);
										});
								})
								.catch(() => {
									toast.error("Failed to guess RSS URL");
									setLoadingPreview(false);
									setGuessFailed(true);
								});
						}
					}}
					className="flex items-center gap-1"
				>
					{loadingPreview ? (
						<>
							<Spinner className="animate-spin" />
							Loading...
						</>
					) : (
						"Preview feed"
					)}
				</Button>

				{previewChannelResponse && (
					<div
						className={clsx(
							"mt-4 min-w-0 truncate",
							loadingPreview && "animate-pulse",
						)}
					>
						{previewChannelResponse.existing_feeds.length > 0 && (
							<Alert variant="warning">
								<Warning weight="bold" className="shrink-0 text-xl" />
								<AlertTitle>Feed already added</AlertTitle>
								<AlertDescription>
									This channel is already indexed in your feeds.
								</AlertDescription>
							</Alert>
						)}
						<div className="mt-4 flex min-w-0 flex-col overflow-hidden truncate rounded-md border text-sm">
							<div className="flex items-center gap-2 border-b p-2">
								{previewChannelResponse.feed_preview.feed_channel_link ? (
									<Favicon
										url={previewChannelResponse.feed_preview.feed_channel_link}
										alt={cleanXmlText(
											previewChannelResponse.feed_preview.feed_channel_title,
										)}
										className="h-10 w-10 shrink-0 rounded-md bg-neutral-200"
									/>
								) : (
									<div className="h-10 w-10 shrink-0 rounded-md bg-neutral-200" />
								)}
								<div className="w-full min-w-0 truncate">
									<h2 className="font-semibold">
										{cleanXmlText(
											previewChannelResponse.feed_preview.feed_channel_title,
										)}
									</h2>
									<h3 className="w-full min-w-0 truncate text-neutral-500">
										{cleanXmlText(
											previewChannelResponse.feed_preview
												.feed_channel_subtitle ?? "No description",
										)}
									</h3>
								</div>
							</div>
							{previewChannelResponse.feed_preview.items ? (
								<div className="flex max-h-64 w-full flex-col gap-0.5 overflow-y-scroll p-2">
									{previewChannelResponse.feed_preview.items.map((item) => (
										<div key={item.feed_item_guid} className="p-1">
											<h4 className="flex min-w-0 items-center gap-1 truncate font-semibold">
												<span className="min-w-0 truncate">
													{cleanXmlText(item.feed_item_title ?? "")}
												</span>
												<a
													href={item.feed_item_link}
													target="_blank"
													rel="noreferrer"
													className="shrink-0 text-neutral-600 hover:text-neutral-900"
												>
													<ArrowUpRight weight="bold" />
												</a>
											</h4>
											<p className="min-w-0 truncate text-neutral-500">
												{cleanXmlText(item.feed_item_description ?? "")}
											</p>
										</div>
									))}
								</div>
							) : (
								<div>No items</div>
							)}
						</div>
					</div>
				)}
				{previewChannelResponse && (
					<div className="flex justify-end gap-2">
						<Button
							variant="ghost"
							onClick={() => {
								setUrl("");
								setRssUrl("");
								setPreviewChannelResponse(null);
							}}
						>
							Reset
						</Button>

						<DialogClose asChild>
							<Button
								className="w-full"
								disabled={loadingPreview}
								onClick={() => {
									appContext.addFeedChannel({
										feedChannelPreview: previewChannelResponse.feed_preview,
									});
									toast.success(
										`${previewChannelResponse.feed_preview.feed_channel_title} added to feeds`,
									);
								}}
							>
								Confirm and add feed
							</Button>
						</DialogClose>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
});

export const CommandKDialog = observer(() => {
	const appContext = useAppContext();
	const feedChannels = appContext.sortedFeedChannels;
	const toggleOpen = () => {
		runInAction(() => {
			appContext.cmdKOpen = !appContext.cmdKOpen;
		});
	};
	const [value, setValue] = React.useState("");

	const tabStore = useTabStore();
	const { signOut } = useAuth();

	const fileSearchResults = appContext.searchFiles(value);
	const mergedSearchResults = React.useMemo(() => {
		return [...fileSearchResults.uploads, ...fileSearchResults.feedItems];
	}, [fileSearchResults]);

	const onSelectSearch = () => {
		const newUrlSearchParams = new URLSearchParams();
		newUrlSearchParams.set("q", value);
		newUrlSearchParams.set("mode", "hybrid");
		newUrlSearchParams.set("upload_ids", "");
		newUrlSearchParams.set("group_results_by_upload", "false");
		// TODO(John): make URL params for search work
		// navigate(`/search?${newUrlSearchParams.toString().replace(/%20/g, "+")}`);
		tabStore.navigate({
			path: "search",
		});
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	React.useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				toggleOpen();
			}
		};

		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, []);

	return (
		<>
			<AddFeedDialog />
			<Dialog
				open={appContext.cmdKOpen}
				onOpenChange={(open) => appContext.setCmdKOpen(open)}
			>
				<DialogContent className="overflow-hidden p-0">
					<VisuallyHidden.Root>
						<DialogTitle>Command K</DialogTitle>
						<DialogDescription>Press Esc to close Command K</DialogDescription>
					</VisuallyHidden.Root>
					<Command
						className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-neutral-500 dark:[&_[cmdk-group-heading]]:text-neutral-400 [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5"
						onKeyDown={(e) => {
							// Escape goes to previous page
							// Backspace goes to previous page when search is empty
							if (e.key === "Escape" || (e.key === "Backspace" && !value)) {
								e.preventDefault();
								appContext.popCmdKPage();
							}
						}}
					>
						{appContext.lastCmdKPage === "RSS" ? (
							<>
								<CommandInputWithBadge
									placeholder="Type the name or URL of an RSS feed..."
									value={value}
									onValueChange={setValue}
								/>
								<CommandList>
									<CommandGroup heading="">
										{value && (
											<CommandItem
												onSelect={() => {
													runInAction(() => {
														appContext.showAddFeedDialog = true;
													});
												}}
												value={value}
											>
												<Plus className="mr-2 h-4 w-4" />
												<span>Add a feed</span>
											</CommandItem>
										)}
									</CommandGroup>
									<CommandGroup heading="Feeds">
										{feedChannels?.map((channel) => (
											<CommandItem
												key={channel.feed_channel_id}
												onSelect={() => {
													tabStore.navigate({
														path: "file",
														fileId: channel.feed_channel_id,
													});
													appContext.setCmdKOpen(false);
												}}
											>
												{channel.feed_channel_link ? (
													<Favicon
														url={channel.feed_channel_link}
														alt={channel.file_name}
														className="h-6 w-6 shrink-0 rounded-md bg-neutral-200"
													/>
												) : (
													<div className="h-6 w-6 shrink-0 rounded-md bg-neutral-300" />
												)}
												<span className="ml-2">{channel.file_name}</span>
											</CommandItem>
										))}
									</CommandGroup>
								</CommandList>
							</>
						) : (
							<></>
						)}

						{!appContext.lastCmdKPage ? (
							<>
								<CommandInput
									placeholder="Type a command or search..."
									value={value}
									onValueChange={setValue}
								/>
								<CommandList>
									<CommandEmpty>No results found.</CommandEmpty>
									<CommandGroup heading="Files">
										{mergedSearchResults.map((file) => {
											const getFileIcon = () => {
												if ("upload_id" in file) {
													return <Book className="mr-2 h-4 w-4" />;
												}
												if ("table_id" in file) {
													return <Table className="mr-2 h-4 w-4" />;
												}
												if ("folder_id" in file) {
													return <Folder className="mr-2 h-4 w-4" />;
												}
												if ("feed_item_id" in file) {
													return <Rss className="mr-2 h-4 w-4" />;
												}
												if ("feed_channel_id" in file) {
													return <Rss className="mr-2 h-4 w-4" />;
												}
											};

											const getSelectionHandler = () => {
												return () =>
													tabStore.navigate({
														path: "file",
														fileId: file.file_id,
													});
											};

											return (
												<CommandItem
													key={file.file_id}
													onSelect={() => {
														getSelectionHandler()();
														appContext.setCmdKOpen(false);
													}}
												>
													{getFileIcon()}
													{file.file_name}
												</CommandItem>
											);
										})}
										{mergedSearchResults.length === 0 && (
											<div className="py-1 text-center text-neutral-500 text-sm">
												No results found.
											</div>
										)}
									</CommandGroup>
									<CommandSeparator />
									<CommandGroup heading="Actions">
										<CommandItem
											onSelect={() => {
												appContext.pushCmdKPage(CommandKPage.RSS);
											}}
										>
											<Rss className="mr-2 h-4 w-4" />
											<span>Manage RSS Feeds</span>
										</CommandItem>
										<CommandItem
											onSelect={() => {
												if (value) {
													onSelectSearch();
												} else {
													tabStore.navigate({
														path: "search",
													});
												}
												appContext.setCmdKOpen(false);
											}}
										>
											<MagnifyingGlass className="mr-2 h-4 w-4" />
											<span>{value ? `Search \"${value}\"` : "Search"}</span>
										</CommandItem>
									</CommandGroup>
									<CommandSeparator />

									<CommandGroup heading="Navigation">
										<CommandItem
											onSelect={() => {
												tabStore.navigate({
													path: "search",
												});
												appContext.setCmdKOpen(false);
											}}
										>
											<MagnifyingGlass className="mr-2 h-4 w-4" />
											<span>Navigate to Search</span>
										</CommandItem>
										<CommandItem
											onSelect={() => {
												tabStore.navigate({
													path: "file",
													fileId: null,
												});
												appContext.setCmdKOpen(false);
											}}
										>
											<Books className="mr-2 h-4 w-4" />
											<span>Navigate to Library</span>
										</CommandItem>
										<CommandItem
											onSelect={() => {
												window.open(
													"https://vllg.notion.site/How-does-the-research-assistant-work-77ae2b1dc43a48a69ce3260e68d3b4e8",
													"_blank",
												);
											}}
										>
											<Info className="mr-2 h-4 w-4" />
											<span>How does the research assistant work?</span>
										</CommandItem>
										<CommandItem
											onSelect={() => {
												window.open(
													"https://vllg.notion.site/How-does-the-search-page-work-f24c59a827474449ab320860314d6019?pvs=25",
													"_blank",
												);
											}}
										>
											<Info className="mr-2 h-4 w-4" />
											<span>How does the search page work?</span>
										</CommandItem>
									</CommandGroup>
									<CommandSeparator />
									<CommandGroup heading="Settings">
										<CommandItem
											onSelect={() => {
												signOut();
											}}
										>
											<Gear className="mr-2 h-4 w-4 text-red-700" />

											<span className="text-red-700">Sign out</span>
										</CommandItem>
									</CommandGroup>
								</CommandList>
							</>
						) : (
							<></>
						)}
					</Command>
				</DialogContent>
			</Dialog>
		</>
	);
});
