import {
	ColumnTypeIcons,
	ColumnTypeLabels,
} from "@/components/table/column-type-indicators";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { useTableContext } from "@/contexts/table-context/table-context";
import {
	type ColumnMetadata,
	ColumnType,
	type MaterializedColumn,
} from "@api/schemas";
import { CaretUpDown, Plus } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { toast } from "sonner";

const ProxyColumnSelector = observer(
	({
		setProxiedColumn,
		setColumnType,
	}: {
		proxiedColumn: MaterializedColumn | null;
		setProxiedColumn: (value: MaterializedColumn | null) => void;
		setColumnType: (value: ColumnType) => void;
	}) => {
		const tableContext = useTableContext();

		if (!tableContext.parentTableColumns) {
			return null;
		}

		const sortedColumns = [...tableContext.parentTableColumns.values()].sort(
			(a, b) => {
				return a.column_order.localeCompare(b.column_order);
			},
		);

		return (
			<DropdownMenuPortal>
				<DropdownMenuSubContent>
					{sortedColumns.map((column) => {
						return (
							<DropdownMenuItem
								key={column.column_id}
								onSelect={() => {
									setProxiedColumn(column);
									setColumnType(ColumnType.proxy);
								}}
							>
								<div className="flex items-center gap-3">
									{ColumnTypeIcons[column.column_metadata.column_type]({})}
									{column.column_metadata.column_name}
								</div>
							</DropdownMenuItem>
						);
					})}
				</DropdownMenuSubContent>
			</DropdownMenuPortal>
		);
	},
);

export const ColumnCreationHeader = observer(() => {
	const tableContext = useTableContext();
	const [columnName, setColumnName] = useState("");
	const [columnType, setColumnType] = useState<ColumnType>(ColumnType.text);
	const [columnDescription, setColumnDescription] = useState("");

	const [proxiedColumn, setProxiedColumn] = useState<MaterializedColumn | null>(
		null,
	);

	const [open, setOpen] = useState(false);

	return (
		<div className="flex items-center gap-2 p-0.5">
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<button
						type="button"
						className="rounded-md p-1.5 hover:bg-neutral-100"
					>
						<Plus className="text-lg " />
					</button>
				</PopoverTrigger>
				<PopoverContent align="start" className="w-64 p-0">
					<section className="px-2 py-1">
						<Label>Add a column</Label>
					</section>

					<Separator />

					<section className="flex flex-col px-2 pt-2">
						<Label>Name</Label>
						<Input
							className="mt-2 h-8"
							value={columnName}
							onChange={(e) => setColumnName(e.target.value)}
						/>
					</section>

					<section className="flex flex-col px-2 pt-2">
						<Label>Type</Label>
						<DropdownMenu>
							<DropdownMenuTrigger className="mt-2 flex w-full items-center rounded-md border px-2 py-1">
								{columnType ? (
									<div className="flex grow items-center">
										{(() => {
											const IconComponent = ColumnTypeIcons[columnType];
											return <IconComponent className="mr-2" />;
										})()}
										<span className="text-sm">
											{ColumnTypeLabels[columnType]}
										</span>
									</div>
								) : (
									<SelectValue placeholder="Select..." />
								)}
								<CaretUpDown />
							</DropdownMenuTrigger>
							<DropdownMenuContent className="max-w-72">
								<DropdownMenuItem
									onSelect={() => setColumnType(ColumnType.text)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.text]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Text</h2>
											<span className="text-neutral-500">
												A simple text field that you directly input data into.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setColumnType(ColumnType.document)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.document]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Document</h2>
											<span className="text-neutral-500">
												A reference to an upload or article in your workspace.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setColumnType(ColumnType.category)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.category]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Select</h2>
											<span className="text-neutral-500">
												An option you can select from a list. Helpful for
												categorizing data.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setColumnType(ColumnType.boolean)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.boolean]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Checkbox</h2>
											<span className="text-neutral-500">
												Indicate if an item matches a condition.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setColumnType(ColumnType.date)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.date]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Date</h2>
											<span className="text-neutral-500">
												A specific date. Useful for deadlines or events.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setColumnType(ColumnType.datetime)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.datetime]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Date + time</h2>
											<span className="text-neutral-500">
												A specific date and time. Useful for deadlines or
												events.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								<DropdownMenuItem
									onSelect={() => setColumnType(ColumnType.number)}
								>
									<div className="flex items-center gap-3">
										{ColumnTypeIcons[ColumnType.number]({
											className: "shrink-0 text-2xl text-neutral-700",
										})}
										<div>
											<h2>Number</h2>
											<span className="text-neutral-500">
												A number. Useful for quantities or measurements.
											</span>
										</div>
									</div>
								</DropdownMenuItem>
								{tableContext.table.lineage.parentTableIds.size > 0 ? (
									<DropdownMenuSub>
										<DropdownMenuSubTrigger>
											<div className="flex items-center gap-3">
												{ColumnTypeIcons[ColumnType.proxy]({
													className: "shrink-0 text-2xl text-neutral-700",
												})}
												<div>
													<h2>Reference</h2>
													<span className="text-neutral-500">
														Sync a column from the parent table.
													</span>
												</div>
											</div>
										</DropdownMenuSubTrigger>
										<ProxyColumnSelector
											proxiedColumn={proxiedColumn}
											setProxiedColumn={setProxiedColumn}
											setColumnType={setColumnType}
										/>
									</DropdownMenuSub>
								) : null}
							</DropdownMenuContent>
						</DropdownMenu>
					</section>

					{columnType === ColumnType.proxy && proxiedColumn ? (
						<section className="px-2 pt-3">
							<Label className="text-neutral-500">Referencing</Label>
							<div className="flex items-center gap-2 rounded-md border bg-neutral-100 px-2 py-1 text-sm">
								{ColumnTypeIcons[proxiedColumn.column_metadata.column_type]({})}
								{proxiedColumn.column_metadata.column_name}
							</div>
						</section>
					) : null}

					<section className="px-2 pt-3 pb-2">
						<Button
							className="w-full"
							onClick={() => {
								let columnMetadata: ColumnMetadata;
								if (columnType === ColumnType.proxy) {
									if (!proxiedColumn) {
										toast.error("Please select a rollup column.");
										return;
									}
									columnMetadata = {
										column_type: columnType,
										proxied_column_ids: [proxiedColumn.column_id],
										column_name: columnName,
										column_description: columnDescription,
									};
								} else if (columnType === ColumnType.proxy_group) {
									throw new Error("Rollup group columns are not supported.");
								} else if (columnType === ColumnType.groupby_key) {
									throw new Error("Groupby key columns are not supported.");
								} else if (columnType === ColumnType.category) {
									columnMetadata = {
										column_type: columnType,
										categories: {},
										column_name: columnName,
										column_description: columnDescription,
									};
								} else {
									columnMetadata = {
										column_type: columnType,
										column_name: columnName,
										column_description: columnDescription,
									};
								}
								if (!columnMetadata.column_name) {
									toast.error("Please enter a label.");
									return;
								}
								// TODO(John): deal with descriptions
								tableContext.createColumn({
									columnMetadata,
								});
								setColumnName("");
								setColumnDescription("");
								setColumnType(ColumnType.text);
								setProxiedColumn(null);
								setOpen(false);
							}}
						>
							Add column
						</Button>
					</section>
				</PopoverContent>
			</Popover>
		</div>
	);
});
