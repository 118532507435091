import { MetadataEditDialogContent } from "@/components/metadata-edit-dialog";
import type { TreeState } from "@/components/tree/tree-state";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuSeparator,
} from "@/components/ui/context-menu";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAppContext } from "@/contexts/app-context/app-context";
import { FileUploadDialogContent } from "@/pages/folder/file-upload-dialog-content";
import type { File, Upload } from "@api/schemas";
import {
	DownloadSimple,
	PencilSimple,
	Trash,
	UploadSimple,
} from "@phosphor-icons/react";
import { ContextMenuTrigger } from "@radix-ui/react-context-menu";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const RenameFileDialog = observer(
	({
		onOpenChange,
		file,
	}: {
		onOpenChange: (open: boolean) => void;
		file: File;
	}) => {
		const appContext = useAppContext();
		const [fileName, setFileName] = useState(file.file_name);

		return (
			<Dialog open onOpenChange={onOpenChange}>
				<DialogContent
					// Is there a cleaner way to prevent the dialog from affecting the tree?
					onClick={(e) => {
						e.stopPropagation();
					}}
					onSelect={(e) => {
						e.stopPropagation();
					}}
					onKeyDown={(e) => {
						e.stopPropagation();
					}}
				>
					<DialogHeader>
						<DialogTitle>Edit folder</DialogTitle>
					</DialogHeader>
					<div className="grid grid-cols-4 items-center gap-4">
						<Label htmlFor="folderName" className="text-right text-neutral-900">
							Name
						</Label>
						<Input
							id="fileName"
							value={fileName}
							className="col-span-3"
							type="text"
							onChange={(e) => setFileName(e.currentTarget.value)}
						/>
					</div>
					<div className="flex justify-end">
						<DialogClose
							className="mt-4 rounded-md bg-black px-8 py-2 text-white"
							onClick={() => {
								appContext.renameFile({
									fileId: file.file_id,
									newName: fileName,
								});
							}}
						>
							Apply changes
						</DialogClose>
					</div>
				</DialogContent>
			</Dialog>
		);
	},
);

const DeleteFilesDialog = observer(
	({
		onOpenChange,
		files,
	}: {
		onOpenChange: (open: boolean) => void;
		files: File[];
	}) => {
		const appContext = useAppContext();

		let alertTitle: string;
		let alertDescription: string;

		if (files.length === 1) {
			switch (files[0].file_type) {
				case "folder":
					alertTitle = "Delete folder?";
					alertDescription =
						"This will delete the folder and all its contents.";
					break;
				case "upload":
					alertTitle = "Delete upload?";
					alertDescription = "This will delete the upload.";
					break;
				default:
					alertTitle = "Delete file?";
					alertDescription = "This will delete the file.";
			}
		} else {
			alertTitle = "Delete files?";
			alertDescription = "This will delete the selected files.";
		}

		return (
			<AlertDialog open onOpenChange={onOpenChange}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>{alertTitle}</AlertDialogTitle>
						<AlertDialogDescription>{alertDescription}</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancel</AlertDialogCancel>
						<AlertDialogAction
							onClick={() => {
								appContext.deleteFiles({
									fileIds: files.map((file) => file.file_id),
								});
							}}
						>
							Continue
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		);
	},
);

const EditUploadMetaDialog = observer(
	({
		onOpenChange,
		upload,
	}: {
		onOpenChange: (open: boolean) => void;
		upload: Upload;
	}) => {
		return (
			<Dialog open onOpenChange={onOpenChange}>
				<MetadataEditDialogContent uploadId={upload.upload_id} />
			</Dialog>
		);
	},
);

const UploadToParentDialog = observer(
	({
		onOpenChange,
		parent,
	}: {
		onOpenChange: (open: boolean) => void;
		parent: File;
	}) => {
		return (
			<Dialog open onOpenChange={onOpenChange}>
				<FileUploadDialogContent parent={parent} />
			</Dialog>
		);
	},
);

export const ContextMenuActions = observer(
	({
		treeState,
	}: {
		treeState: TreeState;
	}) => {
		const appContext = useAppContext();
		const rightClickedNode = treeState.rightClickedNode;
		const selectedNodes = Array.from(treeState.selectedNodes.values());
		const [openDialog, setOpenDialog] = useState<
			| { type: "renameFile"; props: { file: File } }
			| { type: "deleteFiles"; props: { files: File[] } }
			| { type: "editUploadMeta"; props: { upload: Upload } }
			| { type: "uploadToParent"; props: { parent: File } }
			| null
		>(null);
		if (!rightClickedNode) {
			return null;
		}
		const file = rightClickedNode.file;
		const onOpenChangeHandler = (open: boolean) => {
			if (!open) {
				setOpenDialog(null);
				treeState.setRightClickedNode(null);
			}
		};
		let openDialogComponent: React.ReactNode | null = null;
		switch (openDialog?.type) {
			case "renameFile":
				openDialogComponent = (
					<RenameFileDialog
						file={openDialog.props.file}
						onOpenChange={onOpenChangeHandler}
					/>
				);
				break;

			case "deleteFiles":
				openDialogComponent = (
					<DeleteFilesDialog
						files={openDialog.props.files}
						onOpenChange={onOpenChangeHandler}
					/>
				);
				break;

			case "editUploadMeta":
				openDialogComponent = (
					<EditUploadMetaDialog
						upload={openDialog.props.upload}
						onOpenChange={onOpenChangeHandler}
					/>
				);
				break;

			case "uploadToParent":
				openDialogComponent = (
					<UploadToParentDialog
						parent={openDialog.props.parent}
						onOpenChange={onOpenChangeHandler}
					/>
				);
				break;

			case undefined:
				break;

			default: {
				const _exhaustiveCheck: never = openDialog;
				openDialogComponent = _exhaustiveCheck;
			}
		}

		return (
			<>
				<ContextMenuContent>
					<ContextMenuItem
						onClick={() => {
							setOpenDialog({
								type: "renameFile",
								props: {
									file: file,
								},
							});
						}}
						className="flex items-center gap-2"
					>
						<PencilSimple weight="bold" /> Rename file
					</ContextMenuItem>
					<ContextMenuItem
						className="flex items-center gap-2"
						onClick={() => {
							setOpenDialog({
								type: "uploadToParent",
								props: {
									parent: file,
								},
							});
						}}
					>
						<UploadSimple weight="bold" />
						Upload to file
					</ContextMenuItem>
					{file.file_type === "upload" && (
						<>
							<ContextMenuItem
								onClick={() => {
									setOpenDialog({
										type: "editUploadMeta",
										props: {
											upload: file,
										},
									});
								}}
								className="flex items-center gap-2"
							>
								<PencilSimple weight="bold" /> Edit upload metadata
							</ContextMenuItem>
							<ContextMenuItem
								className="flex items-center gap-2"
								onClick={() => {
									appContext.downloadUploadPdf(file.upload_id);
								}}
							>
								<DownloadSimple weight="bold" /> Processed PDF
							</ContextMenuItem>
							<ContextMenuItem
								className="flex items-center gap-2"
								onClick={() => {
									appContext.downloadOriginalUploadFile(file.upload_id);
								}}
							>
								<DownloadSimple weight="bold" /> Original{" "}
								{file.upload_filetype.toUpperCase()}
							</ContextMenuItem>
						</>
					)}
					<ContextMenuSeparator />
					{treeState.selectedNodes.has(rightClickedNode.id) &&
					selectedNodes.length > 1 ? (
						<ContextMenuItem
							onClick={() => {
								setOpenDialog({
									type: "deleteFiles",
									props: { files: selectedNodes.map((node) => node.file) },
								});
							}}
							className="flex items-center gap-2"
						>
							<Trash weight="bold" />
							Delete {selectedNodes.length} selected
						</ContextMenuItem>
					) : (
						<ContextMenuItem
							onClick={() => {
								setOpenDialog({
									type: "deleteFiles",
									props: {
										files: [file],
									},
								});
							}}
							className="flex items-center gap-2"
						>
							<Trash weight="bold" />
							Delete file
						</ContextMenuItem>
					)}
				</ContextMenuContent>
				{openDialogComponent}
			</>
		);
	},
);

export const FolderTreeContextMenuWrapper = observer(
	(props: { children: React.ReactNode; treeState: TreeState }) => {
		return (
			<ContextMenu>
				<ContextMenuTrigger>{props.children}</ContextMenuTrigger>
				<ContextMenuActions treeState={props.treeState} />
			</ContextMenu>
		);
	},
);
