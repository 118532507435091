/**
 * An ObjectLink is a link within a message that references an object in our
 * workspace.
 */
import { Button } from "@/components/ui/button";
import { useAppContext } from "@/contexts/app-context/app-context";
import { TabIconMap, useTabStore } from "@/contexts/tabs-context/tabs-context";
import { cn } from "@/lib/utils";
import {
	type PathObject,
	pathObjectToString,
	pathStringToObject,
} from "@/paths";
import { CustomReactRenderer } from "@/plugins/custom-react-renderer";
import {
	FloatingPortal,
	type VirtualElement,
	autoUpdate,
	useFloating,
} from "@floating-ui/react";
import { Chat, Eyeglasses, House, type Icon } from "@phosphor-icons/react";
import Link, { type LinkOptions } from "@tiptap/extension-link";
import Mention, {
	type MentionOptions as BaseMentionOptions,
	type MentionNodeAttrs,
} from "@tiptap/extension-mention";
import {
	type NodeViewProps,
	NodeViewWrapper,
	ReactNodeViewRenderer,
	mergeAttributes,
} from "@tiptap/react";
import { observer } from "mobx-react-lite";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";

// John: Did these type inferences as an exercise. They work, trust.
type SuggestionItemType = {
	id: string;
	label: string;
};
type MentionOptions = BaseMentionOptions<SuggestionItemType, MentionNodeAttrs>;
type SuggestionOptions = MentionOptions["suggestion"];
type SuggestionProps = Parameters<
	NonNullable<ReturnType<NonNullable<SuggestionOptions["render"]>>["onStart"]>
>[0];

interface ObjectListRef {
	setReference: (element: VirtualElement | null) => void;
	onKeyDown: (event: KeyboardEvent) => boolean;
}

const ObjectList = observer<SuggestionProps, ObjectListRef>(
	forwardRef((props, ref) => {
		const { refs, floatingStyles } = useFloating({
			placement: "top-start",
			whileElementsMounted: autoUpdate,
		});
		const [highlightedIndex, setHighlightedIndex] = useState<number>(0);

		const selectHandler = (index: number) => {
			props.command({
				id: props.items[index].id,
				label: props.items[index].label,
			});
		};

		useImperativeHandle(ref, () => ({
			setReference: refs.setReference,
			onKeyDown: (event: KeyboardEvent) => {
				if (event.key === "ArrowUp") {
					setHighlightedIndex(Math.max(0, highlightedIndex - 1));
					return true;
				}
				if (event.key === "ArrowDown") {
					setHighlightedIndex(
						Math.min(props.items.length - 1, highlightedIndex + 1),
					);
					return true;
				}
				if (event.key === "Enter") {
					selectHandler(highlightedIndex);
					return true;
				}
				return false;
			},
		}));

		// TODO(John): why does the scroll happen first, then the highlight
		// move?
		const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);
		useEffect(() => {
			itemRefs.current[highlightedIndex]?.scrollIntoView({
				block: "nearest",
				inline: "nearest",
				behavior: "instant",
			});
		}, [highlightedIndex]);

		return (
			<FloatingPortal>
				<div
					ref={refs.setFloating}
					style={floatingStyles}
					className="max-h-48 w-96 overflow-y-scroll border bg-white"
				>
					{props.items.length > 0 ? (
						props.items.map((item, index) => (
							<Button
								// biome-ignore lint/suspicious/noArrayIndexKey: we want the button to re-render when the index changes. I guess I could use the id to select though...
								key={index}
								ref={(el) => {
									itemRefs.current[index] = el;
								}}
								onClick={() => selectHandler(index)}
								variant="ghost"
								// Truncate only works on block elements
								data-highlighted={index === highlightedIndex}
								className="block h-fit w-full truncate rounded-none p-1 text-left font-normal text-sm data-[highlighted=true]:bg-neutral-100"
							>
								{item.label}
							</Button>
						))
					) : (
						<div className="p-2 text-neutral-500 text-sm">No objects found</div>
					)}
				</div>
			</FloatingPortal>
		);
	}),
);

export const getSuggestion = (
	items: SuggestionOptions["items"],
): SuggestionOptions => ({
	char: "++",
	allowSpaces: true,
	items: items,
	render: () => {
		let component: CustomReactRenderer<ObjectListRef, SuggestionProps> | null =
			null;
		let virtualElement: VirtualElement | null = null;

		return {
			onStart: (props) => {
				component = new CustomReactRenderer(ObjectList, {
					props,
					editor: props.editor,
				});

				if (!props.clientRect) {
					console.error("No client rect function.");
					return;
				}
				const clientRect = props.clientRect();
				if (!clientRect) {
					console.error("No client rect.");
					return;
				}
				virtualElement = {
					getBoundingClientRect: () => clientRect,
				};
				if (!component.ref) {
					console.error("No component ref.");
					return;
				}
				component.ref.setReference(virtualElement);
			},
			onUpdate(props) {
				// biome-ignore lint/style/noNonNullAssertion: component is set in onStart
				component = component!;
				// biome-ignore lint/style/noNonNullAssertion: component.ref is set in onStart
				component.ref = component.ref!;
				// biome-ignore lint/style/noNonNullAssertion: virtualElement is set in onStart
				virtualElement = virtualElement!;

				component.updateProps(props);
				if (!props.clientRect) {
					console.error("No client rect function.");
					return;
				}
				const clientRect = props.clientRect();
				if (!clientRect) {
					console.error("No client rect.");
					return;
				}
				virtualElement.getBoundingClientRect = () => clientRect;
				component.ref.setReference(virtualElement);
			},
			onKeyDown(props) {
				// biome-ignore lint/style/noNonNullAssertion: component is set in onStart
				component = component!;
				// biome-ignore lint/style/noNonNullAssertion: component.ref is set in onStart
				component.ref = component.ref!;
				return component.ref.onKeyDown(props.event);
			},
			onExit() {
				// biome-ignore lint/style/noNonNullAssertion: component is set in onStart
				component = component!;
				component.destroy();
			},
		};
	},
});

type ObjectLinkComponentProps = {
	pathObject: PathObject;
	className?: string;
	children: React.ReactNode;
};

export const ObjectLinkComponent = observer(
	({ pathObject, className, children }: ObjectLinkComponentProps) => {
		const appContext = useAppContext();
		const tabStore = useTabStore();

		let handler: (event: React.MouseEvent<HTMLAnchorElement>) => void;

		switch (pathObject.path) {
			case "message": {
				handler = (event) => {
					event.preventDefault();
					appContext.rightSidebarState.navigateMessages(pathObject.messageId);
				};
				break;
			}

			case "assistant-session": {
				handler = (event) => {
					event.preventDefault();
					appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
						pathObject.sessionAssistantId;
					appContext.rightSidebarState.rightSidebarTab = "assistant_activity";
				};
				break;
			}

			default: {
				handler = (event) => {
					event.preventDefault();
					tabStore.navigate(pathObject);
				};
			}
		}
		return (
			<a
				href={pathObjectToString(pathObject)}
				onClick={handler}
				className={className}
			>
				{children}
			</a>
		);
	},
);

type ObjectLinkContentProps = {
	pathObject: PathObject;
	label?: string;
	showIcon?: boolean;
	className?: string;
};

export const ObjectLinkContent = observer(
	({
		pathObject,
		label,
		showIcon = true,
		className,
	}: ObjectLinkContentProps) => {
		const appContext = useAppContext();
		let IconComponent: Icon | null = null;

		let defaultLabel: string;

		// TODO(John): unify with the tab default name
		switch (pathObject.path) {
			case "message": {
				IconComponent = Chat;
				defaultLabel = "Message";
				break;
			}

			case "assistant-session": {
				IconComponent = Eyeglasses;
				defaultLabel = `Session ${pathObject.sessionAssistantId.slice(-4)}`;
				break;
			}

			case "manage-feeds": {
				IconComponent = TabIconMap.manage_feeds;
				defaultLabel = "Manage Feeds";
				break;
			}

			case "manage-tables": {
				IconComponent = TabIconMap.manage_tables;
				defaultLabel = "Manage Tables";
				break;
			}

			case "web-search": {
				IconComponent = TabIconMap.websearch;
				defaultLabel = "Web Search";
				break;
			}

			// TODO(John): deal with search queries/states
			case "search": {
				IconComponent = TabIconMap.search;
				defaultLabel = "Search";
				break;
			}

			case "file": {
				const fileId =
					"fileId" in pathObject ? pathObject.fileId : pathObject.uploadId;
				if (fileId === null) {
					IconComponent = House;
					defaultLabel = "My Files";
					break;
				}

				const file = appContext.files.get(fileId);
				if (file === undefined) {
					defaultLabel = "Unknown File";
					label = defaultLabel;
					break;
				}

				defaultLabel = file.file_name;
				IconComponent = TabIconMap[file.file_type];
				break;
			}
		}

		return (
			<span
				className={cn(
					"inline-flex min-w-0 max-w-full items-center gap-1 rounded-sm border border-neutral-300 bg-neutral-100 px-0.5",
					className,
				)}
			>
				{showIcon && IconComponent && (
					<IconComponent
						size={12}
						weight="fill"
						className="flex-none text-neutral-400"
					/>
				)}
				<span className="min-w-0 truncate">{label}</span>
			</span>
		);
	},
);

const ObjectLinkNodeView = (props: NodeViewProps) => {
	const pathObject = pathStringToObject(props.node.attrs.id);
	if (pathObject instanceof Error) {
		console.error("Invalid path object", props.node.attrs.id);
		return null;
	}
	return (
		<NodeViewWrapper as="span">
			<ObjectLinkComponent pathObject={pathObject}>
				<ObjectLinkContent
					pathObject={pathObject}
					label={props.node.attrs.label}
				/>
			</ObjectLinkComponent>
		</NodeViewWrapper>
	);
};

export const ObjectLink = Mention.extend({
	priority: 1002, // Higher priority than Link (1000) to process the nodes first
	addAttributes() {
		return {
			id: {
				default: null,
				parseHTML: (element) => element.getAttribute("href"),
				// TODO(John): fix this attribute handling
				renderHTML: (attributes) => {
					if (!attributes.id) {
						return {};
					}
					return {
						href: attributes.id,
					};
				},
			},
			label: {
				default: null,
				parseHTML: (element) => element.innerText,
				renderHTML: (attributes) => {
					if (!attributes.label) {
						return {};
					}
					return {};
				},
			},
		};
	},
	parseHTML() {
		return [
			{
				tag: `a[href^="/"]`,
			},
		];
	},
	renderHTML({ node, HTMLAttributes }) {
		return ["a", mergeAttributes(HTMLAttributes), node.attrs.label];
	},
	renderText({ node }) {
		return node.attrs.label;
	},
	addNodeView() {
		return ReactNodeViewRenderer(ObjectLinkNodeView);
	},
});

export const getObjectLinkExtension = (
	search: (query: string) => SuggestionItemType[],
) => {
	return ObjectLink.configure({
		suggestion: getSuggestion(({ query }) => search(query)),
	});
};

// From the Link extension.
// From DOMPurify
// https://github.com/cure53/DOMPurify/blob/main/src/regexp.js
// eslint-disable-next-line no-control-regex
const ATTR_WHITESPACE =
	// biome-ignore lint/suspicious/noControlCharactersInRegex: <explanation>
	/[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g;
function isAllowedUri(
	uri: string | undefined,
	protocols?: LinkOptions["protocols"],
) {
	const allowedProtocols: string[] = [
		"http",
		"https",
		"ftp",
		"ftps",
		"mailto",
		"tel",
		"callto",
		"sms",
		"cid",
		"xmpp",
	];

	if (protocols) {
		for (const protocol of protocols) {
			const nextProtocol =
				typeof protocol === "string" ? protocol : protocol.scheme;

			if (nextProtocol) {
				allowedProtocols.push(nextProtocol);
			}
		}
	}

	return (
		!uri ||
		uri
			.replace(ATTR_WHITESPACE, "")
			.match(
				new RegExp(
					`^(?:(?:${allowedProtocols.join("|")}):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))`,
					"i",
				),
			)
	);
}

export const CustomLink = Link.extend({
	parseHTML() {
		return [
			{
				tag: "a[href]:not([href^='/'])",
				getAttrs: (dom) => {
					const href = (dom as HTMLElement).getAttribute("href");

					// prevent XSS attacks
					if (!href || !isAllowedUri(href, this.options.protocols)) {
						return false;
					}
					return null;
				},
			},
		];
	},
});
