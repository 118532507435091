import { Button } from "@/components/ui/button";
import type { FileType } from "@/contexts/app-context/tree-handlers";
import {
	type TabTypeStateMap,
	useTab,
} from "@/contexts/tabs-context/tabs-context";
import { FeedChannelPage } from "@/pages/feed-channel-page";
import { FolderPage } from "@/pages/folder/folder-page";
import { PagePage } from "@/pages/page/page";
import { TablePage } from "@/pages/table-page";
import { UploadPage } from "@/pages/upload-page";
import { observer } from "mobx-react-lite";

// implement switch here between different pages
// the pages modify the tab state
export const FilePage = observer(() => {
	const tab = useTab();
	// Only rendered on file matches
	const tabState = tab.state as TabTypeStateMap[FileType] | null;
	if (!tabState) {
		return (
			<div className="flex h-full flex-col items-center justify-center gap-4">
				<h1>File not found.</h1>
				<Button onClick={() => tab.navigate({ path: "file", fileId: null })}>
					Go Home
				</Button>
			</div>
		);
	}
	switch (tabState.type) {
		case "upload":
			return <UploadPage />;
		case "folder":
			return <FolderPage />;
		case "table":
			return <TablePage />;
		// TODO(John): link these to the proper component
		case "feed_channel":
			return <FeedChannelPage />;
		case "feed_item":
			// Render the same component, but the page should read what item is
			// open based on the tab state
			return <FeedChannelPage />;
		case "page":
			return <PagePage />;
		default: {
			const _exhaustiveCheck: never = tabState;
			return _exhaustiveCheck;
		}
	}
});
