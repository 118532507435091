/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Enumeration of possible table column types.
 */
export type ColumnType = typeof ColumnType[keyof typeof ColumnType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ColumnType = {
  text: 'text',
  document: 'document',
  number: 'number',
  boolean: 'boolean',
  date: 'date',
  datetime: 'datetime',
  category: 'category',
  proxy: 'proxy',
  proxy_group: 'proxy_group',
  groupby_key: 'groupby_key',
} as const;
