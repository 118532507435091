import type { AppState } from "@/contexts/app-context/app-context";
import type { SearchLibraryResultWithUpload } from "@/contexts/tabs-context/tab-states/search-state";
import type { Upload, UploadId } from "@api/schemas";

export type SearchResult = {
	activeGroupedSearchResults: SearchLibraryResultWithUpload[];
	activeSearchResult: SearchLibraryResultWithUpload;
};

export class UploadState {
	type = "upload" as const;
	appState: AppState;

	uploadId: UploadId;
	searchResult: SearchResult | null;

	constructor(
		appState: AppState,
		uploadId: UploadId,
		searchResult: SearchResult | null,
	) {
		this.appState = appState;
		this.uploadId = uploadId;
		this.searchResult = searchResult;
	}

	get upload(): Upload {
		const upload = this.appState.getUploadById(this.uploadId);
		if (!upload) {
			throw new Error("Upload not found");
		}
		return upload;
	}
}
